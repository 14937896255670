/* eslint-disable */
import React, { useEffect } from "react";
import "./sourceOfMeetBanner.scss";
// localizations
import { withTranslation, useTranslation } from "react-i18next";
// import Redux
import { useDispatch, useSelector } from "react-redux";
// owl carasoul work start
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// owl carasoul work end
import Button from "react-bootstrap/Button";
import CloseIcon from "../../layout-one/js/assets/images/closeBtn.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import sourceOfMeet from "../../layout-one/js/assets/images/sourceOfMeet.jpeg"
function SourceOfMeetBanner(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [somImages, setSomImages] = React.useState([])
  const handleClose = () => {
    appDispatch({ type: "setSourceOfMeetBanner", sourceOfMeetBannerImage: false });
    setOpen(false);
  };

  const onLoad = (i) => {
    if (i === somImages.length - 1) {
      setOpen(true);
    }
  };

  useEffect(() => {
    const getImages = async () => {
      await setSomImages([...somImages, sourceOfMeet])
    }
    getImages()
  }, [])

  return (
    appState.sourceOfMeetBannerImageUrls != [] && (
      <>
        <div
          id="mySidenav"
          className={`promotionBannerPopup col-xl-5 py-3   ${appState.langValue == "ar" ? "promotionBannerPopupArabic" : " "
            }`}
        >
          {
            <Dialog
              style={open ? {} : { display: "none" }}
              className="classToTargetsomModal"
              open={appState.sourceOfMeetBannerImage}
              onClose={handleClose}
            >
              <DialogContent className="p-0">
                <button
                  type="button"
                  onClick={handleClose}
                  className="close pb-1"
                >
                  <span aria-hidden="true">
                    <img src={CloseIcon} alt="CloseIcon" />
                  </span>
                </button>
                <OwlCarousel
                  className="owl-theme"
                  items="1"
                  margin={0}
                  customtransition="2000ms"
                  transitionduration={500}
                  loop
                  autoplay={true}
                >
                  {" "}
                  {appState.sourceOfMeetBannerImageUrls != [] &&
                    appState.sourceOfMeetBannerImageUrls.map((banner, i) => {
                      return (
                        <div
                          className={`promotionBannerParent  ${appState.langValue === "ar" ? "directionRtl" : ""
                            }`}
                          key={i}
                        >
                          <img
                            className=" promotionBannerImage"
                            src={appState.sourceOfMeetBannerImageUrls}
                            alt=""
                            onLoad={() => onLoad(i)}
                            key={banner}
                          />
                        </div>
                      );
                    })}
                </OwlCarousel>
                <div className="bottomBtn px-3 py-3">
                  <Button
                    onClick={handleClose}
                    className={` py-3 px-4 w-100  justify-content-center confirmBtn ${appState.langValue === "ar"
                      ? " familyGraphikArabic600"
                      : ""
                      }`}
                  >
                    {" "}
                    <span>{t("checkOut.close")}</span>{" "}
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          }
        </div>
      </>
    )
  );
}

export default withTranslation()(SourceOfMeetBanner);
