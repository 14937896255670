/* eslint-disable */
import React, { useEffect, useState, useReducer } from "react";
import "./paymentMethods.scss";
import { useHistory } from "react-router-dom";

// material ui
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Backdrop from "@mui/material/Backdrop";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import TabsUnstyled from "@mui/base/TabsUnstyled";
// import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import Checkbox from '@mui/material/Checkbox';
// localizations
import { withTranslation, useTranslation, Trans } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";

import credit from "../../../../layout-three/js/assets/images/credit.png";
import knetSmall from "../../../../layout-three/js/assets/images/knet.png";
import simpleCash from "../../../../layout-three/js/assets/images/cashlogo.png";
import enabill from "../../../../layout-three/js/assets/images/enabill.png";
import { Tab } from '@mui/base';
import { TabsList } from '@mui/base';
import { Tabs } from '@mui/base';

import GoSellElementsTrans from "../../../../layout-one/js/components/GoSell";
// Global Fucntions
import { makeStyles } from "@material-ui/core/styles";
import ApplePayBtn from "../applePayButton/applePayButton";
import GooglePayButtonCompo from "../googlePayButton/googlePayButton"

const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#052d72",
    fontWeight: 600,
    fontSize: "1.1rem",
  },
}));

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};


function PaymentMethods({
  handlCheckoutWithApplePay,
  handleCheckout,
  payments,
  setPaymentMethod,
  paymentMethod,
  tapStatus,
  setTapId,
  setLoading,
  childRef,
  handleCloseAll,
  setCheckedSaveCard,
  checkedSaveCard,
  campaignDiscount
}) {
  const history = useHistory();
  const appDispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const appState = useSelector((state) => state);
  const [showAppleBtn, setShowAppleBtn] = useState(false);
  const handleChangeSaveCard = (event) => {
    setCheckedSaveCard(!checkedSaveCard);
  };
  useEffect(() => {
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      setShowAppleBtn(true);
    }
  }, []);

  // This is used only for the example
  const handleClickPay = () => {
    history.push(`/checkout${appState.orderQrCode}`);
  };
  const handleClickOrder = () => {
    appDispatch({
      type: "setPaymentPopUp",
      paymentPopUp: false,
    });
  };

  const arLanguageHandle = (e) => {
    // console.log("ar change");
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");

    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  const handleTabChange = (event, newValue) => {
    // console.log(event, newValue)
    if (newValue == "wallet") {
      setPaymentMethod("wallet")
    } else if (newValue == "knet") {
      setPaymentMethod("knet")
    } else if (newValue == "card") {
      setPaymentMethod("card")
    } else if (newValue == "cash") {
      setPaymentMethod("cash")
    }
  };








  return (
    <>
      {/* <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        open={appState.paymentPopUp}
      /> */}

      <div
        id="mySidenav"
        className={`paymentMethodsl1  py-3 ${appState.langValue == "ar" ? "paymentMethodsl1Arabic" : " "
          }`}
      >
        <h3 className="d-flex px-4 my-3" onClick={handleClickOrder}>
          {t("checkOut.Pay-With")}
        </h3>
        {/* <div className="px-4">

          <GooglePayButtonCompo/>

          <p className="text-center divider familyPoppins600 mb-0 py-3 text-capitalize ">
            <span> <div className="circleDiv d-flex justify-content-center align-items-center">{t("Login.OR")}</div> </span>
          </p>
        </div> */}


        {showAppleBtn && <>{(payments.includes("apple_pay") && !tapStatus) && (
          <div className="px-4">
            <ApplePayBtn handlCheckoutWithApplePay={handlCheckoutWithApplePay} handleCloseAll={handleCloseAll} />

            <p className="text-center divider familyPoppins600 mb-0 py-3 text-capitalize ">
              <span> <div className="circleDiv d-flex justify-content-center align-items-center">{t("Login.OR")}</div> </span>
            </p>
          </div>
        )}</>}



        <div className="paymentMethod px-3 tabNavigation">
          <Tabs onChange={handleTabChange} >
            <TabsList className="tabListCustomized">
              {payments.includes("wallets") && (
                <Tab
                  className="customTabs"
                  disabled={tapStatus}
                  value="wallet"

                >
                  <img className="mb-1" src={enabill} alt="enabill" />
                  <p className="m-0">{t("checkOut.Wallet")}</p>
                </Tab>
              )}
              {payments.includes("knet") && (
                <Tab
                  className="customTabs"
                  value="knet"

                // disabled={tapStatus}
                >
                  <img className="mb-1" src={knetSmall} alt="knet" />
                  <p
                    className="m-0">{t("checkOut.Knet")}</p>
                </Tab>
              )}

              {payments.includes("card") && (
                <Tab
                  className="customTabs"
                  value="card"
                  disabled={tapStatus || appState.storeInfo.tapKey === null}
                >
                  <img className="mb-1" src={credit} alt="credit" />
                  <p className="m-0">{t("checkOut.Credit-Card")}</p>
                </Tab>
              )}
              {!appState.splitBill
                ? payments.includes("cashable") && (
                  <Tab className="customTabs"
                    value="cash"

                    disabled={campaignDiscount > 0}
                  >
                    <img className="mb-1" src={simpleCash} alt="cash" />
                    <p className="m-0">{t("checkOut.in-store")}</p>
                  </Tab>
                )
                : ""}
            </TabsList>
          </Tabs>
        </div>
        {paymentMethod == "card" ? (
          <>
            <div className="col-12">
              <GoSellElementsTrans
                ref={childRef}
                status={"checkout"}
                //   inpValue={inpValue}v
                setTapId={setTapId}
                payWithValue={paymentMethod}
                setButtonLoading={setLoading}
              />
            </div>
            {(appState.loggedIn && payments.includes("save_card")) && <div className="d-flex mx-2 align-items-center save_card_checkbox"
              onClick={handleChangeSaveCard}
            >

              <Checkbox
                color="primary"
                inputProps={{
                  "aria-label": "checkbox with default color",
                }}
                checked={checkedSaveCard}
              />
              <p className="my-0"> {t("Forms.Save-card")}</p>
            </div>}

          </>
        ) : (
          ""
        )}


        <div id="process-btn" className="row   col-md-12 m-0">
          <Button
            className={`${paymentMethod == "" ? `disabledCheckoutBtn` : `btn-main`
              } col-12 text-capitalize align-item-center`}
            disabled={paymentMethod == ""}
            onClick={handleCheckout}
          >
            {t("navBar.pay")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(PaymentMethods);
