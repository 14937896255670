/* eslint-disable */
import React, { useEffect, useState, useReducer } from "react";
import "./checkoutShortCutPopup.scss";
import { useHistory } from "react-router-dom";

// material ui
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Backdrop from "@mui/material/Backdrop";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// localizations
import { withTranslation, useTranslation, Trans } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import {
  freezOn,
  getDineInOrder,
  getPayTableInvoice,
  getTableInvoice,
} from "../../../../redux-store/action";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Avatar, Dialog, useMediaQuery } from "@material-ui/core";
import noImg from "../../assets/images/no-image.svg";
import { get_lang } from "../../../../global-functions/apiGlobal";
import CheckOutShortCut from "../../Pages/Checkout/CheckOutShortCut";
import { getServiceName } from "../../../../global-functions/variables";
import Accordion from 'react-bootstrap/Accordion';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function checkoutShortCutPopup({ table }) {
  const history = useHistory();
  const appDispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const appState = useSelector((state) => state);
  const matches = useMediaQuery("(min-width:1200px)");
  const {
    totalAmount = 0,
    subTotalAmount = 0,
    discount = 0,
    totalCharges = 0,
    totalTax = 0,
    adjustedAmount = 0,
    tip = 0,
    charges = [],
    currentInvoiceStatus = 0,
    paidAmount = 0,
    leftToPay = 0,
    fullAmount = 0,
  } = appState.invoiceTaxesChargesExtras;



  useEffect(() => {
    if (!Boolean(appState.openBill)) {
      let data = {
        ...appState.formData,
        order_invoice: appState.hotelQrCode
          ? "hotel_order"
          : appState.qrPickup
            ? "pick_up"
            : "dine_in",
      };
      appDispatch({
        type: "SET_FORM_DATA",
        formData: data,
      });
      localStorage.setItem(
        `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
        JSON.stringify(data)
      );
    }
  }, [])


  return (
    <>
      {/* <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        // open={appState.checkoutShortCutPopup}
        open={true}
        className="payTableBackdrop"
      /> */}


      <div
        id="mySidenav"
        className={`checkoutShortCutPopup pt-5  ${appState.langValue == "ar" ? "ArabicCheckoutShortCutPopup" : " "
          }`}
      >

        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <div className="d-flex justify-content-center">
              <Accordion.Header>
                <IconButton
                  id="headerBtn"
                  className="arabicBtn"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Accordion.Header>
            </div>

            {/* <Accordion.Body className="mt-4"> */}
              <CheckOutShortCut />
            {/* </Accordion.Body> */}
          </Accordion.Item>
        </Accordion>

      </div>
    </>
  );
}

export default withTranslation()(checkoutShortCutPopup);
