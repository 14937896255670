/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./payCustomAmount.scss";
// material ui
import Button from "@mui/material/Button";
// localizations
import { withTranslation, useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { roundFixed } from "../../global-functions/roundFixed";
import { handleDeleteOption } from "../../layout-one/js/Pages/Checkout";
import { get_lang } from "../../global-functions/apiGlobal";

function PayCustomAmount(props) {
  const { t } = useTranslation();
  const { subTotal, tip } = props;
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const { discount = 0, totalCharges = 0, totalTax = 0, adjustedAmount = 0, charges = [] } = appState.invoiceTaxesChargesExtras;

  const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
  };
  const [inputRef] = useFocus();

  const handleClickConfirm = () => {
    appDispatch({ type: "setPaymentPopUp", paymentPopUp: true });
    appDispatch({
      type: "setPayCustomAmount",
      payCustomAmount: false,
    });
    appDispatch({
      type: "setSplitBillOptions",
      splitBillOptions: false,
    });
  };

  const handleChangeSplit = () => {
    handleDeleteOption(appState, appDispatch, 'payCustomAmount')
    props.setSplitBillAmount(0);
    props.setPartialType("");
  };

  const [customAmount, setCustomAmount] = useState(props.splitBillAmount);

  const handleCustomAmount = (event) => {
    const re = /^[.0-9\b]+$/;
    console.log(event.target.value)

    if (
      event.target.value === "" ||
      event.nativeEvent.data == null ||
      re.test(event.target.value)
    ) {
      if (event.target.value.includes(".")) {
        if (event.target.value.split(".")[1].length < 4) {
          let dot = event.target.value
          console.log('dot <= subTotal', dot <= (subTotal + tip));
          if (dot <= (subTotal + tip)) {
            props.setSplitBillAmount(event.target.value);
            setCustomAmount(event.target.value);
          } else {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `Amount must be less or equal to ${parseFloat(
                (subTotal + tip)
              ).toFixed(3)}`,
            });
          }
        }
        else {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: `Amount must be less or equal to ${parseFloat(
              subTotal
            ).toFixed(3)}`,
          });
        }
      } else {
        if (event.target.value <= (subTotal + tip)) {
          props.setSplitBillAmount(Number(event.target.value));
          setCustomAmount(Number(event.target.value));
        } else {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: `Amount must be less or equal to ${parseFloat(
              (subTotal + tip)
            ).toFixed(3)}`,
          });
        }
      }

    }
  };

  useEffect(() => {
    let roundTotal = subTotal;
    let tax = appState.storeInfo.taxPricing == "exclusive" ? totalTax : 0;
    console.log(typeof (roundTotal), typeof (tip))
    let afterDiscount = Number(roundTotal) + Number(tip);
    // let a = parseFloat(subTotal - props.campaignDiscount).toFixed(3);
    setCustomAmount(subTotal)
    // if (props.splitBillAmount <= 0) {
    //   setCustomAmount(afterDiscount);
    //   props.setSplitBillAmount(afterDiscount)
    // } else {
    //   setCustomAmount(props.splitBillAmount);
    // }
    props.setPartialType("customer");
  }, []);


  return (
    <>
      <div
        id="mySidenav"
        className={`payCustomAmount ${appState.langValue === "ar" ? "divideBillArabic" : " "
          }`}
      >
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div className="container-fluid content px-4 pb-3 mt-4">
          <h1
            className={` ${appState.langValue === "ar"
                ? "text-right familyGraphikArabic800 "
                : " familyPoppins600"
              }`}
          >
            {t("checkOut.How-much-you-want-to-pay")}?
          </h1>

          <div className="inputFieldParent my-3 py-5">
            <TextField
              inputProps={{ max: 3, inputMode: "decimal", pattern: "[0-9]*" }}
              type="decimal"
              value={customAmount}
              label={null}
              variant="outlined"
              ref={inputRef}
              autoComplete={"off"}
              onChange={(event) => handleCustomAmount(event)}
              id="outlined-basic"
              name="phoneNumber"
              autoFocus={true}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
            />
            <h5 className="text-center familyPoppins600">{get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</h5>
          </div>
          <div className="bottomBtn">
            <Button
              onClick={
                props.splitBillAmount <= props.subTotal
                  ? handleClickConfirm
                  : () =>
                    appDispatch({
                      type: "SHOW_ALERT",
                      alertType: "error",
                      message: `Given amount is wrong`,
                    })
              }
              disabled={customAmount <= 0}
              className={` py-3 px-4  mt-3 justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                } ${customAmount <= 0 ? `disabledBtn` : `confirmBtn`}`}
            >
              {" "}
              <span>{t("checkOut.Confirm")}</span>{" "}
            </Button>
            <Button
              onClick={handleChangeSplit}
              className={` py-3 px-4 mt-3 deletebtn justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                }`}
            >
              {" "}
              <span>{t("checkOut.Delete-Split")}</span>{" "}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(PayCustomAmount);
