import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import noImg from "../layout-one/js/assets/images/no-image.svg";


export const orderAgain = async (appState, orderId) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/order_invoice/${orderId}`,{
      headers: {
        ContentType: 'application/json',
      }
    }
  );
  let reOrders = await res.data.data.invoiceable.orders;
  var arry = [];
  const filterProduct = appState.products.filter(({ id: id }) =>
    reOrders.some(({ product: order }) => order.id === id)
  );

  filterProduct.forEach((prodEle) => {
    var addArr = [];
    var addonLimit = [];
    reOrders.forEach((orderProd, ind) => {
      if (prodEle.type == "Simple") {
        if (orderProd.product.id == prodEle.id) {
          if (
            prodEle.quantity >= orderProd.quantity ||
            prodEle.quantity == null
          ) {
            if (appState.qrBranch != null && prodEle.branches.length > 0) {
              let getBranch = prodEle.branches.find(
                (item) => item.branch.id == appState.qrBranch
              );
              // console.log(getBranch)
              if (getBranch != [] && getBranch != undefined) {
                if (getBranch.quantity != undefined) {
                  prodEle.quantity = parseInt(getBranch.quantity);
                }
                if (getBranch.price != undefined) {
                  prodEle.price = getBranch.price;
                }
                if (getBranch.discount != undefined) {
                  prodEle.discount = getBranch.discount;
                }
              }
            }

            if (prodEle.product_addon_categories.length > 0) {
              prodEle.product_addon_categories.forEach((addonArry) => {
                if (addonArry.product_addons.length > 0) {
                  let objAddonLimit = {
                    id: addonArry.id,
                    title: addonArry.title,
                    limit:
                      addonArry.limit != undefined ? addonArry.limit : null,
                    required:
                      addonArry.required != undefined
                        ? addonArry.required
                        : null,
                    min: addonArry.min != undefined ? addonArry.min : null,
                    max: addonArry.max != undefined ? addonArry.max : null,
                    unique:
                      addonArry.unique != undefined ? addonArry.unique : false,
                    addons_qty: 0,
                    addons_length: addonArry.product_addons.length,
                  };
                  addonArry.product_addons.forEach((addonItem) => {
                    if (orderProd.addons.length > 0) {
                      orderProd.addons.forEach((orderAddonsItem) => {
                        if (addonItem.id == orderAddonsItem.product_addon.id) {
                          // console.log(addonItem)
                          if (appState.qrBranch != null && addonItem.branches.length > 0) {
                            let getBranch = addonItem.branches.find(
                              (item) => item.branch.id == appState.qrBranch
                            );
                            // console.log(getBranch)
                            if (getBranch != [] && getBranch != undefined) {
                              if (getBranch.quantity != undefined) {
                                addonItem.quantity = parseInt(getBranch.quantity);
                              }
                              if (getBranch.price != undefined) {
                                addonItem.price = getBranch.price;
                              }
                              if (getBranch.discount != undefined) {
                                addonItem.discount = getBranch.discount;
                              }
                            }
                          }

                          if (addonItem.quantity >= orderAddonsItem.quantity) {
                            let addonData = {
                              category_addon_id: addonArry.id,
                              product_addon_id: addonItem.id,
                              addon: addonItem.addon,
                              required:
                                addonArry.required != undefined
                                  ? addonArry.required
                                  : null,
                              min:
                                addonArry.min != undefined
                                  ? addonArry.min
                                  : null,
                              max:
                                addonArry.max != undefined
                                  ? addonArry.max
                                  : null,
                              unique:
                                addonArry.unique != undefined
                                  ? addonArry.unique
                                  : false,
                              description: addonItem.description,
                              price: addonItem.price,
                              check_status: true,
                              maxQuantity: addonItem.quantity,
                              product_id: prodEle.id,
                              quantity: orderAddonsItem.quantity,
                              total: function () {
                                return this.quantity * this.price;
                              },
                            };
                            addArr.push(addonData);
                          }
                        }
                      });
                    }
                  });
                  addonLimit.push(objAddonLimit);
                }
              });
            }
            let cart_id = uuidv4();
      let orderTypeName =  appState.hotelQrCode > 0 ? `hotel` : appState.qrPickup > 0 ? `pickUp` : appState.qrTable > 0 ? `dineIn` : `menu`

            var currentCart = {
              id: cart_id,
              orderType: orderTypeName,
              product_id: prodEle.id,
              maxQuantity: prodEle.quantity,
              prod_title: prodEle.name,
              prod_slug: prodEle.slug,
              prod_desc: prodEle.description
                ? prodEle.description
                : "N/A",
              quantity: orderProd.quantity,
              price: prodEle.price - prodEle.discount,
              image: prodEle.image,
              category_name: prodEle.category_name,
              redirect_url: `${prodEle.category.slug}/${prodEle.slug}?`,
              sum_addons: orderProd.sum_addons,
              overAllSum: Number(((prodEle.price - prodEle.discount) * orderProd.quantity) + (orderProd.sum_addons *orderProd.quantity)),

              // total: orderProd.total,
              addons: addArr,
              addons_limit: addonLimit,
              meta: cart_id,
              placed_by:  4,
                product: {
                  name: prodEle.name,
                  image: prodEle.image != null ? prodEle.image : noImg,
                },
            };
            arry.push(currentCart);
          }
        }
      } else {
        prodEle.childer.forEach((prodElement) => {
          if (orderProd.product.id == prodElement.id) {
            if (
              prodElement.quantity >= orderProd.quantity ||
              prodElement.quantity == null
            ) {
              if (prodElement.product_addon_categories.length > 0) {
                prodElement.product_addon_categories.forEach((addonArry) => {
                  if (addonArry.product_addons.length > 0) {
                    let objAddonLimit = {
                      id: addonArry.id,
                      title: addonArry.title,
                      limit:
                        addonArry.limit != undefined ? addonArry.limit : null,
                      required:
                        addonArry.required != undefined
                          ? addonArry.required
                          : null,
                      min: addonArry.min != undefined ? addonArry.min : null,
                      max: addonArry.max != undefined ? addonArry.max : null,
                      unique:
                        addonArry.unique != undefined
                          ? addonArry.unique
                          : false,
                      addons_qty: 0,
                      addons_length: addonArry.product_addons.length,
                    };
                    addonArry.product_addons.forEach((addonItem) => {
                      if (orderProd.addons.length > 0) {
                        orderProd.addons.forEach((orderAddonsItem) => {
                          if (
                            addonItem.id == orderAddonsItem.product_addon.id
                          ) {
                            if (
                              addonItem.quantity >= orderAddonsItem.quantity
                            ) {
                              let addonData = {
                                category_addon_id: addonArry.id,
                                product_addon_id: addonItem.id,
                                addon: addonItem.addon,
                                required:
                                  addonArry.required != undefined
                                    ? addonArry.required
                                    : null,
                                min:
                                  addonArry.min != undefined
                                    ? addonArry.min
                                    : null,
                                max:
                                  addonArry.max != undefined
                                    ? addonArry.max
                                    : null,
                                unique:
                                  addonArry.unique != undefined
                                    ? addonArry.unique
                                    : false,

                                description: addonItem.description,
                                price: addonItem.price,
                                check_status: true,
                                maxQuantity: addonItem.quantity,
                                product_id: prodElement.id,
                                quantity: orderAddonsItem.quantity,
                                total: function () {
                                  return this.quantity * this.price;
                                },
                              };
                              addArr.push(addonData);
                            }
                          }
                        });
                      }
                    });
                    addonLimit.push(objAddonLimit);
                  }
                });
              }

              let cart_id = uuidv4();
              var currentCart = {
                id: cart_id,
                product_id: prodElement.id,
                maxQuantity: prodElement.quantity,
                prod_title: prodElement.name,
                prod_slug: prodElement.slug,
                prod_desc: prodElement.description
                  ? prodElement.description
                  : "N/A",
                quantity: orderProd.quantity,
                price: prodElement.pirce - prodElement.discount,
                image: prodElement.image,
                category_name: prodElement.category_name,
                redirect_url: `${prodElement.category.slug}/${prodElement.slug}?`,
                sum_addons: orderProd.sum_addons,
                // total: orderProd.total,
                addons: addArr,
                addons_limit: addonLimit,
                meta: cart_id,
                placed_by:  4,
                product: {
                  name: prodElement.name,
                  image: prodElement.image != null ? prodElement.image : noImg,
                },
              };
              arry.push(currentCart);
            }
          }
        });
      }
    });
  });
  return arry;
};
