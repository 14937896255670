/* eslint-disable */
import React from 'react'
import "./error404.scss";

const Error404 = () => {

  return (
    <section id="error404">
      <div className='all'>
        <p className='oops py-4'>OOPS! PAGE NOT FOUND</p>
        <span className='center-1f'>4</span>
        <span className='center-0'>0</span>
        <span className='center-lf'>4</span>
        <p className='sorry'>WE ARE SOORY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND</p>
      </div>
    </section>

    )
}

export default Error404