// check is not empty
export const isNotEmpty = (obj) => {
  return obj !== null && obj !== undefined && obj !== 0 && obj.length !== 0;
};

// captilizing
export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// sort by value
export const sortedByNumber  = (obj) => {
  return  obj.sort((a, b) => a.order - b.order);
}

// compare two array
export const twoArryDifference = (array1, array2) => {
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1.category_id == object2.id;
    });
  });
};


export const dataFromat = (start, end) => {
  var dateString1 = end 
var dateString2 = start 
const currentDate = new Date();


// Split the date strings to rearrange them in a format that JavaScript can interpret
var [day1, month1, year1, time1] = dateString1.split(/[\s/:]+/);
var [day2, month2, year2, time2] = dateString2.split(/[\s/:]+/);

// Rearrange date components to a format that JavaScript can recognize (DD/MM/YYYY to YYYY/MM/DD)
var isoDateString1 = `${year1}-${month1}-${day1}T${time1}:00`;
var isoDateString2 = `${year2}-${month2}-${day2}T${time2}:00`;

var isLarger =        new Date() >= new Date(isoDateString2) &&  new Date() <= new Date(isoDateString1) ;
  return isLarger
}


export const stringDateFromate = (start_at, end_at) =>{
  const currentDate = new Date();
  const startDate = new Date(start_at);
  const endDate = new Date(end_at);
  if (currentDate >= startDate && currentDate <= endDate) {
    console.log('active');
    return true
} else {
  console.log('Non-active');

    return false
}
}

// convert into rgba format
export const colorRGB = (hexColor) => {
  // Parse the hex color into its RGB components
  const red = parseInt(hexColor.slice(1, 3), 16);
  const green = parseInt(hexColor.slice(3, 5), 16);
  const blue = parseInt(hexColor.slice(5, 7), 16);
  // Specify the opacity (alpha) value (between 0 and 1)
  const opacity = 0.5; // Change this value as needed
  // Create the rgba color value
  const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  return rgbaColor
};

// get difference time between current with starting or ending time
export const compareStartAndEndTime = (days = [], start, end) => {
  let show = false;
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  var current = new Date();
  var currentDay = weekday[current.getDay()];
  let flag = false;
  if (days.length > 0) {
    flag = days.includes(currentDay);
  } else {
    flag = true;
  }
  if (flag) {
    var starting = new Date();
        starting.setHours(start.split(":")[0]);
        starting.setMinutes(start.split(":")[1]);
        var ending = new Date();
        ending.setHours(end.split(":")[0]);
        ending.setMinutes(end.split(":")[1]);
    if (starting != ending) {
      if (starting > ending) {
        if (current < starting && current < ending) {
          starting.setDate(starting.getDate() - 1);
        } else {
          ending.setDate(ending.getDate() + 1);
        }
      }
      if (starting <= current && current <= ending) {
        show = true;
      }
    }
  } else {
    show = true;
  }
  return show;
};
