/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from "react";
import "./shareInvoiceQrcode.scss";
import { useHistory } from "react-router-dom";

// material ui
import { Button, IconButton } from "@material-ui/core";
import Box from '@mui/material/Box';
// localizations
import { withTranslation, useTranslation, Trans } from "react-i18next";
// redux
import { useSelector, useDispatch } from 'react-redux';
import QRCodeStyling from "qr-code-styling";
import TextField from '@mui/material/TextField';
import { RWebShare } from "react-web-share";
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyIcon from "../../assets/images/ContentCopyIcon.svg";

const qrCode = new QRCodeStyling({
  width: 200,
  height: 200,

  dotsOptions: {
    color: "#000",
    type: "dot"
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20
  }
});

function ShareInvoiceQrcode() {
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);
  const appDispatch = useDispatch();
  const appState = useSelector((state) => state);
  const [url, setUrl] = useState(appState.shareBillUrl);

  const handleClickOpenQrCode = () => {
    setOpen(true);
  };
  const handleCloseQrCode = () => {

    appDispatch({
      type: "setShareBillPopup",
      shareBillPopup: false,
      shareBillUrl: appState.shareBillUrl
    });
  };
  useEffect(() => {
    qrCode.append(ref.current);
  }, [handleClickOpenQrCode]);

  useEffect(() => {
    qrCode.update({
      data: url
    });
  }, [url]);
  const onUrlChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);

  };
  useEffect(() => {
    setUrl(appState.shareBillUrl);
  }, []);

  const handleCopyText = () => {
    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  }

  return (
    <>
      <div id="mySidenav" className={`shareInvoiceQrcode  ${appState.langValue == "ar" ? "shareInvoiceQrcodeArabic" : " "
        }`}>
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div className="container-fluid classForQrcodeStyling content px-4 pb-3 mt-4">
          <h1 className={`text-center text-uppercase ${appState.langValue === "ar" ? " familyGraphikArabic800 " : " familyPoppins600 "
            }`}
          >{t("open-order.share-the-bill")}</h1>


          <div className=" py-3 MscBannerQrcodeInner"> <div className="row px-2 ">

          </div>
            <div className="App  ">
              <div className="d-flex justify-content-center" ref={ref} />
              <div className=" d-flex  mt-3 inputWrapper" style={styles.inputWrapper}>
                <div className="d-flex align-items-center w-100">
                  <TextField id="myInput"


                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">

                          <IconButton onClick={handleCopyText} onmouseout="outFunc()" size="small">
                            <img src={ContentCopyIcon} alt="ContentCopyIcon" />

                          </IconButton>

                        </InputAdornment>
                      ),
                    }}
                    label={t("open-order.Invoice-URL")} variant="outlined" className="inputBox " value={url} onChange={onUrlChange} style={styles.inputBox} />
                </div>
              </div>
            </div>

          </div>
          <div className="bottomBtn">
            <RWebShare
              data={{
                text: "invoice",
                url: appState.shareBillUrl,
                title: `${t("open-order.share-the-bill")}`
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <Button className={` py-3 px-4 confirmBtn mt-3 justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                }`}

              > <span >{t("open-order.share")}</span>  </Button>
            </RWebShare>

            <Button onClick={handleCloseQrCode} className={` py-3 px-4 mt-3 deletebtn justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              }`}

            > <span >{t("open-order.Cancel")}</span>  </Button>
          </div>
        </div>
      </div>
    </>
  );
}
const styles = {
  inputWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputBox: {
    width: "100%",
    margin: "0px"

  }
};
export default withTranslation()(ShareInvoiceQrcode);