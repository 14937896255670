// import { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// /* eslint-disable */

// const useGaTracker = () => {
//     const location = useLocation();
//     const [initialized, setInitialized] = useState(false);

//     useEffect(() => {
//         if (!window.location.href.includes("localhost")) {
//         ReactGA.initialize("G-DSJ8Y451YZ");
//         }
//         setInitialized(true);
//         // console.log('Analiytic')
//     }, []);
    

//     useEffect(() => {
//         if (initialized) {
//         ReactGA.pageview(location.pathname + location.search);
//         }
//     }, [initialized, location]);
// };

// export default useGaTracker;



/* eslint-disable */
import ReactGA from "react-ga";

const useGaTracker = (category="Blog category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}
export default useGaTracker;
