/* eslint-disable */
import React from "react";
import { LoopCircleLoading } from "react-loadingg";
/* eslint-disable */
import ReactLoading from "react-loading";
import { useSelector, useDispatch } from "react-redux";

export default function CircularIndeterminate() {
  const appState = useSelector((state) => state);

  return  (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <ReactLoading className="mx-auto" type="spin" color={appState.storeInfo ? appState.storeInfo.primaryColor : '#00000' } />
    </div>

  );
}
