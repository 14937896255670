/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import "./loginModalv2Screen.scss";
// react router dom
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
// localizations
import { withTranslation, useTranslation } from "react-i18next";
// import Redux
import { useDispatch, useSelector } from "react-redux";
// material ui
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { IconButton } from "@material-ui/core"
import DoneIcon from "@mui/icons-material/Done";
// svg icons
import IMAGES from "../Assets/images2"
import InputMaskField from "../../sharedComponent/react-input-mask/inputfield"
import Page from "../../layout-one/js/components/Page";
import Backdrop from "@mui/material/Backdrop";
import leftArrow from "../../layout-three/js/assets/images/leftArrow.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Axios from "axios";
import CircularIndeterminate from "../../layout-one/js/components/CircularIndeterminate";
//  cookie functions
import { setCookie } from "../../global-functions/cookieFunction";
import { freezOn, freezOff, handleError } from "../../redux-store/action";
import TextField from "@mui/material/TextField";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function LoginModalv2Screen(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [fullWidth] = React.useState(true);
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginValues, setLoginValues] = useState({
    phoneCode: "965",
    phoneNumber: "",
  });
  const [alertMessage, setAlertMessage] = useState({
    open: false,
    message: "",
  });
  const [success, setSuccess] = useState(false);
  const handleClose = () => {
    appDispatch({ type: "setLoginModal_Screen", loginModalScreen: false });
  };

  const handleSkip = () => {
    appDispatch({ type: "setLoginSkip", loginSkip: true });
    appDispatch({ type: "setLoginModal_Screen", loginModalScreen: false });
  }
  const date = new Date();
  let year = date.getFullYear();

  const updateField = (event) => {
    if (event.target.name === "phoneNumber") {
      if (
        loginValues.phoneNumber.length < 8 ||
        event.nativeEvent.data === null
      ) {
        setLoginValues({
          ...loginValues,
          [event.target.name]: event.target.value,
        });
        // console.log(event.target.value)
      }
    } else {
      setLoginValues({
        ...loginValues,
        [event.target.name]: event.target.value,
      });
    }
  };


  const handleGoToShop = () => {
    const shop_url = localStorage.getItem(`redirect`);
    window.location.replace(shop_url);

  }
  // console.log(appState.loginModal)
  const phoneLogin = async (event) => {

    event.preventDefault();
    setLoading(true);
    appDispatch(freezOn());
    appDispatch({ type: "setLoginValues", loginValuesState: loginValues });
    if (loginValues.phoneCode && loginValues.phoneNumber !== "") {
      try {
        // let mobile_no = `${loginValues.phoneCode}${loginValues.phoneNumber}`;
        const response = await Axios.post(
          `${process.env.REACT_APP_API_URL}/send/guest/otp`,
          {
            country_code: loginValues.phoneCode,
            number: loginValues.phoneNumber
          }
        );
        setLoading(false);
        if (response.data.data === true) {
          setLoading(false);
          appDispatch(freezOff());
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "success",
            message: response.data.message,
          });
          appDispatch({ type: "setLoginModal_Screen", loginModalScreen: false });
          appDispatch({ type: "setOtpModal", otpModal: true });

          setLoginValues({
            phoneCode: 965,
            phoneNumber: "",
          })

        } else {
          setLoading(false);
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: response.data.message,
          });
          appDispatch(freezOff());


        }

      } catch (e) {
        appDispatch(freezOff());
        setLoading(false);
        if (eval == undefined) {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "warning",
            message: "Please check your network.",
          });
        } else {
          appDispatch(handleError(e));
        }
      }

    } else {
      appDispatch(freezOff());
      setLoading(false);
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: t("Login.plz-fill-required-fields"),
      });

    }

  };

  // localization  start

  const arLanguageHandle = (e) => {
    // console.log("ar change");
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
    //   i18n.changeLanguage('ar')
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");

    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  return (

    <>
      <div id="mySidenav" className={`loginModalMainv2Screen col-xl-5 py-3   ${appState.langValue == "ar" ? "loginModalMainv2SreenArabic" : " "
        }`}>

        <div
          className={`${appState.langValue === "ar" ? "directionRtl" : ""}`}
        >
          <div className="row pt-2 mx-0 ">
            <div className="col-md-12 px-0 d-flex justify-content-between classforPaddinglogo ">
              <div>
                <IconButton
                  onClick={handleClose}
                  variant="contained"
                  className="BackBtn"
                >
                  {appState.langValue === "ar" ? (
                    <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L7.53033 6.96967C7.82322 7.26256 7.82322 7.73744 7.53033 8.03033L1.53033 14.0303C1.23743 14.3232 0.762561 14.3232 0.469668 14.0303C0.176774 13.7374 0.176775 13.2626 0.469668 12.9697L5.93934 7.5L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967Z" fill="#22282F" />
                    </svg>

                  ) : (
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M7.53033 13.5303C7.23744 13.8232 6.76256 13.8232 6.46967 13.5303L0.46967 7.53033C0.176777 7.23744 0.176777 6.76256 0.46967 6.46967L6.46967 0.46967C6.76256 0.176777 7.23744 0.176777 7.53033 0.46967C7.82322 0.762563 7.82322 1.23744 7.53033 1.53033L2.06066 7L7.53033 12.4697C7.82322 12.7626 7.82322 13.2374 7.53033 13.5303Z" fill="#22282F" />
                    </svg>
                  )}
                </IconButton>
              </div>

              <img src={IMAGES.enabillGroupBill} alt="enabill-logo" />
              <div>
                {appState.langValue === "ar" ? (
                  <Button
                    className="  arabicBtn"
                    variant="contained"
                    onClick={(e) => enLanguageHandle(e)}
                  >
                    English
                  </Button>
                ) : (
                  <Button
                    className="  arabicBtn"
                    variant="contained"
                    onClick={(e) => arLanguageHandle(e)}
                  >
                    عربي
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div>
            <h1
              className={`familyManrope800 mt-3 ${appState.langValue === "ar" ? "d-flex" : ""
                }`}
            >
              {t("group-order.Group-Order")}
            </h1>
            <p
              className={`familyManrope600 mt-1 textf4f4 ${appState.langValue === "ar" ? "d-flex" : ""
                }`}
            >
              {t("group-order.Invite-everyone-to-get-in-and-see-what-being-delivered-to-the-table")}
            </p>
          </div>
          <hr className="my-1" />
          <h1
            className={`familyManrope800 mt-3 ${appState.langValue === "ar" ? "d-flex" : ""
              }`}
          >
            {t("Login.Verify-Your-Mobile-Number")}
          </h1>
          <p
            className={`familyManrope600 mt-1 textf4f4 ${appState.langValue === "ar" ? "d-flex" : ""
              }`}
          >
            {t("Login.You-will-recieve-a-one-time-password-shortly")}
          </p>

          <form onSubmit={phoneLogin}>
            <div
              className={`classforPaddingTextfield ${appState.langValue === "ar" ? "classforArabicPhone" : ""
                }`}
            >
              <div className={`row mt-3  ${appState.langValue === "ar" ? "classforArabicPhoneField" : ""
                }`}>
                <div className="col-12">
                  <InputMaskField
                    value={loginValues.phoneNumber}
                    onChange={updateField}
                    inputProps={{ inputMode: "numeric" }}
                    label={t("Forms.phone-number")} className="mt-2"
                    variant="outlined"
                    type="tel"
                    mask="99999999"
                    id="outlined-basic" name="phoneNumber"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            className="inputadornmentImg flagImg"
                            src={IMAGES.kuwaitinput}
                            alt="flag"
                          />{" "}
                          <span className="px-2 textOver mobCode">
                            +965<span className="countryCode"></span>{" "}
                          </span>
                        </InputAdornment>
                      ),
                    }} />
                </div>
              </div>
              <div className={`row mt-3  ${appState.langValue === "ar" ? "classforArabicTextField" : ""
                }`} >
                <div className="col-12">
                  <TextField
                    className="mt-1"
                    label={t("Forms.fullName")}
                    type="text"
                    name="fullName"
                    value={loginValues.fullName}
                    onChange={updateField}
                    variant="outlined"
                  />
                </div>
              </div>
            </div>
            <div className="classforBtnPadding">
              <div className="row my-3">
                <div className="col-12">
                  <Button
                    className=" blackBtn fontSize1rem  py-3 textCapitalized familyManrope800 "
                    variant="contained"
                    type="submit"
                  >
                    {loading ? t("Login.redirecting") : t("Login.Verify")}
                  </Button>
                </div>
              </div>
            </div>

            {(appState.storeInfo.payTableLogin == "3" && appState.payTableMode) ? <div className="classforBtnPadding">
              <div className="row my-3">
                <div className="col-12">
                  <p onClick={handleSkip}
                    className="w-100 fontSize0P9rem btnEnabill2  my-0 text-center textCapitalized familyManrope800 "> {t("checkOut.Skip")}</p>
                </div>
              </div>
            </div> : ""}


          </form>

          <div className="row mt-4 pb-2">
            <div
              className={`col-12 ${appState.langValue === "ar" ? "text-right" : ""
                }`}
            >
              <p className="familyManrope600 notRegisterMain ">
                <span>
                  <img className="img-fluid" src={IMAGES.union} alt="" />
                </span>
                <span className="notRegister px-2">
                  {" "}
                  {t("Forms.Save-your-cards")}
                </span>
              </p>

              <hr />

              <p className="familyManrope600 notRegisterMain">
                <span>
                  <img className="img-fluid" src={IMAGES.newsPaper} alt="" />
                </span>
                <span className="notRegister px-2">
                  {" "}
                  {t("Forms.Tap-to-re-order")}
                </span>
              </p>
              <hr />
              <p className="familyManrope600 notRegisterMain">
                <span>
                  <img className="img-fluid" src={IMAGES.giftPaper} alt="" />
                </span>
                <span className="notRegister px-2">
                  {" "}
                  {t("Forms.Get-discounts-and-cashbacks")}
                </span>
              </p>
              <hr />
            </div>
          </div>
          <div className="row ">
            <div className={`col-12 ${appState.langValue === "ar" ? "" : ""}`}>
              <p
                className={`familyManrope600 termsAndCondition text-center ${appState.langValue === "ar" ? "direction-ltr" : ""
                  }`}
              >
                <span className="textf9f9">
                  {" "}
                  <DoneIcon /> {t("Forms.Terms-&-Conditions-apply")}
                </span>
              </p>

              <p className={`familyManrope rights-reserved ${appState.langValue === "ar" ? "text-right" : ""}`} >
                © {year} {t("Forms.Enabill-All-rights-reserved")}
              </p>
            </div>
          </div>
        </div>

      </div>

    </>
  );
}



export default withTranslation()(LoginModalv2Screen);