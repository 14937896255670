/* eslint-disable */
import React from "react";
import InputMask from 'react-input-mask';
import TextField from "@mui/material/TextField";
// localizations
import { withTranslation } from "react-i18next";
const InputMaskField = (props) => {
  const { InputProps,sx, type, label,autoFocus, className, variant, id, name, value, onChange, mask, autoComplete} = props

  return (
    <InputMask maskChar={null} mask={mask} value={value} onChange={onChange}>
      {(inputProps) => <TextField {...inputProps}
        type={type} sx={sx} id={id} autoFocus={autoFocus} name={name} variant={variant} className={className} label={label} InputProps={InputProps}  autoComplete={autoComplete}/>}
    </InputMask>
  )
}

export default withTranslation()(InputMaskField);