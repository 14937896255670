/* eslint-disable eqeqeq */
/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import GoSellElementsTrans from "./GoSell";
import { withTranslation, useTranslation, Trans } from "react-i18next";

import axios from "axios";
import { getCookie } from "../Functions/cookieFunction";
// React Redux
import { useSelector, useDispatch } from "react-redux";
// Global Fucntions
import { get_lang } from "../../../global-functions/apiGlobal";
import { handleError } from "../../../redux-store/action";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

 function CustomizedDialogs ({ handleClose, open }) {
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const childRef = useRef();
  const { t } = useTranslation();
  const [tapId, setTapId] = useState(undefined);
  const [payWith, setPayWith] = useState("");
  const [paymentValue, setPaymentValue] = useState(1.5);
  const [inpValue, setInpValue] = useState(1.5);
  const [buttonLoading, setButtonLoading] = useState(false);
  useEffect(() => {
    setInpValue(paymentValue);
  }, [paymentValue]);

  const handlePaymentValue = (e) => {
    setPaymentValue(parseFloat(e.target.value));
  };
  const handleInpValue = (e) => {
    isNaN(parseFloat(e.target.value))
      ? setInpValue(0)
      : setInpValue(parseFloat(e.target.value));
  };
  const handlePay = (e) => {
    setPayWith(e.target.value);
    // console.log(e.target.value);
  };

  const topUp = async () => {
    setButtonLoading(true);
    // console.log(tapId,"tapToken")
    const config = {
      headers: { Authorization: `Bearer ${getCookie("tokenAuth")}` },
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customer/topup`,
        {
          amount: inpValue,
          payment_source: payWith,
          token: tapId ? tapId : null,
        },
        config
      );
      if (response.data.success == true) {
        const win = window.open(`${response.data.data.url}`, "_self");
        win.focus();
      } else {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `${response.data.message}: ${response.data.data}`,
        });
      }
    } catch (error) {
      if (error == undefined) {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      } else {
        appDispatch(handleError(error));
      }
      appDispatch({ type: "logout" });
      handleClose();
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonLoading(true);
    if (payWith != undefined && payWith != "" && payWith != null) {
      if (payWith == "knet") {
        topUp();
      } else if (payWith == "card") {
        if (tapId != undefined && tapId != "" && tapId != null) {
          topUp();
        } else {
          childRef.current.submitRequest();
        }
      }
    } else {
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: "Please select any payment method.",
      });
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    if (tapId != "" && tapId != undefined) {
      topUp();
    }
    // console.log(tapId);
  }, [tapId]);

  useEffect(() => {
    setButtonLoading(false);
  }, [handleClose]);

  return (
    <>
      <div className={`dialogs`}>
        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}

        <Dialog
          fullWidth={true}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></DialogTitle>
          <DialogContent>
            <div className={`${buttonLoading ? "disableClass" : ""} `}>
              <div className="row mx-2 popUp-content">
                <h3>
                  {" "}
                  <strong>Balance</strong>{" "}
                </h3>
                {appState.loggedIn ? (
                  <h4>
                    {appState.userInfo
                      ? appState.userInfo.amount
                      : "0"}{" "}
                    {t(`${appState.storeInfo.currencySymbol}`)}
                  </h4>
                ) : (
                  <h4>0{t(`${appState.storeInfo.currencySymbol}`)}</h4>
                )}
                <div className="row m-0 amount-head ">
                  <h5>Amount</h5>

                  <div className="popUp-amount p-0">
                    <OutlinedInput
                      id="standard-adornment-weight"
                      type="number"
                      // value={currentAmount}
                      // onChange={(e)=> {
                      //   setCurrentAmount(e.target.value)
                      // }}
                      style={{ textAlign: "center" }}
                      onChange={handleInpValue}
                      value={inpValue}
                      className="border-0 w-100 text-center amount-field hello"
                      endAdornment={
                        <InputAdornment position="end" className="text-muted">
                          {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}
                        </InputAdornment>
                      }
                    />
                  </div>

                  <p className="py-2 m-0 text-muted">
                    Or quickly select amount
                  </p>
                  <div className="selectPayment">
                    <div
                      id="menuContainer"
                      role="group"
                      className="row m-0 menuContainer btn-group selectPaymentRow"
                      onChange={(e) => handlePaymentValue(e)}
                    >
                      <div className="d-flex">
                        <div className="col-md-3 col-6">
                          <div className="paymentContainer">
                            <input
                              type="radio"
                              value="10"
                              name="selectPayment"
                              id="paymentTwenty"
                            />
                            <label htmlFor="paymentTwenty">
                              <span>
                                <strong>10 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</strong>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className="paymentContainer">
                            <input
                              type="radio"
                              value="20"
                              name="selectPayment"
                              id="paymentThirty"
                            />
                            <label htmlFor="paymentThirty">
                              <span>
                                <strong className="payment">20 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</strong>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className="paymentContainer">
                            <input
                              type="radio"
                              value="30"
                              name="selectPayment"
                              id="paymentFourty"
                            />
                            <label htmlFor="paymentFourty">
                              <span>
                                <strong>30 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</strong>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className="paymentContainer">
                            <input
                              type="radio"
                              value="40"
                              name="selectPayment"
                              id="paymentFifty"
                            />
                            <label htmlFor="paymentFifty">
                              <span>
                                <strong>40 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</strong>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className="paymentContainer">
                            <input
                              type="radio"
                              value="50"
                              name="selectPayment"
                              id="paymentSixty"
                            />
                            <label htmlFor="paymentSixty">
                              <span>
                                <strong>50 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</strong>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className="paymentContainer">
                            <input
                              type="radio"
                              value="60"
                              name="selectPayment"
                              id="paymentSeventy"
                            />
                            <label htmlFor="paymentSeventy">
                              <span>
                                <strong>60 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</strong>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section
                id="paySelection"
                onChange={handlePay}
                className="topUpPayments"
              >
                <div className="mt-3 paymentContainer p-0">
                  <div>
                    <input
                      type="radio"
                      checked={payWith == "knet"}
                      id="payKnet"
                      value="knet"
                      name="payWith"
                      defaultChecked
                    />

                    <label htmlFor="payKnet">
                      <div className="row m-0 align-items-center">
                        <span className="pl-3">
                          <img
                            className="img-pro"
                            width="30"
                            src={`/images/Knet.png`}
                            alt=""
                          />
                        </span>
                        <span className="pl-3">
                          <h4 className="mt-2">Knet</h4>
                          {/* <h6> 15 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</h6> */}
                        </span>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="mt-3 mb-5 paymentContainer  p-0">
                  <div>
                    <input
                      type="radio"
                      checked={payWith == "card"}
                      value="card"
                      name="payWith"
                      id="payMaster"
                    />
                    <label htmlFor="payMaster">
                      <div className="row m-0 align-items-center">
                        <span className="pl-3">
                          <img
                            className="img-pro"
                            width="30"
                            src={`/images/masterVisa.png`}
                            alt=""
                          />
                        </span>
                        <span className="pl-3">
                          <h4 className="mt-2">Debit / Credit Card</h4>
                        </span>
                      </div>
                    </label>
                  </div>
                </div>

                {payWith == "card" ? (
                  <div className="col-12">
                    <GoSellElementsTrans
                      ref={childRef}
                      // state={state}
                      //   inpValue={inpValue}
                      setTapId={setTapId}
                      payWithValue={payWith}
                      setButtonLoading={setButtonLoading}
                    />
                  </div>
                ) : (
                  ""
                )}
              </section>
              <section id="process-btn" className="row m-0">
                <Button
                  className="col-12  btn-main align-item-center justify-content-center"
                  disabled={buttonLoading}
                  // type="submit"
                  onClick={handleSubmit}
                >
                  {buttonLoading ? "Redirecting" : " Confirm and pay"}
                </Button>
              </section>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default withTranslation()(CustomizedDialogs);
