// hooks
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import noImg from "../assets/images/no-image.svg";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { handleFeaturedProduct } from "../../../redux-store/action";
import { globalHandleCheck, globalHandleRadio, handleAddonsDecrement, handleAddonsIncrement } from "../../../global-functions/addToCartGlobal";



export const useFeaturedProductAddonsQuantity = (addonLimit,addons,quantityValue,setAddons,setAddOnsTotal,setAddonLimit) => {

	//States:
	const appState = useSelector((state) => state);
	const appDispatch = useDispatch();
	const { mSlug, slug, islug } = useParams();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	let parent_id = searchParams.get("parent");




	///// handleFunctions:
	const getTotal = () => {
		var total = 0;
		addons.forEach((element) => {
		  if (element.check_status) total += element.total();
		});
		setAddOnsTotal(total);
	  };
	const handleCheck = (addon_id, category_id) => () => {
		let res = globalHandleCheck (
		  addonLimit,
		  addons,
		  addon_id,
		  category_id,
		  appDispatch,
		  appState,
		  quantityValue
		);
		setAddons(res);
		getTotal();

	  };
	  const handleRadio = (addon_id, category_id, ind) => () => {
		let res = globalHandleRadio(
		  addonLimit,
		  addons,
		  addon_id,
		  category_id,
		  appDispatch,
		  appState,
		  quantityValue
		);
		setAddons(res);
	
		getTotal();
	  };

	    // addon Quantity Funtion
  const handleIncrementAddons = (addon_id, category_id) => {
    let res = handleAddonsIncrement (
      addonLimit,
      addons,
      addon_id,
      category_id,
      appDispatch,
      appState,
      quantityValue
    );
    setAddons(res);
    getTotal ();
    // handleAddonsLimit();
  };

  const handleDecrementAddons = (addon_id, category_id) => {
    // e.preventDefault();
    let res = handleAddonsDecrement (addonLimit, addons, addon_id, category_id);
    setAddons(res);
    getTotal();
    // handleAddonsLimit();
  };
  const handleAddonsLimit = () => {
    let newArry = [...addonLimit];
    for (let i = 0; i < newArry.length; i++) {
      var arr = [];
      let sum = 0;
      for (let k = 0; k < addons.length; k++) {
        if (addons[k].category_addon_id == newArry[i].id) {
          arr.push(addons[k].quantity);
        }
      }
      for (let j = 0; j < arr.length; j++) {
        sum += arr[j];
        newArry[i].addons_qty = sum;
      }
    }
    setAddonLimit(newArry);
  };
	///// useffects

	useEffect(() => {
		if (addons.length > 0) {
		  handleAddonsLimit();
		}
	  }, [addons]);
	useEffect(() => {
	}, []);



	return {handleCheck,handleRadio,handleIncrementAddons,handleDecrementAddons};
};