/* eslint-disable  */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import other components
import StateContext from "../../../store/StateContext";
import DispatchContext from "../../../store/DispatchContext";
import StoreOption from "../components/StoreOption";
// import noImg from "../compnent/assets/images/no-image.svg"
import noImg from "../assets/images/no-image.svg";
// redux
import { useSelector,  } from "react-redux";
import { get_lang } from "../../../global-functions/apiGlobal";

function IsClosed() {
  const appState = useSelector((state) => state);
  return (
      
    <div>
       <section id="info">
          <Link to="/contact">
            <div className="row m-0 align-items-center">
              <div className="logoImg">
                <img
                  src={
                    appState.storeInfo.logo
                      ? `${appState.storeInfo.logo}`
                      : `${noImg}`
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="infoContent pl-2">
                <h4 className="m-0">
                  <b>{get_lang(appState.storeInfo.businessName, appState.langValue)}</b>
                </h4>
              </div>
              <div
                className={
                  appState.langValue === "ar"
                    ? "infoIcon mr-auto mx-0"
                    : "infoIcon ml-auto mx-0"
                }
              >
                <i className="fa fa-info"></i>
              </div>
            </div>
          </Link>
        </section>
        {/* StoreOption component */}
        <StoreOption />

        <div id="storeBusy">
          <div className="storeBusy">
            <div className="storeBusy-404"></div>
            <img
              src={`/images/StoreClosed.png`}
              className="img-pro"
              width={100}
              alt=""
            />
            <h2>Store Closed</h2>
            <h5>Oops! Store Is Closed Right Now</h5>
            <p className="text-muted">
              Sorry but the page you are looking for does not exist, have been
              removed. name changed or is temporarily unavailable
            </p>
            {/* <Link to="/">Back to homepage</Link>x */}
          </div>
          {/* <p className="text-muted mt-3"> Store will be open in </p>? */}
        </div>
    </div>
      
  );
}

export default IsClosed;
