/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./reOrder.scss";
import { useSelector, useDispatch } from "react-redux";
// cookie functions
import { erase, getCookie } from "../../../../global-functions/cookieFunction";
// for Localization
import { withTranslation, useTranslation } from "react-i18next";
// owl carasoul work start
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// owl carasoul work end
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { Button } from "@material-ui/core";
import { get_lang } from "../../../../global-functions/apiGlobal";
// axios for api
import axios from "axios";
// material ui
import Backdrop from "@mui/material/Backdrop";
import CircularIndeterminate from "../../../../layout-one/js/components/CircularIndeterminate";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { orderAgain } from "../../../../global-functions/reOrder";
import { freezOff, freezOn, handleError } from "../../../../redux-store/action";
import { roundFixed } from "../../../../global-functions/roundFixed";
import { getServiceName } from "../../../../global-functions/variables";
import { handleStockQuantity } from "../../../../global-functions/addToCartGlobal";

const ReOrder = ({ item }) => {
  const { t } = useTranslation();
  let history = useHistory();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [invoiceId, setInvoiceId] = useState([]);
  const handleLogout = () => {
    appDispatch({
      type: "SET_LOGOUT",
      loggedIn: false,
    });
    erase("tokenAuth");
    erase("Profile");

    localStorage.removeItem("userInfo");
    localStorage.setItem("loggedIn", false);
    appDispatch({
      type: "SET_USERINFO",
      userInfo: [],
    });
    appDispatch({
      type: "SHOW_ALERT",
      alertType: "success",
      message: "Successfully LogOut",
    });
  };

  const ReOrder = async (id) => {
    let flag = true;
    if (id) {
      appDispatch(freezOn());
      let invoiceId = id;
      let orderResponse = await orderAgain(appState, invoiceId); // like for cart
      if(orderResponse?.length > 0) {
        orderResponse.forEach(product => {
          let resLikeRealProduct = {...product, id: product?.product_id}
        });
      }
      if (orderResponse.length > 0) {
        appDispatch({
          type: "updateCart",
          cart: orderResponse,
        });
        localStorage.setItem(
          `${appState.storeInfo.slug}_${getServiceName(appState)}_cart`, 
          JSON.stringify(orderResponse)
        );
        appDispatch({
          type: "showAddToCartPopup",
          addToCartPopup: true,
          cartProd: orderResponse[0],
        });
        history.push("/cart");
      } else {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: t("custom-msg.product-notfound"),
        });
      }
      appDispatch(freezOff());
    }
  };

  const fetchData = async () => {
    let arry = [];
    let id = [];

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/customer/invoice?paginate=10&store=` +
          appState.storeInfo.slug,
        {
          headers: {
            Authorization: `Bearer ${appState.loginToken}`,
          },
        }
      );

      if (response.data.success) {
        response.data.data.data.forEach((element) => {
          if (element.business.slug == appState.storeInfo.slug) {
            arry.push(element.invoiceable);
            id.push(element.id);
          }
        });
        setOrders(arry);
        setInvoiceId(id);
      }
      setIsLoading(false);
    } catch (e) {
      if(e.code == "ERR_NETWORK"){
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      }else
      if (e == undefined) {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      } else {
        appDispatch(handleError(e));
      }
      setIsLoading(false);
      Promise.reject(e);
    }
  };

  useEffect(() => {
    if (appState.loggedIn) {
      fetchData();
    }
  }, [appState.loggedIn]);

  return (
    <>
      {isLoading ? (
        <>
          <div className="row m-0 mb-4 mt-3 d-flex flex-row">
            <div className="col-6  ">
              <Skeleton height={50} />
            </div>
            <div className="col-10 ">
              <Skeleton height={50} />
            </div>
            <div className="col-3 ">
              <Skeleton height={50} />
            </div>
          </div>
        </>
      ) : (
        orders.length > 0 && (
          <div
            id="reOrderMain"
            className={`px-3 pt-1 ${
              appState.langValue === "ar"
                ? "directionRt classForArabicReOrderMain"
                : ""
            } ${appState.langValue == "ar" ? "arabicFamilyParent" : " "}`}
          >
            <h1
              className={`reOrderheading familyManrope800 px-2 m-0  ${
                appState.langValue === "ar" ? "text-right" : "text-left"
              }`}
            >
              {" "}
              {t("open-order.Previously-ordered")}
            </h1>
            <div
              className={`row   ${
                appState.langValue === "ar" ? "pr-2 pl-3" : "pr-3 pl-2"
              } `}
            >
              <OwlCarousel
                className="owl-theme  "
                items="1"
                nav
                dots
                margin={0}
                customtransition="2000ms"
                transitionduration={500}
              >
                {orders.map((order, ind) => {
                  ind += 1;
                  
                  return (
                    Number(order.amount) != 0 &&
                    <div className="item black " key={ind}>
                      <a>
                        {" "}
                        <span className="card-item">
                          <p className="cardDates familyManrope600">
                            Last order {order.created_at} {ind}
                          </p>

                          <div className="d-flex mt-2">
                            <div className="largeAvatar">
                              {/* <img
                            src={order.orders[0] ? order.orders[0].product_image : "../images/no-image.svg"}
                            alt=""
                            className="img-fluid imgDiv h-80p"
                          /> */}
                              <Avatar
                                variant="square"
                                src={
                                  order.orders[0]
                                    ? order.orders[0].product?.image
                                    : "../images/no-image.svg"
                                }
                              ></Avatar>
                            </div>
                            <div className="px-3">
                              <h1 className="cardProductName text-uppercase familyManrope500 ">
                                {order.orders[0]
                                  ? get_lang(
                                      order.orders[0].product?.name,
                                      appState.langValue
                                    )
                                  : "Product name missing"}
                              </h1>
                              <h4 className=" text-capitalize cardPrice  ">
                                {Number(order.amount) <= 0 ? (
                                  t("home.Free")
                                ) : (
                                  <>
                                    {" "}
                                    {roundFixed(
                                      Number(order.amount),
                                      appState.storeInfo.roundLevel,
                                      appState.storeInfo.roundMethod
                                    )}{" "}
                                    <span className="px-2">
                                    {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}                                    </span>{" "}
                                  </>
                                )}
                              </h4>
                            </div>
                          </div>
                          {/* here will insert */}
                          <div className="row mt-2 smallAvatar d-flex align-items-center justify-content-between">
                            <div className="pl-3">
                              <AvatarGroup max={3}>
                                {order.orders.map((item, i) => {
                                  return (
                                    <Avatar key={i}>
                                      <img
                                        src={
                                          item
                                            ? item?.product?.image
                                            : "../images/no-image.svg"
                                        }
                                        alt=""
                                        className="img-fluid imgDiv"
                                      />
                                    </Avatar>
                                  );
                                })}

                                {/* <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" /> */}
                              </AvatarGroup>
                            </div>
                            {/* <a href="" className={`skipBtn  familyManrope800 ${appState.langValue == "ar" ? "pr-4" : "pr-4 "
                            }`}> {t("open-order.Skip")}</a> */}
                            {!appState.payTableMode &&
                              appState.isAddToCartEnable && (
                                <div className="orderAgain pr-2">
                                  <Button
                                    className={` px-4 ${
                                      appState.langValue === "ar"
                                        ? "direction-ltr"
                                        : ""
                                    }`}
                                    variant="text"
                                    onClick={() => ReOrder(invoiceId[ind - 1])}
                                  >
                                    {t("open-order.Re-order")}
                                  </Button>
                                </div>
                              )}
                          </div>
                        </span>
                      </a>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default withTranslation()(ReOrder);
