export const handleMaxPunch = (value) => {
    let max = Number(value)
    max = max  > 5 ? 5 : max
    return Number(max)
}


export const handleLoyalPunch = (collected, total, limit = 5) => {
    let collectedPunch = Number(collected) ?? 0;
    let totalPunch =  Number(total) > 5 ? 5 : Number(total);
    let limitPunch = Number(handleMaxPunch(totalPunch));

    let cal = Math.floor((collectedPunch / totalPunch) * limitPunch);
    return Number(cal)

};  

