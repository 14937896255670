const IMAGES = [{
    image: require('./most-selling.svg').default,
    name: 'most-selling.svg'

}, {
    image: require('./trending.svg').default,
    name: 'trending.svg'

}, {
    image: require('./chef-choice.svg').default,
    name: 'chef-choice.svg'

}, {
    image: require('./Megaphone.svg').default,
    name: 'Megaphone.svg'

}, {
    image: require('./dairy-free.svg').default,
    name: 'dairy-free.svg'

}, {
    image: require('./Gluten-Free.svg').default,
    name: 'Gluten-Free.svg'

}, {
    image: require('./vegan.svg').default,
    name: 'vegan.svg'

}, {
    image: require('./vegetarian.svg').default,
    name: 'vegetarian.svg'

}, {
    image: require('./mild.svg').default,
    name: 'mild.svg'

},
{
    image: require('./spicy.svg').default,
    name: 'spicy.svg'

}
    , {
    image: require('./nut.svg').default,
    name: 'nut.svg'
},
{
    image: require('./allery-1.svg').default,
    name: 'allery-1.svg'
},
{
    image: require('./allery-2.svg').default,
    name: 'allery-2.svg'
},
{
    image: require('./allery-3.svg').default,
    name: 'allery-3.svg'
},
{
    image: require('./allery-4.svg').default,
    name: 'allery-4.svg'
},
{
    image: require('./allery-5.svg').default,
    name: 'allery-5.svg'
},
{
    image: require('./allery-6.svg').default,
    name: 'allery-6.svg'
},

{
    image: require('./dairy.svg').default,
    name: 'dairy.svg'
}
    , {
    image: require('./egg3.svg').default,
    name: 'egg3.svg'
},
{
    image: require('./gluten.svg').default,
    name: 'gluten.svg'
}
    ,
{
    image: require('./mustard.svg').default,
    name: 'mustard.svg'
}
    , {
    image: require('./soy.svg').default,
    name: 'soy.svg'
}

]
export default IMAGES;

