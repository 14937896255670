/* eslint-disable default-case */
/* eslint-disable no-useless-escape */
/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { useHistory,   Link,Redirect } from "react-router-dom";
import PropTypes from "prop-types";
// Components
import StateContext from "../../../store/StateContext";
import DispatchContext from "../../../store/DispatchContext";
// import SearchList from "../components/SearchList";
import DineInForm from "../components/DineInForm";
import DeliveryForm from "../components/DeliveryForm";
// Material Ui
import { Tab, Tabs } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import IconButton from "@material-ui/core/IconButton";
import PickupForm from "../components/PickupForm";
import CircularIndeterminate from "../components/CircularIndeterminate";

// react-reveal animations
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import { withTranslation, useTranslation, Trans } from "react-i18next";

// React Redux
import { useSelector, useDispatch } from "react-redux";

// TabPanel
function TabPanel(props) {

  const { children, value, index, ...other } = props;
  // console.log(index);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
// A11 Y PROPS
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// Branch
const Branch = () => {
  const { t } = useTranslation();
  // @States & Variables
  const history = useHistory();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [value, setValue] = useState();
  const [storeStatus, setStoreStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [otpFieldsStatus, setOtpFieldsStatus] = useState(false)
  
  const [customModes, setCustomModes] = useState([
    {
      id: 1,
      name: "dine-in",
    },
    {
      id: 2,
      name: "pick-up",
    },
    {
      id: 3,
      name: "delivery",
    },
  ]);

  const compareDay = async () => {
    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var workingHour = appState.storeInfo.workingHours.filter(
      (x) => x.day === days[new Date().getDay()]
    )[0];
    if (workingHour.is_closed) {
      return false;
    }

    var closingTime = new Date();
    closingTime.setHours(workingHour.close_time.split(":")[0]);
    closingTime.setMinutes(workingHour.close_time.split(":")[1]);
    closingTime.setSeconds(workingHour.close_time.split(":")[2]);

    var startingTime = new Date();
    startingTime.setHours(workingHour.open_time.split(":")[0]);
    startingTime.setMinutes(workingHour.open_time.split(":")[1]);
    startingTime.setSeconds(workingHour.open_time.split(":")[2]);

    var currentTime = new Date();

    var IsOpen = startingTime <= currentTime && currentTime <= closingTime;
    return IsOpen;
  };

  useEffect(async () => {
    var arry =  [];
    for (var i = 0; i < appState.serviceMode.length; i++) {
      for (var j = 0; j < customModes.length; j++) {
        if (appState.serviceMode[i] == customModes[j].name) {
          if(appState.qrTable != null){
             customModes[j].name != 'pick-up' && arry.push(customModes[j]); 
          }
          else{
            customModes[j].name == 'pick-up' && arry.push(customModes[j]); 
          }
        }
      }
    }
    setCustomModes(arry);
    setValue(arry[0].id);
    setIsLoading(false);
  }, []);

  // @Functions
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log(newValue);
  };
  // component for first tab active with form

  const Done = () => {
    // console.log(appState.fields, "fields");
    let tab = document.getElementById(`simple-tab-${value}`);
    let fields = {
      ...appState.fields,
      serviceMode: tab.innerText,
      serviceModeId: value,
    };
    appDispatch({
      type: "UPDATE_FIELDS",
      fields: fields,
    });
    history.push("/cart");
  };

  const goBackBtn = () => {
    if(appState.formData.openBill == false){
      localStorage.removeItem("formData")
    }
    // history.goBack() 
  }

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0);
    });
  }, []);

  const arLanguageHandle = (e) => {
    // console.log("ar change");
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");

    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };
  if (isLoading)
    return (
      <div className="d-flex justify-content-center align-items-center h-100vh">
        {" "}
        <CircularIndeterminate />{" "}
      </div>
    );
  return (
    <React.Fragment>

  {   appState.cart.length >0 ? <div id="wrapper">
        <div id="Branch">
          { !otpFieldsStatus && <section id="topNav" className="fixed-top  col-md-12 p-0 col-xl-5 d-flex align-items-center justify-content-between"  >
            <nav className="bckNavbar d-flex align-items-center">
              <Link to="/">
                <IconButton
                  aria-label="back"
                  className={
                    appState.langValue === "ar" ? "backBtnAr" : "backBtn2"
                  }
                  // orderbckBtn"
                  onClick={goBackBtn}
                >
                  {appState.langValue === "ar" ? (
                    <ArrowForwardIosIcon
                      style={{ padding: 0 }}
                      fontSize="inherit"
                    />
                  ) : (
                    <ArrowBackIosIcon fontSize="inherit" />
                  )}
                </IconButton>
              </Link>
  
              {/* <p className="text-center ">{get_lang(appState.storeInfo.businessName, appState.langValue)}</p> */}
                <h5 className="mt-2">{t("cart.Order")}</h5>
            </nav>
            {appState.langValue == "ar" ? (
              <IconButton
                id="headerBtn"
                onClick={(e) => enLanguageHandle(e)}
              >
                <span  style={{color: 'black'}}>EN</span>
              </IconButton>
            ) : (
              <IconButton
                id="headerBtn"
                onClick={(e) => arLanguageHandle(e)}
                className="arabicBtn"
              >
                <span  style={{color: 'black'}} >عربي </span>
              </IconButton>

            )}
          </section>}
  
          {/* changes end may-24 */}
          {/* <!--  --> */}
          <section id="chooseLocation " className="px-4 mt-5"> 
            <div className="branchBtn ordersForm">
              {/* ..........................  SAJID STYLE CHANGES */}
             {!otpFieldsStatus && <Tabs
                indicatorColor="primary"
                id="wpc_tabId"
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                variant="fullWidth"
                className="wpc_tabs"
                // initialSelectedIndex={x}
              >
                {appState.qrTable === null? customModes
                    ? customModes.map((mode, index) => (
                      <Tab
                        className="wpc_tab"
                        value={mode.id}
                        label={mode.name}
                        key={index}
                        {...a11yProps(mode.id)}
                      />
                    ))
                  : ""
                :
                <Tab
                className="wpc_tab"
                value={customModes[0].id}
                label={customModes[0].name}
                key={0}
                {...a11yProps(customModes[0].id)}
              />
                }
              </Tabs>}
            </div>
            {/* changes sajid may-24 */}
            <section id="srchList">
              <div className="tab-content" id="myTabContent">
                <TabPanel value={value} index={1}>
                  <DineInForm otpFieldsStatus={otpFieldsStatus} setOtpFieldsStatus={setOtpFieldsStatus} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {/* <SearchList Done={Done} /> */}
                  <PickupForm />
                </TabPanel>
                {/* <!-- There is an error --> */}
                <TabPanel value={value} index={3}>
                  <DeliveryForm />
                </TabPanel>
              </div>
            </section>
          </section>
        </div>
      </div>
  :
  <Redirect push to={`/`} />
  
  }  
    </React.Fragment>
  );
};

export default withTranslation()(Branch);


