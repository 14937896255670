import { roundFixed } from "./roundFixed";

export const handleCalculatedSubtotal = (service, store_charge, amount) => {
  const { discount, total_amount } = service; 
  const { calculated_using_subtotal} = store_charge;
  let calculateAmount = 0
    if(calculated_using_subtotal){
      calculateAmount = (Number(total_amount) * (Number(amount)/100)) 
    }
    else{
        calculateAmount = ((Number(total_amount) - Number(discount)) * (Number(amount)/100))
    }
    return calculateAmount
}

export const handleTapCharges =  (service, state) => {
  const { charges, total_charges, total_amount } = service;
  console.log(service);
  let arr =  [];
  if (charges?.length > 0) {
    charges.forEach((item) => {
      const { store_charge, amount, type, total_tax } = item;
      let data = {
          id: store_charge?.id,
          name: store_charge?.name,
          pos: store_charge?.pos,
          type: store_charge?.type,
          amount: amount,
          calculateAmount : type == "fixed" ? amount  :  roundFixed( handleCalculatedSubtotal(service, store_charge, amount), state.storeInfo.roundLevel, state.storeInfo.roundMethod) ,
          total_charges: total_charges,
          tax: Number(total_tax)
        };

      arr.push(data);
    });

  }
  return arr
};
