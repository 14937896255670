import * as React from 'react';
import "./QrCodeOptions.scss";
import { useHistory } from "react-router-dom";

// material ui
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop from "@mui/material/Backdrop";
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// localizations
import { withTranslation, useTranslation, Trans } from "react-i18next";
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getDineInOrder, getTableInvoice } from "../../../../redux-store/action";

function SwipeableEdgeDrawer(props) {
  const history = useHistory();
  const appDispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const appState = useSelector((state) => state);


  // This is used only for the example
  const handleClickPay = () => {
    if (appState.openBill && appState.qrTable && props.oldInvoice != null) {
      // appDispatch(getDineInOrder(appState, history, true))
      var pay_url = `${process.env.REACT_APP_PAY_URL}`
      window.location.replace(`${pay_url}/invoice/in/${appState.oldInvoice}`);
    }

  }
  const handleClickOrder = () => {
    appDispatch({
      type: 'setQrOptionStatus',
      qrOptionStatus: false
    })
  }

  return (
    <>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        open={appState.qrOptionStatus}
      />

      <div id="mySidenav" className={`SwipeableDrawerHomel1   ${appState.langValue == "ar" ? "SwipeableDrawerHomel1Arabic" : " "
        }`}>
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div className="container-fluid content px-4 mt-5">
          <h1 className={` ${appState.langValue === "ar" ? "text-right familyGraphikArabic800 " : " familyManrope800"
            }`}
          >{t("contact.Please-choose-an-option")}</h1>
          <div className="row mt-3 px-2">
            <Button className={`py-3 px-4 d-flex justify-content-between ${appState.langValue === "ar" ? " familyGraphikArabic600 " : ""
              }`}
              onClick={() => handleClickOrder()}
            ><span>  {t("contact.I-would-like-to-order-first.normal")} <strong>{t("contact.I-would-like-to-order-first.bold")}</strong></span> <span >{appState.langValue === "ar" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}</span> </Button>
            <Button className={` py-3 px-4 mt-3 justify-content-between ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              }`}
              onClick={() => handleClickPay()}
            > <span > {t("contact.I-already-ordered-I-want-to-pay.normal")} <strong> {t("contact.I-already-ordered-I-want-to-pay.bold")} </strong></span> <span >{appState.langValue === "ar" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}</span> </Button>
          </div>
        </div>
      </div>
    </>


  );
}



export default withTranslation()(SwipeableEdgeDrawer);