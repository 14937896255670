/* eslint-disable */
import React, { useState } from "react";
import './copyText.scss'
import IsClosed from "../../Pages/IsClosed";
function CopyText({ textToCopy }) {
  const [isCopied, setIsCopied] = useState(false);
  const [toolTipText, setToolTipText] = useState("Copy this url!");

  function handleCopyClick() {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
    setToolTipText("Copied!");
    const change = () => {
      setToolTipText("Copy this url!");
      setIsCopied(false);
    };
    setTimeout(change, 3000);
  }

  return (
    <>
        <span  id='copy-text' >
        <p
        className=" pointer-cursor p-0 m-0"
        style={isCopied ? { color: "green" } : { color: "black" }}
        type="button"
        title={toolTipText}
        onClick={handleCopyClick}
      >
        {textToCopy}
      </p>
     { isCopied &&  <p className="tooltiptext">{toolTipText}</p>}
        </span>
     
    </>
  );
}

export default CopyText;
