/* eslint-disable */
import React, { useState } from "react";
import "./signupPhoneModal.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import kuwaitinput from "../Assets/images/kuwaitinput.png";
import enabill from "../Assets/images/enabill.png";
import Backdrop from "@mui/material/Backdrop";
// Localization
import { withTranslation, useTranslation } from "react-i18next";

import CircularIndeterminate from "../../layout-one/js/components/CircularIndeterminate";
import FbLogin from "../../layout-one/js/components/FbLogin";
import LoginGoogle from "../../layout-one/js/components/LoginGoogle";
import AppleLogin from "../../layout-one/js/components/AppleLogin";
import VerifyPhoneNumber from "../verifyPhoneNumber/verifyPhoneNumber";
import { freezOn, freezOff } from "../../redux-store/action";
import InputAdornment from '@mui/material/InputAdornment';
// axios
import Axios from "axios";

// import Redux
import { useSelector, useDispatch } from "react-redux";

const SignupPhoneModal = () => {
  const [purpose, setPurpose] = useState("");
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");
  const [signUpValues, setSignUpValues] = useState({
    phoneCode: "965",
    phoneNumber: ""
  });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const appDispatch = useDispatch();
  const appState = useSelector((state) => state);
  const handleClose = () => {
    appDispatch({ type: "setSignUpPhoneModal", signUpPhoneModal: false });
  };
  const handleOTP = () => {
    setPurpose("SignUp");
    appDispatch({ type: "setOtpModal", otpModal: true });
    // appDispatch({ type: "setSignUpModal", signUpModal: true });
    appDispatch({ type: "setSignUpPhoneModal", signUpPhoneModal: false });
  };
  const handleOpenLogin = () => {
    appDispatch({ type: "setLoginModal", loginModal: true });
    appDispatch({ type: "setSignUpPhoneModal", signUpPhoneModal: false });
  };



  const updateField = (e) => {
    console.log(e.target.value)
    if(e.target.name === "phoneNumber" ){
      if(signUpValues.phoneNumber.length < 8 || e.nativeEvent.data == null){
        setSignUpValues({
          ...signUpValues,
          [e.target.name]: e.target.value,
        });
      }
    }
    else{
      setSignUpValues({
        ...signUpValues,
        [e.target.name]: e.target.value,
      });
    }
  
  };
  const handleSendOtp = async (e) => {
    e.preventDefault();
    appDispatch({ type: "setFieldValues", fieldValues: signUpValues });
    setLoading(true);
    appDispatch(freezOn());
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_URL}/send/otp`,
        { mobile_no: `${signUpValues.phoneCode}${signUpValues.phoneNumber}` }
      );
      if (response.data.success === true) {
        setPurpose("SignUp");
        setLoading(false);
        appDispatch(freezOff());
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "success",
          message: response.data.message,
        });
        setSignUpValues({
          email: "",
          password: "",
          phoneCode: 965,
          phoneNumber: "",
        })
        handleOTP();
      } else if (response.data.success === false) {
        setLoading(false);
        appDispatch(freezOff());
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: response.data.message,
        });
      }
    } catch (e) {
      setLoading(false);
      appDispatch(freezOff());
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: e.message,
      });
    }
  };

  // localization  start

  const arLanguageHandle = (e) => {
    // console.log("ar change");

    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
    //   i18n.changeLanguage('ar')
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");

    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  // localixatio end
  return (
    <section>
    {appState.otpModal &&  <VerifyPhoneNumber
        closeBtn={false}
        Purpose={purpose}
        phone={signUpValues}
      />}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={appState.signUpPhoneModal}
        // BackdropProps={{ invisible: true }}
        onClose={handleClose}
      >
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularIndeterminate />
          </Backdrop>
        )}
        <DialogContent>
          <div
            className={`${appState.langValue === "ar" ? "directionRtl" : ""}`}
            id="signupPhoneModalMain"
          >
            <div className="row  pt-2">
              <div className="col-md-12 d-flex justify-content-between classforPaddinglogo">
                <img src={enabill} alt="enabill-logo" />
                <div>
                  {appState.langValue === "ar" ? (
                    <Button
                      className="px-5 mx-2 arabicBtn"
                      variant="contained"
                      onClick={(e) => enLanguageHandle(e)}
                    >
                      English
                    </Button>
                  ) : (
                    <Button
                      className="px-5  arabicBtn"
                      variant="contained"
                      onClick={(e) => arLanguageHandle(e)}
                    >
                      العربيه
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <h1
              className={`familyManrope800 mt-2 ${appState.langValue === "ar" ? "d-flex" : ""
                }`}
            >
              {" "}
              {t("Login.sign-up")}
            </h1>
            <p
              className={`familyManrope600 loginTitle m-0${appState.langValue === "ar" ? "text-right d-flex" : ""
                }`}
            >
              {t("Forms.enter-phone")}
            </p>
            <form onSubmit={handleSendOtp}>
              <div
                className={`classforPaddingTextfield ${appState.langValue === "ar" ? "classforArabic" : ""
                  }`}
              >
                <div className="row mt-3  ">
                  <div
                    className={`col-4 px-2 col-lg-3 classForNonSelectableInput selectCountry classforPaddingSeclectTextfield ${appState.langValue === "ar"
                        ? "classforArabic classforArabicSelectField"
                        : ""
                      }`}
                  >
                    <TextField
                      className="mt-3"
                      id="outlined-basic"
                      // label={t("checkOut.Name-(optional)")}
                      variant="outlined"
                      disabled={true}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><img
                          className="inputadornmentImg flagImg"
                          src={kuwaitinput}
                          alt="flag"
                        />{" "}
                          <span className="px-2 textOver">+965<span className="countryCode"></span>  </span></InputAdornment>,
                      }}
                    />
                    {/* <FormControl sx={{ m: 1, minWidth: 78 }}>
                      <Select
                        value={signUpValues.phoneCode}
                        onChange={updateField}
                        name="phoneCode"
                        // displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem className="" value="965">
                          <img
                            className="inputadornmentImg"
                            src={kuwaitinput}
                            alt="flag"
                          />{" "}
                          <span className="px-2">965</span>
                        </MenuItem>
                      </Select>
                    </FormControl> */}
                  </div>
                  <div className="col-8 col-lg-9">
                    <TextField
                      type="number"
                      inputProps={{ maxLength: 8, inputMode: "numeric" }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 8);
                      }}
                      className="mt-2"
                      variant="outlined"
                      id="outlined-basic"
                      label={t("Forms.phone-number")}
                      value={signUpValues.phoneNumber}
                      onChange={updateField}
                      name="phoneNumber"
                    />
                  </div>
                </div>
              </div>
              <div className="classforBtnPadding">
                <div className="row mt-2">
                  <div className="col-12 mt-1">
                    <Button
                      className=" blackBtn bgEnabill py-3 familyManrope800 "
                      variant="contained"
                      type="submit"
                    >
                      {t("Login.sign-up")}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            {/* mt-2 but for sajid */}
            <div className="classforBtnPadding ">
              <div className="row mt-2 d-flex justify-content-center">
                <p className="m-0">{t("Login.OR")}</p>
              </div>
            </div>
            <div className="classforBtnPadding socialLoginIconOnLargeScreen">
              {/* mt-2 but for sajid */}
              <div className="row mt-2 mx-0">
                <div className="col-md-6 p-0">
                  <FbLogin
                    fbloginHandleclose={handleClose}
                    fbloading={loading}
                    setfbLoading={setLoading}
                  />
                </div>
                {/* mt-2 but for sajid */}
                <div className="col-md-6 mt-2 mt-md-0 p-0">
                  <LoginGoogle
                    loginHandleclose={handleClose}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 ">
                  <AppleLogin
                    loginHandleclose={handleClose}
                    loading={loading}
                    setLoading={setLoading}
                  />

                  {/* <Button
                          className=" samsungBtn py-3 familyManrope800 "
                          variant="contained"
                        >
                          <span> Login with Samsung</span>
                        </Button> */}
                </div>
              </div>
            </div>

            <div className="row d-flex justify-content-evenly socialLoginIconOnsmallScreen mt-1">

              <FbLogin
                fbloginHandleclose={handleClose}
                fbloading={loading}
                setfbLoading={setLoading}

              />
              <LoginGoogle
                loginHandleclose={handleClose}
                loading={loading}
                setLoading={setLoading}

              />
              <AppleLogin
                loginHandleclose={handleClose}
                loading={loading}
                setLoading={setLoading}

              />
            </div>
            <div className="row mt-2">
              <div
                className={`col-12 ${appState.langValue === "ar" ? "d-flex" : ""
                  }`}
              >
                <p className="familyManrope700 notRegisterMain">
                  <span className="notRegister">
                    {t("Forms.already-account")}{" "}
                  </span>
                  <span>
                    {" "}
                    <a
                      className="createAccountAndforgotPass familyManrope800"
                      onClick={handleOpenLogin}
                    >
                      {t("Forms.sign-in")}
                    </a>{" "}
                  </span>{" "}
                </p>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default withTranslation()(SignupPhoneModal);
