import Pusher from "pusher-js";
import {
  cleanRest,
  freezOn,
  getDineInOrder,
  getPayTableInvoice,
} from "../redux-store/action";
import { getServiceName } from "./variables";

const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
  cluster: "eu",
  encrypted: true,
});

export const getLiveData = async (
  invoiceId,
  appState,
  appDispatch,
  setDineInOrders,
  dineinOrders,
  getDineIn,
) => {
  // console.log("invoiceId", invoiceId);
  let channel = pusher.subscribe(`App.Models.Invoice.${invoiceId}`);

  if (invoiceId != "") {
  // console.log("invoiceId", invoiceId);
if(appState.pusherActive){
  appDispatch({
    type: "setPusherActive",
    pusherActive: true,
  });
}
    channel.bind("order-updated", async (data) => {
      console.log(data, "order-updated");
      if (appState.payTableMode) {
        appDispatch(getPayTableInvoice(appState, appState.tableInvoice));
      } else if (Array.isArray(dineinOrders)) {
        console.log(dineinOrders,'dineInOrders', appState.pusherActive);
      
        let getOrder = await [...dineinOrders];
        getOrder.forEach((val) => {
          if (val.id == data.order.id) {
            if (val.status == "Completed") {
              appDispatch(freezOn());
              localStorage.setItem(
                `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
                JSON.stringify([])
              );
              appDispatch({
                type: "SET_FORM_DATA",
                formData: [],
              });
              const winUrl = window.open(
                `${process.env.REACT_APP_PAY_URL}/invoice/in/${invoiceId}`,
                "_self"
              );
              winUrl.focus();  
              appDispatch(cleanRest());
            } else {
              val.status = data.order.status;
              setDineInOrders(getOrder);
              // console.log('object');
            }
          }
        });
      }
    });

    channel.bind("invoice-cancel", function (data) {
      // console.log(data, "invocie-cancle");

      let id = data?.data?.id;
      appDispatch(cleanRest());
      const winUrl = window.open(
        `${process.env.REACT_APP_PAY_URL}/invoice/in/${id}`,
        "_self"
      );
      winUrl.focus();
    });

    channel.bind("order-deleted", function (data) {
      if (
        !appState.payTableMode &&
        dineinOrders != [] &&
        dineinOrders != undefined
      ) {
        getDineIn();
        let getOrder = [...dineinOrders];
        const itemIndx = getOrder.findIndex((el) => el.id === data.order_id);
        getOrder.splice(itemIndx, 1);
        setDineInOrders(getOrder);
      }
    });

    channel.bind("order-added", function (data) {
      console.log(data, "order-added");
      if (!appState.payTableMode) {
        getDineIn();

        // let getOrder = [...dineinOrders];
        // getOrder.push(data.order);
        // setDineInOrders(getOrder);
      }
    });

    channel.bind("invoice-updated", function (data) {
      // console.log(data, "invoice-updated");
      let id = data?.data?.id;

      if (!appState.payTableMode) {
        getDineIn();
      } else {
        appDispatch(getPayTableInvoice(appState, appState.tableInvoice));
      }
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }
};

export const getTableOrderId = (qrTable, appState, appDispatch, history) => {
  // console.log("invoiceId", qrTable);
  let channel = pusher.subscribe(`App.Models.Bench.${qrTable}`);

  if (appState.payTableMode) {
    channel.bind("order-pay-at-table", function (data) {
      let id = data?.data?.id;
      console.log(id, "order-added");
      if (data) {
        getLiveData(id, appState, appDispatch);
        // history.push('/checkout')
        appDispatch({
          type: "setTableInvoice",
          tableInvoice: id,
        });
        appDispatch(getPayTableInvoice(appState, id));
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "success",
          message: "Order is created on your table please check.",
        });
      }
    });
  }

  return () => {
    channel.unbind_all();
    channel.unsubscribe();
    pusher.disconnect();
  };
};

export const getNotification = (appState, appDispatch, device_id) => {
  let channel = pusher.subscribe(`Device.${device_id}`);
  
  // For Invoice closed
  channel.bind("invoice-closed", function (data) {
    console.log(data, "invoice-closed");
    if (data) {
     
    }
  });

  // Table change by vendor
  channel.bind("table-changed", function (data) {
    console.log(data, "table-changed");
    if (data) {
     
    }
  });
  return () => {
    channel.unbind_all();
    channel.unsubscribe();
    pusher.disconnect();
  };
};
