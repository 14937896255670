/* eslint-disable */
import React, { useState } from "react";
import "./loginModal.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import kuwaitinput from "../Assets/images/kuwaitinput.png";
import enabill from "../Assets/images/enabill.png";
import Axios from "axios";
import { withTranslation, useTranslation } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";
import CircularIndeterminate from "../../layout-one/js/components/CircularIndeterminate";
import InputAdornment from "@mui/material/InputAdornment";
// import Redux
import { useSelector, useDispatch } from "react-redux";

import FbLogin from "../../layout-one/js/components/FbLogin";
import LoginGoogle from "../../layout-one/js/components/LoginGoogle";
import AppleLogin from "../../layout-one/js/components/AppleLogin";
import SignupPhoneModal from "../signupPhoneModal/signupPhoneModal";
import { freezOn, freezOff } from "../../redux-store/action";


const LoginModal = ({ closeBtn }) => {
  const { t } = useTranslation();
  const appDispatch = useDispatch();
  const appState = useSelector((state) => state);
  const [check_status, setCheck_status] = useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");
  const [ setAlertMessage] = useState({
    open: false,
    message: "",
  });
  const [ setPurpose] = useState("signIn");
  const [loginValues, setLoginValues] = useState({
    email: "",
    password: "",
    phoneCode: 965,
    phoneNumber: "",
  });

  const [loading, setLoading] = useState(false);
  const [ setSuccess] = useState(false);


  const handleClose = () => {
    appDispatch({ type: "setLoginModal", loginModal: false });
  };

  const handleCheckbox = () => {
    setCheck_status(!check_status);
  };
  const handleRegistration = () => {
    setPurpose("signUp");
    appDispatch({ type: "setSignUpPhoneModal", signUpPhoneModal: true });
    handleClose();
  };
  const updateField = (e) => {
    if (e.target.name === "phoneNumber") {
      if (loginValues.phoneNumber.length < 8 || e.nativeEvent.data === null) {
        setLoginValues({
          ...loginValues,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setLoginValues({
        ...loginValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleOTP = () => {
    setPurpose("signIn");
    appDispatch({ type: "setOtpModal", otpModal: true });
    appDispatch({ type: "setLoginModal", loginModal: false });
  };

  const phoneLogin = async (e) => {
    appDispatch(freezOn());
    setLoading(true);
    setSuccess(false);
    e.preventDefault();
    appDispatch({ type: "setFieldValues", fieldValues: loginValues });
    if (loginValues.phoneCode && loginValues.phoneNumber !== "") {
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_API_URL}/check/mobile`,
          {
            country_code: `${loginValues.phoneCode}`,
            number: `${loginValues.phoneNumber}`,
          }
        );
        if (response.data.success === true) {
          appDispatch(freezOff());
          setLoading(false);
          setLoginValues({
            email: "",
            password: "",
            phoneCode: 965,
            phoneNumber: "",
          });
          appDispatch({
            type: "setFieldValues",
            fieldValues: response.data.data.user_id,
          });
          // console.log(appState.fieldValues)
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "success",
            message: response.data.message,
          });
          handleOTP();
        } else if (response.data.success === false) {
          appDispatch(freezOff());
          setLoading(false);
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: response.data.message,
          });
        }
      } catch (e) {
        // console.log(e);
        appDispatch(freezOff());
        setLoading(false);
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: e.message,
        });
      }
    } else {
      appDispatch(freezOff());
      setLoading(false);
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: t("Login.plz-fill"),
      });
    }
  };

  // localization  start

  const arLanguageHandle = (e) => {
    // console.log("ar change");
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
    //   i18n.changeLanguage('ar')
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");

    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  return (
    <div>
      {appState.signUpPhoneModal && <SignupPhoneModal />}
      {/* {appState.otpModal && (
        <VerifyPhoneNumber
          closeBtn={false}
          Purpose={purpose}
          phone={loginValues}
          sec={60}
        />
      )} */}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        // open={appState.loginModal}
        // BackdropProps={{ invisible: true }}
        onClose={closeBtn ? handleClose : ""}
      >
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularIndeterminate />
          </Backdrop>
        )}

        <DialogContent>
          <div
            className={`${appState.langValue === "ar" ? "directionRtl" : ""}`}
            id="loginModalMain"
          >
            {/* {closeBtn ? (
              <IconButton onClick={handleClose} className="ml-auto my-1 d-flex">
                <CloseIcon />
              </IconButton>
            ) : (
              ""
            )} */}
            <div className="row pt-2 ">
              <div className="col-md-12 d-flex justify-content-between classforPaddinglogo ">
                <img src={enabill} alt="enabill-logo" />

                <div>
                  {appState.langValue === "ar" ? (
                    <Button
                      className="px-5 mx-2 arabicBtn"
                      variant="contained"
                      onClick={(e) => enLanguageHandle(e)}
                    >
                      English
                    </Button>
                  ) : (
                    <Button
                      className="px-5  arabicBtn"
                      variant="contained"
                      onClick={(e) => arLanguageHandle(e)}
                    >
                      العربيه
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <h1
              className={`familyManrope800 mt-3 ${
                appState.langValue === "ar" ? "d-flex" : ""
              }`}
            >
              {t("Login.Login")}
            </h1>
            <form onSubmit={phoneLogin}>
              <div
                className={`classforPaddingTextfield ${
                  appState.langValue === "ar" ? "classforArabic" : ""
                }`}
              >
                <div className="row mt-3  ">
                  <div
                    className={`col-4 px-2 col-lg-3 classForNonSelectableInput selectCountry classforPaddingSeclectTextfield ${
                      appState.langValue === "ar"
                        ? "classforArabic classforArabicSelectField"
                        : ""
                    }`}
                  >
                    <TextField
                      className="mt-3"
                      id="outlined-basic"
                      // label={t("checkOut.Name-(optional)")}
                      variant="outlined"
                      disabled={true}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              className="inputadornmentImg flagImg"
                              src={kuwaitinput}
                              alt="flag"
                            />{" "}
                            <span className="px-2 textOver">
                              +965<span className="countryCode"></span>{" "}
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <FormControl sx={{ m: 1, minWidth: 78 }}>
                      <Select
                        value={loginValues.phoneCode}
                        onChange={updateField}
                        name="phoneCode"
                        // displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem className="" value="965">
                          <img
                            className="inputadornmentImg"
                            src={kuwaitinput}
                            alt="flag"
                          />{" "}
                          <span className="px-2">965</span>
                        </MenuItem>
                      </Select>
                    </FormControl> */}
                  </div>
                  <div className="col-8 col-lg-9">
                    <TextField
                      inputProps={{ inputMode: "numeric" }}
                      type="number"
                      className="mt-2"
                      variant="outlined"
                      id="outlined-basic"
                      label={t("Forms.phone-number")}
                      value={loginValues.phoneNumber}
                      onChange={updateField}
                      name="phoneNumber"
                    />
                  </div>
                </div>
              </div>

              <div className={`row py-2 mt-1 `}>
                <div className="col-md-12 rememberMeSec  d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center checkboxParent classforPaddingcheckbox">
                    <Checkbox
                      checked={check_status}
                      onClick={handleCheckbox}
                      color="default"
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                    <p className="familyManrope600 m-0 textBlack px-2">
                      {t("Login.remember")}
                    </p>
                  </div>

                  {/* <p className="m-0">
                    <a
                      className="createAccountAndforgotPass textBlack familyManrope600"
                      href=""
                    >
                      {t("Login.Forget-Pass")}
                    </a>
                  </p> */}
                </div>
              </div>
              <div className="classforBtnPadding">
                <div className="row mt-2">
                  <div className="col-12">
                    <Button
                      className=" blackBtn bgEnabill py-3 textCapitalized familyManrope800 "
                      variant="contained"
                      type="submit"
                    >
                      {loading ? t("Login.redirecting") : t("Login.Login")}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            <div className="row mt-2 d-flex justify-content-center">
              <p className="m-0">{t("Login.OR")}</p>
            </div>
            <div className="classforBtnPadding socialLoginIconOnLargeScreen">
              <div className="row mt-2 mx-0 ">
                <div className="col-md-6 p-0">
                  <FbLogin
                    fbloginHandleclose={handleClose}
                    fbloading={loading}
                    setfbLoading={setLoading}
                    setAlertMessage={setAlertMessage}
                  />
                </div>

                <div className="col-md-6 mt-2 mt-md-0 p-0">
                  <LoginGoogle
                    loginHandleclose={handleClose}
                    loading={loading}
                    setLoading={setLoading}
                    setAlertMessage={setAlertMessage}
                  />
                </div>
              </div>
              <div className="row mt-2 ">
                <div className="col-12">
                  <AppleLogin
                    loginHandleclose={handleClose}
                    loading={loading}
                    setLoading={setLoading}
                    setAlertMessage={setAlertMessage}
                  />

                  {/* <Button
                          className=" samsungBtn py-3 familyManrope800 "
                          variant="contained"
                        >
                          <span> Login with Samsung</span>
                        </Button> */}
                </div>
              </div>
            </div>

            <div className="row d-flex justify-content-evenly socialLoginIconOnsmallScreen mt-1">
              <FbLogin
                fbloginHandleclose={handleClose}
                fbloading={loading}
                setfbLoading={setLoading}
                setAlertMessage={setAlertMessage}
              />
              <LoginGoogle
                loginHandleclose={handleClose}
                loading={loading}
                setLoading={setLoading}
                setAlertMessage={setAlertMessage}
              />
              <AppleLogin
                loginHandleclose={handleClose}
                loading={loading}
                setLoading={setLoading}
                setAlertMessage={setAlertMessage}
              />
            </div>

            <div className="row mt-3 pb-2">
              <div
                className={`col-12 ${
                  appState.langValue === "ar" ? "d-flex" : ""
                }`}
              >
                <p className="familyManrope700 notRegisterMain">
                  <span className="notRegister">
                    {" "}
                    {t("Forms.not-register")},
                  </span>
                  <span>
                    {" "}
                    <a
                      className="createAccountAndforgotPass familyManrope800"
                      onClick={handleRegistration}
                    >
                      {t("Forms.create-account")}
                    </a>{" "}
                  </span>{" "}
                </p>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default withTranslation()(LoginModal);
