import React, { useState, useEffect } from "react";
import "./FeaturedProductSlider.scss";
import { useSelector, useDispatch } from "react-redux";
// cookie functions
import { erase, getCookie } from "../../../../global-functions/cookieFunction";
// for Localization
import { withTranslation, useTranslation } from "react-i18next";
// owl carasoul work start
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// owl carasoul work end
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { AppBar, Button } from "@material-ui/core";
import { get_lang } from "../../../../global-functions/apiGlobal";
// axios for api
import axios from "axios";
// material ui
import Backdrop from "@mui/material/Backdrop";
import { Skeleton } from "@material-ui/lab";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { orderAgain } from "../../../../global-functions/reOrder";
import { Link } from "react-router-dom";
import noImg from "../../assets/images/no-image.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FeaturedProduct from "../FeaturedProduct/FeaturedProduct";
import { sortedByNumber } from "../../../../utils";
import { handleStockQuantity } from "../../../../global-functions/addToCartGlobal";
import { useFeaturedProductIncDec } from "../../hooks/useFeaturedProductIncDec";
import FeaturedProductPopup from "../FeaturedProductPopup/FeaturedProductPopup";

const FeaturedProductSlider = (props) => {
  const { t } = useTranslation();
  const { product, placedBy, type } = props;
  let history = useHistory();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  // const { handleIncrement, handleDecrement, handleItemRemove } = useFeaturedProductIncDec(product, quantityValue, setQuantityValue, featuredProduct, addons, setCheckCart, placedBy);
  const [featuredProducts, setFeaturedProducts] = useState(null);
  const { handleIncrement, handleDecrement, handleItemRemove } = useFeaturedProductIncDec( type, placedBy, featuredProducts, setFeaturedProducts);
  
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);
  let tabInd = -1;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFeaturedProductQuanity = async (sortFeatured) => {
    setIsLoading(true);
    let prodTotalQty = 0;
    let data = [];

    sortFeatured.forEach(async (item) => {
      if (item.branches != undefined) {
        if (appState.qrBranch != null) {
          let getBranch = item.branches.find(
            (item) => item?.branch?.id == appState.qrBranch
          );
          if (getBranch != [] && getBranch != undefined) {
            if (getBranch.quantity != undefined) {
              item.quantity = parseInt(getBranch.quantity);
            }
            if (getBranch.price != undefined) {
              item.price = getBranch.price;
            }
            if (getBranch.discount != undefined) {
              item.discount = getBranch.discount;
            }
          }
        }
      }
      item.maxQuantity = item.quantity;
      item.addOnsTotal = 0;
      let findCartQty =  appState.cart.find((el, i) =>  el?.product_id == item?.id && el.placed_by == placedBy && el.pGroupId == appState.productGroupId);
     
      item.checkedCart = findCartQty != undefined ? findCartQty.checkedCart : false
      item.quantity = findCartQty != undefined ? findCartQty.quantity : (item.quantity > 0 || item.quantity == null) ? 1 : item.quantity
      // console.log(findCartQty,'findddd',item.checkedCart, item.name, item.quantity);

      prodTotalQty = await handleStockQuantity(
        appState.cart,
        item,
        appDispatch,
        appState
      );
      if (prodTotalQty > 0) {
        if (item.maxQuantity != null) {
          item.maxQuantity = item.maxQuantity - prodTotalQty;
        }
      }
     
    });
    setFeaturedProducts(sortFeatured);
    setIsLoading(false);
  };
/// commit for update 6 dec
  // useEffect(() => {
  //   if (product.featured_products != null || undefined) {
  //     let sortFeatured = sortedByNumber(product.featured_products)
  //     handleFeaturedProductQuanity(sortFeatured)

  
   

  const handleProductUpdateByCart = () => {
		let newCart = appState.cart[appState.cart.length - 1]
    console.log(newCart);
		const itmIndx = featuredProducts.findIndex((el) => newCart?.product_id == el?.id && newCart.placed_by == placedBy && newCart.pGroupId == appState.productGroupId);
		let filterProd = featuredProducts.find(
			(el, i) =>  newCart?.product_id == el?.id && newCart.placed_by == placedBy && newCart.pGroupId == appState.productGroupId);
		if (filterProd) {
			featuredProducts[itmIndx].quantity = newCart.quantity;
			featuredProducts[itmIndx].checkedCart = newCart.checkedCart;
			featuredProducts[itmIndx].addOnsTotal = newCart.sum_addons;
		}
	}
useEffect(() => {
 if(appState.cart != [] && appState.cart.length >0 && featuredProducts != null  ) {
  handleProductUpdateByCart()
 }
}, [appState.cart])

  useEffect(() => {
    if (product != undefined) {
      if (product != null || undefined) {
        let sortFeatured = sortedByNumber(product);
        handleFeaturedProductQuanity(sortFeatured);
      }
    }
  }, [product]);

  return (
    <div className="px-3" id="wrapperFeaturedProductSlider">
      {isLoading ? (
        <>
          <div className="row m-0 mb-4 d-flex flex-row">
            <div className="col-3  ">
              <Skeleton height={150} />
            </div>
            <div className="col-3 ">
              <Skeleton height={150} />
            </div>
            <div className="col-3">
              <Skeleton height={150} />
            </div>
            <div className="col-3">
              <Skeleton height={150} />
            </div>
          </div>
        </>
      ) : (
        featuredProducts && (
          <>
          
            <div
              className={`pt-1 mt-2 ${
                appState.langValue === "ar"
                  ? "directionRt classForArabicReOrderMain"
                  : ""
              } ${appState.langValue == "ar" ? "arabicFamilyParent" : " "}`}
            >
              <h4
                className={`reOrderheading familySora600  mt-3 ${
                  appState.langValue === "ar" ? "text-right" : "text-left"
                }`}
              >
                {" "}
                {/* {t("event.Categories")} */}
                {t("featured.Often-ordered-with")}
              </h4>
              <p
                className={`headLine  text-muted mb-3  ${
                  appState.langValue === "ar"
                    ? "text-right d-flex"
                    : "familyPoppins600"
                }`}
              >
                {t("featured.People-usually-order-these-items-as-well")}
              </p>
            </div>

            <div
              className={`row foodHallOwlCarousel ${
                appState.langValue === "ar"
                  ? "foodHallOwlCarouselArabic pr-2 pl-3"
                  : "foodHallOwlCarouselEn pr-3 pl-2"
              } `}
            >
              <AppBar className="appBar" position="static">
                <Tabs
                  onChange={handleChange}
                  value={value}
                  aria-label="icon label tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  {featuredProducts.map((featuredProduct, ind) => {
                    tabInd =
                      featuredProduct.maxQuantity > 0 ||
                      featuredProduct.maxQuantity == null
                        ? tabInd + 1
                        : tabInd;
                    return (
                      (featuredProduct.maxQuantity > 0 ||
                        featuredProduct.maxQuantity == null) &&
                      product != undefined && (
                        <Tab
                          disableRipple={true}
                          disableFocusRipple={true}
                          key={ind}
                          value={tabInd}
                          className="categoryTab"
                          icon={
                            product != undefined && (
                              <FeaturedProduct
                                featuredProduct={featuredProduct}
                                product={product}
                                ind={ind}
                                placedBy={placedBy}
                                handleIncrement ={handleIncrement} handleDecrement={handleDecrement} handleItemRemove={handleItemRemove}  
                                type={type}
                              />
                            )
                          }
                        />
                      )
                    );
                  })}
                </Tabs>
              </AppBar>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default withTranslation()(FeaturedProductSlider);



