
var shop_url = `${process.env.REACT_APP_SHOP_URL}`
if( ( shop_url == " https://shop.enabill.com" ||  shop_url == "https://app.enabill.com")){
(function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", "ivjlf255k4");
}
