/* eslint-disable */
import React from 'react'
// for Localization
import { withTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { get_lang } from '../../../../global-functions/apiGlobal';
import Product from '../Product';

function ProgressiveSearch({ t, i18n, userSearch }) {
  const appState = useSelector((state) => state);
  return (
    <div className="col-12 p-1 prod_main_div">
      <div className="col-12 inner_prod ">
        {userSearch != "" ? (
          appState.productsForSearch
          .filter(
            (sr) =>
              get_lang(sr.name, appState.langValue)
                .toLowerCase()
                .indexOf(userSearch.toLowerCase()) > -1
          ).length > 0 ? (
            appState.productsForSearch
              .filter(
                (sr) =>
                  get_lang(sr.name, appState.langValue)
                    .toLowerCase()
                    .indexOf(userSearch.toLowerCase()) > -1
              )
              .map((item, ind) => {
                return <Product item={item} key={ind} callingFrom="search" />;
              })
          ) : (
            <div className="text-center mt-5">
              <h4 className="text-muted">
                {/* Product not found */}
                {t("Search.productNot")}
              </h4>
            </div>
          )
        ) : (
          <div className="text-center mt-5">
            <h4 className="text-muted">
              {/* Search for a product */}
              {t("Search.searchProduct")}
            </h4>
          </div>
        )}
      </div>
    </div>)
}

export default withTranslation()(ProgressiveSearch)