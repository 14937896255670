
/* eslint-disable no-useless-escape */
/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
// global Fuctions
import { socialLoginApi, socialRegisterApi, socialUser } from '../../../global-functions/socialUser';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Button from "@material-ui/core/Button";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import facebook from "../assets/images/facebook.png";
import facebookIcon from "../assets/images/facebookIcon.jpg";


import axios from 'axios'
import DispatchContext from '../../../store/DispatchContext';
import GetPhoneNo from './GetPhoneNo';
// React Redux
import { useSelector, useDispatch } from "react-redux";
import { data } from "jquery";

function FbLogin({ fbloginHandleclose, fbloading, setfbLoading }) {
  const { t } = useTranslation();
  const appDispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [fbUser, setFbUser] = useState([]);
  const [noStatus, setNoStatus] = useState(false);
  const [dataRes, setDataRes] = useState({
    first_name: "",
    last_name: "",
    email: "",
    id: "",
    phone_no: "",
  });
  const getPhoneNo = () => {
    setOpen(true)
  }
  const handleClosePhoneNo = () => {
    setOpen(false)
  }

  const responseFacebook = async (response) => {
    setfbLoading(true)
    let userData = []
    const res = await response
    if (res) {
      userData.push({
        loginSource: 'facebook',
        firstName: res.first_name,
        lastName: res.last_name,
        userEmail: res.email ? res.email : "",
        Title: 'userID',
        titleValue: res.id,
      })
      if (res.status == 'unknown') {
        setfbLoading(false)
      }
      setDataRes(res)
    }
    let result = await socialLoginApi(userData, appDispatch, fbloginHandleclose, fbloading, setfbLoading, getPhoneNo)

  }
  const componentClicked = (data) => {
    console.log(data)
  }
  const handleChangeUserInfo = (e) => {
    setDataRes({
      ...dataRes, [e.target.name]: e.target.value
    })
  }
  useEffect(async () => {

    if (noStatus) {
      handleClosePhoneNo()
      let userData = []
      if (dataRes != {}) {
        userData.push({
          loginSource: 'facebook',
          firstName: dataRes.first_name,
          lastName: dataRes.last_name,
          userEmail: dataRes.email,
          Title: 'userID',
          titleValue: dataRes.id,
          phoneNo: dataRes.phone_no
        })
        let resg = await socialRegisterApi(userData, appDispatch, fbloginHandleclose, fbloading, setfbLoading)
        setNoStatus(false)
      }
    }
  }, [noStatus])

  return (
    <>
      <GetPhoneNo handleClose={handleClosePhoneNo} open={open} setNoStatus={setNoStatus} handleChangeUserInfo={handleChangeUserInfo} purpose="Facebook" dataRes={dataRes} />

      <div className="justify-content-center align-item-center px-1 fbLoginOnLargeScreen ">
        <FacebookLogin
          appId="602689070765739"
          autoLoad={false}
          fields="name,email,first_name,last_name,picture"
          onClick={componentClicked}

          render={renderProps => (
              <Button
                className="fbBtn py-3 familyManrope800 d-flex  justify-content-sm-center"
                variant="contained"
                onClick={renderProps.onClick}
              >
                <span className="px-2">
                  <img src={facebook} alt="" />
                </span>
                <span>{t("Forms.login-with-facebook")}</span>
              </Button>


          )}
          callback={responseFacebook} />
      </div>

      <div className="fbLoginOnsmallScreen">
        <FacebookLogin
          appId="602689070765739"
          autoLoad={false}
          fields="name,email,first_name,last_name,picture"
          onClick={componentClicked}

          render={renderProps => (
              <Button
                className="socialButtonAuthModal"
                onClick={renderProps.onClick}


              >
                <img className="img-fluid" src={facebookIcon} alt="" />
              </Button>


          )}
          callback={responseFacebook} />
      </div>


    </>
  );
}

export default withTranslation()(FbLogin);

