/* eslint-disable */
import React, { useState, useEffect } from "react";
import GoogleLogin from "react-google-login";
import Button from "@material-ui/core/Button";
import {
  socialLoginApi,
  socialRegisterApi,
} from "../../../global-functions/socialUser";
import GetPhoneNo from "./GetPhoneNo";
import { withTranslation, useTranslation } from "react-i18next";
import google from "../assets/images/google.png";
import googleIcon from "../assets/images/googleIcon.png";

// React  Redux
import { useDispatch } from "react-redux";

function LoginGoogle({ loginHandleclose, loading, setLoading }) {
  const appDispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [noStatus, setNoStatus] = useState(false);
  const [dataRes, setDataRes] = useState({
    first_name: "",
    last_name: "",
    email: "",
    id: "",
    phone_no: "",
  });
  const clientId =
    "169152409843-8mj25ltqn1h64q310d39gte30sh9qhmm.apps.googleusercontent.com";

  var userData = [];

  //consolelog(dataRes)
  //consolelog(userData)
  const getPhoneNo = () => {
    setOpen(true);
  };
  const handleClosePhoneNo = () => {
    setOpen(false);
  };

  const responseGoogle = async (response) => {
    setLoading(true);
    let userData = [];
    const res = await response.profileObj;
    if (res) {
      userData.push({
        loginSource: "google",
        firstName: res.givenName,
        lastName: res.familyName,
        userEmail: res.email,
        Title: "userID",
        titleValue: res.googleId,
      });
      setDataRes(res);
      let result = await socialLoginApi(
        userData,
        appDispatch,
        loginHandleclose,
        loading,
        setLoading,
        getPhoneNo
      );
    } else {
      // //consolelog(response);
      setLoading(false);
      loginHandleclose();
    }
    // //consolelog(response, "gettting");
  };

  const componentClicked = (data) => {
    // //consolelog(data);
    // responseGoogle();
  };

  const onFailureSuccess = (res) => {
    // //consolelog("onFailureSuccess", res);
  };
  const handleChangeUserInfo = (e) => {
    setDataRes({
      ...dataRes, [e.target.name]: e.target.value
    })
  }
  useEffect(async () => {
    if (noStatus) {
      let userData = [];
      if (dataRes != {}) {
        userData.push({
          loginSource: "google",
          firstName: dataRes.givenName,
          lastName: dataRes.familyName,
          userEmail: dataRes.email,
          Title: "userID",
          titleValue: dataRes.googleId,
          phoneNo: dataRes.phone_no
        });
        let resg = await socialRegisterApi(
          userData,
          appDispatch,
          loginHandleclose,
          loading,
          setLoading,
          getPhoneNo
        );
        setNoStatus(false)
        // //consolelog(resg, "successfull");
      }
    }
  }, [noStatus]);

  return (
    <>
      <GetPhoneNo
        handleClose={handleClosePhoneNo}
        loginClose={loginHandleclose}
        open={open}
        setNoStatus={setNoStatus}
        handleChangeUserInfo={handleChangeUserInfo}
        purpose="Google" dataRes={dataRes}
      />
      <div className="text-center px-1 googleLoginOnLargeScreen ">
        <GoogleLogin
          clientId={clientId}
          buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={onFailureSuccess}
          cookiePolicy={"single_host_origin"}
          render={(renderProps) => (
            <Button
              className=" googleBtn py-3 familyManrope800 d-flex  justify-content-sm-center"
              onClick={renderProps.onClick}
              variant="contained"
            >
              <span className="px-2">
                <img src={google} alt="" />
              </span>
              <span>
                {t("Forms.login-with-google")}
              </span>
            </Button>




          )}
        />
      </div>
      <div className="googleLoginOnsmallScreen ">
        <GoogleLogin
          clientId={clientId}
          buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={onFailureSuccess}
          cookiePolicy={"single_host_origin"}
          render={(renderProps) => (
            <Button
              className="socialButtonAuthModal"
              onClick={renderProps.onClick}
            >
              <img className="img-fluid" src={googleIcon} alt="" />
            </Button>


          )}
        />
      </div>


    </>
  );
}

export default withTranslation()(LoginGoogle);
