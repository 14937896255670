import { Button } from '@mui/material';
import "./CheckoutBtn.scss";

/* eslint-disable */
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { get_lang } from '../../../../global-functions/apiGlobal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { withTranslation, useTranslation } from "react-i18next";

function CheckoutBtn({label, handleCheckout}) {
  const appState = useSelector((state) => state);
  const { t } = useTranslation();
  let history = useHistory()
  // const handleClick = () => {
  //   history.push('/checkout')
  //   // if (appState.tableInvoice != null) {
  //   //   history.push('/checkout')
  //   //   history.push('/checkout-no-orders')
  //   // } else {
  //   //   history.push('/checkout-no-orders')
  //   // }
  // }
  console.log(handleCheckout);
  return (

    <section
    id="reviewOrderButton"
    className="m-0 p-0 mt-5 col-12"
  >
  <div className="reviewOrdButton   col-md-12 col-xl-6 offset-xl-3 mr-auto">

    <div id="process-btn" className=" col-md-12   ">
      <Button
        variant="contained"
        className="col-12 px-4  payBtn text-capitalize d-flex justify-content-between align-items-center"
        onClick={handleCheckout}
      >

        <strong>{t(`checkOut.${label}`)}</strong>

        <div className="iconsParent d-flex justify-content-center">
                {appState.acceptedPayments.map((item, index) => {
                  return (
                    <>
                      {item === "knet" ? (
                        <span className=" iconsBg ">
                          <img
                            className="img-pr"
                            // width="25"
                            src={`/images/pat-knet.svg`}
                            alt=""
                          />
                        </span>
                      ) : (
                        ""
                      )}

                      {item === "card" ? (
                        <span className="iconsBg">
                          <img
                            className="img-pr"
                            // width="25"
                            src={`/images/visa-small.svg`}
                            alt=""
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      
                      {item == "apple_pay" ? (
                        <span key={index} className="iconsBg">
                          <img
                            className="img-pr"
                            width="50"
                            src={`/images/pat-apple-n.svg`}
                            alt=""
                          />
                        </span>
                      ) : (
                        ""
                      )}
                       
                    </>
                  );
                })}
              </div>

      </Button>
    </div>
    </div>
                </section>
  )
}

export default withTranslation()(CheckoutBtn)