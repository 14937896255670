/* eslint-disable */
import React, { useEffect, useState, useReducer } from "react";
import "./PayTablePopUp.scss";
import { useHistory } from "react-router-dom";

// material ui
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Backdrop from "@mui/material/Backdrop";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// localizations
import { withTranslation, useTranslation, Trans } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import {
  freezOn,
  getDineInOrder,
  getPayTableInvoice,
  getTableInvoice,
} from "../../../../redux-store/action";
import { Link } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Avatar, useMediaQuery } from "@material-ui/core";
import noImg from "../../assets/images/no-image.svg";
import { get_lang } from "../../../../global-functions/apiGlobal";

function PayTablePopup({ table }) {
  const history = useHistory();
  const appDispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const appState = useSelector((state) => state);
  const matches = useMediaQuery("(min-width:1200px)");
  const {
    totalAmount = 0,
    subTotalAmount = 0,
    discount = 0,
    totalCharges = 0,
    totalTax = 0,
    adjustedAmount = 0,
    tip = 0,
    charges = [],
    currentInvoiceStatus = 0,
    paidAmount = 0,
    leftToPay = 0,
    fullAmount = 0,
  } = appState.invoiceTaxesChargesExtras;
  // This is used only for the example
  const handleClickPay = () => {
    history.push(`/checkout${appState.orderQrCode}`)
    // history.push(`/checkout?table=${appState?.qrTable}&branch=${appState?.qrBranch}`);
    appDispatch({
      type: "setPayTablePopUp",
      payTablePopUp: false,
    });
  };
  const handleClickOrder = () => {
    if (appState.storeInfo.iframeUrl != null) {
      history.push("/external");
    } else {
      appDispatch({
        type: "setPayTablePopUp",
        payTablePopUp: false,
      });
      appDispatch({
        type: "setQrOptionStatus",
        qrOptionStatus: false,
      });
    }
  };

  const arLanguageHandle = (e) => {
    // console.log("ar change");
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");
    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
        open={appState.payTablePopUp}
        className="payTableBackdrop"
        style={
          matches
            ? {}
            : {
                background: `url(${
                  appState.storeInfo.cover != null
                    ? `${appState.storeInfo.cover}`
                    : `${noImg}`
                }) center top/100% 73% no-repeat`,
              }
        }
      />

      <div
        id="mySidenav"
        className={`PayTablePopUp pb-3  ${
          appState.langValue == "ar" ? "ArabicPayTablePopUp" : " "
        }`}
      >
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div id="storeTitlePayTable">
          <div className="container-fluid py-3">
            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <Link to="/">
                    <div>
                      <Avatar
                        variant="square"
                        src={
                          appState.storeInfo.logo
                            ? `${appState.storeInfo.logo}`
                            : `https://api.enabill.dev/storage/app/public/stores/logo/no_image_logo.png`
                        }
                        className="img-fluid"
                      ></Avatar>
                    </div>
                  </Link>

                  <div className="mt-2 px-3">
                    <h1
                      className={`d-flex familyPoppins700 ${
                        appState.langValue === "ar" ? "text-right" : "text-left"
                      }`}
                    >
                      {get_lang(
                        appState.storeInfo.businessName,
                        appState.langValue
                      )}
                    </h1>

                    {table && (
                      <h1
                        className={`d-flex  tableNo ${
                          appState.langValue === "ar"
                            ? "text-right familyGraphikArabic"
                            : "text-left familyPoppins600"
                        }`}
                      >
                        {t("Forms.tableNo")}:{table}
                      </h1>
                    )}
                  </div>
                </div>
                {appState.langValue == "ar" ? (
                  <Button
                    className={`infoBtn ${
                      appState.langValue === "ar"
                        ? "classForAr familyGraphikArabic600"
                        : "familyManrope600"
                    }`}
                    variant="outlined"
                    onClick={(e) => enLanguageHandle(e)}
                  >
                    EN
                  </Button>
                ) : (
                  <Button
                    className={`infoBtn pb-3 ${
                      appState.langValue === "ar"
                        ? "classForAr familyGraphikArabic600"
                        : "classForAr familyGraphikArabic600"
                    }`}
                    onClick={(e) => arLanguageHandle(e)}
                    variant="outlined"
                  >
                    عربي
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid content px-4 mt-5">
          <div className="row mt-3 px-2">
            {/* {appState.tableInvoice != null && } */}
            <Button
              className={` py-3 px-4  viewMenuBtn  justify-content-between ${
                appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              }`}
              onClick={() => handleClickPay()}
            >
              {/* <img src="./images/billIcon.svg" className="mx-2" alt="bill" /> */}
              <span>{t("checkOut.Pay-the-Bill")}
              {/* {appState.tableInvoice != null &&<span>
                &nbsp; ({(appState.total).toFixed(3)}
                {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}
                )
              </span>} */}
              </span>
             
              {/* <span > {t("contact.I-already-ordered-I-want-to-pay.normal")} <strong> {t("contact.I-already-ordered-I-want-to-pay.bold")} </strong></span> <span >{appState.langValue === "ar" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}</span>  */}
              <div className="iconsParent d-flex justify-content-center">
                {appState.acceptedPayments.map((item, index) => {
                  return (
                    <>
                      {item === "knet" ? (
                        <span className=" iconsBg ">
                          <img
                            className="img-pr"
                            // width="25"
                            src={`/images/pat-knet.svg`}
                            alt=""
                          />
                        </span>
                      ) : (
                        ""
                      )}

                      {item === "card" ? (
                        <span className="iconsBg">
                          <img
                            className="img-pr"
                            // width="25"
                            src={`/images/visa-small.svg`}
                            alt=""
                          />
                        </span>
                      ) : (
                        ""
                      )}

                      {item == "apple_pay" ? (
                        <span key={index} className="iconsBg">
                          <img
                            className="img-pr"
                            width="50"
                            src={`/images/pat-apple-n.svg`}
                            alt=""
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </div>
            </Button>
            <Button
              className={`py-3 px-4 mt-3 payBillBtn  d-flex justify-content-center ${
                appState.langValue === "ar" ? " familyGraphikArabic600 " : ""
              }`}
              onClick={() => handleClickOrder()}
            >
              {/* <img src="./images/BookOpenWhite.svg" className="mx-2" alt="book" /> */}
              <span> {t("checkOut.view-menu")} </span>
              {/* <span>  {t("contact.I-would-like-to-order-first.normal")} <strong>{t("contact.I-would-like-to-order-first.bold")}</strong></span> <span >{appState.langValue === "ar" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}</span>  */}
            </Button>

            <div className="d-flex paySecure w-100 mt-4 pb-2 align-items-center justify-content-center">
              <p
                className={` m-0 ${
                  appState.langValue === "ar"
                    ? "familyGraphikArabic"
                    : "familyManrope400"
                }`}
              >
                {t("checkOut.pay-securely-with")}
              </p>
              <img
                src={`/images/pat-enabill.svg`}
                className="mx-2 img-pro"
                height="21px"
                alt="bill"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(PayTablePopup);
