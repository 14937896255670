import parsePhoneNumber from 'libphonenumber-js'
export const getCountryCode = (phoneNumber) => {
    try {
        const parsedNumber = parsePhoneNumber(phoneNumber);
        return parsedNumber.countryCallingCode;
    } catch (error) {
        return "Invalid country code";
    }
};
export const getPhoneNumber = (phoneNumber,countryCode) => {
  return   phoneNumber.replaceAll("+"+countryCode,"")
};


export const handelNumber = (phoneNumber) => {
    var  number = phoneNumber;

    if (!phoneNumber.startsWith("+")) {
        number = "+" + phoneNumber;
    }else{
        number = phoneNumber;
    }
     if (number.startsWith("+00")){
        console.log("first",number)
        number =  "+" + number.slice(3);
    }else{
        number = number;
    }

    return number

};
