/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react";
import { GoSellElements } from "@tap-payments/gosell";
import Axios from "axios";
import StateContext from "../../../store/StateContext";
import DispatchContext from "../../../store/DispatchContext";

// React-Redux
import { useSelector, useDispatch } from "react-redux";

import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { handleError } from "../../../redux-store/action";
import { get_lang } from "../../../global-functions/apiGlobal";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GoSellElementsTrans = forwardRef((props, ref) => {
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();

  const [alertMessage, setAlertMessage] = useState({
    open: false,
    message: "",
    alertSuccess: false,
  });

  const [credToken, setCredToken] = useState("");
  const data = {
    amount: parseFloat(props.inpValue),
    currency: appState.storeInfo.currencySymbol,
    threeDSecure: true,
    save_card: false,
    description: "Test Description",
    statement_descriptor: "Sample",
    metadata: {
      udf1: "test 1",
      udf2: "test 2",
    },
    reference: {
      transaction: "txn_0001",
      order: "ord_0001",
    },
    receipt: {
      email: false,
      sms: true,
    },
    customer: {
      first_name: appState.loggedIn
        ? appState.userInfo.name
        : props.name
          ? props.name
          : null,
      email: appState.loggedIn
        ? appState.userInfo.email
        : props.email
          ? props.email
          : null,
      phone: {
        country_code: "965",
        number: appState.loggedIn
          ? parseFloat(appState.userInfo.mobile_no)
          : props.mobile_no
            ? props.mobile_no
            : null,
      },
    },
    merchant: {
      id: "",
    },
    source: {
      id: "src_kw.knet",
    },
    post: {
      url: `${window.location.href}`,
    },
    redirect: {
      url: `${window.location.href}`,
    },
  };

  useImperativeHandle(ref, () => ({
    submitRequest() {
      // props.setButtonLoading(true)
      GoSellElements.submit();
    },
  }));

  const callbackFunc = async (response) => {
    if (props.payWithValue == "card") {
      let res = await response.id;
      // console.log(response);
      props.setTapId(response.id);
    } else {
      // console.log(response, "// console cred");
      setCredToken(response.id);
      props.setSaveCard(false);
      setCookie("cardToken", JSON.stringify(response.id));
    }
  };

  // Erase Cookies
  const erase = (name) => {
    setCookie(name, "", -1);
  };

  // SetCookie
  const setCookie = (name, value, days) => {
    var domain, domainParts, date, expires, host;
    if (days) {
      date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }

    host = window.location.host;
    if (host.split(".").length === 1) {
      // no "." in a domain - it's localhost or something similar
      document.cookie = name + "=" + value + expires + "; path=/";
    } else {
      domainParts = host.split(".");
      domainParts.shift();
      domain = "." + domainParts.join(".");
      document.cookie =
        name + "=" + value + expires + "; path=/; domain=" + domain;
      if (getCookie(name) === null || getCookie(name) !== value) {
        domain = "." + host;
        document.cookie =
          name + "=" + value + expires + "; path=/; domain=" + domain;
      }
    }
  };

  const handleLogout = () => {
    appDispatch({ type: "logout" });
  }

  // Cookies Function
  const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
 
  useEffect(() => {
    const getResponse = async () => {
      try {
        // console.log("cred", credToken);
        const newReq = await Axios.post(
          "${process.env.REACT_APP_API_URL}/customer/save_card",
          {
            card_id: credToken,
          },
          {
            headers: {
              Authorization: `Bearer ${getCookie("tokenAuth")}`,
              ContentType: 'application/json',
            },
          }
        );
        // console.log("getREsponse");

        // console.log(newReq);
        appDispatch({
          type: "updatedCardList",
          cardList: newReq.data.data,
        });
        setAlertMessage({
          open: true,
          message: newReq.data.data.message,
          alertSuccess: newReq.data.data.success,
        });
      } catch (e) {
        Promise.reject(e);
        if (e == undefined) {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "warning",
            message: "Please check your network.",
          });
        } else {
          appDispatch(handleError(e));
        }
        if (e.response.status == 401) {
          handleLogout();
        }
      }
    };
    // // console.log('running........................')
    if (credToken !== "" && credToken !== null && credToken !== undefined) {
      // props.setButtonLoading(true)
      getResponse();
    } else {
      // props.setAlertMessage({ open: true, message: "There is some error while processing your request. Please check your details and try again", alertSuccess: false })
      // props.setButtonLoading(false)
    }
  }, [credToken]);

  // }

  useEffect(() => {
    // console.log(appState.storeInfo.tapKey,"tap KEys")
  }
    , [])

  return (
    <div className="App">
      {/* {props.payWithValue === "Master" || props.payWithValue === "CREDIT" ? */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={alertMessage.open}
        className={alertMessage.alertSuccess ? "success-alert" : "danger-alert"}
      >
        {alertMessage.alertSuccess ? (
          <Alert severity="success">{alertMessage.message}</Alert>
        ) : (
          <Alert severity="error">{alertMessage.message}</Alert>
        )}
      </Snackbar>
      <div>
        <GoSellElements
          gateway={{
            publicKey: props.status == "checkout" ? ((appState.storeInfo.tapKey != null || appState.storeInfo.tapKey != undefined) && appState.storeInfo.tapKey) : (appState.storeInfo.live ? process.env.REACT_APP_TAP_LIVE_KEY_SAVE_CARD : process.env.REACT_APP_TAP_TEST_KEY_SAVE_CARD),
            // publicKey: props.statunps == "checkout" ? appState.storeInfo.tapKey.test : 'pk_test_86hEIwQToAH0ZbqFYNtfKGCX',
            language: "en",

            supportedCurrencies: "all",
            supportedPaymentMethods: "all",
            notifications: "msg",
            callback: callbackFunc,
            saveCardOption: true,
            saveCardOption: true,
            labels: {
              cardNumber: "Card Number",
              expirationDate: "MM/YY",
              cvv: "CVV",
              cardHolder: "Name on Card",
              actionButton: "Pay",

            },
            style: {
              base: {
                color: "#535353",
                lineHeight: "18px",
                fontFamily: "sans-serif",
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                  color: "rgba(0, 0, 0, 0.26)",
                  fontSize: "15px",
                },
              },
              invalid: {
                color: "red",
                iconColor: "#fa755a ",
              },
            },
          }}
        />

        <p id="msg"></p>
      </div>
      {/* <button style={{display: 'none'}} onClick={submitRequest}>Submit</button> */}
      {/* <button onClick={getResponse}>Check Respose</button> */}
      {/* <button >Check Respose</button> */}

    </div>
  );
});
export default GoSellElementsTrans;
