/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./loyalityPopup.scss";
// material ui
import Button from "@mui/material/Button";
// handleTotalPersonf
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import LoyalityProduct from "../loyalityProduct/loyalityProduct";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Backdrop, Checkbox, useMediaQuery } from "@mui/material";
import SwapHand from "../swapHand/SwapHand";
import { get_lang } from "../../../../global-functions/apiGlobal";
import { getCurrentCards } from "../../../../global-functions/customeObj";
import Avatar from '@mui/material/Avatar';
import LoyalityDiscountAmount from "../loyalityDiscountAmount/loyalityDiscountAmount";
import { handleLoyalPunch, handleMaxPunch } from "../../../../global-functions/handlePunch"


const StyledRating = styled(Rating)({
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  console.log("first", index);
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function LoyalityPopup(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [storeLoyalty, setStoreLoyalty] = useState(null);
  const [rewardInfo, setRewardInfo] = useState(null);
  const [conditionInfo, setConditionInfo] = useState()
  const matches = useMediaQuery("(min-width:1200px)");
  const handleClose = () => {
    appDispatch({
      type: "setLoyalityPopupMode",
      loyalityPopupMode: false,
    });
  };

  const theme = useTheme();
  const [value, setValue] = React.useState(0);


  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    getCurrentCards(appState, setStoreLoyalty, setRewardInfo);
  }, [appState.selectedLoyaltyId]);

  useEffect(() => {
    storeLoyalty?.forEach((ele, ind) => {
      if (ele.objType == "conditions") {
        var conitionInfoObj = {};
        ele.condition?.forEach((item, ind) => {
          if (item?.type?.name == "Amount") {
            conitionInfoObj = { ...conitionInfoObj, amount: item?.condition?.amount }
          }
          else if (item?.type?.name == "Product") {
            if (item?.condition?.not == true && item?.condition?.products.length == 0) {
              conitionInfoObj = { ...conitionInfoObj, product: "allProducts" }
            } else if (item?.condition?.not == true && item?.condition?.products.length > 0) {
              conitionInfoObj = { ...conitionInfoObj, product: "notThese" }
            } else if (item?.condition?.not == false && item?.condition?.products.length == 0) {
              conitionInfoObj = { ...conitionInfoObj, product: "noProducts" }
            }
            else if (item?.condition?.not == false && item?.condition?.products.length > 0) {
              conitionInfoObj = { ...conitionInfoObj, product: "these" }
            }
          }
        });
        setConditionInfo(conitionInfoObj)
      }
    });

  }, [storeLoyalty])


  return isLoading ? (
    <>loaidng....</>
  ) : (
    <>
      <div
        id="mySidenav"
        className={`loyalityPopupMain ${appState.langValue === "ar" ? "loyalityPopupMainMainArabic" : " "
          }`}
      >
        <div className="container-fluid content px-4 pb-3">
          <div className="d-flex justify-content-between alignn-items-center">
            <div className="d-flex align-items-center mt-2">
              <h1
                className={` mb-0 ${appState.langValue === "ar"
                  ? "text-right familyGraphikArabic800 "
                  : " familyPoppins600"
                  }`}
              >
                {" "}
                {t("loyalty.Punch-Rules")}
              </h1>
            </div>

            <IconButton onClick={handleClose} className="classForSvgColor mt-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
                  fill="#22282F"
                />
              </svg>
            </IconButton>
          </div>
          <hr className="my-1" />

          <div className={`tabsParent`}>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              {storeLoyalty?.map((item, i) => {
                return (
                  <TabPanel value={value} index={i} dir={theme.direction}>
                    <div id="TagCheckBoxParent">
                      {item.objType == "rewards" ? (
                        <div>
                          <div className="d-flex mb-4 mt-3 py-3 px-3 flex-column justify-content-center   w-100 progress_card">
                            <h1
                              className={`mt-2 mb-0 ${appState.langValue === "ar"
                                ? "text-right "
                                : "text-left familyPoppins600"
                                }`}
                            >
                              {t("loyalty.How-to-collect-Punch")}
                            </h1>
                            {conditionInfo?.amount != null || undefined ? <p
                              className={`mt-2 liner mb-0  ${appState.langValue === "ar"
                                ? "text-right "
                                : "text-left familyPoppins"
                                }`}
                            >
                              For every {conditionInfo?.amount} {get_lang(appState.storeInfo.storeCurrency, appState.langValue)} you will get a punch !
                            </p> : ""}

                            {conditionInfo?.product != null || undefined ? <p
                              className={`mt-2 liner mb-0  ${appState.langValue === "ar"
                                ? "text-right "
                                : "text-left familyPoppins"
                                }`}
                            >
                              {conditionInfo?.product == "allProducts"? t("loyalty.Products-listed-below-are-not-part-of-the-rewards-program") : conditionInfo?.product == "noProducts" ? "No products are awardable!" : conditionInfo?.product == "notThese" ?"These  products are not  awardable!": conditionInfo?.product == "these" ? "These products are awardable!":""}
                            </p> : ""}

                            <div
                              className={`w-100 d-flex punchRatingMain ${appState.langValue === "ar"
                                ? "justify-content-start"
                                : "text-left"
                                }`}
                            >
                              {((rewardInfo?.punch?.total != null || undefined)) && rewardInfo.active == true && <StyledRating
                                name="customized-color"
                                defaultValue={handleLoyalPunch(rewardInfo?.punch?.collected, rewardInfo?.punch?.total, 5 )}
                                max={handleMaxPunch(rewardInfo?.punch?.total) }                              
                                    getLabelText={(value) =>
                                  `${value} Heart${value !== 1 ? "s" : ""}`
                                }
                                precision={0.5}
                                icon={<img src={`/images/loyalVectorFilled.svg`} />}
                                emptyIcon={<img src={`/images/loyalVectorunFilled.svg`} />}
                                disabled
                              />}

                            </div>

                            {matches && (
                              <div className="d-flex align-items-center justify-content-evenly arrowButtons">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => handleChangeIndex(i - 1)}
                                  disabled={i == 0}
                                >
                                  <ArrowBackIosIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => handleChangeIndex(i + 1)}
                                  disabled={i + 1 == storeLoyalty.length}
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </div>
                            )}
                          </div>
                          <h1
                            className={` mt-2 ${appState.langValue === "ar"
                              ? "text-right familyGraphikArabic800 "
                              : " familyPoppins600"
                              }`}
                          >
                            {" "}
                            {t("loyalty.rewards")}
                          </h1>
                          <section
                            id="Products"
                            className={`products  ${item?.type?.name == "Product" && item?.reward?.products.length > 2 ? "classForScrolling" : ""}  ${appState.products.length > 0 ? "" : "noProducts"
                              } ${appState.langValue === "ar"
                                ? "direction-rtl"
                                : ""
                              }`}
                          >

                            {item?.type?.name == 'Discount' ?
                              <>
                                <LoyalityDiscountAmount

                                  type={item?.type?.name}
                                  desc={item?.description}
                                  name={item?.type?.name}
                                  amount={item?.reward?.discount_amount}
                                  percent={item?.reward?.discount_percent}
                                  objType={item?.objType}

                                />
                              </>

                              : item?.type?.name == "Product" ? <div className="row m-0 productsRo">
                                <div className="d-flex justify-content-start w-100">
                                  {item?.reward?.not == true && item?.reward?.products.length == 0 && <p className="familyPoppins600 text-primary text-capitalize">{t("loyalty.Points-can-be-earned-with-every-purchase-no-matter-which-product-you-buy!")}</p>}
                                  {item?.reward?.not == true && item?.reward?.products.length > 0 && <p className="familyPoppins600 text-primary text-capitalize">These  products are not  awardable!</p>}
                                  {item?.reward?.not == false && item?.reward?.products.length == 0 && <p className="familyPoppins600 text-primary text-capitalize">No products are awardable!</p>}
                                  {item?.reward?.not == false && item?.reward?.products.length > 0 && <p className="familyPoppins600 text-primary text-capitalize">These products are awardable!</p>}
                                </div>

                                {item?.reward?.products.map((product, ind) => (
                                  <LoyalityProduct
                                    item={product}
                                    ind={ind}
                                    myRef=""
                                  />
                                ))}
                              </div> : ""}
                          </section>
                        </div>
                      ) : item.objType == "conditions" ? (
                        <div>
                          <div className="d-flex mb-4 mt-3 py-3 px-3 flex-column justify-content-center  w-100 progress_card">
                            <h1
                              className={`mt-2 mb-0 ${appState.langValue === "ar"
                                ? "text-right "
                                : "text-left familyPoppins600"
                                }`}
                            >
                              {t("loyalty.How-to-earn-loyalty-points")}
                            </h1>
                            {conditionInfo?.amount != null || undefined ? <p
                              className={`mt-2 liner mb-0  ${appState.langValue === "ar"
                                ? "text-right "
                                : "text-left familyPoppins"
                                }`}
                            >
                              For every {conditionInfo?.amount} {get_lang(appState.storeInfo.storeCurrency, appState.langValue)} you will get a punch !
                            </p> : ""}

                            {conditionInfo?.product != null || undefined ? <p
                              className={`mt-2 liner mb-0  ${appState.langValue === "ar"
                                ? "text-right "
                                : "text-left familyPoppins"
                                }`}
                            >
                           {conditionInfo?.product == "allProducts"? t("loyalty.Points-can-be-earned-with-every-purchase,-no-matter-which-product-you-buy") : conditionInfo?.product == "noProducts" ? "No products are awardable!" : conditionInfo?.product == "notThese" ?"These  products are not  awardable!": conditionInfo?.product == "these" ? "These products are awardable!":""}
                            </p> : ""}
                            <div
                              className={`w-100 d-flex punchRatingMain ${appState.langValue === "ar"
                                ? "justify-content-start"
                                : "text-left"
                                }`}
                            >

                              {((rewardInfo?.punch?.total != null || undefined)) && rewardInfo.active == true && <StyledRating
                                name="customized-color"
                                defaultValue={handleLoyalPunch(rewardInfo?.punch?.collected, rewardInfo?.punch?.total, 5 )}
                                max={handleMaxPunch(rewardInfo?.punch?.total) }  
                                getLabelText={(value) =>
                                  `${value} Heart${value !== 1 ? "s" : ""}`
                                }
                                precision={0.5}
                                icon={<img src={`/images/loyalVectorFilled.svg`} />}
                                emptyIcon={<img src={`/images/loyalVectorunFilled.svg`} />}
                                disabled
                              />}
                            </div>

                            {matches && (
                              <div className="d-flex align-items-center justify-content-evenly arrowButtons">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => handleChangeIndex(i - 1)}
                                  disabled={i == 0}
                                >
                                  <ArrowBackIosIcon />
                                </IconButton>

                                <IconButton
                                  aria-label="delete"
                                  onClick={() => handleChangeIndex(i + 1)}
                                  disabled={i + 1 == storeLoyalty.length}
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </div>
                            )}
                          </div>
                          <h1
                            className={` mt-2 ${appState.langValue === "ar"
                              ? "text-right familyGraphikArabic800 "
                              : " familyPoppins600"
                              }`}
                          >
                            {" "}
                            {t("loyalty.Condition")}
                          </h1>
                          <section
                            id="Products"
                            className={`products classForScrolling   ${appState.products.length > 0 ? "" : "noProducts"
                              } ${appState.langValue === "ar"
                                ? "direction-rtl"
                                : ""
                              }`}
                          >

                            {item.condition.length > 0 ? item?.condition?.map((cond, i) => {
                              return (
                                cond?.type?.name == 'Amount' ?
                                  <>

                                    <LoyalityDiscountAmount

                                      type={item?.type?.name}
                                      desc={cond?.description}
                                      name={cond?.type?.name}
                                      amount={cond?.condition?.amount}

                                    />
                                  </>
                                  : (cond?.type?.name == 'Product') ? <div className="row m-0 productsRo">
                                    <div className="d-flex justify-content-start w-100">
                                      {cond?.condition?.not == true && cond?.condition?.products.length == 0 && <p className="familyPoppins600 text-primary text-capitalize">{t("loyalty.After-earning-all-your-points-you-can-redeem-rewards-for-any-product-of-your-choice")}</p>}
                                      {cond?.condition?.not == true && cond?.condition?.products.length > 0 && <p className="familyPoppins600 text-primary text-capitalize">{t("loyalty.Products-listed-below-are-not-part-of-the-rewards-program")}</p>}
                                      {cond?.condition?.not == false && cond?.condition?.products.length == 0 && <p className="familyPoppins600 text-primary text-capitalize">No products are awardable!</p>}
                                      {cond?.condition?.not == false && cond?.condition?.products.length > 0 && <p className="familyPoppins600 text-primary text-capitalize">These products are awardable!</p>}
                                    </div>
                                    {cond?.condition?.products.map((product, ind) => (
                                      <LoyalityProduct
                                        item={product}
                                        ind={ind}
                                        // parent_id={parent_id}
                                        myRef=""
                                      />
                                    ))}
                                  </div> : ""
                              )
                            }) : <h5 className="text-center text-uppercase mt-3 text-muted">No condition found!</h5>}
                          </section>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </TabPanel>
                );
              })}
            </SwipeableViews>
          </div>
          <div className="bottomBtn">
            <Button
              onClick={handleClose}
              className={` py-3 px-4 confirmBtn mt-3 justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                }`}
            >
              {" "}
              <span>{t("checkOut.close")}</span>{" "}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(LoyalityPopup);
