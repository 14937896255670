/* eslint-disable */
import React, { useEffect } from "react";
import "./LoyaltyBanner.scss";
// localizations
import { withTranslation, useTranslation } from "react-i18next";
// import Redux
import { useDispatch, useSelector } from "react-redux";
// owl carasoul work start
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// owl carasoul work end
import Button from "react-bootstrap/Button";
import CloseIcon from "../../layout-one/js/assets/images/closeBtn.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import sourceOfMeet from "../../layout-one/js/assets/images/sourceOfMeet.jpeg"
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});
function LoyaltyBanner(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [somImages, setSomImages] = React.useState([])


  const handleClose = () => {
    appDispatch({ type: "setLoyaltyBanner", loyaltyBanner: false });
    setOpen(false);
  };

  const onLoad = (i) => {
    if (i === somImages.length - 1) {
      setOpen(true);
    }
  };

  useEffect(() => {
    const getImages = async () => {
      await setSomImages([...somImages, sourceOfMeet])
    }
    getImages()
  }, [])
  return (
    somImages != [] && (
      <>
        <div
          id="mySidenav"
          className={`promotionBannerPopup col-xl-5 py-3   ${appState.langValue == "ar" ? "promotionBannerPopupArabic" : " "
            }`}   
        >
          {
            <Dialog
              style={open ? {} : { display: "none" }}
              className="classToTargetLBModal"
              open={appState.loyaltyBanner}
              onClose={handleClose}
            >
              <DialogContent className="p-0">
                <button
                  type="button"
                  onClick={handleClose}
                  className="close pb-1"
                >
                  <span aria-hidden="true">
                    <img src={CloseIcon} alt="CloseIcon" />
                  </span>
                </button>

                <OwlCarousel
                  className="owl-theme"
                  items="1"
                  margin={0}
                  customtransition="2000ms"
                  transitionduration={500}
                  loop
                  autoplay={true}
                >
                  {somImages != [] &&
                    somImages.map((banner, i) => {
                      return (
                        <div
                          className={`promotionBannerParent  ${appState.langValue === "ar" ? "directionRtl" : ""
                            }`}
                          key={i}
                        >
                          {/* <img
                            className=" promotionBannerImage"
                            src={somImages}
                            alt=""
                            onLoad={() => onLoad(i)}
                            key={banner}
                          /> */}
                          <div className="punchRatingMain  mt-3 pt-3">
                            <StyledRating
                              name="customized-color"
                              defaultValue={3}
                              getLabelText={(value) =>
                                `${value} Heart${value !== 1 ? "s" : ""}`
                              }
                              disabled
                              precision={0.5}
                              icon={<svg xmlns="http://www.w3.org/2000/svg" width="41" height="48" viewBox="0 0 41 48" fill="none">
                              <path d="M33.3499 48H7.41242C3.74892 48 0.818115 45.0821 0.818115 41.4347V6.56535C0.818115 2.91793 3.74892 0 7.41242 0C25.4369 0 40.0908 14.5897 40.0908 32.535V41.4347C40.0908 45.0821 37.0134 48 33.3499 48Z" fill="#EB5B5E"/>
                              </svg>}
                              emptyIcon={<svg xmlns="http://www.w3.org/2000/svg" width="40" height="48" viewBox="0 0 40 48" fill="none">
                              <path d="M33.1681 48H7.23054C3.56703 48 0.63623 45.0821 0.63623 41.4347V6.56535C0.63623 2.91793 3.56703 0 7.23054 0C25.255 0 39.909 14.5897 39.909 32.535V41.4347C39.909 45.0821 36.8316 48 33.1681 48Z" fill="#EEEEEE"/>
                            </svg>}
                            />

                          </div>
                          <h1 className="py-3  text-uppercase familyPoppins600 ">
                            You got a punch!
                          </h1>
                          <p className="py-3  text-uppercase familyPoppins600 ">Collect punches and get free
                            food & drinks!</p>
                        </div>
                      );
                    })}

                </OwlCarousel>

                <div className="bottomBtn px-3 py-3">
                  <Button
                    onClick={handleClose}
                    className={` py-3 px-4 w-100  justify-content-center confirmBtn ${appState.langValue === "ar"
                      ? " familyGraphikArabic600"
                      : ""
                      }`}
                  >
                    {" "}
                    <span>{t("checkOut.close")}</span>{" "}
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          }
        </div>
      </>
    )
  );
}

export default withTranslation()(LoyaltyBanner);
