/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AssignmentIcon from "@material-ui/icons/Assignment";
// import LogoutIcon from '@material-ui/icons/Logout';
import ListIcon from "@material-ui/icons/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { IconButton, Badge } from "@material-ui/core";
import StateContext from "../../../store/StateContext";
import DispatchContext from "../../../store/DispatchContext";
import Zoom from "react-reveal/Zoom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { getLogOut } from "../../../redux-store/action";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 0,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    // justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function UserInfo(IsLoading) {
  const { t } = useTranslation();

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const appState = useSelector((state) => state);
  const { initiator } = appState.groupBillRes;
  const { userFirstName, customerId } = appState.userInfo;

  const appDispatch = useDispatch();
  const matches = useMediaQuery("(min-width:1200px)");
  // Erase Cookies
  const erase = (name) => {
    setCookie(name, "", -1);
  };
  // SetCookie
  const setCookie = (name, value, days) => {
    var domain, domainParts, date, expires, host;
    if (days) {
      date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }

    host = window.location.host;
    if (host.split(".").length === 1) {
      // no "." in a domain - it's localhost or something similar
      document.cookie = name + "=" + value + expires + "; path=/";
    } else {
      domainParts = host.split(".");
      domainParts.shift();
      domain = "." + domainParts.join(".");
      document.cookie =
        name + "=" + value + expires + "; path=/; domain=" + domain;
      if (getCookie(name) == null || getCookie(name) != value) {
        domain = "." + host;
        document.cookie =
          name + "=" + value + expires + "; path=/; domain=" + domain;
      }
    }
  };

  const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const signOut = () => {
    if ((initiator != undefined) && (initiator.id == customerId)) {
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "warning",
        message: "Please close the group first.",
      });

    }
    else {
      // appDispatch({
      //   type: "SET_LOGOUT",
      //   loggedIn: false,
      // });
      appDispatch(getLogOut(appState.userInfo.userToken))
      appDispatch({ type: "setLoginSkip", loginSkip: false });

      // erase("tokenAuth");
      // erase("Profile");

      // localStorage.removeItem("userInfo");
      // localStorage.setItem("loggedIn", false);
      // appDispatch({
      //   type: "SET_USERINFO",
      //   userInfo: [],

    }
    // console.log(appState.loggedIn);
  };

  const handleSourceOfMeet = () => {
    handleDrawerClose()
    appDispatch({ type: "setSourceOfMeetBanner", sourceOfMeetBannerImage: true })
  }


  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <div className={classes.root}>
        <Zoom>
          <IconButton
            className="signInBtn classForSvgColor"
            onClick={!open ? handleDrawerOpen : handleDrawerClose}
          >
            {matches ? (
              <i className="fa fa-user mx-2"></i>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 2.25C9.37665 2.25 7.25 4.37665 7.25 7C7.25 9.62335 9.37665 11.75 12 11.75C14.6234 11.75 16.75 9.62335 16.75 7C16.75 4.37665 14.6234 2.25 12 2.25ZM8.75 7C8.75 5.20507 10.2051 3.75 12 3.75C13.7949 3.75 15.25 5.20507 15.25 7C15.25 8.79493 13.7949 10.25 12 10.25C10.2051 10.25 8.75 8.79493 8.75 7Z"
                  fill="#fff"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 14.25C6.37665 14.25 4.25 16.3766 4.25 19C4.25 20.5188 5.48122 21.75 7 21.75H17C18.5188 21.75 19.75 20.5188 19.75 19C19.75 16.3766 17.6234 14.25 15 14.25H9ZM5.75 19C5.75 17.2051 7.20507 15.75 9 15.75H15C16.7949 15.75 18.25 17.2051 18.25 19C18.25 19.6904 17.6904 20.25 17 20.25H7C6.30964 20.25 5.75 19.6904 5.75 19Z"
                  fill="#fff"
                />
              </svg>
            )}
          </IconButton>
        </Zoom>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className="drawer">
            <List>
              <ListItem>
                <h2 className="text-capitalize">
                  {appState.userInfo
                    ? appState.userInfo.userFirstName
                    : appState.storeInfo.slug}
                </h2>
              </ListItem>
              <ListItem>
                <h4>
                  {appState.userInfo != "" ? appState.userInfo.email != "" : ""}
                </h4>
              </ListItem>

              <Divider />
              <a
                className="text-rest drawerColor"
                href={`${process.env.REACT_APP_PAY_URL}`}
                onClick={handleDrawerClose}
              >
                <ListItem>
                  <ListItemIcon className="drawerIcon">
                    <i className="fa fa-user mx-2"></i>
                  </ListItemIcon>
                  <ListItemText primary={t("side-bar.profile")} />
                </ListItem>
              </a>
              <Link
                className="text-rest drawerColor"
                value={3}
                to={`/`}
                onClick={handleDrawerClose}
              >
                <ListItem>
                  <ListItemIcon className="drawerIcon">
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("side-bar.Category")} />
                </ListItem>
              </Link>
              <Link
                className="text-rest drawerColor"
                value={3}
                to={`/checkout`}
                onClick={handleDrawerClose}
              >
                <ListItem>
                  <ListItemIcon className="drawerIcon">
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("side-bar.CheckOut")} />
                </ListItem>
              </Link>
              <Link
                className="text-rest drawerColor"
                value={3}
                to={`/cart`}
                onClick={handleDrawerClose}
              >
                <ListItem>
                  <ListItemIcon className="drawerIcon">
                    <ShoppingCartIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("side-bar.Cart")} />
                </ListItem>
              </Link>
              {appState.sourceOfMeetBanner && <a
                className="text-rest drawerColor cursorPointer"
                value={4}
                onClick={() => handleSourceOfMeet()}
              >
                <ListItem>
                  <ListItemIcon className="drawerIcon">
                  <i className="fa fa-seedling mx-2"></i>
                  </ListItemIcon>
                  <ListItemText primary={t("side-bar.source-of-meet")} />
                </ListItem>
              </a>}



              {false && (
                <a
                  className="text-rest drawerColor"
                  href="https://pay.enabill.com/wallet"
                  onClick={handleDrawerClose}
                >
                  <ListItem>
                    <ListItemIcon className="drawerIcon">
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("side-bar.Wallet")} />
                  </ListItem>
                </a>
              )}
            </List>
            <div className="logoutButton__icon">
              <ListItem button onClick={signOut}>
                <ListItemIcon>
                  {" "}
                  <ExitToAppIcon className="logOutBtn" />
                </ListItemIcon>
                {/* <ListItemIcon> <img src={`${window.env.MIX_API_URL}public/logout.png`} width={35} className="pr-3" alt="" /> </ListItemIcon> */}

                <ListItemText primary={t("side-bar.Sign-Out")} />
              </ListItem>
            </div>
          </div>
        </Drawer>
      </div>
    </ClickAwayListener>
  );
}
export default withTranslation()(UserInfo);
