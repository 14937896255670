export const handleTime = () => {
    let show = false;  
    const time = new Date();
   let customizeTime = time.toLocaleString('en-US', { 
        hour: '2-digit', 
        minute: '2-digit',
        second: '2-digit',
        hour12: true 
      }) 
        return customizeTime;
  }