/* eslint-disable no-useless-escape */
/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import LoginDialog from "./LoginModal"

// Global Fucntions
// import { registerCustomer } from "../../../global-functions/authGlobal";

// redux 
import { useSelector, useDispatch } from "react-redux";

// Materila ui
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TextField from "@material-ui/core/TextField";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
// import Page from "../Components/Page";
// import { useNavigate} from "react-router"
import { Link, useHistory } from "react-router-dom";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DispatchContext from "../../../store/DispatchContext";

import MenuItem from "@material-ui/core/MenuItem";
import { Select, FormControl, InputLabel, Input } from "@material-ui/core/";
import { CountryCode } from "../components/CountryCode";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import kuwaitFlag from "../assets/images/kuiwat flag.png";
import { handleError } from "../../../redux-store/action";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    marginTop: "1.5rem",
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  menu: {
    width: 200,
  },
});
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  greyColor: {
    color: "#696969",
  },
}));

// gender

const Gender = [
  {
    value: "Gender",
    label: "male",
  },
  {
    value: "Gender",
    label: "female",
  },
  {
    value: "Gender",
    label: "Other",
  },
];

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SignUpDialog({ handleClose, open, openLoginModal }) {
  const classes = useStyles();
  const appDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [registerName, setRegisterName] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPhone, setRegisterPhone] = useState();
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerConfirmPassword, setRegisterConfirmPassword] = useState("");
  const [countryCodeValues, setCountryCodeValues] = useState([]);
  let history = useHistory();

  const [alertMessage, setAlertMessage] = useState({
    open: false,
    message: "",
    alertSuccess: false,
  });

  // password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(true);
  };

  const handleMouseDownPassword = () => {
    setShowPassword(false);
  };
  const handleClickShowConfirm = () => {
    setShowConfirm(true);
  };

  const handleMouseDownConfirm = () => {
    setShowConfirm(false);
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // getting response from auth-global component
    // var result = await registerCustomer(
    //   registerName,
    //   registerEmail,
    //   registerConfirmPassword,
    //   registerPassword,
    //   registerPhone
    // );
    // console.log(result,"result");

    if (true) {
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "success",
        message: "Your account has been created successfully!",
      });
      handleClose();
      setLoading(false);
    } else {
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: "",
      });
      setLoading(false);
    }
  };

  // sikandar work start

  // registration fields
  const [RegisterValues, setRegisterValues] = useState({ gender: "male" });

  const updateField = (prop) => (event) => {
    // console.log(prop + ":" + event.target.value);
    setRegisterValues({ ...RegisterValues, [prop]: event.target.value });
  };
  // console.log(RegisterValues)
  // for geting  multiple country codes
  useEffect(() => {
    const codeValues = async () => {
      var code = await CountryCode();
      setCountryCodeValues(code);
      // console.log("codes...", code);
    };
    codeValues();
  }, []);

  // select date of birth function
  const [selectedDate, setSelectedDate] = useState({});
  const handleDateChange = async (date) => {
    // console.log("date chali");
    let new_date = new Date(date);
    // console.log('get date', new_date)
    let year = await new_date.getFullYear();
    let month = await new_date.getMonth();
    month++;
    let day = await new_date.getDate();
    // console.log("day", new_date.getDate());
    // console.log("full year", `${year}-${month}-${day}`);
    setSelectedDate(`${year}/${month}/${day}`);
    // console.log(selectedDate);
    setRegisterValues({ ...RegisterValues, dob: `${year}/${month}/${day}` });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      RegisterValues.first_name &&
      RegisterValues.middle_name &&
      RegisterValues.last_name &&
      RegisterValues.email &&
      RegisterValues.password &&
      RegisterValues.gender &&
      RegisterValues.dob &&
      RegisterValues.number !== ""
    ) {
      // console.log(RegisterValues);

      let emailValidation = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      if (!emailValidation.test(RegisterValues.email)) {
        setAlertMessage({
          open: true,
          message: "Email Format is not correct!",
          alertSuccess: false,
        });
        setLoading(false);
        return;
      }
      if (
        RegisterValues.number.length >= 7 &&
        RegisterValues.number.length < 15
      ) {
        if (RegisterValues.password === RegisterValues.confirm_password) {
          axios
            .post(`${process.env.REACT_APP_API_URL}/customer/register`, {
              first_name: RegisterValues.first_name,
              last_name: RegisterValues.middle_name,
              middle_name: RegisterValues.last_name,
              email: RegisterValues.email,
              password: RegisterValues.password,
              gender: RegisterValues.gender,
              country_code: "965",
              number: RegisterValues.number,
              dob: RegisterValues.dob,
            })
            .then((response) => {
              // console.log("api response", response);
              if (response.status === 200) {
                if (response.data.success === true) {
                  appDispatch({
                    type: "SHOW_ALERT",
                    alertType: "success",
                    message: response.data.message,
                  });
                  handleClose();
                  setRegisterValues({});

                  setLoading(false);
                  setTimeout(() => {
                    // history.push(`/`);
                  }, 3000);
                } else {
                  appDispatch({
                    type: "SHOW_ALERT",
                    alertType: "error",
                    message: response.data.message,
                  });

                  setLoading(false);
                }

                // setRegistered(true);
              } else {
                appDispatch({
                  type: "SHOW_ALERT",
                  alertType: "error",
                  message: `Something Went Wrong. Please Try Again`,
                });

                return;
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error == undefined) {
                appDispatch({
                  type: "SHOW_ALERT",
                  alertType: "error",
                  message: "Please check your network.",
                });
              } else {
                appDispatch(handleError(error));
              }
              console.log(error);
              return;
            });
        } else {
          setLoading(false);

          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: `Password Didn't Match`,
          });
          return;
        }
      } else {
        setLoading(false);
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please Enter Correct Mobile No",
        });
        return;
      }
    } else {
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: "Please Fill All The Fields",
      });
      setLoading(false);
      return;
    }
  };

// login User function
const useLogin = () =>{
  handleClose() // function for signup modal
  openLoginModal() // function for open login modal
}

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={alertMessage.open}
        className={alertMessage.alertSuccess ? "success-alert" : "danger-alert"}
      >
        {alertMessage.alertSuccess ? (
          <Alert severity="success">{alertMessage.message}</Alert>
        ) : (
          <Alert severity="error">{alertMessage.message}</Alert>
        )}
      </Snackbar>

  
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open} // yahn open ai ga
        // fullWid
        maxWidth="sm"
        id="Login-Modal"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/* Modal title */}
        </DialogTitle>

        <div className={`${loading ? "disableClass" : ""}`}>
          <div className="login-head mt-4">
            <h2>REGISTRATION</h2>
            {loading ? (
              <CircularProgress
                style={{ top: "33%", zIndex: "999" }}
                size={24}
                className={`${classes.buttonProgress} mt-5`}
              />
            ) : (
              ""
            )}
          </div>

          <form action="" onSubmit={handleSubmit}>
            <div className="login-fields ">
              <div className="row mx-3">
                <div className="col-lg-4 col-md-4  col-sm-12 p-1">
                  <TextField
                    value={RegisterValues.first_name}
                    onChange={updateField("first_name")}
                    name="name"
                    type="text"
                    variant="filled"
                    // fullWid
                    id="input-with-icon-textfield"
                    placeholder="First Name *"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className="mb-3">
                          <PersonIcon className={`${classes.greyColor}`} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 p-1">
                  <TextField
                    type="text"
                    variant="filled"
                    name="middle_name"
                    value={RegisterValues.middle_name}
                    onChange={updateField("middle_name")}
                    // fullWid
                    id="input-with-icon-textfield"
                    placeholder="Middle Name *"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className="mb-3">
                          <PersonIcon className={`${classes.greyColor}`} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 p-1 ">
                  <TextField
                    name="last_name"
                    value={RegisterValues.last_name}
                    onChange={updateField("last_name")}
                    type="text"
                    variant="filled"
                    // fullWid
                    id="input-with-icon-textfield"
                    placeholder="Last Name *"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className="mb-3">
                          <PersonIcon className={`${classes.greyColor}`} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="row mx-3">
                <div className="col-12 col-md-6 col-sm-12 p-1">
                  <TextField
                    value={RegisterValues.email}
                    onChange={updateField("email")}
                    name="email"
                    type="email"
                    variant="filled"
                    // fullWidth
                    id="input-with-icon-textfield"
                    placeholder="Email Address *"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className="mb-3">
                          <EmailIcon className={`${classes.greyColor}`} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col-12 col-md-6 col-sm-12 p-1 phone_code">
                  <TextField
                    type="phone"
                    prefix="+965"
                    required
                    variant="filled"
                    value={RegisterValues.number}
                    onChange={updateField("number")}
                    inputProps={{ maxLength: 8, inputMode: "numeric" }}
                    // label="Phone No"
                    name="phone_no"
                    className="w-100"
                    id="phone_no"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className="">
                          <div className="d-flex mt-2">
                            {/* <EmailIcon className={`${classes.greyColor}`} /> */}
                            <img
                              src={kuwaitFlag}
                              alt="kuwaitFlag"
                              height="25px"
                            />

                            <p className="ml-1 mt-2 pt-1">+965</p>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <FormControl
                  //         id="registerPhoneArrowDown"
                  //         className={`d-flex ${classes.formControl}`}
                  //       >
                  //         <Select
                  //           labelId="demo-simple-select-label"
                  //           defaultValue="965"
                  //           id="demo-simple-select"
                  //           value={
                  //             RegisterValues.country_code != ""
                  //               ? RegisterValues.country_code
                  //               : 956
                  //           }
                  //           onChange={updateField("country_code")}

                  //         >
                  //           {countryCodeValues.map((val, ind) => (
                  //             <MenuItem value={val.code}>
                  //               <span> {val.name} </span> &nbsp; {val.code}
                  //             </MenuItem>
                  //           ))}

                  //         </Select>
                  //       </FormControl>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  />
                </div>
              </div>

              <div className="row mx-3">
                <div className="col-12 col-md-6 col-sm-12 p-1">
                  <TextField
                    value={RegisterValues.password}
                    onChange={updateField("password")}
                    inputProps={{ maxLength: 16 }}
                    name="password"
                    type={showPassword != true ? "password" : "text"}
                    variant="filled"
                    // fullWidth
                    id="input-with-icon-textfield"
                    placeholder="Password *"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className="mb-3">
                          <LockIcon className={`${classes.greyColor}`} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{
                              height: "100%",
                              padding: "20px 14px 20px 13px",
                            }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="text-left passwordCondition ">
                    <p
                      style={{ fontSize: "9px" }}
                      className="text-muted m-0 small passwordVal"
                    >
                      * Password should be 8 characters long and must include
                      Numbers, Symbols, Uppercase and Lowercase Letters
                    </p>
                  </div>
                </div>
                {/* <div className="mx-4 text-muted">(Password should be 8 characters long and must include Numbers, Symbols, Uppercase and Lowercase Letters)</div> */}
                <div className="col-12 col-md-6 col-sm-12 p-1">
                  <TextField
                    value={RegisterValues.confirmPassword}
                    onChange={updateField("confirm_password")}
                    inputProps={{ maxLength: 16 }}
                    name="confirm-password"
                    type={showConfirm != true ? "password" : "text"}
                    variant="filled"
                    // fullWidth
                    id="input-with-icon-textfield"
                    placeholder="Confirm Password *"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className="mb-3">
                          <LockIcon className={`${classes.greyColor}`} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{
                              height: "100%",
                              padding: "20px 14px 20px 13px",
                            }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirm}
                            onMouseDown={handleMouseDownConfirm}
                            edge="end"
                          >
                            {!showConfirm ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="row mx-3 mb-2">
                <div className="col-12 col-md-6 col-sm-12 p-1 genderRowSignUpModal">
                  <TextField
                    id="select-currency"
                    placeholder="Gender *"
                    select
                    variant="filled"
                    className={classes.textField}
                    value={RegisterValues.gender}
                    onChange={updateField("gender")}
                    //   value={this.state.currency}
                    //   onChange={this.handleChange('currency')}
                    //   helperText="Please select your gender"
                    //   label="Gender"

                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className="mb-3">
                          <AccessibilityIcon
                            className={`${classes.greyColor}`}
                          />
                        </InputAdornment>
                      ),
                    }}
                  >
                    {Gender.map((option) => (
                      <MenuItem key={option.value} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className="col-12 col-md-6 col-sm-12 p-1 signupdate">
                  {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    clearable
                    value={selectedDate}
                    id="dob-field"
                    inputVariant="filled"
                    placeholder="10/10/2018"
                    // onChange={(date) => meraFunc(date)}
                    // onChange={(date)=>updateField("dob")}
                    onChange={handleDateChange}
                    className="w-100 m-0 d-flex"
                    minDate={new Date()}
                    format="MM/dd/yyyy"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider> */}

                  <TextField
                    type="date"
                    variant="filled"
                    name="dob-field"
                    value={RegisterValues.dob}
                    onChange={updateField("dob")}
                    id="dob-field"
                  />
                </div>
              </div>

              <div
                className="row 
            mx-3 mb-2 regBtn"
              >
                <Button
                  variant="contained"
                  className="btn-main"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? (
                    <React.Fragment>
                      {/* {" "}
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />{" "} */}
                      Redirecting{" "}
                    </React.Fragment>
                  ) : (
                    "REGISTER"
                  )}
                </Button>
             
              </div>
              <div className="row mx-3 mt-2 text d-flex justify-content-center loginOR ">
                <p>
                  <span className="px-4">OR</span>
                </p>
              </div>
              <div
                className="row 
            mx-3 mb-5 regBtn"
              >
                <Button
                  variant="contained"
                  className="register-btn text-primary"
                  disabled={loading}
                  type="button"
                  onClick={useLogin}
                >
                  {/* {loading ? (
                    <React.Fragment>
                      {" "}
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />{" "}
                      Redirecting{" "}
                    </React.Fragment>
                  ) : (
                    "Login"
                  )} */}

                  Login
                </Button>
             
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
}
