/* eslint-disable no-useless-escape */
/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import StateContext from "../../../store/StateContext";
import noImg from "../assets/images/no-image.svg";
// Redux
import { useSelector, useDispatch } from "react-redux";
// global funtion for store
import { get_lang } from "../../../global-functions/apiGlobal";
import { withTranslation, useTranslation } from "react-i18next";



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

const OrderProduct = ({ item }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const appState = useSelector((state) => state)
  const isAddons = item.sum_addons;
  // console.log(item.product_name.en)
  return (
    <div id={item.prod_id}>
      <div className="row m-0 d-flex align-items-center">
        <div className="col-2 px-0">
          <img
            src={
              item.product_image
                && `${item.product_image}`
            }
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="col-8">
          <div className="row m-0 justify-content-between">
            <div className="col-10 col-sm-11 px-1 d-flex justify-content-between">
              <Typography gutterBottom variant="h5" component="h2">
                <strong className="toCapitialize d-flex">
                  {item.product != null
                    ? get_lang(item.product?.name, appState.langValue)
                    : "Name is not available"}
                </strong>
              </Typography>


            </div>
            {/* <div className="col-2 col-sm-1 px-1">
                                <IconButton
                                    size="small"
                                    onClick={() => handleItemRemove(item)}
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </div> */}
          </div>
          <div className="row m-0 align-items-center justify-content-between">
            <div className="row m-0 align-items-center">
              <h5 className="text-muted m-0">
                {item.total
                  ? item.total !== ""
                    ? `${parseFloat(item.total).toFixed(3)}  ${get_lang(appState.storeInfo.storeCurrency, appState.langValue)}`
                    : `""  ${get_lang(appState.storeInfo.storeCurrency, appState.langValue)}`
                  : ""}
              </h5>
             

              <div className="mx-2"
              >
                <span>(Qty:</span> <span>{item.quantity})</span>
              </div>
            </div>
          </div>

          {!item.addons.length ? "" : <h5 className="pt-2"> {t("single-product.Extras")}
          </h5>}
          {item.addons
            ? item.addons.map((itemAddon, ind) => {
              return (
                <div className="row m-0 align-items-center justify-content-between cart-items">
               
                  <div className="col-10 pl-5 d-flex flex-column addonCart ">
                    <p>
                      {" "}
                      <strong>{itemAddon.product_addon.addon.name.en}</strong>
                    </p>
                    <div className="d-flex align-items-center">
                      <h6 className="text-muted m-0">
                        {itemAddon.price != ""
                          ? parseFloat(
                            itemAddon.price * itemAddon.quantity
                          ).toFixed(3)
                          : ""}{" "}
                      {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                      </h6>
                      <span
                        style={{
                          padding: "0px 10px",
                        }}
                      >
                        <span>(Qty:</span> <span>{itemAddon.quantity})</span>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
            : ""}
        </div>
        <div className="col-2 px-0 statusContent">
          <span className={`${item.status == "Pending"
              ? "pendingColor-L1"
              : item.status == "Rejected"
                ? "rejectedColor-L1"
                : item.status == "Processing"
                  ? "processColor-L1"
                  : item.status == "ROS"
                    ? "readyColor-L1"
                    : ""
            } `}>
            {/* <img
                  src={`/images/${item.status}.png`}
                  alt={item.status}
                  className="img-fluid"
                  width={20}
                /> */}
            {item.status.includes("Pending") && <i className="fas fa-exclamation-circle"></i>}
            {item.status.includes("ROS") && <i className="fas fa-check-circle"></i>}
            {item.status.includes("Processing") && <i className="fas fa-spinner"></i>}
            {item.status.includes("Rejected") && <i className="fas fa-times-circle"></i>}
            <p>
              {item.status.includes("ROS") ?   t("open-order.Ready")
                : item.status.includes("Processing") ? t("open-order.Processed")
                  : item.status.includes("Pending") ? t("open-order.Placed")
                    : t("open-order.Rejected")
                    }
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(OrderProduct);
