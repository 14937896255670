/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// Change by sajid from top to bottom
import { Skeleton } from "@material-ui/lab";
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Page from "../../components/Page";
import { withTranslation, useTranslation, Trans } from "react-i18next";
// React Redux
import { useSelector, useDispatch } from "react-redux";
import ReplayIcon from "@mui/icons-material/Replay";
import RestoreIcon from "@mui/icons-material/Restore";
import { getPayTableInvoiceNoOrders } from "../../../../redux-store/action";

function CheckoutNoOrder() {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(30);
  const [retryAgain, setRetryAgain] = useState(false);
  let history = useHistory();
  // console.log("retryAgain",retryAgain)
  const handleClickOrder = () => {
    if (appState.storeInfo.iframeUrl != null) {
      history.push("/external");
    } else {
      history.push("/");
    }
  };

  const arLanguageHandle = (e) => {
    // console.log("ar change");
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");

    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  const handleRetry = () => {
    setRetryAgain(true);
    getPayTableInvoiceNoOrders(appState, appDispatch);

    var timeleft = 29;
    var downloadTimer = setInterval(function () {
      if (timeleft <= -1) {
        clearInterval(downloadTimer);
        setRetryAgain(false);
        setTimer(2);
      } else {
        setTimer(timeleft);
        timeleft -= 1;
      }
    }, 1000);
  };

  if (loading)
    return (
      <>
        <div className="row mx-1">
          <div className="col-1 col-md-1 px-2">
            <Skeleton height={50} />
          </div>
          <div className="col-3 col-md-2 px-2">
            <Skeleton height={50} />
          </div>
        </div>
        <div className="row mx-1 d-flex justify-content-between">
          <div className="col-3 col-md-2 px-2">
            <Skeleton height={35} />
            <Skeleton height={35} />
          </div>
          <div className="col-3 col-md-2 px-2">
            <Skeleton height={35} />
            <Skeleton height={35} />
          </div>
        </div>

        {[0, 1, 2].map((a) => (
          <div key={a} className="row m-0 mb-4 mt-3">
            <div className="col-3 col-md-2 px-2">
              <Skeleton variant="rect" className="prRectSkeleton" />
            </div>
            <div className="col-9 col-md-10 px-2">
              <Skeleton
                height={30}
                style={{
                  transform: "translate(2px, 0)",
                  marginBottom: "10px",
                }}
              />
              <Skeleton height={25} />
              <Skeleton height={25} />
            </div>
          </div>
        ))}
      </>
    );

  return (
    <Page title="Cart">
      <div
        id="wrapper "
        className={`vh-100 ${appState.langValue === "ar" ? "ml-2" : ""} `}
        // style={{ display:'flex',flexDirection:"column",minHeight:"96vh" }}
      >
        <section
          id="topNav"
          className={
            appState.langValue === "ar"
              ? "row m-0 fixed-top  justify-content-between align-items-center pl-2 bg-white"
              : "row m-0 fixed-top  justify-content-between align-items-center bg-white"
          }

        >
          <span className="col-12 offset-xl-3 mr-auto
            col-xl-6 d-flex align-item-center justify-content-between">
          <div className="d-flex align-items-center">
            <IconButton
              aria-label="back"
              className={appState.langValue === "ar" ? "backBtnAr" : "backBtn2"}
              onClick={handleClickOrder}
            >
              {appState.langValue === "ar" ? (
                <ArrowForwardIosIcon
                  style={{ padding: "0px !important" }}
                  fontSize="inherit"
                />
              ) : (
                <ArrowBackIosIcon fontSize="inherit" />
              )}
            </IconButton>
          </div>
          <div className="pl-1 mt-2">
            <h4>
              <strong> {t("navBar.Checkout")}</strong>
            </h4>
          </div>

          {appState.langValue == "ar" ? (
            <IconButton id="headerBtn" onClick={(e) => enLanguageHandle(e)}>
              <span className={`lang-textScroll p-0`}>EN</span>
            </IconButton>
          ) : (
            <IconButton
              id="headerBtn"
              onClick={(e) => arLanguageHandle(e)}
              className="arabicBtn"
            >
              <span className={`arabicText lang-textScroll p-0 mb-1`}>
                عربي{" "}
              </span>
            </IconButton>
          )}
        </span>

        </section>


        <section id="cartItemsNoOrder" className=" mt-5  mx-2">
          <div className="row m-0">
            <div className="col-12 p-0">
              <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                <img className="img-pro" src={`/images/checkNo2.svg`} alt="" />
                <h3 className="text-center mt-4">
                  {" "}
                  {t("cart.looks-like-you-have-not-orderd-yet")}!
                </h3>
                <p className="noOrderText m-0"> {t("cart.no-order-to-pay")}</p>
              </div>

              {!retryAgain ? (
                <div className="d-flex justify-content-center mt-3">
                  <Button onClick={handleRetry} className="retryButton">
                    {" "}
                    <ReplayIcon className="mx-2" />
                    {t("checkOut.Retry")}
                  </Button>
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-3">
                  <RestoreIcon className="mx-2" />
                  <p className="retryText">
                    {" "}
                    {t("checkOut.Retry-in")} 00:{timer}
                  </p>
                </div>
              )}
            </div>
          </div>
        </section>

        {(appState.cart.length <= 0 || appState.cart.length > 0) &&
          appState.payTableMode && (
            <>
              <div className="row">
                <section
                  id="btnSection"
                  className={` col-12 offset-xl-3  col-xl-6 bottomFixedBtn py-2`}
                >
                  <div className="row">
                    <div className="col-12 ">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="btn-main w-100 text-capitalize"
                        onClick={handleClickOrder}
                      >
                        {t("cart.check-menu")}
                      </Button>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
      </div>
    </Page>
  );
}

export default withTranslation()(CheckoutNoOrder);
