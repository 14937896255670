/* eslint-disable */
import React, { useEffect } from "react";
import "./addToCartPopup.scss";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { withTranslation, useTranslation } from "react-i18next";
// import Redux
import { useSelector, useDispatch } from "react-redux";
import DoneIcon from '@mui/icons-material/Done';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Fade from 'react-reveal/Fade';
import noImg from "../../assets/images/no-image.svg";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const AddToCartPopup = ({ item, closeBtn }) => {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [statePopUp,setStatePopUp] = React.useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
  });

  const { vertical, horizontal } = statePopUp;
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setOpen(true);
  };
  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (appState.addToCartPopup) {
      setTimeout(() => {
        appDispatch({
          type: 'showAddToCartPopup',
          addToCartPopup: false
        })
      }, 2000);
    }
  }, [appState.addToCartPopup])

  useEffect(() => {
    const changePopUpDirection = ()=>{
      if(appState.langValue === "ar"){
        setStatePopUp({
          open: false,
          vertical: 'bottom',
          horizontal: 'left',
        })
      }else{
        setStatePopUp({
          open: false,
          vertical: 'bottom',
          horizontal: 'right',
        })
      }

    }
    changePopUpDirection()
  }, [appState.langValue])
  return (
    <div id="AddToCartPopupMain">
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          key={transition ? transition.name : ""}
          anchorOrigin={{ vertical, horizontal }}
          open={appState.addToCartPopup}
          autoHideDuration={6000}
          onClose={handleClose}

        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}

            className={`${appState.langValue === "ar" ? "mr-auto ml-5" : ""
          }`}
          >
            <>
              <Fade bottom>
                <div className="card-main p-2 d-flex row m-0 w-100">
                  <div className="circleDivParent p-1">
                    <div className="circleDiv d-flex align-items-center justify-content-center">
                      <DoneIcon />
                    </div>
                  </div>
                  <div className="col-3 px-0 imgParent">
                    <img
                      src={appState.cartProd?.image != null ? appState.cartProd.image : noImg}
                      alt=""
                      className="img-fluid imgDiv"
                    />

                     
                  </div>
                  <div className="col-9 px-2 d-flex flex-column justify-content-center px-0 py-lg-2">
                    <div
                      className={`${appState.langValue === "ar" ? "pr-3" : "pl-3"
                        }`}
                    >
                      <h3
                        className={`familyManrope800 mb-0 ${appState.langValue === "ar"
                          ? "text-right"
                          : "text-left"
                          }`}
                      >

                        {t("cart.Your-way-meel-has-been-added-to-cart")}!
                      </h3>
                    </div>
                  </div>
                </div>
              </Fade>
            </>
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
};
export default withTranslation()(AddToCartPopup);