import React, { useState, useEffect } from "react";
import "./FeaturedProduct.scss";
import { useSelector, useDispatch } from "react-redux";
// cookie functions
import { erase, getCookie } from "../../../../global-functions/cookieFunction";
// for Localization
import { withTranslation, useTranslation } from "react-i18next";
// owl carasoul work start
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// owl carasoul work end
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Chip from "@mui/material/Chip";
import { AppBar, Button } from "@material-ui/core";
import { get_lang } from "../../../../global-functions/apiGlobal";
// axios for api
import axios from "axios";
// material ui
import Backdrop from "@mui/material/Backdrop";
import { Skeleton } from "@material-ui/lab";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { orderAgain } from "../../../../global-functions/reOrder";
import { Link } from "react-router-dom";
import noImg from "../../assets/images/no-image.svg";
import Tabs from "@mui/material/Tabs";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tab from "@mui/material/Tab";
import AddIcon from "@mui/icons-material/Add";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { useFeaturedProductAddToCart } from "../../hooks/useFeaturedProductAddToCart";
import { useFeaturedProductIncDec } from "../../hooks/useFeaturedProductIncDec";
import {
  freezOff,
  freezOn,
  handleFeaturedProduct,
} from "../../../../redux-store/action";
import { handleStockQuantity } from "../../../../global-functions/addToCartGlobal";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Badge } from "@material-ui/core";

const FeaturedProduct = (props) => {
  const { t } = useTranslation();
  let prodTotalQty = 0;
  const {
    featuredProduct,
    ind,
    product,
    placedBy,
    handleIncrement,
    handleDecrement,
    handleItemRemove,
    type,
  } = props;
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  let history = useHistory();
  const [quantityValue, setQuantityValue] = useState(1);
  const [checkCart, setCheckCart] = useState(false);
  const [addons, setAddons] = useState([]);
  const [addOnsTotal, setAddOnsTotal] = useState(0);
  const [addonLimit, setAddonLimit] = useState([]);
  const [errorStatus, setErrorStatus] = useState(false);
  const { handleAddToCart } = useFeaturedProductAddToCart(
    featuredProduct,
    product,
    featuredProduct.quantity,
    setErrorStatus,
    placedBy
  );
  const [stock, setStock] = useState(null);

  ///// handleFunctions:
  const getFeaturedProduct = (id) => {
    appDispatch(freezOn());
    const res = appDispatch(handleFeaturedProduct(id));
    appDispatch(freezOff());
  };



  useEffect(() => {
    if (
      appState.featuredProducts != null &&
      appState.featuredProducts.length > 0
    ) {
      appDispatch({
        type: "SET_FEATURED_PRODUCTS",
        featuredProductPopupMode: true,
      });
    }
  }, [appState.featuredProducts]);

  const handleClick = (e, url) => {
    e.preventDefault();
    history.push(url);
  };

  return (
    <>
      {featuredProduct != null && (
        <div className="item black " key={ind}>
          <a className="cursor-pointer text-decoration-none ">
            <span className="card-item">
              <section id="ProductsFeatured">
                <div
                  id={`category${featuredProduct.id}`}
                  // id={`category${featuredProduct.name.split(" ").join("")}`}
                  className="w-100"
                >
                  <div className="inner_prod">
                    <span>
                      <div className="prdCard w-100 menuProd ">
                        <span className="d-flex flex-column">
                          <div
                            className="AvatarParent"
                            onClick={
                              !featuredProduct.hasAddons && type !== "open-bill"
                                ? ""
                                : () => getFeaturedProduct(featuredProduct.id)
                            }
                          >
                            <Chip
                              className={`ChipClass ${
                                featuredProduct.checkedCart
                                  ? "ChipClassAnimation"
                                  : ""
                              }`}
                              label={t("single-product.added-to-cart")}
                              icon={<FavoriteIcon />}
                              variant="outlined"
                            />

                            <Avatar
                              variant="square"
                              className={`  ${
                                appState.langValue === "ar" ? "" : ""
                              } `}
                              src={
                                featuredProduct?.image
                                  ? `${featuredProduct?.image}`
                                  : `${noImg}`
                              }
                            ></Avatar>
                          </div>

                          <div className="d-flex quantityAvatar">
                            {/* {featuredProduct.checkedCart && (
                              <Avatar className="mx-2 my-2" variant="circular">
                                {featuredProduct.quantity}
                              </Avatar>
                            )} */}

                            <b
                              className={` px-2  text-uppercase catName familySora400 mt-2 px-2 ${
                                appState.langValue === "ar"
                                  ? "text-right"
                                  : "text-left"
                              } `}
                            >{`${
                              featuredProduct?.name != null
                                ? get_lang(
                                    featuredProduct?.name,
                                    appState.langValue
                                  )
                                : "Not Available"
                            }`}</b>
                          </div>
                        </span>
                        {/* <div className="py-2">

                        {(checkCart && !featuredProduct.hasAddons) ? <div
                          className={` d-flex  FPIncDecBtnMain pt-1 w-30 justify-content-center `}
                        >
                          <Button
                            onClick={() => quantityValue==1 ? handleItemRemove(featuredProduct) : handleDecrement(featuredProduct)}
                            className={`incrementBtn`}
                            variant="contained"
                           
                          >
                            {quantityValue==1 ?  <DeleteIcon className="px-1 textWhite"/>: <RemoveRoundedIcon className="px-1 textWhite" />}
                           
                           
                          </Button>

                          <div className="IncDecValue d-flex justify-content-center align-items-center">
                            <p className="px-2 mb-0  font-weight-900">{quantityValue}</p>
                          </div>

                          <Button
                            onClick={() => handleIncrement(featuredProduct)}
                            className={(quantityValue == featuredProduct.maxQuantity && featuredProduct.maxQuantity != null) ? `btnDisabled` : `incrementBtn`}
                            variant="contained"
                            disabled={(quantityValue == featuredProduct.maxQuantity && featuredProduct.maxQuantity != null)}

                          >
                            <AddIcon className="px-1 textWhite" />
                          </Button>
                        </div> : (checkCart && featuredProduct.hasAddons) ?

                        <div className="px-2"><Button
                        variant="contained"
                        className="prodAddToCart textOver w-100"
                        onClick={() => getFeaturedProduct(featuredProduct.id)}

                      >
                        <strong className="textOver"> Edit Item </strong>


                      </Button></div>
                        
                         : <div className="px-2">
                          <Button
                          variant="contained"
                          className="prodAddToCart textOver w-100 d-flex "
                          onClick={() => featuredProduct.hasAddons ? getFeaturedProduct(featuredProduct.id) : handleAddToCart(featuredProduct, addons, addonLimit, addOnsTotal)}

                        >
                          <span>  <AddIcon className="px-1 textWhite" /></span>
                          <strong className="textOver">{t("single-product.Add")} </strong>


                        </Button>
                          </div>}
                      </div> */}

                        <div className="py-2 d-flex flex-column">
                          <b
                            className={` textOver text-uppercase catName familySora400 mt-2 px-2 py-2 ${
                              appState.langValue === "ar"
                                ? "text-right"
                                : "text-left"
                            } `}
                          >
                            {" "}
                            {(
                              Number(featuredProduct.price) -
                              Number(featuredProduct.discount)
                            ).toFixed(3)}{" "}
                           {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                          </b>

                          {!appState.payTableMode &&
                            appState.isAddToCartEnable && (
                              <>
                                {featuredProduct.quantity > 0 &&
                                featuredProduct.checkedCart ? (
                                  // (featuredProduct.quantity > 0 && featuredProduct.hasAddons && featuredProduct.checkedCart)
                                  <>
                                    {/* { featuredProduct.hasAddons ?
                          <div className="px-2 d-flex justify-content-center  smallIcons">
                          <Button
                            onClick={() =>
                              handleItemRemove(featuredProduct)
                            }
                            className={`deleteIcon mx-1`}
                            variant="contained"
                          >
                            <DeleteIcon className="px-1 textWhite " />
                          </Button>

                          <Button
                            variant="contained"
                            className="prodAddToCart mx-1"
                            onClick={() =>
                              getFeaturedProduct(featuredProduct.id)
                            }
                          >
                            <EditIcon className="px-1 textWhite" />
                          </Button>
                        </div>
                        

                        : */}

                                    <div
                                      className={` d-flex  FPIncDecBtnMain pt-1 w-30 justify-content-center `}
                                    >
                                      <Button
                                        onClick={
                                          featuredProduct.quantity == 1
                                            ? () =>
                                                handleItemRemove(
                                                  featuredProduct
                                                )
                                            : () =>
                                                handleDecrement(featuredProduct)
                                        }
                                        className={`incrementBtn`}
                                        variant="contained"
                                      >
                                        {featuredProduct.quantity == 1 ? (
                                          <DeleteIcon className="px-1 textWhite" />
                                        ) : (
                                          <RemoveRoundedIcon className="px-1 textWhite" />
                                        )}
                                      </Button>

                                      <div className="IncDecValue d-flex justify-content-center align-items-center">
                                        <p className="px-2 mb-0  font-weight-900">
                                          {featuredProduct.quantity}
                                        </p>
                                      </div>

                                      <Button
                                        onClick={
                                          featuredProduct.quantity ==
                                          featuredProduct.maxQuantity
                                            ? ""
                                            : () =>
                                                handleIncrement(featuredProduct)
                                        }
                                        className={
                                          featuredProduct.quantity ==
                                            featuredProduct.maxQuantity &&
                                          featuredProduct.maxQuantity != null
                                            ? `btnDisabled`
                                            : `incrementBtn`
                                        }
                                        variant="contained"
                                        disabled={
                                          featuredProduct.quantity ==
                                            featuredProduct.maxQuantity &&
                                          featuredProduct.maxQuantity != null
                                        }
                                      >
                                        <AddIcon className="px-1 textWhite" />

                                      </Button>
                                    </div>
                                    {/* // } */}
                                  </>
                                ) : (
                                  <div className="px-2">
                                    <Button
                                      variant="contained"
                                      className="prodAddToCart addToCartBtn textOver w-100 d-flex "
                                      onClick={
                                        type == "open-bill"
                                          ? () =>
                                              history.push(
                                                `${featuredProduct?.pathUrl}`
                                              )
                                          : featuredProduct.hasAddons
                                          ? () =>
                                              getFeaturedProduct(
                                                featuredProduct.id
                                              )
                                          : () =>
                                              handleAddToCart(
                                                featuredProduct,
                                                addons,
                                                addonLimit,
                                                addOnsTotal
                                              )
                                      }
                                    >
                                      <span>
                                        {" "}
                                        <AddIcon className="px-1 textWhite" />
                                      </span>
                                      <strong className="textOver">
                                        {t("single-product.Add")}{" "}
                                      </strong>
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </section>
            </span>
          </a>
        </div>
      )}
    </>
  );
};

export default withTranslation()(FeaturedProduct);
