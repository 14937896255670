/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./payForItems.scss";
// material ui
import Button from "@mui/material/Button";
import Checkbox from "@material-ui/core/Checkbox";
// localizations
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import { get_lang } from "../../global-functions/apiGlobal";
import { parseNumber, roundFixed } from "../../global-functions/roundFixed";
import { handleDeleteOption } from "../../layout-one/js/Pages/Checkout";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function PayForItems(props) {
  const { t } = useTranslation();
  const {
    orders,
    paySelectedItems,
    setPaySelectedItems,
    setPartialType,
    splitBillAmount,
    setSplitBillAmount,
    submitCheckout,
    campaignDiscount,
    discount,
    tip,
    invoiceTotal
  } = props;
  const [orderId, setOrderId] = useState([]);
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [currentBill, setcurrentBill] = useState(splitBillAmount);
  const {
    totalCharges = 0,
    totalTax = 0,
    adjustedAmount = 0,
    charges = [],
  } = appState.invoiceTaxesChargesExtras;

  const handleSelectOrder = (event, index) => {
    let itemAmount = event.target.value;
    let id = event.target.id;
    let selectedItem = [...paySelectedItems];

    let findProd = paySelectedItems.find((x) => x.order_id == id);

    if (findProd) {
      selectedItem.splice(paySelectedItems.indexOf(findProd), 1);
    } else {
      let sumAddons = 0;
      let productName = "";
      let prodDisc = 0
      orders.forEach((ele) => {
        if (
          appState.serviceMode.includes("open-bill") ||
          appState.payTableMode
        ) {
          if (ele.meta === id) {
            sumAddons = ele.sum_addons;
            productName = ele.product?.name;
            
          }
        } else {
          if (ele.id === id) {
            sumAddons = ele.sum_addons;
            productName = ele.prod_title;
          }
        }
      });
      let data = {
        addons_total: sumAddons,
        order_id: id,
        amount: parseFloat(itemAmount).toFixed(3),
        prod_name: productName,
      };

      selectedItem.push(data);
    }
    setPaySelectedItems(selectedItem);

    let findOrderID = orderId.find((x) => x == event.target.id);
    if (findOrderID) {
      orderId.splice(orderId.indexOf(findOrderID), 1);
    } else {
      let data = event.target.id;
      if (appState.payTableMode) {
        orderId.push(data);
      } else {
        orderId.push(data);
      }
    }
    setOrderId(orderId);
  };

  const handleSelectOrderCombo = (event, index) => {
    let itemAmount = event.target.value;
    let id = event.target.id;
    let selectedItem = [...paySelectedItems];

    let findProd = paySelectedItems.find((x) => x.order_id === id);

    if (findProd) {
      selectedItem.splice(paySelectedItems.indexOf(findProd), 1);
    } else {
      let sumAddons = 0;
      let productName = "";
      let prooductDisc = ""
      appState.combos.forEach((ele) => {
        if (
          appState.serviceMode.includes("open-bill") ||
          appState.payTableMode
        ) {
          if (ele.meta === id) {
            productName = ele.combo.name
           prooductDisc = ele?.discounted_amount
          }
        }
      });
      let data = {
        order_id: id,
        addons_total: 0,
        amount: parseFloat(itemAmount).toFixed(3),
        prod_name: productName,
        prooduct_discount: prooductDisc
      };
      selectedItem.push(data);
    }
    setPaySelectedItems(selectedItem);

    let findOrderID = orderId.find((x) => x == event.target.id);
    if (findOrderID) {
      orderId.splice(orderId.indexOf(findOrderID), 1);
    } else {
      let data = event.target.id;
      if (appState.payTableMode) {
        orderId.push(data);
      } else {
        orderId.push(data);
      }
    }
    setOrderId(orderId);
  };

  useEffect(() => {
    if (paySelectedItems.length > 0) {
      let totalAmount = 0;
      // let beforeTip = invoiceTotal - Number(tip) 
      // let getRatio = totalAmount/beforeTip  //beforeTip in inovice total
      // let discountAndTip = Number((Number(discount) + Number(tip)) * getRatio)
      paySelectedItems.forEach((element) => {
        totalAmount += Number(element.amount) ;
        let roundTotal =   Number(totalAmount);
        let tax = appState.storeInfo.taxPricing == "exclusive" ? totalTax : 0;
        let getDiscount = campaignDiscount > 0 ? campaignDiscount : discount 
        let afterDiscount = roundTotal - getDiscount  + totalCharges + tax + Number(tip) + adjustedAmount;
        roundTotal =  afterDiscount;
        console.log(getDiscount, tax, totalAmount, Number(tip), Number(afterDiscount), Number(adjustedAmount), totalCharges);
        console.log(afterDiscount);
        setcurrentBill(totalAmount);
        setSplitBillAmount(afterDiscount);
      });
    } else {
      setSplitBillAmount(0);
      setcurrentBill(0);
    }
  }, [paySelectedItems, setSplitBillAmount]);

  useEffect(() => {
    let localData = JSON.parse(localStorage.getItem("selectedItems"));

    if (localData && localData.length > 0) {
      setPaySelectedItems(localData);
      localData.forEach((ele, i) => {
        orderId.push(ele.order_id);
      });
      setOrderId(orderId);
    }
    setPartialType("product");
  }, []);

  const handleChangeSplit = () => {
    handleDeleteOption(appState, appDispatch, 'payForItems')
   
    setSplitBillAmount(0);
    setPaySelectedItems([]);
  };

  const handleClickConfirm = () => {
    appDispatch({ type: "setPaymentPopUp", paymentPopUp: true });
    appDispatch({
      type: "setPayForItems",
      payForItems: false,
    });
    appDispatch({
      type: "setSplitBillOptions",
      splitBillOptions: false,
    });
  };
console.log(appState.serviceMode.includes("open-bill") ||
appState.payTableMode, )
  return (
    <>
      <div
        id="mySidenav"
        className={`PayForItems   ${
          appState.langValue === "ar" ? "PayForItemsArabic" : " "
        }`}
      >
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div className="container-fluid content px-4 pb-3 mt-3">
          <h1
            className={` ${
              appState.langValue === "ar"
                ? "text-right familyGraphikArabic800 "
                : " familyPoppins600"
            }`}
          >
            {t("checkOut.Pay-for-your-items")}
          </h1>
          {((appState.serviceMode.includes("open-bill") ||
            appState.payTableMode) && !appState.hotelMode) && (
            <div className="row classForCheckBoxBtn mt-3 px-2">
              {orders.length > 0
                ? orders.map((item, ind) => {
                    return (
                      (item.status !== "Rejected" && !item.paymentStatus) && (
                        <div
                          className="col-12 m-0 p-0"
                          onChange={(e) => handleSelectOrder(e, ind)}
                        >
                          <Checkbox
                            id={item.meta}
                            value={item.amount}
                            {...label}
                            checked={orderId.includes(item.meta) ? true : false}
                            icon={
                              <Button
                                className={`py-3 px-3 my-2 d-flex justify-content-between ${
                                  appState.langValue === "ar"
                                    ? " familyGraphikArabic600 "
                                    : ""
                                }`}
                              >
                                <span
                                  className={` d-flex justify-content-between ${
                                    appState.langValue === "ar"
                                      ? " familyGraphikArabic600 "
                                      : ""
                                  }`}
                                >
                                  {get_lang(
                                    item.product?.name,
                                    appState.langValue
                                  )}
                                </span>{" "}
                                <div className="d-flex">
                                  <span className="px-3">
                                    <div>
                                      {parseNumber(
                                        Number(item.total)
                                          ,
                                        appState.storeInfo.numberFixed
                                      )}{" "}
                                       {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                                    </div>
                                    {Number(item.discount) > 0 && (
                                      <div style={{ color: "red" }}>
                                        - {Number(item.discount).toFixed(3)}  {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                                      </div>
                                    )}
                                  </span>
                                  <span>
                                    {appState.langValue === "ar" ? (
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="#DCDCDC"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9 18C13.9706 18 18 13.9706 18 9C18 7.2993 17.5283 5.70877 16.7085 4.35213L18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L15.8164 3.12296C14.166 1.21049 11.7244 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15.8164 3.12296L9 9.93934L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75C9.19891 11.75 9.38968 11.671 9.53033 11.5303L16.7085 4.35213C16.4456 3.91698 16.1468 3.5059 15.8164 3.12296Z"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="#DCDCDC"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9 18C13.9706 18 18 13.9706 18 9C18 7.2993 17.5283 5.70877 16.7085 4.35213L18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L15.8164 3.12296C14.166 1.21049 11.7244 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15.8164 3.12296L9 9.93934L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75C9.19891 11.75 9.38968 11.671 9.53033 11.5303L16.7085 4.35213C16.4456 3.91698 16.1468 3.5059 15.8164 3.12296Z"
                                        />
                                      </svg>
                                    )}
                                  </span>
                                </div>{" "}
                              </Button>
                            }
                            checkedIcon={
                              <Button
                                className={`py-3 px-3 my-2 d-flex justify-content-between ${
                                  appState.langValue === "ar"
                                    ? " familyGraphikArabic600 "
                                    : ""
                                }`}
                              >
                                <span>
                                  {get_lang(
                                    item.product.name,
                                    appState.langValue
                                  )}
                                </span>{" "}
                                <div className="d-flex">
                                  {item.addons.length > 0 && (
                                    <span className="text-muted">
                                      <span className="px-1">
                                        {" "}
                                        {parseNumber(
                                          Number(item.sum_addons),
                                          appState.storeInfo.numberFixed
                                        )}
                                      </span>
                                      <span className="px-1"> {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</span>{" "}
                                    </span>
                                  )}
                                  <span className="px-3">
                                    <div>{(item.total).toFixed(3)}  {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</div>
                                    {Number(item.discount) > 0 && (
                                      <div style={{ color: "red" }}>
                                        - {Number(item.discount).toFixed(3)}  {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                                      </div>
                                    )}
                                  </span>{" "}
                                  <span>
                                    {appState.langValue === "ar" ? (
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="#60B200"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9 18C13.9706 18 18 13.9706 18 9C18 7.2993 17.5283 5.70877 16.7085 4.35213L18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L15.8164 3.12296C14.166 1.21049 11.7244 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15.8164 3.12296L9 9.93934L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75C9.19891 11.75 9.38968 11.671 9.53033 11.5303L16.7085 4.35213C16.4456 3.91698 16.1468 3.5059 15.8164 3.12296Z"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="#60B200"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9 18C13.9706 18 18 13.9706 18 9C18 7.2993 17.5283 5.70877 16.7085 4.35213L18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L15.8164 3.12296C14.166 1.21049 11.7244 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15.8164 3.12296L9 9.93934L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75C9.19891 11.75 9.38968 11.671 9.53033 11.5303L16.7085 4.35213C16.4456 3.91698 16.1468 3.5059 15.8164 3.12296Z"
                                        />
                                      </svg>
                                    )}
                                  </span>
                                </div>{" "}
                              </Button>
                            }
                          />
                        </div>
                      )
                    );
                  })
                : ""}

              {appState.combos != null
                ? appState.combos.map((combo, ind) => {
                  let val = combo.total - combo.discounted_amount
                    return (
                      combo.status !== "Rejected" && (
                        <div
                          className="col-12 m-0 p-0"
                          onChange={(e) => handleSelectOrderCombo(e, ind)}
                        >
                          <Checkbox
                            id={combo.meta}
                            value={combo.amount}
                            {...label}
                            checked={
                              orderId.includes(combo.meta) ? true : false
                            }
                            icon={
                              <Button
                                className={`py-3 px-3 my-2 d-flex justify-content-between ${
                                  appState.langValue === "ar"
                                    ? " familyGraphikArabic600 "
                                    : ""
                                }`}
                              >
                                <span
                                  className={` d-flex justify-content-between ${
                                    appState.langValue === "ar"
                                      ? " familyGraphikArabic600 "
                                      : ""
                                  }`}
                                >
                                  {combo.combo?.name != null
                                    ? get_lang(
                                        combo.combo?.name,
                                        appState.langValue
                                      )
                                    : "Name is not available"}
                                </span>{" "}
                                <div className="d-flex">
                                  <span className="px-3">
                                    <div>{combo.total.toFixed(3)}  {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</div>
                                    {Number(combo.discount) > 0 && (
                                      <div style={{ color: "red" }}>
                                        - {Number(combo.discount).toFixed(3)}  {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                                      </div>
                                    )}
                                  </span>
                                  <span>
                                    {appState.langValue === "ar" ? (
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="#DCDCDC"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9 18C13.9706 18 18 13.9706 18 9C18 7.2993 17.5283 5.70877 16.7085 4.35213L18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L15.8164 3.12296C14.166 1.21049 11.7244 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15.8164 3.12296L9 9.93934L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75C9.19891 11.75 9.38968 11.671 9.53033 11.5303L16.7085 4.35213C16.4456 3.91698 16.1468 3.5059 15.8164 3.12296Z"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="#DCDCDC"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9 18C13.9706 18 18 13.9706 18 9C18 7.2993 17.5283 5.70877 16.7085 4.35213L18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L15.8164 3.12296C14.166 1.21049 11.7244 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15.8164 3.12296L9 9.93934L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75C9.19891 11.75 9.38968 11.671 9.53033 11.5303L16.7085 4.35213C16.4456 3.91698 16.1468 3.5059 15.8164 3.12296Z"
                                        />
                                      </svg>
                                    )}
                                  </span>
                                </div>{" "}
                              </Button>
                            }
                            checkedIcon={
                              <Button
                                className={`py-3 px-3 my-2 d-flex justify-content-between ${
                                  appState.langValue === "ar"
                                    ? " familyGraphikArabic600 "
                                    : ""
                                }`}
                              >
                                <span>
                                  {combo.combo?.name != null
                                    ? get_lang(
                                        combo.combo?.name,
                                        appState.langValue
                                      )
                                    : "Name is not available"}
                                </span>{" "}
                                <div className="d-flex">
                                  <span className="px-3">
                                    <div>{combo.total.toFixed(3)}  {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</div>
                                    {Number(combo.discount) > 0 && (
                                      <div style={{ color: "red" }}>
                                        - {Number(combo.discount).toFixed(3)}  {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                                      </div>
                                    )}
                                  </span>
                                  <span>
                                    {appState.langValue === "ar" ? (
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="#60B200"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9 18C13.9706 18 18 13.9706 18 9C18 7.2993 17.5283 5.70877 16.7085 4.35213L18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L15.8164 3.12296C14.166 1.21049 11.7244 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15.8164 3.12296L9 9.93934L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75C9.19891 11.75 9.38968 11.671 9.53033 11.5303L16.7085 4.35213C16.4456 3.91698 16.1468 3.5059 15.8164 3.12296Z"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="#60B200"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9 18C13.9706 18 18 13.9706 18 9C18 7.2993 17.5283 5.70877 16.7085 4.35213L18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L15.8164 3.12296C14.166 1.21049 11.7244 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15.8164 3.12296L9 9.93934L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75C9.19891 11.75 9.38968 11.671 9.53033 11.5303L16.7085 4.35213C16.4456 3.91698 16.1468 3.5059 15.8164 3.12296Z"
                                        />
                                      </svg>
                                    )}
                                  </span>
                                </div>{" "}
                              </Button>
                            }
                          />
                        </div>
                      )
                    );
                  })
                : ""}
            </div>
          )}

          {((!appState.serviceMode.includes("open-bill") &&
            !appState.payTableMode  ) || appState.hotelMode) && (
              <div className="row classForCheckBoxBtn mt-3 px-2">
                {orders.length > 0
                  ? orders.map((item, ind) => {
                      return (
                        <div
                          className="col-12 m-0 p-0"
                          onChange={(e) => handleSelectOrder(e, ind)}
                          key={ind}
                        >
                          <Checkbox
                            id={item.id}
                            value={item.price}
                            {...label}
                            checked={orderId.includes(item.id) ? true : false}
                            icon={
                              <Button
                                className={`py-3 px-3 my-2 d-flex justify-content-between ${
                                  appState.langValue === "ar"
                                    ? " familyGraphikArabic600 "
                                    : ""
                                }`}
                              >
                                <span
                                  className={` d-flex justify-content-between ${
                                    appState.langValue === "ar"
                                      ? " familyGraphikArabic600 "
                                      : ""
                                  }`}
                                >
                                  {get_lang(
                                    item.prod_title,
                                    appState.langValue
                                  )}
                                </span>{" "}
                                <div className="d-flex">
                                  <span className="px-3">
                                    {item.price.toFixed(3)}  {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                                  </span>{" "}
                                  <span>
                                    {appState.langValue === "ar" ? (
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="#DCDCDC"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9 18C13.9706 18 18 13.9706 18 9C18 7.2993 17.5283 5.70877 16.7085 4.35213L18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L15.8164 3.12296C14.166 1.21049 11.7244 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15.8164 3.12296L9 9.93934L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75C9.19891 11.75 9.38968 11.671 9.53033 11.5303L16.7085 4.35213C16.4456 3.91698 16.1468 3.5059 15.8164 3.12296Z"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="#DCDCDC"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9 18C13.9706 18 18 13.9706 18 9C18 7.2993 17.5283 5.70877 16.7085 4.35213L18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L15.8164 3.12296C14.166 1.21049 11.7244 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15.8164 3.12296L9 9.93934L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75C9.19891 11.75 9.38968 11.671 9.53033 11.5303L16.7085 4.35213C16.4456 3.91698 16.1468 3.5059 15.8164 3.12296Z"
                                        />
                                      </svg>
                                    )}
                                  </span>
                                </div>{" "}
                              </Button>
                            }
                            checkedIcon={
                              <Button
                                className={`py-3 px-3 my-2 d-flex justify-content-between ${
                                  appState.langValue === "ar"
                                    ? " familyGraphikArabic600 "
                                    : ""
                                }`}
                              >
                                <span>
                                  {get_lang(
                                    item.prod_title,
                                    appState.langValue
                                  )}
                                </span>{" "}
                                <div className="d-flex">
                                  {item.addons.length > 0 && (
                                    <span className="text-muted">
                                      <span className="px-1">
                                        {" "}
                                        {item.sum_addons.toFixed(3)}
                                      </span>
                                      <span className="px-1"> {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</span>{" "}
                                    </span>
                                  )}
                                  <span className="px-3">
                                    <div>{item.price.toFixed(3)}  {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</div>
                                    {Number(item.discount) > 0 && (
                                      <div style={{ color: "red" }}>
                                        - {Number(item.discount).toFixed(3)}  {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                                      </div>
                                    )}{" "}
                                  </span>{" "}
                                  <span>
                                    {appState.langValue === "ar" ? (
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="#60B200"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9 18C13.9706 18 18 13.9706 18 9C18 7.2993 17.5283 5.70877 16.7085 4.35213L18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L15.8164 3.12296C14.166 1.21049 11.7244 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15.8164 3.12296L9 9.93934L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75C9.19891 11.75 9.38968 11.671 9.53033 11.5303L16.7085 4.35213C16.4456 3.91698 16.1468 3.5059 15.8164 3.12296Z"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="19"
                                        height="18"
                                        viewBox="0 0 19 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill="#60B200"
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M9 18C13.9706 18 18 13.9706 18 9C18 7.2993 17.5283 5.70877 16.7085 4.35213L18.5303 2.53033C18.8232 2.23744 18.8232 1.76256 18.5303 1.46967C18.2374 1.17678 17.7626 1.17678 17.4697 1.46967L15.8164 3.12296C14.166 1.21049 11.7244 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15.8164 3.12296L9 9.93934L6.53033 7.46967C6.23744 7.17678 5.76256 7.17678 5.46967 7.46967C5.17678 7.76256 5.17678 8.23744 5.46967 8.53033L8.46967 11.5303C8.61032 11.671 8.80109 11.75 9 11.75C9.19891 11.75 9.38968 11.671 9.53033 11.5303L16.7085 4.35213C16.4456 3.91698 16.1468 3.5059 15.8164 3.12296Z"
                                        />
                                      </svg>
                                    )}
                                  </span>
                                </div>{" "}
                              </Button>
                            }
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>
            )}

          <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
            <h5
              className={` totalAmount mb-0  ${
                appState.langValue === "ar" ? "text-right" : "familyManrope800"
              }`}
            >
              {t("checkOut.Total-Bill")}:
            </h5>
            <h5
              className={` totalAmount mb-0 ${
                appState.langValue === "ar" ? "text-right" : "familyManrope800"
              }`}
            >
              {/* <span className="px-1">   { promoAmount > 0  ? <>   {().toFixed(3)}  -  {splitBillAmount.toFixed(3)}  </>:   splitBillAmount.toFixed(3)}</span>.toFixed(3) */}
              <span className="px-1"> {currentBill.toFixed(3)} </span>
              <span className="px-1"> {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</span>{" "}
            </h5>
          </div>

          {(campaignDiscount > 0 || discount > 0) && (
            <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
              <h5
                className={` totalAmount mb-0  ${
                  appState.langValue === "ar"
                    ? "text-right"
                    : "familyManrope800"
                }`}
              >
                {t("checkOut.discount-amount")}:
              </h5>

              <h5
                className={` totalAmount mb-0 ${
                  appState.langValue === "ar"
                    ? "text-right"
                    : "familyManrope800"
                }`}
              >
                {/* <span className="px-1">   { promoAmount > 0  ? <>   {().toFixed(3)}  -  {splitBillAmount.toFixed(3)}  </>:   splitBillAmount.toFixed(3)}</span>.toFixed(3) */}
                <span className="px-1">
                  {" "}
                  {campaignDiscount
                    ? parseNumber(
                        campaignDiscount,
                        appState.storeInfo.numberFixed
                      )
                    : parseNumber(
                        discount,
                        appState.storeInfo.numberFixed
                      )}{" "}
                </span>
                <span className="px-1"> {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</span>{" "}
              </h5>
            </div>
          )}

          {totalCharges > 0 && (
            <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
              <h5
                className={` totalAmount mb-0 text-capitalize  ${
                  appState.langValue === "ar"
                    ? "text-right"
                    : "familyManrope800"
                }`}
              >
                {t("checkOut.charges")}:
              </h5>

              <h5
                className={` totalAmount mb-0 ${
                  appState.langValue === "ar"
                    ? "text-right"
                    : "familyManrope800"
                }`}
              >
                {/* <span className="px-1">   { promoAmount > 0  ? <>   {().toFixed(3)}  -  {splitBillAmount.toFixed(3)}  </>:   splitBillAmount.toFixed(3)}</span>.toFixed(3) */}
                <span className="px-1">{totalCharges}</span>
                <span className="px-1"> {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</span>{" "}
              </h5>
            </div>
          )}

          {totalTax > 0 && (
            <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
              <h5
                className={` totalAmount mb-0 text-capitalize  ${
                  appState.langValue === "ar"
                    ? "text-right"
                    : "familyManrope800"
                }`}
              >
                {t("checkOut.Tax")}:
              </h5>

              <h5
                className={` totalAmount mb-0 ${
                  appState.langValue === "ar"
                    ? "text-right"
                    : "familyManrope800"
                }`}
              >
                {/* <span className="px-1">   { promoAmount > 0  ? <>   {().toFixed(3)}  -  {splitBillAmount.toFixed(3)}  </>:   splitBillAmount.toFixed(3)}</span>.toFixed(3) */}
                <span className="px-1">{totalTax}</span>
                <span className="px-1"> {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</span>{" "}
              </h5>
            </div>
          )}

          <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
            <h5
              className={` totalAmount mb-0  ${
                appState.langValue === "ar" ? "text-right" : "familyManrope800"
              }`}
            >
              {t("checkOut.You-are-paying")}:
            </h5>
            <h5
              className={` totalAmount mb-0 ${
                appState.langValue === "ar" ? "text-right" : "familyManrope800"
              }`}
            >
              {/* <span className="px-1">   { promoAmount > 0  ? <>   {().toFixed(3)}  -  {splitBillAmount.toFixed(3)}  </>:   splitBillAmount.toFixed(3)}</span>.toFixed(3) */}
              <span className="px-1">
                {parseNumber(splitBillAmount, appState.storeInfo.numberFixed)}
              </span>
              <span className="px-1"> {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</span>{" "}
            </h5>
          </div>

          <div className="bottomBtn">
            <Button
              onClick={handleClickConfirm}
              disabled={splitBillAmount <= 0}
              className={` py-3 px-4  mt-3 justify-content-center ${
                appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              } ${splitBillAmount <= 0 ? `disabledBtn` : `confirmBtn`}`}
            >
              {" "}
              <span className="text-capitalize">
                {t("checkOut.Confirm")}
              </span>{" "}
            </Button>
            <Button
              onClick={handleChangeSplit}
              className={` py-3 px-4 mt-3 deletebtn justify-content-center ${
                appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              }`}
            >
              {" "}
              <span className="text-capitalize">
                {t("checkOut.Delete-Split")}
              </span>{" "}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(PayForItems);
