/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./divideBill.scss";
// material ui
import Button from "@mui/material/Button";
import { PieChart } from "react-minimal-pie-chart";
// handleTotalPerson
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import IconButton from "@mui/material/IconButton";
import { parseNumber, roundFixed } from "../../global-functions/roundFixed";
import { handleDeleteOption } from "../../layout-one/js/Pages/Checkout";
import { get_lang } from "../../global-functions/apiGlobal";

function DivideBill(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const {
    discount = 0,
    totalCharges = 0,
    totalTax = 0,
    adjustedAmount = 0,
    // tip = 0,
    charges = [],
  } = appState.invoiceTaxesChargesExtras;
  const {
    subTotal,
    fullAmount,
    orders,
    setPartialType,
    splitBillAmount,
    setSplitBillAmount,
    totalPerson,
    setTotalPerson,
    personWillPay,
    setPersonWillPay,
    submitCheckout,
    campaignDiscount,
    tip,
  } = props;
  const [divideBillContent, setDivideBillContent] = useState([]);
  var grayObj = { title: "gray", value: 1, color: "#D9D9D9" };
  var greenObj = { title: "green", value: 1, color: "#60B200" };
  var storeObj = {
    title: "store",
    value: 1,
    color: appState.storeInfo.secondaryColor,
  };
  const [currentBill, setCurrentBill] = useState(0);
  const [currentCharge, setCurrentCharge] = useState(0);
  const [currentTax, setCurrentTax] = useState(0);
  const [currentDiscount, setCurrentDiscount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [paidPerson, setPaidPerson] = useState(0);
  const [paritalTotal, setParitalTotal] = useState(0);
  const handleChangeSplit = () => {
    handleDeleteOption(appState, appDispatch, "divideBill");
    setSplitBillAmount(0);
    setPartialType("");
  };

  useEffect(() => {
    if (appState.shopMode.includes("is_partial")) {
      console.log(orders);
      let person = 0;
      let payer = 0;
      let stop = false;
      orders.forEach((ele) => {
        if (!stop) {
          if (ele.divideBillInfo != undefined) {
            person = Number(ele?.divideBillInfo?.person);
            payer = Number(ele?.divideBillInfo?.payer);
            setPaidPerson(payer);
            setParitalTotal(person);
            stop = true;
          }
        }
      });
      if (splitBillAmount <= 0) {
        setTotalPerson(Number(person - payer));
        setPersonWillPay(1);
        let grayCount = Number(person - payer - 1);
        let greenCount = Number(payer);
        let arr = [];
        for (let i = 0; i < greenCount; i++) {
          arr.push(greenObj);
        }
        arr.push(storeObj);
        for (let j = 0; j < grayCount; j++) {
          arr.push(grayObj);
        }
        setDivideBillContent(arr);
      } else {
        let localData = JSON.parse(localStorage.getItem("divideColor"));
        if (localData && localData.length) {
          setDivideBillContent(localData);
        }
      }
    } else {
      if (splitBillAmount <= 0) {
        setTotalPerson(2);
        setPersonWillPay(1);
        setDivideBillContent([grayObj, storeObj]);
      } else {
        let localData = JSON.parse(localStorage.getItem("divideColor"));
        if (localData && localData.length) {
          setDivideBillContent(localData);
        }
      }
    }

    setPartialType("ratio");
  }, []);

  const handleNoOFPerson = (type) => {
    if (appState.shopMode.includes("is_partial")) {
      if (type === "inc" && totalPerson < 10) {
        setTotalPerson(totalPerson + 1);
        let dbc = divideBillContent;
        setDivideBillContent([grayObj, ...dbc]);
      } else if (type === "dec") {
        if (totalPerson > 2) {
          if (totalPerson - 1 < personWillPay) {
            setPersonWillPay(totalPerson - 1);
          }
          setTotalPerson(totalPerson - 1);
          let dbc = divideBillContent;
          dbc.shift();
          setDivideBillContent(dbc);
        }
      }
    } else {
      if (type === "inc" && totalPerson < 10) {
        setTotalPerson(totalPerson + 1);
        let dbc = divideBillContent;
        setDivideBillContent([grayObj, ...dbc]);
      } else if (type === "dec") {
        if (totalPerson > 2) {
          if (totalPerson - 1 < personWillPay) {
            setPersonWillPay(totalPerson - 1);
          }
          setTotalPerson(totalPerson - 1);
          let dbc = divideBillContent;
          dbc.shift();
          setDivideBillContent(dbc);
        }
      }
    }
  };

  const handlePersonWillPay = (type) => {
    if (appState.shopMode.includes("is_partial")) {
      if (type === "inc" && personWillPay < totalPerson) {
        setPersonWillPay(personWillPay + 1);
        let dbc = divideBillContent;
        let flag = true;
        dbc.forEach((ele, i) => {
          if (flag) {
            if (ele.color === "#D9D9D9") {
              dbc[i] = storeObj;
              flag = false;
            }
          }
        });
        // setDivideBillContent([...dbc, storeObj]);
      } else if (type === "dec") {
        if (personWillPay > 1) {
          setPersonWillPay(personWillPay - 1);
          let dbc = divideBillContent;
          let flag = true;
          dbc.forEach((ele, i) => {
            if (flag) {
              if (ele.color === appState.storeInfo.secondaryColor) {
                dbc.splice(i, 1);
                flag = false;
              }
            }
          });
          setDivideBillContent([...dbc, grayObj]);
        }
      }
    } else {
      if (type === "inc" && personWillPay < totalPerson) {
        setPersonWillPay(personWillPay + 1);
        let dbc = divideBillContent;
        if (dbc[0].color === "#D9D9D9") {
          dbc.shift();
        }
        setDivideBillContent([...dbc, storeObj]);
      } else if (type === "dec") {
        if (personWillPay > 1) {
          setPersonWillPay(personWillPay - 1);
          let dbc = divideBillContent;
          dbc.unshift(grayObj);
          dbc.pop();
          setDivideBillContent(dbc);
        }
      }
    }
  };

  const numberFixed = (value) => {
    let result = parseNumber(value, appState.storeInfo.numberFixed);
    return result;
  };
  useEffect(() => {
    console.log('person',totalPerson,'willpay',personWillPay );
    let tax = appState.storeInfo.taxPricing == "exclusive" ? totalTax : 0;
    let newTip = Number((tip / totalPerson) * personWillPay);
    let anyDiscount = (campaignDiscount > 0 ? campaignDiscount : discount);
    let charge = Number((totalCharges / totalPerson) * personWillPay);
    let amount = Number((subTotal / totalPerson) * personWillPay).toFixed(3);
    let adjusAmount = (adjustedAmount / totalPerson) * personWillPay;
    tax = Number((tax / totalPerson) * personWillPay);
    anyDiscount = Number((anyDiscount / totalPerson) * personWillPay);

    // let afterDiscount = amount - anyDiscount + totalCharges + tip + tax + adjustedAmount  ;
    let afterDiscount = Number(Number(amount) + Number(tip));
    setCurrentBill(Number(amount));
    setCurrentCharge(charge);
    setCurrentTax(tax);
    setCurrentDiscount(anyDiscount);
    setSplitBillAmount(afterDiscount);
  }, [divideBillContent, personWillPay, totalPerson]);


  useEffect(() => {
    console.log(typeof(splitBillAmount),splitBillAmount, splitBillAmount <= 0.10)
  }, [splitBillAmount])
  
  
  const handleClickConfirm = () => {
    appDispatch({ type: "setPaymentPopUp", paymentPopUp: true });
    appDispatch({ type: "setDivideBill", divideBill: false });
    appDispatch({ type: "setSplitBillOptions", splitBillOptions: false });
  };

  return (
    <>
      <div
        id="mySidenav"
        className={`divideBill ${
          appState.langValue === "ar" ? "divideBillArabic" : " "
        }`}
      >
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div className="container-fluid content px-4 pb-3 mt-4">
          <h1
            className={` ${
              appState.langValue === "ar"
                ? "text-right familyGraphikArabic800 "
                : " familyPoppins600"
            }`}
          >
            {" "}
            {t("checkOut.Divide-the-bill-equally")}
          </h1>
          {/* <div className="d-flex justify-content-center py-3">     <img src={InvoiceProcessing} /></div> */}
          <div className="pieChart d-flex justify-content-center">
            <PieChart
              animation
              animationDuration={500}
              animationEasing="ease-out"
              center={[50, 50]}
              data={divideBillContent}
              labelPosition={50}
              lengthAngle={360}
              lineWidth={15}
              paddingAngle={5}
              radius={40}
              startAngle={92.5}
              viewBoxSize={[100, 100]}
            />
          </div>
         {appState.shopMode.includes("is_partial") && <p style={{ color: "#60B200" }}>
            * {t("checkOut.part-of-the-amount-is-already-paid")}
          </p>}

          <div className="py-3">
            <div className="row align-items-center">
              <div className="col-6">
                <span
                  className={` ${
                    appState.langValue === "ar"
                      ? "text-right d-flex familyGraphikArabic800 "
                      : " familyPoppins"
                  }`}
                >
                  {t("checkOut.Number-of-people-at-the-table")}
                </span>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <div
                  className={` d-flex  IncDecBtnMain   ${
                    appState.langValue === "ar"
                      ? "flex-row-reverse justify-content-start"
                      : "justify-content-end"
                  } `}
                >
                  {
                    <IconButton
                      onClick={() => handleNoOFPerson("dec")}
                      className={`  ${
                        appState.shopMode.includes("is_partial")
                          ? `btnDisabled`
                          : `decrementBtn`
                      }`}
                      variant="contained"
                      disabled={appState.shopMode.includes("is_partial")}
                    >
                      <RemoveRoundedIcon className="px-1 " />
                    </IconButton>
                  }

                  <div className="IncDecValue d-flex px-2 justify-content-center align-items-center">
                    <p className={`mb-0 familyPoppins700 px-2`}>
                      {appState.shopMode.includes("is_partial")
                        ? paritalTotal
                        : totalPerson}
                    </p>
                  </div>

                  {
                    <IconButton
                      onClick={() => handleNoOFPerson("inc")}
                      className={
                        appState.shopMode.includes("is_partial")
                          ? `btnDisabled`
                          : `incrementBtn`
                      }
                      disabled={appState.shopMode.includes("is_partial")}
                      variant="contained"
                    >
                      <AddIcon className="px-1 " />
                    </IconButton>
                  }
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-3">
              <div className="col-6">
                <span
                  className={` ${
                    appState.langValue === "ar"
                      ? "text-right d-flex familyGraphikArabic800 "
                      : " familyPoppins"
                  }`}
                >
                  {t("checkOut.You-will-pay-for")}
                </span>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <div
                  className={` d-flex  IncDecBtnMain   ${
                    appState.langValue === "ar"
                      ? "flex-row-reverse justify-content-start"
                      : "justify-content-end"
                  } `}
                >
                  <IconButton
                    onClick={() => handlePersonWillPay("dec")}
                    className="decrementBtn"
                    variant="contained"
                  >
                    <RemoveRoundedIcon className="px-1 " />
                  </IconButton>

                  <div className="IncDecValue d-flex justify-content-center px-2 align-items-center">
                    <p className="px-2 mb-0 familyPoppins700">
                      {personWillPay}
                    </p>
                  </div>

                  <IconButton
                    onClick={() => handlePersonWillPay("inc")}
                    className={false ? `btnDisabled` : `incrementBtn`}
                    variant="contained"
                  >
                    <AddIcon className="px-1 " />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
            <h5
              className={` totalAmount mb-0  ${
                appState.langValue === "ar" ? "text-right" : "familyManrope800"
              }`}
            >
              {t("checkOut.Total-Bill")}:
            </h5>
            <h5
              className={` totalAmount mb-0 ${
                appState.langValue === "ar" ? "text-right" : "familyManrope800"
              }`}
            >
              {/* <span className="px-1">   { promoAmount > 0  ? <>   {().toFixed(3)}  -  {splitBillAmount.toFixed(3)}  </>:   splitBillAmount.toFixed(3)}</span>.toFixed(3) */}
              {/* {t("checkOut.Tip-Total")} */}
              {/*   <span className="px-1"> { numberFixed(currentBill) } </span> */}
              <span className="px-1">
                {" "}
                {appState.shopMode.includes("is_partial")
                  ? fullAmount
                  : numberFixed(subTotal)}{" "}
              </span>
              <span className="px-1">{get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
              </span>{" "}
            </h5>
          </div>
          {appState.shopMode.includes("is_partial") && (
            <>
              <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
                <h5
                  className={` totalAmount mb-0  ${
                    appState.langValue === "ar"
                      ? "text-right"
                      : "familyManrope800"
                  }`}
                >
                  {t("checkOut.You-Paid")}:
                </h5>
                <h5
                  className={` totalAmount mb-0 ${
                    appState.langValue === "ar"
                      ? "text-right"
                      : "familyManrope800"
                  }`}
                >
                  {/* <span className="px-1">   { promoAmount > 0  ? <>   {().toFixed(3)}  -  {splitBillAmount.toFixed(3)}  </>:   splitBillAmount.toFixed(3)}</span>.toFixed(3) */}
                  {/* {t("checkOut.Tip-Total")} */}
                  {/*   <span className="px-1"> { numberFixed(currentBill) } </span> */}
                  <span className="px-1" style={{ color: "#60B200" }}>
                    {" "}
                    {appState.shopMode.includes("is_partial")
                      ? numberFixed(fullAmount - subTotal)
                      : numberFixed(subTotal)}{" "}
                  </span>
                  <span className="px-1" style={{ color: "#60B200" }}>
                    {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}

                  </span>{" "}
                </h5>
              </div>

              <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
                <h5
                  className={` totalAmount mb-0  ${
                    appState.langValue === "ar"
                      ? "text-right"
                      : "familyManrope800"
                  }`}
                >
                  {t("checkOut.Left-to-Pay")}:
                </h5>
                <h5
                  className={` totalAmount mb-0 ${
                    appState.langValue === "ar"
                      ? "text-right"
                      : "familyManrope800"
                  }`}
                >
                  {/* <span className="px-1">   { promoAmount > 0  ? <>   {().toFixed(3)}  -  {splitBillAmount.toFixed(3)}  </>:   splitBillAmount.toFixed(3)}</span>.toFixed(3) */}
                  {/* {t("checkOut.Tip-Total")} */}
                  {/*   <span className="px-1"> { numberFixed(currentBill) } </span> */}
                  <span className="px-1" style={{color: appState.storeInfo.secondaryColor}}> {numberFixed(subTotal)} </span>
                  <span className="px-1" style={{color: appState.storeInfo.secondaryColor}}>{get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                  </span>{" "}
                </h5>
              </div>
            </>
          )}

          {tip > 0 && (
            <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
              <h5
                className={` totalAmount mb-0  ${
                  appState.langValue === "ar"
                    ? "text-right"
                    : "familyManrope800"
                }`}
              >
                {t("checkOut.Tip-Total")}:
              </h5>
              <h5
                className={` totalAmount mb-0 ${
                  appState.langValue === "ar"
                    ? "text-right"
                    : "familyManrope800"
                }`}
              >
                <span className="px-1"> {numberFixed(tip)} </span>
                <span className="px-1">{get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                </span>{" "}
              </h5>
            </div>
          )}
          {/* 
        {(campaignDiscount > 0 || discount > 0 ) &&  <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
            <h5
              className={` totalAmount mb-0  ${
                appState.langValue === "ar" ? "text-right" : "familyManrope800"
              }`}
            >
              {t("checkOut.discount-amount")}:
            </h5>
            <h5
              className={` totalAmount mb-0 ${
                appState.langValue === "ar" ? "text-right" : "familyManrope800"
              }`}
            >
              {/* <span className="px-1">   { promoAmount > 0  ? <>   {().toFixed(3)}  -  {splitBillAmount.toFixed(3)}  </>:   splitBillAmount.toFixed(3)}</span>.toFixed(3) 
              <span className="px-1">
                {numberFixed(currentDiscount)}
              </span>
              <span className="px-1">{get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
              </span>{" "}
            </h5>
          </div>}

          {totalCharges > 0 && (
            <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
              <h5
                className={` totalAmount mb-0 text-capitalize  ${
                  appState.langValue === "ar"
                    ? "text-right"
                    : "familyManrope800"
                }`}
              >
                {t("checkOut.charges")}:
              </h5>

              <h5
                className={` totalAmount mb-0 ${
                  appState.langValue === "ar"
                    ? "text-right"
                    : "familyManrope800"
                }`}
              >
                {/* <span className="px-1">   { promoAmount > 0  ? <>   {().toFixed(3)}  -  {splitBillAmount.toFixed(3)}  </>:   splitBillAmount.toFixed(3)}</span>.toFixed(3) 
                <span className="px-1">{numberFixed(currentCharge)}</span>
                <span className="px-1">{get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                </span>{" "}
              </h5>
            </div>
          )}
          {totalTax > 0 && (
            <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
              <h5
                className={` totalAmount mb-0 text-capitalize  ${
                  appState.langValue === "ar"
                    ? "text-right"
                    : "familyManrope800"
                }`}
              >
                {t("checkOut.Tax")}:
              </h5>

              <h5
                className={` totalAmount mb-0 ${
                  appState.langValue === "ar"
                    ? "text-right"
                    : "familyManrope800"
                }`}
              >
                {/* <span className="px-1">   { promoAmount > 0  ? <>   {().toFixed(3)}  -  {splitBillAmount.toFixed(3)}  </>:   splitBillAmount.toFixed(3)}</span>.toFixed(3)
                <span className="px-1">{numberFixed(currentTax)} </span>
                <span className="px-1">{get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                </span>{" "}
              </h5>
            </div>
          )}
           */}

          <div className="d-flex  align-items-center py-3 w-100 justify-content-between ">
            <h5
              className={` totalAmount mb-0  ${
                appState.langValue === "ar"
                  ? "text-right d-flex"
                  : "familyPoppins600"
              }`}
            >
              {t("checkOut.You-are-paying")}:
            </h5>
            <h5
              className={` totalAmount mb-0 ${
                appState.langValue === "ar" ? "text-right" : "familyPoppins600"
              }`}
            >
              <span className="px-1">{splitBillAmount.toFixed(3)} </span>
              <span className="px-1">{get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
              </span>{" "}
            </h5>
          </div>

          <div className="bottomBtn">
            <Button
              disabled={Number(splitBillAmount) <= 0.10}
              variant="contained"
              onClick={handleClickConfirm}
              className={` py-3 px-4 confirmBtn mt-3 justify-content-center ${
                appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              } ${splitBillAmount <= 0.10 ?
                `disabledBtn` : `confirmBtn`}`}
            >
              {" "}
              <span>{t("checkOut.Confirm")}</span>{" "}
            </Button>
            <Button
              onClick={handleChangeSplit}
              className={` py-3 px-4 mt-3 deletebtn justify-content-center ${
                appState.langValue === "ar" ? " familyGraphikArabic600" : ""
              }`}
            >
              {" "}
              <span>{t("checkOut.Delete-Split")} </span>{" "}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(DivideBill);
