export const handleServiceModes = (branchObj,hotel_qrcode_obj,dispatch) => {
    if(branchObj?.services.includes("dine-in") && !branchObj?.services.includes("pay-table") && !branchObj?.services.includes("open-bill")){
        let services = [...branchObj?.services]
        services.push("close-bill")
        dispatch({
          type: "SET_SERVICES_MODE",
          serviceMode: services ? services : [],
        });
      }else{
        dispatch({
          type: "SET_SERVICES_MODE",
          serviceMode: branchObj?.services ? branchObj?.services : [],
        });
      }
      if(hotel_qrcode_obj){
        let services = [...branchObj?.services]
        services.push("hotel")
        dispatch({
          type: "SET_SERVICES_MODE",
          serviceMode: services ? services : [],
        });
      }
};