/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./cartItemv2.scss";
import { Button } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import noImg from "../../assets/images/no-image.svg";
import trashNew from "../../assets/images/trashltt.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// for Localization
import { withTranslation, useTranslation, Trans } from "react-i18next";
// global funtion for store
import { get_lang } from "../../../../global-functions/apiGlobal";
import { handleAddonsDecrement, handleAddonsIncrement, handleAddonsQtyByProduct } from "../../../../global-functions/addToCartGlobal";
import { roundFixed } from "../../../../global-functions/roundFixed";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';



const CartItem = ({ item }) => {
  const appState = useSelector((state) => state);
  const { t } = useTranslation();
  const appDispatch = useDispatch();
  const [quantityValue, setQuantityValue] = useState(item.quantity);
  const [addonQuantity, setAddonQuantity] = useState(item.addons.quantity);
  const [maxquantity] = useState(item.maxQuantity);
  const [openModal, setOpenModal] = useState(false);
  const [addonsCount, setAddonsCount] = useState(0)

  const handleAddonsLimit = () => {
    let newArry = [...item.addons_limit];
    for (let i = 0; i < newArry.length; i++) {
      var arr = [];
      let sum = 0;
      for (let k = 0; k < item.addons.length; k++) {
        if (item.addons[k].category_addon_id == newArry[i].id) {
          arr.push(item.addons[k].quantity);
        }
      }
      for (let j = 0; j < arr.length; j++) {
        sum += arr[j];
        newArry[i].addons_qty = sum;
      }
    }
    item.addons_limit = newArry;
  };
  // addon Quantity Funtion
  const handleIncrementAddons = async (addon_id, category_id) => {
    let res = handleAddonsIncrement(
      item.addons_limit,
      item.addons,
      addon_id,
      category_id,
      appDispatch, appState, quantityValue
    );
    item.addons = res;
    sumAddons();
    handleAddonsLimit();
  };

  const handleDecrementAddons = (addon_id, category_id) => {
    let res = handleAddonsDecrement(
      item.addons_limit,
      item.addons,
      addon_id,
      category_id
    );
    item.addons = res;
    sumAddons();
    handleAddonsLimit();
  };

  // sum_addons function
  const sumAddons = async () => {
    let arry = item.addons;
    let sum_addons = 0;
    let count = 0;
    for (let i = 0; i < arry.length; i++) {
      let price_qty = arry[i].price * arry[i].quantity;
      count = count + arry[i].quantity;
      sum_addons = sum_addons + price_qty;
    }

    item.sum_addons = sum_addons;
    setAddonsCount(count);
    setAddonQuantity(sum_addons);
  };
  // qunatity work funtion for product
  const handleIncrement = (e) => {
    e.preventDefault();
    const qtyFlag = handleAddonsQtyByProduct(item.addons, quantityValue, appDispatch, appState);
    const product = appState.orderProdQty.find(order => order.product_id === item.product_id);
    if (product && qtyFlag && (!product.maxQuantity || product.prodTotalQty < product.maxQuantity)) {
      product.prodTotalQty++;
      setQuantityValue(quantityValue + 1);
    }
    // console.log(product);
  };

  const handleDecrement = (e) => {
    e.preventDefault();

    appState.orderProdQty.forEach((order) => {
      if (order.product_id == item.product_id) {
        if (quantityValue <= 1) {
          return;
        }
        order.prodTotalQty = order.prodTotalQty - 1;
        setQuantityValue(quantityValue - 1);
      }
    });

  };
  useEffect(() => {
    setQuantityValue(item.quantity);
    setAddonQuantity(item.addons.quantity);
    sumAddons();
    handleAddonsLimit();
    // console.log(appState.cart);
    // console.log(appState.orderProdQty);
  }, [appState.cart]);

  const addToCart = async () => {
    let newCart = [...appState.cart];
    const itmIndx = newCart.findIndex((el) => el.id === item.id);

    let prodTotalQty = 0;
    // await  appState.forEach(ele => {
    //       if(ele.product_id === item.id){
    //         prodTotalQty  = prodTotalQty + ele.quantity

    //       }
    //   });

    //  await  appState.forEach(ele => {
    //     if(ele.product_id === item.id){
    //       if(prodTotalQty > 0)
    //         ele.maxQuantity  = ele.maxQuantity - prodTotalQty
    //     }
    // });

    newCart[itmIndx].quantity = quantityValue;
    // newCart[itmIndx].price = parseFloat(item.price_after_discount) * newCart[itmIndx].quantity;
    newCart[itmIndx].sum_addons = addonQuantity;
    appDispatch({ type: "updateCart", cart: newCart });
  };

  useEffect(() => {
    addToCart();
  }, [quantityValue, addonQuantity, addonsCount]);

  const handleItemRemove = (item) => {
    let newCart = [...appState.cart];
    let addonCart = [...appState.addonCart];
    appState.orderProdQty.forEach((order) => {
      if (order.product_id == item.product_id) {
        order.prodTotalQty = order.prodTotalQty - item.quantity;
      }
    });

    const itemIndx = newCart.findIndex((el) => el.id === item.id);
    // console.log(newCart[itemIndx]);
    let total = appState.subTotal - newCart[itemIndx].price;
    total = roundFixed(
      Number(total),
      appState.storeInfo.roundLevel,
      appState.storeInfo.roundMethod
    );

    appDispatch({
      type: "updateSubTotal",
      subTotal: total,
    });
    newCart.splice(itemIndx, 1);
    appDispatch({ type: "updateCart", cart: newCart });
    if (appState.campaignState.length > 0) {
      let flag = true;
      newCart.forEach((cart) => {
        if (flag) {
          if (appState.campaignState[0].assigner_id == cart.category_id) {
            flag = false;
          }
          // console.log(cart)
        }
      });
      if (flag) {
        appDispatch({
          type: "setCampaignState",
          campaignState: [],
        });
      }
      // console.log(appState.campaignState)
    }

    // Remove Multiple Items From An Array
    let indexes = [];
    for (let i = 0; i < appState.addonCart.length; i++) {
      if (appState.addonCart[i].product_id === item.product_id) {
        indexes.push(i);
      }
    }
    let indexSet = new Set(indexes);
    let removed = addonCart.filter(
      (filteredItem, index) => !indexSet.has(index)
    );
    appDispatch({ type: "updateAddons", addonCart: removed });
    // Remove Multiple Items From An Array
  };

  const handleAddonsRemove = (items) => {
    let newCart = [...appState.cart];
    const itemIndx = newCart.findIndex(
      (el) => el.product_id === items.product_id
    );
    newCart[itemIndx].addons.splice(
      newCart[itemIndx].addons.findIndex(
        (a) => a.product_addon_id === items.product_addon_id
      ),
      1
    );
    appDispatch({ type: "updateCart", cart: newCart });
  };
  return (
    <>
      <div id="cartItemMainl1" className={`${appState.langValue == "ar" ? "arabicFamilyParent" : " "
        }`} >
        <div className="card-main px-md-3 pt-3 pb-2  w-100">
          <div className="d-flex">
            <div className="d-flex w-70">
              <Link to={`/${item.redirect_url}`} className="prod_link">
                <div className="imgDiv">
                  {/* <img
                    className="img-fluid"
                    src={item.image ? `${item.image}` : `${noImg}`}
                    alt=""
                    width="100%"
                    height="100%"
                  /> */}
                  <Avatar variant="square"
                    src={item.image ? `${item.image}` : `${noImg}`}
                  ></Avatar>
                </div>
              </Link>
              <div className="px-3 d-flex flex-column">
                <Link to={`/${item.redirect_url}`} className="prod_link">
                  <h3 className={`${appState.langValue == "ar" ? "text-right" : "text-left familyPoppins600 familyManrope40"
                    } text-capitalize`}
                  >
                    {get_lang(item.prod_title, appState.langValue)}{" "}

                  </h3>
                </Link>
                <h4 className={`${appState.langValue == "ar" ? "text-right" : "text-left familyPoppins400 familyManrope40"
                  } `}
                >
                  {" "}
                  {parseFloat(item.price * item.quantity).toFixed(3)}{" "}
                  <span className="px-1">{get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</span>{" "}
                </h4>
                <p className={`${appState.langValue == "ar" ? "text-right" : "text-left familyPoppins400 familyManrope40"
                  } `}>  {item.note && (
                    <span dangerouslySetInnerHTML={{ __html: item.note.replace(/\n/g, '<br>') }} />
                  )} </p>
                <div
                  onClick={() => handleItemRemove(item)}
                  className={`${appState.langValue == "ar" ? "text-right" : "text-left"
                    } d-flex cursorPointer align-items-center`}

                >
                  <Button className="deletebtn familyPoppins500 my-0 py-0" aria-label="delete" >
                    {/* <DeleteIcon className={`${appState.langValue === "ar" ? "pl-2" : "pr-2"
                      }`} /> */}
                    <img src={trashNew} className={`  ${appState.langValue === "ar" ? "pl-2" : "pr-2"
                      }`} alt="delete" />
                    {t("cart.Delete")}
                  </Button>


                </div>
              </div>
            </div>
            <div
              className={` d-flex  IncDecBtnMain pt-2 w-30 ${appState.langValue == "ar" ? "flex-row-reverse justify-content-start" : "justify-content-end"
                } `}
            >
              {quantityValue > 1 ? (
                <Button
                  onClick={handleDecrement}
                  className="incrementBtn"
                  variant="contained"
                >
                  <RemoveRoundedIcon className="px-1 textWhite" />
                </Button>
              ) : (
                <Button disabled className="decrementBtn" variant="contained">
                  <RemoveRoundedIcon className="px-1 textWhite" />
                </Button>
              )}

              <div className="IncDecValue d-flex justify-content-center align-items-center">
                <p className="px-2 mb-0  font-weight-900">{quantityValue}</p>
              </div>

              <Button
                onClick={handleIncrement}
                className={quantityValue == item.maxQuantity ? `btnDisabled` : `incrementBtn`}
                variant="contained"
                disabled={quantityValue == item.maxQuantity}

              >
                <AddIcon className="px-1 textWhite" />
              </Button>
            </div>
          </div>

          <div  className={` ${appState.langValue === "ar" ? "addonsWrapAr" : "addonsWrapEn"}`}>

            {!item.addons.length ? "" :
              <div className="d-flex ">
                <div>
                  <h3 className={`px-3 mt-2 ${appState.langValue === "ar" ? "text-right" : "text-left familyPoppins600 familyManrope40"}  text-capitalize`}>
                    {t("single-product.Extras")}:
                  </h3>
                </div>

              </div>

            }
            {item.addons
              ? item.addons.map((items, ind) => {
              
                return (
                  <div className="addonsMainDiv d-flex  pb-2" key={ind}>
                    <div className="d-flex">
                      <div className="px-3 d-flex flex-column">
                        <span className="d-flex align-items-center flex-wrap">
                          <h3 className={`addonsh3  ${appState.langValue === "ar" ? "text-right" : "text-left familyPoppins600 familyManrope40"}  text-capitalize`}
                          >
                            {get_lang(items.addon.name, appState.langValue)}

                          </h3>
                          <FiberManualRecordIcon className={` mx-2 ${appState.langValue === "ar" ? "" : ""}`} />
                          <h3 className={`addonsh3   ${appState.langValue === "ar" ? "text-right" : "text-left familyPoppins400 familyManrope40"} `}

                          >

                           
                            {" "}
                            {parseFloat(items.price * items.quantity).toFixed(3)}{" "}
                            <span className="">{get_lang(appState.storeInfo.storeCurrency, appState.langValue)}</span>{" "}


                         
                          </h3>
                          <FiberManualRecordIcon className={` mx-2 ${appState.langValue === "ar" ? "" : ""}`} />
                          <h4 className={` addonsh4  ${appState.langValue === "ar" ? "text-right " : "text-left familyPoppins600 familyManrope40"}  text-capitalize`}
                          >
                           Qty {items.quantity} 

                          </h4>
                        </span>


                        {items.required == false && <div
                          onClick={() => handleAddonsRemove(items)}
                          className="d-flex cursorPointer align-items-center "
                        >
                          <Button className="deletebtn familyPoppins500 my-0 py-0" aria-label="delete" >
                            {/* <DeleteIcon className={`${appState.langValue === "ar" ? "pl-2" : "pr-2"
                              }`} /> */}
                            <img src={trashNew} className={` mt-2 ${appState.langValue === "ar" ? "pl-2" : "pr-2"
                              }`} alt="delete" />
                            {t("cart.Delete")}
                          </Button>
                        </div>}
                      </div>
                      {items.required == false &&
                        <div className={` d-flex   IncDecBtnMain pt-2 w-30 ${appState.langValue == "ar" ? "flex-row-reverse justify-content-start" : "justify-content-end"
                          } `}
                        >
                          {items.quantity > 1 ? (
                            <Button
                              onClick={() => {
                                handleDecrementAddons(items.product_addon_id, items.category_addon_id);
                              }}
                              className="incrementBtn"
                              variant="contained"
                            >
                              <RemoveRoundedIcon className="px-1 textWhite" />
                            </Button>
                          ) : (
                            <Button disabled className="decrementBtn" variant="contained">
                              <RemoveRoundedIcon className="px-1 textWhite" />
                            </Button>
                          )}

                          <div className="IncDecValue d-flex justify-content-center align-items-center">
                            <p className="px-2 mb-0  font-weight-900">{items.quantity}</p>
                          </div>

                          <Button
                            onClick={() => {
                              handleIncrementAddons(items.product_addon_id, items.category_addon_id);
                            }}
                            disabled={items.quantity === items.maxQuantity}
                            className={items.quantity == items.maxQuantity ? `btnDisabled` : `incrementBtn`}
                            variant="contained"
                          >
                            <AddIcon className="px-1 textWhite" />
                          </Button>
                        </div>}
                    </div>





                  </div>
                );
              })
              : ""}

          </div>


        </div>
      </div>
    </>
  );
};

export default withTranslation()(CartItem);
