// hooks
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";


export const useSelectLoyaltyCard = (loyaltyCardId, setDiscountType, setLoyaltyCardId, setShowLoyaltyCardDetails) => {

	//States:
	const appState = useSelector((state) => state);
	const appDispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [value, setValue] = useState(null);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [LoyaltyCards, setLoyaltyCards] = useState(null)

	///// handleFunctions:
	const handleSelect = (id) => {
		if (selectedItemId == id) {
			setSelectedItemId(null);
			setDiscountType("")
			setLoyaltyCardId(null)

		} else {
			setSelectedItemId(id);
			setLoyaltyCardId(id)
			setDiscountType("punch-card")
		}
	};
	///// useffects
	useEffect(() => {
		if (appState.loyaltyObj.length > 0) {
			setIsLoading(false);
			let arr = []
			appState.loyaltyObj.forEach(item => {
				if(item.punchable && item.show){
					arr.push(item)
				}
			});
			setLoyaltyCards(arr)

		}
	}, []);


	useEffect(() => {
		
		if (loyaltyCardId) {
			setDiscountType("punch-card")
		} else {
			setDiscountType("")
			setSelectedItemId(null);
			setLoyaltyCardId(null)
		}
	}, [loyaltyCardId])




	return { appState, appDispatch, isLoading, value, LoyaltyCards, handleSelect };
};