import axios from 'axios'
import { setCookie } from '../layout-one/js/Functions/cookieFunction';


export const socialLoginApi = async (userData, appDispatch, loginHandleclose, loading, setLoading, getPhoneNo) => {
  const res = userData[0]
  if (res.userEmail) {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/social/login`,
     {
      username: res.userEmail,
      title: res.Title,
      value: res.titleValue,
      source: res.loginSource
    },{
      headers: {
        ContentType: 'application/json',
      }
    })

    if (result.data.data != null) {
      if (result.data.success === true) {
        setLoading(false);
        setCookie("tokenAuth", result.data.data.token);
        setCookie("Profile", JSON.stringify(result.data.data));
        appDispatch({
          type: "SET_USERINFO",
          userInfo: result.data.data,
        })

        localStorage.setItem(`userInfo`, JSON.stringify(result.data.data));
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "success",
          message: result.data.message,
        });
        let token = await result.data.data.token
        appDispatch({
          type: "SET_LOGIN",
          loggedIn: true,
          loginToken: token
        });
        // this loginHandleClose funtion will close main login modal
        loginHandleclose();
        // console.log(result.data.data);
      } else if (result.data.success === false) {
        setLoading(false);
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `${result.data.message} : ${result.data.data}`
        });
        appDispatch({ type: "logout" });
      }
    }
    else
      if (result.data.data == null) {
        setLoading(false);
        getPhoneNo()
        return result.data.data;
      }
  }
}

// global function for social Registration it will work when user not found in api response
export const socialRegisterApi = async (userData, appDispatch, loginHandleclose, loading, setLoading, phone) => {
  setLoading(true)
  const resp = userData[0]
  const reg = await axios
    .post(`${process.env.REACT_APP_API_URL}/customer/register`,
     {
      first_name: resp.firstName,
      last_name: resp.lastName,
      email: resp.userEmail,
      social: {
        title: resp.Title,
        value: resp.titleValue,
        source: resp.loginSource,
      },
      gender: 'male',
      country_code: "965",
      number: resp.phoneNo
    },{
      headers: {
        ContentType: 'application/json',
      }
    })
  if (reg.data.data != null) {
    if (reg.data.success === true) {
      setLoading(false);
      setCookie("tokenAuth", reg.data.data.token);
      setCookie("Profile", JSON.stringify(reg.data.data));
      appDispatch({
        type: "SET_USERINFO",
        userInfo: reg.data.data,
      })
      console.log(reg.data.data)
      localStorage.setItem(`userInfo`, JSON.stringify(reg.data.data));
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "success",
        message: reg.data.message,
      });
      let token = await reg.data.data.token
      appDispatch({
        type: "SET_LOGIN",
        loggedIn: true,
        loginToken: token
      });

      loginHandleclose();

    } else if (reg.data.success === false) {
      setLoading(false);
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: `${reg.data.message} : ${reg.data.data}`,
      });
      appDispatch({ type: "logout" });
    }
  }
}
