/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./FeaturedProductPopup.scss";
// material ui
import { PieChart } from "react-minimal-pie-chart";
// handleTotalPersonf
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import {
  IconButton,
  Badge,
  Button,
  Checkbox,
  TextareaAutosize,
  Radio,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@material-ui/icons/Add";
import Icon from "@material-ui/core/Icon";
import chef from "../../assets/images/chef-choice.svg";
import mostSelling from "../../assets/images/most-selling.svg";
import trending from "../../assets/images/trending.svg";
import Megaphone from "../../assets/images/Megaphone.svg";
import DairyFree from "../../assets/images/dairy-free.svg";
import gluten from "../../assets/images/Gluten-Free.svg";
import vegan from "../../assets/images/vegan.svg";
import vegetarian from "../../assets/images/vegetarian.svg";
import mild from "../../assets/images/mild.svg";
import spicy from "../../assets/images/spicy.svg";
import nutFree from "../../assets/images/nut.svg";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { get_lang } from "../../../../global-functions/apiGlobal";
import { Skeleton } from "@material-ui/lab";
import { useFeaturedProductAddToCart } from "../../hooks/useFeaturedProductAddToCart";
import { handleAllAddonsWithOld } from "../../../../global-functions/productFunction";
import { useFeaturedProductAddonsQuantity } from "../../hooks/useFeaturedProductAddonsQuantity";
import { useGetFeaturedProduct } from "../../hooks/useGetFeaturedProduct";
import { useEditFeaturedProductCart } from "../../hooks/useEditFeaturedProductCart";
import { handleStockQuantity } from "../../../../global-functions/addToCartGlobal";
import noImg from "../../assets/images/no-image.svg";



function FeaturedProductPopup(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const { product, placedBy } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [quantityValue, setQuantityValue] = useState(1)
  const [addNote, setAddNote] = useState("");
  const [allAddonCategories, setAllAddonCategories] = useState([]);
  const [addons, setAddons] = useState([]);
  const [addOnsTotal, setAddOnsTotal] = useState(0);
  const [addonLimit, setAddonLimit] = useState([]);
  const [errorStatus, setErrorStatus] = useState(false);
  const [checkCart, setCheckCart] = useState(false);
  const { featuredProduct, stock } = useGetFeaturedProduct(setIsLoading, addons, quantityValue, setQuantityValue, product, setCheckCart, checkCart, placedBy, setAddNote);
  const { handleIncrement, handleDecrement, handleEditCart } = useEditFeaturedProductCart(product, quantityValue, setQuantityValue, featuredProduct, addons, setCheckCart, placedBy, addNote);
  const { handleCheck, handleRadio, handleIncrementAddons, handleDecrementAddons } = useFeaturedProductAddonsQuantity(addonLimit, addons, quantityValue, setAddons, setAddOnsTotal, setAddonLimit);
  const { handleAddToCart } = useFeaturedProductAddToCart(featuredProduct, product, quantityValue, setErrorStatus, placedBy, addNote);
  let prodTotalQty = 0;


  const handleChangeNote = (e) => {
    setAddNote(e.target.value);
  };

  const setAllAddonsWithOld = () => {
    let filterCart = appState.cart.find( ////// dekh rraha p2
      (item, i) => item?.parent_product_id === product?.id && item.product_id == featuredProduct?.id && item.placed_by == placedBy && item.pGroupId == appState.productGroupId
    );

    let res = handleAllAddonsWithOld(
      allAddonCategories,
      appState,
      featuredProduct,
      filterCart?.id
    );
    setAddOnsTotal(res.total);
    setAddonLimit(res.arryAddonLimit);
    setAddons(res.addonsInfo);
  };

  useEffect(() => {
    if (allAddonCategories != null && allAddonCategories.length != 0) {
      setAllAddonsWithOld();
    }
  }, [allAddonCategories, featuredProduct]);


  useEffect(() => {

    if (appState.featuredProducts != []) {
      // fetchFeaturedProduct()
      console.log(appState.featuredProducts,'AppState');
      setAllAddonCategories(appState.featuredProducts[0]?.product_addon_categories);
    }

  }, [appState.featuredProducts, quantityValue])





  return (
    <>
      <div
        id="mySidenav"
        className={`FeaturedProductPopupMain col-12 ${appState.langValue === "ar" ? "FeaturedProductPopupMainArabic" : " "
          }`}
      >
        {/* <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> */}
        <div className="container-fluid content px-4 pb-3">
          <div className="sticky-top bg-white">
            <div className="d-flex justify-content-end">
              <IconButton onClick={() => appDispatch({
                type: "SET_FEATURED_PRODUCTS",
                featuredProductPopupMode: false,
                featuredProducts: []
              })} className="classForSvgColor mt-2" >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#22282F" />
                </svg>

              </IconButton>
            </div>
            <hr className="my-1" />
          </div>



          {isLoading ? <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              <Skeleton height={200} />
            </div>
          </div> : <>
            <div className="row mt-2 mx-0 d-flex align-items-center justify-content-center">
              <Avatar
                variant="square"
                style={{alignSelf: 'center'}}
                className={`featuredProductImage  ${appState.langValue === "ar"
                  ? ""
                  : ""
                  } `}
                src={
                  featuredProduct?.image
                    ? `${featuredProduct?.image
                    }`
                    : `${noImg}`
                }
              ></Avatar>




            </div>
              <div className="d-flex flex-column my-2  w-100">
                {featuredProduct.name != null ? (
                  <div className="p-0 px-2  d-flex flex-column justify-content-end ">
                    <h5 className="d-flex text-capitalize">
                      {featuredProduct.name != null
                        ? get_lang(featuredProduct.name, appState.langValue)
                        : "Name is not available"}
                    </h5>

                    {featuredProduct.description != null &&
                      get_lang(featuredProduct.description, appState.langValue) !=
                      null
                      ? get_lang(featuredProduct.description, appState.langValue) !=
                        "N/A"
                        ? get_lang(featuredProduct.description, appState.langValue)
                          .split("<br>")
                          .map((item, i) => (
                            <p
                              key={i}
                              className={`d-flex m-0 ${appState.langValue === "ar"
                                ? "text-right"
                                : "text-left"
                                }`}
                            >
                              {item}
                            </p>
                          ))
                        : ""
                      : ""}
                      
                  </div>
                ) : (
                  <div className="row m-0 mb-4 mt-3 d-flex flex-row">
                    <div className="col-6  ">
                      <Skeleton height={50} />
                    </div>
                    <div className="col-10 ">
                      <Skeleton height={50} />
                    </div>
                    <div className="col-3 ">
                      <Skeleton height={50} />
                    </div>
                  </div>
                )}

              </div>
            <div className="row m-0 mt-3">
              <div className="prod-single col-12 p-0">
                {/* <div className="single-prod-qty">
                                      <span id="btn">-</span>
                                      <span style={{ padding: "30px" }}>1</span>
                                      <span id="btn">+</span>
                                  </div> */}
                {!appState.payTableMode &&
                  appState.qrBranch &&
                  !isLoading ? (
                  <div
                    className={
                      appState.langValue == "ar"
                        ? "d-flex  justify-content-between"
                        : "d-flex justify-content-between"
                    }
                  >
                    {featuredProduct?.type == "Simple" && (
                      <React.Fragment>
                        {" "}
                        {featuredProduct.quantity <= 0 &&
                          featuredProduct.quantity != null ? (
                          <p
                            style={{ color: "red", fontSize: "14px" }}
                            className=" d-flex m-0"
                          >
                            {t("single-product.Out-of-stock")}
                          </p>
                        ) : (
                          <div
                            className={
                              appState.langValue === "ar"
                                ? "d-flex flex-row-reverse"
                                : ""
                            }
                          >
                            <span
                              className={`${appState.langValue === "ar"
                                ? "d-flex flex-column justify-content-center"
                                : ""
                                }`}
                            >
                              {quantityValue > 1 ? (
                                <IconButton
                                  id="IncDecItemQuan"
                                  size="small"
                                  className="qtyBtn"
                                  onClick={handleDecrement}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              ) : (
                                <IconButton
                                  id="IncDecItemQuan"
                                  size="small"
                                  disabled
                                >
                                  <RemoveIcon />
                                </IconButton>
                              )}
                            </span>

                            <span
                              style={{
                                fontSize: "1rem",
                                padding: "15px",
                              }}
                            >
                              {quantityValue}
                            </span>

                            <span
                              className={`${appState.langValue === "ar"
                                ? "d-flex flex-column justify-content-center"
                                : ""
                                }`}
                            ><IconButton
                              id="IncDecItemQuan"
                              size="small"
                              onClick={() => (quantityValue == featuredProduct.maxQuantity) || (stock == 0) ? "" : handleIncrement(featuredProduct)}
                              disabled={(quantityValue == featuredProduct.maxQuantity) || (stock == 0)}
                              className={`${(quantityValue == featuredProduct.maxQuantity) || (stock == 0) ? "" : "qtyBtn"}`}
                            >
                                <AddIcon />
                              </IconButton>
                            </span>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                    <h4>
                      <strong>
                        {
                          <React.Fragment>
                            {(
                              (featuredProduct.price - featuredProduct.discount) *
                              quantityValue
                            ).toFixed(3)}
                            <span className="thinBold ">
                              &nbsp;
                              {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                            </span>
                          </React.Fragment>
                        }
                      </strong>{" "}
                    </h4>
                  </div>
                ) : (
                  <span></span>
                )}


              </div>
            </div>

            {addonLimit.length > 0 &&
              addonLimit.map((main, i) => {
                return (
                  <>
                    {main.addons_length > 0 ? (
                      main.addons_length > 0 &&
                        main.min == 1 &&
                        main.max == 1 ? (
                        <span id={`${main.id}`}>

                          <section key={i} id="single-pro" className="row m-0 px-0">
                            <div
                              // ref={myRef}
                              id={`single-pro${main.id}`}
                              className="col-12 p-0"
                            >
                              <div className="row m-0">
                                <div className="col-12 p-0">
                                  <h5
                                    className="d-flex m-0"
                                    style={
                                      main.min != null &&
                                        main.addons_length > 0 &&
                                        main.min > main.addons_qty &&
                                        errorStatus
                                        ? { color: "red" }
                                        : { color: "black" }
                                    }
                                  >
                                    {get_lang(main.title, appState.langValue)}
                                  </h5>
                                  {!appState.payTableMode && appState.qrBranch
                                    ? main.min != null && (
                                      <p
                                        className={`d-flex text-muted choosecontent p-0 m-0 mb-2 `}
                                      >
                                        {main.min == 1 &&
                                          main.max == 1 &&
                                          t(
                                            "single-product.selection-is-required"
                                          )}
                                        {main.min == 1 &&
                                          main.max == null &&
                                          t("single-product.at-least-select ") +
                                          main.min}
                                        {/* single-product.Out-of-stock */}
                                        {/* t("checkOut.Payment-Summary") */}
                                        {/* Min {main.min} to{" "}
                                    {` ${main.max != null
                                      ? `Max ${main.max}`
                                      : `Unlimited`
                                      } `} */}
                                      </p>
                                    )
                                    : ""}

                                  {addons.length > 0 &&
                                    addons.map((item, ind) => {
                                      return (
                                        item.category_addon_id == main.id && (
                                          <div
                                            key={ind}
                                            className="row m-0 p-0 mb-3 chooseOne-content familyManrope600 text-capitalize"
                                          >
                                            <span className=" col-md-12  m-0 p-0 d-flex align-items-center justify-content-between">
                                              <span
                                                className={` ${main.max == main.addons_qty
                                                  ? `pointer-events-none`
                                                  : `cursor-pointer`
                                                  }`}
                                                onClick={
                                                  item.maxQuantity > 0
                                                    ? handleCheck(
                                                      item.product_addon_id,
                                                      main.id
                                                    )
                                                    : undefined
                                                }
                                                id="prodAddonCheck"
                                              >
                                                {item.addon.name != null
                                                  ? get_lang(
                                                    item.addon.name,
                                                    appState.langValue
                                                  )
                                                  : "Name is not available"}
                                                {/* {dumyText} */}
                                              </span>

                                              <span className="d-flex align-items-center ">
                                                <span
                                                  className={`d-flex px-1 ${main.max == main.addons_qty
                                                    ? `pointer-events-none`
                                                    : `cursor-pointer`
                                                    }`}
                                                  onClick={
                                                    item.maxQuantity > 0
                                                      ? handleCheck(
                                                        item.product_addon_id,
                                                        main.id
                                                      )
                                                      : undefined
                                                  }
                                                  id="prodAddonCheck"
                                                >
                                                  {item.price > 0 && (
                                                    <>
                                                      {item.price > 0 &&
                                                        item.quantity !== 0
                                                        ? parseFloat(
                                                          item.price *
                                                          item.quantity
                                                        ).toFixed(3)
                                                        : parseFloat(
                                                          item.price
                                                        ).toFixed(3)}
                                                      &nbsp;
                                                      {get_lang(
                                                        appState.storeInfo
                                                          .storeCurrency,
                                                        appState.langValue
                                                      )}
                                                    </>
                                                  )}
                                                </span>

                                                {!appState.payTableMode &&
                                                  appState.qrBranch &&
                                                  (item.maxQuantity > 0 ||
                                                    item.maxQuantity == null ? (
                                                    <div>
                                                      <div className=" d-flex  justify-content-end  ">
                                                        <>
                                                          <Checkbox
                                                            // value={item.name}
                                                            id="prodAddonCheck"
                                                            onClick={handleRadio(
                                                              item.product_addon_id,
                                                              main.id,
                                                              ind
                                                            )}
                                                            checked={
                                                              item.check_status
                                                                ? true
                                                                : false
                                                            }
                                                            // id={item.id}
                                                            color="default"
                                                            inputProps={{
                                                              "aria-label":
                                                                "checkbox with default color",
                                                              type: "radio",
                                                            }}
                                                            icon={
                                                              <CircleUnchecked />
                                                            }
                                                            checkedIcon={
                                                              <CircleCheckedFilled />
                                                            }
                                                            name={`string`}
                                                          />
                                                        </>
                                                      </div>
                                                    </div> // ""
                                                  ) : (
                                                    <div
                                                      className={`d-flex ${appState.langValue ===
                                                        "ar"
                                                        ? "justify-content-start flex-row-reverse"
                                                        : "justify-content-end"
                                                        }`}
                                                    >
                                                      <p
                                                        style={{
                                                          color: "red",
                                                          fontSize: "14px",
                                                        }}
                                                        className=" d-flex py-2 flex-end m-0"
                                                      >
                                                        {t(
                                                          "single-product.Out-of-stock"
                                                        )}
                                                      </p>
                                                    </div>
                                                  ))}
                                              </span>
                                            </span>
                                          </div>
                                        )
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </section>
                        </span>
                      ) : (
                        <span id={`${main.id}`}>
                          <section key={i} id="single-pro" className="row m-0 px-0 ">
                            <div

                              // ref={myRef}
                              id={`single-pro${main.id}`}
                              className="col-12 p-0"
                            >
                              <div
                                id={`single-pro${main.id}`}
                                className="col-12 p-0"
                              >
                                <div className="row m-0">
                                  <div className="col-12 p-0">
                                    <h5
                                      className=" m-0"
                                      style={
                                        main.min != null &&
                                          main.addons_length > 0 &&
                                          main.min > main.addons_qty &&
                                          errorStatus
                                          ? { color: "red" }
                                          : { color: "black" }
                                      }
                                    >
                                      {get_lang(main.title, appState.langValue)}{" "}
                                      {main.min != null && (
                                        <span className="text-danger">*</span>
                                      )}
                                    </h5>
                                    {!appState.payTableMode && appState.qrBranch
                                      ? main.min != null && (
                                        <p
                                          className={`d-flex text-muted choosecontent p-0 m-0 mb-2 `}
                                        >
                                          Select at least {main.min}{" "}
                                          {` ${main.max != null
                                            ? `up to ${main.max}`
                                            : ``
                                            } `}
                                        </p>
                                      )
                                      : ""}

                                    {addons.length > 0 &&
                                      addons.map((item, ind) => {
                                        return (
                                          item.category_addon_id == main.id && (
                                            <div
                                              key={ind}
                                              className="row m-0 p-0 mb-3 chooseOne-content familyManrope600 text-capitalize"
                                            >
                                              <span className=" col-md-12  m-0 p-0 d-flex align-items-center justify-content-between">
                                                <span
                                                  className={` ${main.max == main.addons_qty
                                                    ? `pointer-events-none`
                                                    : `cursor-pointer`
                                                    }`}
                                                  onClick={
                                                    item.maxQuantity > 0
                                                      ? handleCheck(
                                                        item.product_addon_id,
                                                        main.id
                                                      )
                                                      : undefined
                                                  }
                                                  id="prodAddonCheck"
                                                >
                                                  {item.addon.name != null
                                                    ? get_lang(
                                                      item.addon.name,
                                                      appState.langValue
                                                    )
                                                    : "Name is not available"}
                                                  {/* {dumyText} */}
                                                </span>

                                                <span className="d-flex align-items-center ">
                                                  <span
                                                    className={`d-flex px-1 ${main.max == main.addons_qty
                                                      ? `pointer-events-none`
                                                      : `cursor-pointer`
                                                      }`}
                                                    onClick={
                                                      item.maxQuantity > 0
                                                        ? handleCheck(
                                                          item.product_addon_id,
                                                          main.id
                                                        )
                                                        : undefined
                                                    }
                                                    id="prodAddonCheck"
                                                  >
                                                    {item.price > 0 && (
                                                      <>
                                                        {item.price > 0 &&
                                                          item.quantity !== 0
                                                          ? parseFloat(
                                                            item.price *
                                                            item.quantity
                                                          ).toFixed(3)
                                                          : parseFloat(
                                                            item.price
                                                          ).toFixed(3)}
                                                        &nbsp;
                                                        {get_lang(
                                                          appState.storeInfo
                                                            .storeCurrency,
                                                          appState.langValue
                                                        )}
                                                      </>
                                                    )}
                                                  </span>

                                                  {!appState.payTableMode &&
                                                    appState.qrBranch &&
                                                    (item.maxQuantity > 0 ||
                                                      item.maxQuantity == null ? (
                                                      item.check_status == true &&
                                                        item.unique == 0 ? (
                                                        <div
                                                          className={`d-flex ${appState.langValue ===
                                                            "ar"
                                                            ? "justify-content-start flex-row-reverse"
                                                            : "justify-content-end"
                                                            }`}
                                                        >
                                                          <IconButton
                                                            size="small"
                                                            className="qtyBtn"
                                                            onClick={() => {
                                                              handleDecrementAddons(
                                                                item.product_addon_id,
                                                                main.id
                                                              );
                                                            }}
                                                            id="prodAddonIncDec"
                                                          >
                                                            <RemoveIcon />
                                                          </IconButton>

                                                          <span
                                                            style={{
                                                              fontSize: "1rem",
                                                              padding: "10px 5px",
                                                            }}
                                                          >
                                                            {item.quantity}
                                                          </span>

                                                          {main.max ==
                                                            main.addons_qty ? (
                                                            <IconButton
                                                              size="small"
                                                              className="qtyBtn"
                                                              onClick={() => {
                                                                handleIncrementAddons(
                                                                  item.product_addon_id,
                                                                  main.id
                                                                );
                                                              }}
                                                              id="prodAddonIncDec"
                                                              disabled={
                                                                item.quantity ===
                                                                item.maxQuantity ||
                                                                main.max ==
                                                                main.addons_qty
                                                              }
                                                            >
                                                              <AddIcon />
                                                            </IconButton>
                                                          ) : (
                                                            <IconButton
                                                              size="small"
                                                              className="qtyBtn"
                                                              id="prodAddonIncDec"
                                                              onClick={() => {
                                                                handleIncrementAddons(
                                                                  item.product_addon_id,
                                                                  main.id
                                                                );
                                                              }}
                                                              disabled={
                                                                item.quantity ===
                                                                item.maxQuantity
                                                              }
                                                            >
                                                              <AddIcon />
                                                            </IconButton>
                                                          )}
                                                        </div>
                                                      ) : (
                                                        <div className=" d-flex  justify-content-end  ">
                                                          <Checkbox
                                                            onChange={handleCheck(
                                                              item.product_addon_id,
                                                              main.id
                                                            )}
                                                            disabled={
                                                              main.unique == 0
                                                                ? main.max ==
                                                                main.addons_qty
                                                                : main.max ==
                                                                main.addons_qty &&
                                                                item.check_status ==
                                                                false
                                                            }
                                                            checked={
                                                              item.check_status
                                                            }
                                                            color="primary"
                                                            inputProps={{
                                                              "aria-label":
                                                                "secondary checkbox",
                                                            }}
                                                            className={` ${main.max ==
                                                              main.addons_qty &&
                                                              item.check_status ==
                                                              false
                                                              ? "invisible"
                                                              : "visible"
                                                              }`}
                                                          />
                                                        </div>
                                                      )
                                                    ) : (
                                                      // ""
                                                      <div
                                                        className={`d-flex ${appState.langValue ===
                                                          "ar"
                                                          ? "justify-content-start flex-row-reverse"
                                                          : "justify-content-end"
                                                          }`}
                                                      >
                                                        <p
                                                          style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                          }}
                                                          className=" d-flex py-2 flex-end m-0"
                                                        >
                                                          {t(
                                                            "single-product.Out-of-stock"
                                                          )}
                                                        </p>
                                                      </div>
                                                    ))}
                                                </span>
                                              </span>
                                            </div>
                                          )
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </span>
                      )
                    ) : (
                      ""
                    )}
                  </>
                );
              })}

            {/* <div className="d-flex justify-content-between">
              <h1
            className={` mt-2 ${appState.langValue === "ar"
              ? "text-right familyGraphikArabic800 "
              : " familyPoppins600"
              }`}
          >
            {" "}
            {t("checkOut.Total")}
          </h1>

          <h1
            className={` mt-2 ${appState.langValue === "ar"
              ? "text-right familyGraphikArabic800 "
              : " familyPoppins600"
              }`}
          >
            {" "}
            {quantityValue > 0 &&
                              (
                                (product.price - product.discount) *
                                  quantityValue +
                                addOnsTotal * quantityValue
                              ).toFixed(3)}
          </h1>
              </div> */}


            {!appState.payTableMode && appState.qrBranch && (
              <section id="addNoteSection" className="row  m-0">
                <div className="col-12 px-0 noteCoontent mb-5">
                  <h4 className="d-flex">{t("single-product.Add-a-Note")} </h4>
                  <TextareaAutosize
                    className="textArea"
                    aria-label="minimum height"
                    width="100%"
                    minRows={5}
                    placeholder={t("single-product.Add-Your-Note-Here")}
                    value={addNote.replace(/<br>/g, "\n")}
                    onChange={handleChangeNote}
                  />
                </div>
              </section>
            )}


           {(!appState.payTableMode && appState.isAddToCartEnable) && <div className="bottomBtn">
              <Button
                onClick={() => !checkCart ? handleAddToCart(featuredProduct, addons, addonLimit, addOnsTotal) : handleEditCart(featuredProduct, addons, addonLimit, addOnsTotal)}
                className={` py-3 px-4 confirmBtn mt-3 justify-content-center ${appState.langValue === "ar" ? " familyGraphikArabic600" : ""
                  }`}
              >
                {" "}
                {!checkCart ? <span>{t("single-product.Add-to-cart")}</span> : <span>save changes</span>}  {" "}
              </Button>
            </div>}

          </>}


        </div>
      </div>
    </>
  );
}

export default withTranslation()(FeaturedProductPopup);
