/* eslint-disable no-useless-escape */
/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
import { Button, IconButton, Badge } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ReceiptIcon from "@mui/icons-material/Receipt";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { withTranslation } from "react-i18next";

import UserInfo from "./UserInfo";
import noImg from "../assets/images/no-image.svg";
import { makeStyles } from "@material-ui/core/styles";
import kuwaitFlag from "../../../layout-one/js/assets/images/kuiwat flag.png";
// redux
import { useSelector, useDispatch } from "react-redux";
import FilterListIcon from "@mui/icons-material/FilterList";
import { get_lang } from "../../../global-functions/apiGlobal";

const useStyles = makeStyles((theme) => ({
  flagDiv: {
    width: "27px",
    height: "28px",
    backgroundImage: `url(${kuwaitFlag})`,
    backgroundSize: "cover",
  },
}));

const MiscBanner = ({ children, image, urlLink, name }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const appDispatch = useDispatch();
  const appState = useSelector((state) => state);
  const matches = useMediaQuery("(min-width:600px)");
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const handleClickOpen = () => {
    appDispatch({ type: "setLoginModal", loginModal: true });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const scrolling = () => {
      const handleScroll = () => {
        setScroll(window.scrollY > 0);
      };
  
      window.addEventListener("scroll", handleScroll);
  
      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    };
  
    scrolling();
  }, []);
  const arLanguageHandle = (e) => {
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
    //   i18n.changeLanguage('ar')
  };
  const enLanguageHandle = (e) => {
    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  useEffect(() => {
    setFormData(
      JSON.parse(localStorage.getItem("formData")) !== null
        ? JSON.parse(localStorage.getItem("formData"))
        : {}
    );
  }, [appState.formData]);

  return (
    <div className="categoryBanner">
      <div
        className="categoryBannerImg"
        style={{
          background: `url(${
            image ? `${image}` : `${noImg}`
          })no-repeat center center / cover`,
          // center top/100% 100% no-repeat
        }}
      >
       
        <div className={scroll > 0 ? `top_nav_bar_scroll` : `top_nav_bar`}>
          {appState.payTableMode ? (
            <div className="row  m-0 searhIcon" position="static">
              <div className="d-flex align-items-center col-2 px-1">
                <IconButton
                  component={Link}
                  to={urlLink}
                  aria-label="back"
                  className={`backBtn ${
                    appState.langValue === "ar" ? "backBtnAr" : ""
                  }`}
                >
                  {appState.langValue == "ar" ? (
                    <ArrowForwardIosIcon
                      style={{ padding: 0 }}
                      fontSize="inherit"
                    />
                  ) : (
                    <ArrowBackIosIcon fontSize="inherit" />
                  )}
                </IconButton>
              </div>

              <div className="col-6 d-flex justify-content-end">
                {appState.langValue == "ar" ? (
                  <IconButton
                    className="engBtn"
                    id="headerBtn"
                    onClick={(e) => enLanguageHandle(e)}
                  >
                    <span
                      className={`engBtnSpan ${
                        scroll > 0 ? `lang-textScroll p-0 ` : `lang-text p-0`
                      }`}
                    >
                      EN
                    </span>
                  </IconButton>
                ) : (
                  <IconButton
                    className="arabicbutton"
                    id="headerBtn"
                    onClick={(e) => arLanguageHandle(e)}
                  >
                    <span
                      className={`arabicbuttonSpan ${
                        scroll > 0 ? `lang-textScroll p-0 ` : `lang-text p-0`
                      }`}
                    >
                      عربي
                    </span>
                  </IconButton>
                )}

                <IconButton
                  component={Link}
                  to="/search"
                  className="classForSvgColor mt-1"
                  id="headerBtn"
                >
                  {/* <SearchIcon fontSize="inherit" /> */}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 2.25C6.16751 2.25 2.25 6.16751 2.25 11C2.25 15.8325 6.16751 19.75 11 19.75C13.1462 19.75 15.112 18.9773 16.6342 17.6949L19.4697 20.5303C19.7626 20.8232 20.2374 20.8232 20.5303 20.5303C20.8232 20.2374 20.8232 19.7626 20.5303 19.4697L17.6949 16.6342C18.9773 15.112 19.75 13.1462 19.75 11C19.75 6.16751 15.8325 2.25 11 2.25ZM3.75 11C3.75 6.99594 6.99594 3.75 11 3.75C15.0041 3.75 18.25 6.99594 18.25 11C18.25 15.0041 15.0041 18.25 11 18.25C6.99594 18.25 3.75 15.0041 3.75 11Z"
                      fill="#fff"
                    />
                  </svg>
                </IconButton>

                {appState.formData.openBill &&
               appState.isAddToCartEnable &&
                appState.loggedIn &&
                !appState.payTableMode ? (
                  <IconButton component={Link} to="/openOrder" id="headerBtn">
                    <ReceiptIcon fontSize="inherit" />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <div className="row m-0  searhIcon" position="static">
              <div className="d-flex align-items-center col-4 px-1">
                <IconButton
                  component={Link}
                  aria-label="back"
                  to={urlLink}
                  className={`backBtn ${
                    appState.langValue === "ar" ? "backBtnAr" : ""
                  }`}
                >
                  {appState.langValue == "ar" ? (
                    <ArrowForwardIosIcon
                      style={{ padding: 0 }}
                      fontSize="inherit"
                    />
                  ) : (
                    <ArrowBackIosIcon fontSize="inherit" />
                  )}
                </IconButton>
                {appState.langValue == "ar" ? (
                  <IconButton
                    component={Link}
                    to={`${location.pathname}${location.search}`}
                    className="engBtn"
                    id="headerBtn"
                    onClick={(e) => enLanguageHandle(e)}
                  >
                    <span
                      className={`engBtnSpan ${
                        scroll > 0 ? `lang-textScroll p-0 ` : `lang-text p-0`
                      }`}
                    >
                      EN
                    </span>
                  </IconButton>
                ) : (
                  <IconButton
                    component={Link}
                    to={`${location.pathname}${location.search}`}
                    className="arabicbutton"
                    id="headerBtn"
                    onClick={(e) => arLanguageHandle(e)}
                  >
                    <span
                      className={`arabicbuttonSpan ${
                        scroll > 0 ? `lang-textScroll p-0 ` : `lang-text p-0`
                      }`}
                    >
                      عربي
                    </span>
                  </IconButton>
                )}

                <IconButton
                  component={Link}
                  to="/search"
                  className="classForSvgColor"
                  id="headerBtn"
                >
                  {/* <SearchIcon fontSize="inherit" /> */}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 2.25C6.16751 2.25 2.25 6.16751 2.25 11C2.25 15.8325 6.16751 19.75 11 19.75C13.1462 19.75 15.112 18.9773 16.6342 17.6949L19.4697 20.5303C19.7626 20.8232 20.2374 20.8232 20.5303 20.5303C20.8232 20.2374 20.8232 19.7626 20.5303 19.4697L17.6949 16.6342C18.9773 15.112 19.75 13.1462 19.75 11C19.75 6.16751 15.8325 2.25 11 2.25ZM3.75 11C3.75 6.99594 6.99594 3.75 11 3.75C15.0041 3.75 18.25 6.99594 18.25 11C18.25 15.0041 15.0041 18.25 11 18.25C6.99594 18.25 3.75 15.0041 3.75 11Z"
                      fill="#fff"
                    />
                  </svg>
                </IconButton>

                {!appState.payTableMode && appState.isAddToCartEnable && (
                  <IconButton
                    className="classForSvgColor"
                    component={Link}
                    to="/cart"
                    id="headerBtn"
                  >
                    {appState.cart.lenght !== 0 ? (
                      <Badge
                        badgeContent={appState.cart.length}
                        color="primary"
                        overlap="rectangular"
                      >
                        {/* <i className="fa fa-shopping-cart   "></i> */}
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.50007 2.75C1.78476 2.75001 1.88363 2.75062 1.96779 2.75751C2.81868 2.82715 3.55687 3.37236 3.87364 4.16516C3.90498 4.24358 3.93463 4.3379 4.01838 4.61008L5.99316 11.0281C6.29594 12.0122 6.54213 12.8124 6.81597 13.4359C7.10167 14.0864 7.44679 14.6169 7.98784 15.0165C8.52889 15.4161 9.13745 15.59 9.84321 15.6717C10.5197 15.7501 11.3569 15.7501 12.3865 15.7501H14.581C15.6178 15.7501 16.4607 15.7501 17.1414 15.6709C17.8515 15.5883 18.4635 15.4126 19.0064 15.0087C19.5493 14.6047 19.8934 14.069 20.1766 13.4126C20.4481 12.7834 20.6902 11.9761 20.9881 10.983L21.1909 10.3072C21.5299 9.17741 21.8081 8.2499 21.918 7.50536C22.0325 6.72982 21.9907 5.99351 21.5182 5.35851C21.0458 4.7235 20.3525 4.47191 19.5768 4.35869C18.8321 4.25001 17.8638 4.25003 16.6842 4.25006L5.5 4.25006C5.49234 4.25006 5.48471 4.25018 5.47711 4.25041L5.44241 4.13763C5.37194 3.90851 5.32359 3.7513 5.26657 3.60859C4.73861 2.28727 3.5083 1.37857 2.09014 1.26251C1.93697 1.24997 1.77251 1.24998 1.53278 1.25L1.50002 1.25L0.999908 1.25006C0.585695 1.25011 0.249949 1.58594 0.25 2.00016C0.250051 2.41437 0.585878 2.75011 1.00009 2.75006L1.50007 2.75ZM7.41361 10.544L5.93855 5.75006H16.6239C17.8788 5.75006 18.7367 5.75197 19.3602 5.84297C19.9654 5.93129 20.1875 6.08276 20.3148 6.2539C20.4421 6.42504 20.5234 6.68131 20.4341 7.28634C20.3421 7.90972 20.0974 8.7319 19.7368 9.93394L19.5644 10.5086C19.2504 11.5555 19.0322 12.2787 18.7993 12.8185C18.5742 13.3402 18.3653 13.616 18.111 13.8052C17.8567 13.9944 17.5325 14.1153 16.9681 14.1809C16.3841 14.2489 15.6287 14.2501 14.5358 14.2501H12.4314C11.346 14.2501 10.596 14.2489 10.0158 14.1817C9.45511 14.1167 9.13249 13.9972 8.87899 13.8099C8.6255 13.6227 8.41633 13.3495 8.18936 12.8327C7.95451 12.298 7.73281 11.5814 7.41361 10.544Z"
                            fill="#fff"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.25 20C7.25 18.4812 8.48122 17.25 10 17.25C11.5188 17.25 12.75 18.4812 12.75 20C12.75 21.5188 11.5188 22.75 10 22.75C8.48122 22.75 7.25 21.5188 7.25 20ZM10 18.75C9.30964 18.75 8.75 19.3096 8.75 20C8.75 20.6904 9.30964 21.25 10 21.25C10.6904 21.25 11.25 20.6904 11.25 20C11.25 19.3096 10.6904 18.75 10 18.75Z"
                            fill="#fff"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.25 20C15.25 18.4812 16.4812 17.25 18 17.25C19.5188 17.25 20.75 18.4812 20.75 20C20.75 21.5188 19.5188 22.75 18 22.75C16.4812 22.75 15.25 21.5188 15.25 20ZM18 18.75C17.3096 18.75 16.75 19.3096 16.75 20C16.75 20.6904 17.3096 21.25 18 21.25C18.6904 21.25 19.25 20.6904 19.25 20C19.25 19.3096 18.6904 18.75 18 18.75Z"
                            fill="#fff"
                          />
                        </svg>
                      </Badge>
                    ) : (
                      ""
                    )}
                  </IconButton>
                )}
                {(appState.showTagsAndDitesFilter && (appState.tags.length > 0 || appState.dietaries.length > 0))? (
                  <IconButton
                    onClick={() =>
                      appDispatch({
                        type: "setFilterProductMode",
                        filterProductMode: true,
                      })
                    }
                    component={Link}
                    to={`${
                      appState.storeInfo.progressive
                        ? `${location.pathname}`
                        : `${location.pathname}${location.search}`
                    }`}
                    id="headerBtn"
                  >
                    <FilterListIcon fontSize="inherit" />
                  </IconButton>
                ) : (
                  ""
                )}

                {appState.formData.openBill &&
                appState.isAddToCartEnable && !appState.hotelQrCode &&
                appState.loggedIn &&
                !appState.payTableMode ? (
                  <IconButton
                    component={Link}
                    to="/openOrder"
                    className="classForSvgColor mb-1"
                    id="headerBtn"
                  >
                    <span>
                      <div className="ring-container-open-bill">
                        <div className="ringring"></div>
                        <div className="circle"></div>
                      </div>

                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.25 9C6.25 8.58579 6.58579 8.25 7 8.25H11C11.4142 8.25 11.75 8.58579 11.75 9C11.75 9.41421 11.4142 9.75 11 9.75H7C6.58579 9.75 6.25 9.41421 6.25 9Z"
                          fill="#fff"
                        />
                        <path
                          d="M7 12.25C6.58579 12.25 6.25 12.5858 6.25 13C6.25 13.4142 6.58579 13.75 7 13.75H13C13.4142 13.75 13.75 13.4142 13.75 13C13.75 12.5858 13.4142 12.25 13 12.25H7Z"
                          fill="#fff"
                        />
                        <path
                          d="M6.25 17C6.25 16.5858 6.58579 16.25 7 16.25H13C13.4142 16.25 13.75 16.5858 13.75 17C13.75 17.4142 13.4142 17.75 13 17.75H7C6.58579 17.75 6.25 17.4142 6.25 17Z"
                          fill="#fff"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.62356 2.35132C2.85479 2.21713 3.13998 2.21617 3.37211 2.34882L6.5 4.13619L9.6279 2.34882C9.85847 2.21706 10.1415 2.21706 10.3721 2.34882L13.5 4.13619L16.6279 2.34882C16.86 2.21617 17.1452 2.21713 17.3764 2.35132C17.6077 2.48551 17.75 2.73265 17.75 3V11.25H21C21.4142 11.25 21.75 11.5858 21.75 12V19C21.75 20.5188 20.5188 21.75 19 21.75H8.94513C7.57754 21.75 6.47522 21.75 5.60825 21.6335C4.70814 21.5125 3.95027 21.2536 3.34835 20.6517C2.74643 20.0497 2.48754 19.2919 2.36652 18.3918C2.24996 17.5248 2.24998 16.4225 2.25 15.0549V3C2.25 2.73265 2.39232 2.48551 2.62356 2.35132ZM19 20.25C19.6904 20.25 20.25 19.6904 20.25 19V12.75H17.75V19C17.75 19.6904 18.3096 20.25 19 20.25ZM16.5499 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15V4.29238L6.1279 5.65118C6.35847 5.78294 6.64153 5.78294 6.87211 5.65118L10 3.86381L13.1279 5.65118C13.3585 5.78294 13.6415 5.78294 13.8721 5.65118L16.25 4.29239V19C16.25 19.4501 16.3581 19.875 16.5499 20.25Z"
                          fill="#fff"
                        />
                      </svg>
                    </span>
                  </IconButton>
                ) : (
                  ""
                )}
              </div>

              {matches ? (
                <div className="busniessName col-4 d-flex justify-content-center">
                  {scroll > 0 ? get_lang(get_lang(appState.storeInfo.businessName, appState.langValue), appState.langValue) : ""}
                </div>
              ) : (
                ""
              )}
              {appState.isAddToCartEnable && (
                <div className="col-4 d-flex justify-content-end">
                  {!appState.loggedIn ? (
                    <IconButton
                      className="signInBtn classForSvgColor"
                      onClick={handleClickOpen}
                      id="headerBtn"
                    >
                      {/* <i className="fa fa-user"></i> */}
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 2.25C9.37665 2.25 7.25 4.37665 7.25 7C7.25 9.62335 9.37665 11.75 12 11.75C14.6234 11.75 16.75 9.62335 16.75 7C16.75 4.37665 14.6234 2.25 12 2.25ZM8.75 7C8.75 5.20507 10.2051 3.75 12 3.75C13.7949 3.75 15.25 5.20507 15.25 7C15.25 8.79493 13.7949 10.25 12 10.25C10.2051 10.25 8.75 8.79493 8.75 7Z"
                          fill="#fff"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9 14.25C6.37665 14.25 4.25 16.3766 4.25 19C4.25 20.5188 5.48122 21.75 7 21.75H17C18.5188 21.75 19.75 20.5188 19.75 19C19.75 16.3766 17.6234 14.25 15 14.25H9ZM5.75 19C5.75 17.2051 7.20507 15.75 9 15.75H15C16.7949 15.75 18.25 17.2051 18.25 19C18.25 19.6904 17.6904 20.25 17 20.25H7C6.30964 20.25 5.75 19.6904 5.75 19Z"
                          fill="#fff"
                        />
                      </svg>
                    </IconButton>
                  ) : (
                    <UserInfo />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {children && children}
    </div>
  );
};

export default withTranslation()(MiscBanner);
