import { isNotEmpty } from "../utils";
import { handleTapCharges } from "./charges";
import { roundFixed } from "./roundFixed";

export const handleUserInfo = (response) => {
  let data = {
    name:
      response.user != undefined && response.user.name != undefined
        ? response.user.name
        : null,
    email:
      response.user != undefined && response.user.email != undefined
        ? response.user.email
        : null,
    userId:
      response.user != undefined && response.user.id != undefined
        ? response.user.id
        : null,
    number:
      response.user != undefined && response.user.number != undefined
        ? response.user.number
        : null,
    countryCode:
      response.user != undefined && response.user.country_code != undefined
        ? response.user.country_code
        : null,
    emailVerfiy:
      response.user != undefined && response.user.email_verified_at != undefined
        ? response.email_verified_at
        : null,
    walletAmount:
      response.wallet != undefined && response.wallet.amount != undefined
        ? response.wallet.amount
        : null,
    walletCreated:
      response.wallet != undefined && response.wallet.created_at != undefined
        ? response.wallet.created_at
        : null,
    walletDeleted:
      response.wallet != undefined && response.wallet.deleted_at != undefined
        ? response.wallet.deleted_at
        : null,
    walletId:
      response.wallet != undefined && response.wallet.id != undefined
        ? response.wallet.id
        : null,
    walletReward:
      response.wallet != undefined && response.wallet.reward != undefined
        ? response.wallet.reward
        : null,
    walletUpdated:
      response.wallet != undefined && response.wallet.updated_at != undefined
        ? response.wallet.updated_at
        : null,
    tapWalletId:
      response.meta != undefined && response.meta.tap_wallet_id != undefined
        ? response.meta.tap_wallet_id
        : null,
    tapBankId:
      response.meta != undefined &&
      response.meta.tap_bank_account_id != undefined
        ? response.meta.tap_bank_account_id
        : null,
    tapCustomerId:
      response.tap_customer_id != undefined ? response.tap_customer_id : null,
    userInvoices:
      response.invoices != undefined && response.invoices.count != undefined
        ? response.invoices.count
        : null,
    userActiveStatus:
      response.active.status != undefined ? response.active.status : null,
    userActiveReason:
      response.active.reason != undefined ? response.active.reason : null,
    userCreated: response.created_at != undefined ? response.created_at : null,
    userCurrency: response.currency != undefined ? response.currency : null,
    userDob: response.dob != undefined ? response.dob : null,
    userFirstName:
      response.first_name != undefined ? response.first_name : null,
    userLastName: response.last_name != undefined ? response.last_name : null,
    userMiddleName:
      response.middle_name != undefined ? response.middle_name : null,
    userNationality:
      response.nationality != undefined ? response.nationality : null,
    userToken: response.token != undefined ? response.token : null,
    userGender: response.gender != undefined ? response.gender : null,
    userCountryId:
      response.country_id != undefined ? response.country_id : null,
    userCityId: response.city_id != undefined ? response.city_id : null,
    userAreaId: response.area_id != undefined ? response.area_id : null,
    userAddress: response.address != undefined ? response.address : null,
    customerId: response.id != undefined ? response.id : null,
    userImage: response.image != undefined ? response.image : null,
  };
  return data;
};

export const handleChangeTotal = (
  data = [],
  state,
  dispatch,
  mode = "empty"
) => {
  var rewardTotal = 0;
  if (mode == "product") {
    rewardTotal = data.reduce((total, ele) => {
      const price = Number(ele.price);
      const freeQty = Number(ele.free_qty);
      return total + (price * freeQty);
    }, 0);
  } else if (mode == "discount") {
    rewardTotal = data?.discount;
  }
  
  // Assuming state is accessible here, you can log state.invoiceTaxesChargesExtras.
  let arr = { ...state.invoiceTaxesChargesExtras };
  
  arr.rewardsDiscount = isNotEmpty(rewardTotal) ? rewardTotal : 0;
  

  dispatch({
    type: "setInvoiceTaxesChargesExtras",
    invoiceTaxesChargesExtras: arr,
  });
  return arr;
};

export const handleRewards = (state) => {
  const rewardTotal = state.optionalRewardsDetials.reduce((total, ele) => {
    const price = Number(ele.price);
    const freeQty = Number(ele.free_qty);
    return total + price * freeQty;
  }, 0);
  return rewardTotal;
};

export const handleInvoice = (res, invocie, state) => {
  const {
    amount,
    total_amount,
    total_charges,
    total_tax,
    discounted_amount,
    tip,
    adjusted_amount,
  } = invocie;
  const {
    storeInfo: { roundLevel, roundMethod, taxPricing },
  } = state;
  const roundedAmount = (value) =>
    roundFixed(Number(value) ?? 0, roundLevel, roundMethod);
  const rewardsTotal = handleRewards(state);
  console.log(handleRewards(state));
  let data = {
    currentInvoiceStatus: res?.is_partial ? "Partial" : res.status,
    paidAmount: res?.is_partial ? roundedAmount(res.amount) : 0,
    leftToPay: res?.is_partial ? roundedAmount(amount - res.amount) : 0,
    fullAmount: amount,
    adjustedAmount: adjusted_amount ?? 0,
    charges: handleTapCharges(res, state),
    totalCharges: total_charges,
    totalTax: taxPricing == "exclusive" ? total_tax : 0,
    discount: discounted_amount,
    rewardsDiscount: rewardsTotal ? rewardsTotal : 0,
    subTotalAmount: total_amount,
    totalAmount: res?.is_partial
      ? roundedAmount(amount - res.amount)
      : roundedAmount(amount) - Number(tip != undefined ? tip : 0),
    tip: Number(tip != undefined ? tip : 0),
  };
  return data;
};

export const getCurrentCards = (state, setStoreLoyalty, setRewardInfo) => {
  if (state.selectedLoyaltyId) {
    let data = state.loyaltyObj.find(
      (item) => item.id == state.selectedLoyaltyId
    );
    let rewardInfo = {
      punch: data?.rewards?.punch,
      active: data?.active,
    };
    if (data != undefined && data?.active) {
      var customArr = [];
      let conditions = {
        condition: [...data.conditions],
        objType: "conditions",
      };
      let rewards = { ...data.rewards, objType: "rewards" };
      customArr.push(rewards);
      customArr.push(conditions);
      setStoreLoyalty(data != undefined ? customArr : null);
      setRewardInfo(rewardInfo ?? null);
    }
  }
};
