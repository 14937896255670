/* eslint-disable */
import React from 'react';

function NotFound({storeName, errorMessage}) {
    return (
        <div>
              <div id="notfound">
        <div className="notfound text-center">
            <div className="notfound-404">
                 <img
                        src={`/images/StoreNotFound.png`}
                        className="img-pro"
                        alt=""
                      />
                      <h1>Error 404</h1>
            </div>
            <h2 className='text-capitalize'>We are sorry  {errorMessage.replace(/[_-]/g, ' ')} !</h2>
            <p>
                The page you are looking for might have been removed had its name changed or is temporarily unavailable.
            </p>
            			{/* <a href="#">Back To Homepage</a> */}
        </div>
    </div>        </div>
    )
}

export default NotFound
