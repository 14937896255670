// hooks
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import noImg from "../assets/images/no-image.svg";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { handleFeaturedProduct } from "../../../redux-store/action";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { handleStockQuantity } from "../../../global-functions/addToCartGlobal";
import { useFeaturedProductIncDec } from "./useFeaturedProductIncDec";


export const useFeaturedProductAddToCart = (featuredProduct,product,quantityValue,setErrorStatus,placedBy,addNote) => {

	//States:
	const { t } = useTranslation();
	const appState = useSelector((state) => state);
	const appDispatch = useDispatch();
	const { mSlug, slug, islug } = useParams();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	let parent_id = searchParams.get("parent");





	///// handleFunctions:


	
	const handleAddToCart = (item,addons,addonLimit,addOnsTotal) => {
		// console.log(item.price - item.discount,"item",item.price - item.discount + addOnsTotal)
		// console.log("addonLimit",addonLimit,"addons",addons)
		//item.quatitiy 0 for just simple product and item.quantity 1 for product with addons
		item.checkedCart = true;
		console.log(item, addOnsTotal);
		
		if (item.price - item.discount  + addOnsTotal > 0) {
			if (appState.qrRequired ||
				appState.qrPickup != null||
				appState.hotelQrCode) {
				let flag = true;
				let error = "";
				addonLimit.forEach((ele, ind) => {
					if (
					  ele.min != null &&
					  ele.addons_length > 0 &&
					  ele.addons_qty < ele.min
					) {
					  flag = false;
					  if (ele.min != null) {
						error = `${t("custom-msg.Addons-quantity-required")} (${
						  ele.min
						})`;
						setErrorStatus(true);
						setTimeout(() => {
						  setErrorStatus(false);
						}, 2000);
					   
						const id = ele.id ;
						const yOffset = -10; 
						const element = document.getElementById(id);
						const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
						
						window.scrollTo({top: y, behavior: 'smooth'});
					  }
		  
					  return;
					}
				  });


				if (flag) {
					var arr = [];
					addons.forEach((addonItem, index) => {
					  if (addonItem.check_status) {
						let data = {
						  ...addonItem,
						  product_addon: {
							addon: addonItem.addon,
							description: addonItem.description,
						  },
						};
						arr.push(data);
					  }
					});
		  
					if (item.quantity < 0 && item.quantity != null) {
					  appDispatch({
						type: "SHOW_ALERT",
						alertType: "error",
						message: `${t("custom-msg.out-of-stock")}`,
					  });
					  return;
					}
					let orderTypeName =  appState.hotelQrCode > 0 ? `hotel` : appState.qrPickup > 0 ? `pickUp` : appState.qrTable > 0 ? `dineIn` : `menu`

					let cart_id = uuidv4();
					var localcart = [...appState.cart];
					var currentCart = {
						id: cart_id,
						orderType: orderTypeName,
						checkedCart: true,
						pGroupId: appState.productGroupId,
						parent_product_id:product?.id,
						product_id: item.id,
						product_type: item?.type,
						maxQuantity: item.maxQuantity,
						category_id: item?.category?.id,
						prod_title: item.name,
						prod_slug: item.slug,
						prod_desc: item?.description,
						quantity: quantityValue,
						price: item.price - item.discount,
						discount: item.discount,
						price_after_discount: item.price - item.discount,
						image: item.image != null ? item.image : noImg,
						category_name: item?.category_name?.en,
						redirect_url: appState.storeInfo.progressive
							? `${item?.category?.slug}/${item?.slug}?cart_id=${cart_id}`
							: `${item?.category?.slug}/${item?.slug}?parent=${parent_id}&prodid=${item.id}&cart_id=${cart_id}&`,
						addons: arr,
						addons_limit: addonLimit,
						sum_addons: addOnsTotal,
						overAllSum : (item.price - item.discount) * quantityValue + addOnsTotal,
						note:
						(addNote != undefined && addNote != "" )
						  ? addNote
							  .replace(/(\r\n|\n|\r)/gm, "<br>")
							  .replace(/["'()]/g, "")
						  : "",
						meta: cart_id,
						product: {
							name: item.name,
							image: item.image != null ? item.image : noImg,
						},
						placed_by:placedBy
					};
					localcart = [...localcart, currentCart];
					handleStockQuantity(localcart, item, appDispatch, appState);
					appDispatch({
						type: "updateCart",
						cart: localcart,
					});
					appDispatch({
						type: "SET_FEATURED_PRODUCTS",
						featuredProductPopupMode: false,
						featuredProducts:[]
					  })



					if (item.quantity <= 0 && item.quantity != null) {
						appDispatch({
							type: "SHOW_ALERT",
							alertType: "error",
							message: `out-of-stock`,
						});
						return;
					}

				} else {
					appDispatch({
						type: "SHOW_ALERT",
						alertType: "error",
						message: error,
					});
				}

			} else {
				appDispatch({
					type: "SHOW_ALERT",
					alertType: "error",
					message: `scane-qrcode`,
				});
			}

		} else {
			appDispatch({
				type: "SHOW_ALERT",
				alertType: "error",
				message: `Total Amount must be grater than 0.`,
			});
			
		}

		

	};
	///// useffects
	useEffect(() => {
	}, []);



	return {handleAddToCart};
};