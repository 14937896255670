/* eslint-disable eqeqeq */
/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";

// Global Fucntions
import { get_lang } from "../../../global-functions/apiGlobal";

// redux
import { useSelector, useDispatch } from "react-redux";

// for Localization
import { withTranslation } from "react-i18next";

import Page from "../components/Page";
import Product from "../components/Product";
import { Button } from "@mui/material";
import ProgressiveSearch from "../components/search/ProgressiveSearch";
import { handleSearch } from "../../../redux-store/action";
import NonProgressiveSearch from "../components/search/NonProgressiveSearch";

function Search({ t, i18n }) {
  const [userSearch, setUserSearch] = useState("");
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();

  const handleClick = () => {
    appDispatch(handleSearch(userSearch, appState))
  }
  useEffect(() => {
  if(appState.storeInfo.progressive == 0) {
    appDispatch({
      type: "SET_PRODUCTS",
      products: [],
    });
  }
  }, [])
  
  return (
    <Page title="Search">
      <div id="wrapper">
        {/* <cart-component></cart-component>
//         <authentication-component></authentication-component> */}
        <section id="srchPage">
          <section id="topNav">
            <div className="bckNavbar col-12 row m-0 p-1 d-flex  align-items-center">
              <Link to="/">
                <IconButton
                  aria-label="back"
                  className={
                    appState.langValue == "ar" ? "backBtnAr" : "backBtn2"
                  }
                  // orderbckBtn"
                >
                  {appState.langValue == "ar" ? (
                    <ArrowForwardIosIcon
                      style={{ padding: "0px !important" }}
                      fontSize="inherit"
                    />
                  ) : (
                    <ArrowBackIosIcon fontSize="inherit" />
                  )}
                </IconButton>
              </Link>
              <input
                type="search"
                // v-model="productSearch"
                autoFocus
                placeholder={t("Search.search")}
                name=""
                id=""
                className={`${
                  appState.storeInfo.progressive
                    ? `col-md-11 col-lg-10 col-10`
                    : `col-md-9  col-lg-9 col-9`
                } form-control`}
                onChange={(e) => {
                  // const putSearch = getSearch.charAt(0).toUpperCase() + getSearch.slice(1);
                  setUserSearch(e.target.value);
                  // setCaseSearch(putSearch)
                }}
                // v-on:keyup="searchProducts"
              />
             {appState.storeInfo.progressive == 0 &&
              <IconButton
              aria-label="back"
              className={`${
                appState.langValue == "ar"
                  ? `mr-1`
                  : `ml-1`
              }  searchBtn ${userSearch==''? `disableBtn` : `mainBtn`}`}
              disabled={userSearch==''}
              onClick={handleClick}
            >
                <i className="fa fa-search"></i>

            </IconButton>
              
              
              
              }

            </div>
          </section>
        </section>

        <section id="Search" className="search">
          {appState.storeInfo.progressive == 1 
          ?  <ProgressiveSearch  userSearch={userSearch}/>
          : <NonProgressiveSearch userSearch={userSearch}/>
    }
        </section>
      </div>
    </Page>
  );
}

export default withTranslation()(Search);
