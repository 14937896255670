
/* eslint-disable */
import React , {useEffect, useState} from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';

const TestComponent = () => {
  const handleReviewClosed = () => {
    console.log("closesd");
  };


  const getReviewWidget = async () => {
    // const apiKey = 'AIzaSyAAw3Wc3_O_dKF_Ofi9ZhFL19vNEwEBUKA';
    // const apiKey = "AIzaSyC2mEZEM8OYvmw_xyH1J2ci0qATF7NSuao"; 
    // const apiKey = "AIzaSyAAw3Wc3_O_dKF_Ofi9ZhFL19vNEwEBUKA" // sundat app
    // const originUrl = 'https://demo.sundayapp.xyz' // sunday


    
    const baseUrl = "https://www.google.com/maps/api/js/ReviewsService.LoadWriteWidget2";
    // const apiKey = "AIzaSyBhJkhH_bwySUuWomWUqO_7tsy5dlwRJPI"; 
    // const apiKey = "AIzaSyCIGtE0YbFN2gQoIvFmWCthjYW4FMV6guos";  new1
    const apiKey = "AIzaSyBOsY5Mkjvcii5BDq9BMNKUjRm3K-gySKE"; // new2

    const originUrl =encodeURIComponent("https://devshop.enabill.com");
    const placeId = "ChIJa147K9HX3IAR-lwiGIQv9i4";
    const reviewUrl = `${baseUrl}?ex_is_third_party=1&key=${apiKey}&hl=en&popup=1&origin=${originUrl}&pb=!2m1!1s${placeId}&cb=${handleReviewClosed}`;
    // const reviewUrl = `https://www.google.com/maps/place/?q=place_id:${placeId}&action=write_review`
   // https://www.google.com/maps/api/js/ReviewsService.LoadWriteWidget2?ex_is_third_party=1&key=AIzaSyAAw3Wc3_O_dKF_Ofi9ZhFL19vNEwEBUKA&hl=en-US&popup=1&origin=https%3A%2F%2Fdemo.sundayapp.xyz&pb=!2m1!1sChIJjZbvwVepEmsRfNb0JAya7Bs&cb=60903608
    // const placeId = 'ChIJjZbvwVepEmsRfNb0JAya7Bs';
        // const reviewUrl = `https://www.google.com/maps/api/js/reviews?key=${apiKey}`

      
    
    

  // try {
  //   const response = await axios.get(`https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=Museum%20of%20Contemporary%20Art%20Australia&inputtype=textquery&fields=formatted_address%2Cname%2Crating%2Copening_hours%2Cgeometry&key=${apiKey}`,
  //   {
  //   headers: { }}

  //   );
  //   console.log(response);
  // } catch (error) {
  //   console.log(error);
  // }
        // const reviewUrl = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=place_id&key=${apiKey}`
        // const reviewUrl = `http://search.google.com/local/writereview?placeid=${placeId}`
    // const reviewUrl = `https://www.google.com/maps/contrib/${placeId}/review`;
    // const reviewUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
// const reviewUrl = `https://www.google.com/search?q=Google&ludocid=${placeId}&authuser=0&hl=en#lrd=0x0:0x0,place_id:${placeId}`;

console.log(reviewUrl)
try {
 window.open(
    reviewUrl,
    "_blank",
    "toolbar=yes,scrollbars=yes,resizable=yes,top=1,left=1,width=auto,height=800"
  );} catch (error) {
  console.log("Error opening review window:", error);
}

    // const reviewUrl = `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeId}&hl=en&action=write_review`;
    // // const reviewUrl = `https://www.google.com/maps/place/?q=place_id:${placeId}&action=write_review`;

    // // Redirect the customer to the review URL
    // // window.location.href = reviewUrl;
    //  
  };
  
  useEffect(() => {
    // Function to load the Google Maps Reviews Write Widget
    // console.log(reviewUrl)
    getReviewWidget()

    const loadWriteWidget = () => {

      const script = document.createElement('script');
      // script.src = `https://maps.googleapis.com/maps/api/js/reviews?key=AIzaSyBhJkhH_bwySUuWomWUqO_7tsy5dlwRJPI`
      script.src = `https://www.google.com/maps/api/js/reviews?key=AIzaSyBhJkhH_bwySUuWomWUqO_7tsy5dlwRJPI`
      script.onload = () => {
        // Callback function after the script is loaded
        // You can add any additional logic here if needed
      };

      // document.body.appendChild(script);
      console.log('workd');
      console.log('srcipt');
      // Clean up the script when the component unmounts
      const scripst = document.querySelector(
        'script[src="https://www.google.com/maps/api/js/ReviewsService.LoadWriteWidget2"]');
        console.log('dkfdk',scripst);
    };

    // loadWriteWidget();

    // return () => {
    //  console.log('srcipt');
    //   // Clean up the script when the component unmounts
    //   const script = document.querySelector(
    //     'script[src="https://www.google.com/maps/api/js/ReviewsService.LoadWriteWidget2"]');
    //     console.log('dkfdk',script);
    //     getReviewWidget()
    //     if (script) {

    //     script.remove();
    //   }
    // };
  }, []);



  return <div>Google Review</div>;
};

export default TestComponent;

