// hooks
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";


export const useLoyaltyCard = (callingFrom) => {

	//States:
    const [error, setError] = useState("sik");
	const [isLoading, setIsLoading] = useState(true);
	const appState = useSelector((state) => state);
	const appDispatch = useDispatch();
	const [value, setValue] = useState(null);

///// handleFunctions:
	const handleOpenLoyaltyCard = (id)=>{
		
		  if(appState.loggedIn){
			appDispatch({
				type: "setLoyalityPopupMode",
				loyalityPopupMode: true,
				selectedLoyaltyId: id,
			  })	  
		  }else{
			  appDispatch({ type: "setLoginModal", loginModal: true });
		  }

	}


///// useffects
	useEffect(() => {
		if(callingFrom == "home"){
			setIsLoading(false);
		}
	}, []);

// calling from checkout 


	return {appState,appDispatch,isLoading,handleOpenLoyaltyCard,value };
};