/* eslint-disable */
import React, { useState,  } from "react";
import "./DiscountOptions.scss";
// material ui
import Button from "@mui/material/Button";
// handleTotalPersonf
import { withTranslation, useTranslation } from "react-i18next";
// redux
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress, Skeleton } from "@mui/material";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  floatingLabelFocusStyle: {
    color: "#052d72",
    fontWeight: 600,
    fontSize: "1.1rem",
  },
}));

function Voucher(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const classes = useStyles();
  const appDispatch = useDispatch();
  const {
    promoCode,
    promoStatus,
    getPromo,
    promoLoading,
    setPromoCode,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [showPromocode, setShowPromocode] = useState(false);

  return isLoading ? (
    <>
      <div className="row m-0 d-flex flex-row">
        <div className="col-6  ">
          <Skeleton height={50} />
        </div>
        <div className="col-10 ">
          <Skeleton height={80} />
        </div>
      </div>
    </>
  ) : (
    <div
    className={`promoVocherMain ${
      appState.langValue === "ar" ? "promoVocherMainArabic" : " "
    }`}
  >
      <div className="d-flex justify-content-between align-items-center">
        
        <Button
          className={` vocherBtn familyManrope800    ${
            appState.langValue === "ar" ? "text-right" : "text-left"
          }`}
          onClick={() => setShowPromocode(!showPromocode)}
        >
          {/* {t("open-order.Previously-ordered")} */}
          {showPromocode ? "X" : t("loyalty.add-voucher-code")}
        </Button>
      </div>

      {showPromocode && !appState.hotelMode && (
        // voucher work
        <section id="promoCode" className="py-2 row m-0">
       
          <TextField
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {promoStatus === false ? (
                    <Button
                      variant="contained"
                      className={`btn  btn-block mb-3 btn-main text-capitalize cursorPointer ${
                        promoCode != "" ? "" : `disableClass`
                      } `}
                      onClick={getPromo}
                    >
                      {promoLoading ? (
                        <React.Fragment>
                          {" "}
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />{" "}
                          {t("checkOut.Apply")}{" "}
                        </React.Fragment>
                      ) : (
                        t("checkOut.Apply")
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="btn btn-block btn-main text-capitalize cancelBtn"
                      onClick={getPromo}
                    >
                      {promoLoading ? (
                        <React.Fragment>
                          {" "}
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />{" "}
                          {t("checkOut.cancel")}{" "}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>{t("checkOut.cancel")}</React.Fragment>
                      )}
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
            className={` col-12 px-0 promo AddPromoCheckout ${
              appState.langValue === "ar" ? "AddPromoCheckoutAR" : ""
            }`}
            // id="standard-textarea"
            id="filled-textarea"
            variant="filled"
            label={`${t("loyalty.add-voucher-code")}`}
            placeholder={`${t("loyalty.voucher-code")}`}
            onChange={(e) => {
              setPromoCode(e.target.value);
            }}
            multiline
            disabled={promoStatus}
            value={promoCode}
            focused
          />
        </section>
      )}
    </div>
  );
}

export default withTranslation()(Voucher);
