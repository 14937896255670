/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { withTranslation, useTranslation, Trans } from "react-i18next";
import Button from "@material-ui/core/Button";
import { useSelector } from 'react-redux';


 const SaveCard = ({payments, paymentMethod, tapStatus}) => {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const [seeMore, setSeeMore] = useState(1);

  const handlChangeView = () => {
    if (appState.saveCardList.length > seeMore) {
      setSeeMore(seeMore + 1);
    } else {
      setSeeMore(1);
    }
  };

  return (
      <>
    {payments ? payments.includes("save_card")? appState.loggedIn
    ?appState.saveCardList != null
    ? appState.saveCardList.slice(0, seeMore).map((val, ind) => {
        return (

            
            <div className=" paymentContainer  p-0">
            <div>
              <input
                type="radio"
                checked={paymentMethod == val.id}
                value={val.id}
                name="paymentMethod"
                id={`paymentSaveCard-${val.id}`}
                disabled={tapStatus}
              />
              <label htmlFor={`paymentSaveCard-${val.id}`}>
              <div className="row m-0 align-items-center">
                          <span className="pl-4"> 
                    <img
                    src={`/images/credit-card.png`}
                      alt=""
                      width="30"
                    />
                  </span>
                  <span className="pl-4  ">
                    <h4 className="mt-2 d-flex ">
                      {val.type}
                      <p className="text-muted">
                        {" "}
                          **** **** **** {val.last_four}
                      </p>
                    </h4>
                  </span>
                </div>
              </label>
            </div>
          </div>
        );
      })
    : ""
    : ""
    : ""
    
    : ""}
      {/* button for view more save cards */}
     {appState.loggedIn && <div className="col-12 d-flex justify-content-end mb-2">
      {appState.saveCardList.length > 1 && <Button
        className="text-capitalize"
        variant="text"
        onClick={handlChangeView}
      >
        {seeMore < appState.saveCardList.length
          ? "See More"
          : "See Less"}
      </Button>}
    </div>}
    </>
  )

}
export default withTranslation() (SaveCard);