/* eslint-disable  */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import PropTypes from "prop-types";

// Components
import StateContext from "../../../store/StateContext";
import SearchList from "../components/SearchList";
import DineInForm from "../components/DineInForm";
import Page from "../components/Page";

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Tab, Tabs, AppBar } from "@material-ui/core";
import axios from "axios";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withTranslation, useTranslation, Trans } from "react-i18next";

// redux
import { useSelector, useDispatch } from "react-redux";
import { get_lang } from "../../../global-functions/apiGlobal";
import { Skeleton } from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
// TabPanel

function Contact() {
  // @States & Variables
  const { t } = useTranslation();

  const history = useHistory();
  const appState = useSelector((state) => state);
  const classes = useStyles();
  const appDispatch = useDispatch();
  const [value, setValue] = React.useState(
    history.location.state ? history.location.state : 1
  );
  let services = appState.serviceMode;

  const [toggleIconConditionRender, setToggleIconConditionRender] =
    useState(true);

  const [loading, setLoading] = useState(true);
  const [serviceName, setServiceName] = useState([]);
  useEffect(() => {
    const handleServicesName = () => {
      let arr = [];
      let flag = true

      if (!appState.hotelQrCode) {
        for (const ele of services) {
          if (ele === "dine-in") {
            arr.push("Dine In");
          }
          if (ele === 'pick-up') {
            arr.push("Pick Up");
          }
          if ((ele === "open-bill" || ele === "group-bill") && !appState.storeInfo.buffetMode) {
            arr.push("Open Bill");
          }
        }
      }
      
      if ((appState.hotelQrCode || !arr.includes("Open Bill")) || appState.storeInfo.buffetMode) {
        arr.push("Close Bill");
      } 
       if (appState.storeInfo.buffetMode) {
        arr.push('Buffet');
      }
    
      setServiceName(arr);
      setLoading(false);
    };
    handleServicesName();
  }, []);

  const arLanguageHandle = (e) => {
    // console.log("ar change");
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");

    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  return (
    // changes 20-may by sajid from here to bottom
    //
    <Page title="Info">
      <div id="wrapper">
        {/* <div className="bg_fade"></div> */}
        {/* <cart-component></cart-component>
    <authentication-component></authentication-component> */}
        <div id="contact-page">
          {/* changes by sajid may-24 */}

          <section id="topNav">
            <div className="row m-0 checkOut_Nav justify-content-between align-items-center p-3">
              <div className="d-flex align-items-center">
                <Link to="/">
                  <IconButton
                    aria-label="back"
                    className={
                      appState.langValue === "ar" ? "backBtnAr" : "backBtn2"
                    }
                    // orderbckBtn"
                  >
                    {appState.langValue === "ar" ? (
                      <ArrowForwardIosIcon
                        style={{ padding: "0px !important" }}
                        fontSize="inherit"
                      />
                    ) : (
                      <ArrowBackIosIcon fontSize="inherit" />
                    )}
                  </IconButton>
                </Link>
                <div className="pl-1 mt-2">
                  <h4>
                    <strong>
                      {get_lang(
                        appState.storeInfo.businessName,
                        appState.langValue
                      )}
                    </strong>
                  </h4>
                </div>
              </div>

              {appState.langValue == "ar" ? (
                <IconButton id="headerBtn" onClick={(e) => enLanguageHandle(e)}>
                  <span className={`lang-textScroll p-0`}>EN</span>
                </IconButton>
              ) : (
                <IconButton
                  id="headerBtn"
                  onClick={(e) => arLanguageHandle(e)}
                  className="arabicBtn"
                >
                  <span className={`arabicText lang-textScroll p-0 mb-1`}>
                    عربي{" "}
                  </span>
                </IconButton>
              )}
            </div>
          </section>
          {/* changes end may-24 */}
          {/* Services */}
          {appState.serviceMode.length > 0 ? (
            <div className="row m-0 justify-content-between align-items-center servicesOptions p-4">
              <div className="row m-0 align-items-center">
                {/* <AccessTimeIcon className="clockIcon" /> */}

                <i className="fa fa-cogs text-muted" aria-hidden="true"></i>

                <p className="m-0 text-muted mx-2">
                  {t("Service.serviceOptions")}
                </p>
              </div>
              {!loading ? (
                <div className="row m-0 ">
                  {!appState.payTableMode ? (
                    <>
                      {serviceName.map((mode, index) => {
                        return (
                          <p className="m-0 text-capitalize " key={index}>
                            {appState.qrTable != null ? (
                              mode != "pick-up" && (
                                <b className=" mx-1"> {mode}</b>
                              )
                            ) : (
                              <b className=" mx-1"> {mode}</b>
                            )}
                          </p>
                        );
                      })}
                    </>
                  ) : (
                    <p className="m-0 text-capitalize ">
                      <b className=" mx-1">Pay at table</b>
                    </p>
                  )}
                </div>
              ) : (
                <div className="row m-0">
                  <Skeleton height={25} width={70} className="mr-2" />
                  <Skeleton height={25} width={70} className="mr-2" />
                </div>
              )}
            </div>
          ) : (
            <p className="m-0 text-capitalize ">No Service Available!</p>
          )}

          {/* <!--  --> */}
          <section id="contactSocialIcons">
            <div className="inner-contact_social_icons">
              <div className="row m-0 text-center">
                <div className="col">
                  {appState.socialLinks.Instagram !== undefined ? (
                    <a
                      href={`${appState.socialLinks.Instagram}`}
                      target="_blank"
                    >
                      <i className="fa fa-instagram"></i>
                      <h6>{t("contact.Instagram")}</h6>
                    </a>
                  ) : (
                    <a
                      href={`https://www.instagram.com/?hl=en`}
                      target="_blank"
                    >
                      <i className="fa fa-instagram"></i>
                      <h6>{t("contact.Instagram")}</h6>
                    </a>
                  )}
                </div>
                <div className="col">
                  {appState.socialLinks.Website !== undefined ? (
                    <a href={`${appState.socialLinks.Website}`} target="_blank">
                      <i className="fa fa-globe"></i>
                      <h6>{t("contact.Website")}</h6>
                    </a>
                  ) : (
                    <a href={`https://shop.enabill.com/`} target="_blank">
                      <i className="fa fa-globe"></i>
                      <h6>{t("contact.Website")}</h6>
                    </a>
                  )}
                </div>

                <div className="col whatsApp">
                  {appState.socialLinks.Whatsapp !== undefined ? (
                    <a
                      href={`https://wa.me/${appState.socialLinks.Whatsapp}`}
                      target="_blank"
                    >
                      <i className="fa fa-whatsapp"></i>
                      <h6>{t("contact.WhatsApp")}</h6>
                    </a>
                  ) : (
                    <a href={`https://api.whatsapp.com/`} target="_blank">
                      <i className="fa fa-whatsapp"></i>
                      <h6>{t("contact.WhatsApp")}</h6>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* ---------- */}
          <section id="timings">
            <div className="inner_timings">
              <div className="timing-1">
                {appState.storeInfo.workingHours.length > 0 ? (
                  <React.Fragment>
                    <div className="d-flex px-3 py-2">
                      <button
                        className="btn btn-default"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        onClick={() => {
                          setToggleIconConditionRender(
                            !toggleIconConditionRender
                          );
                        }}
                      >
                        <i
                          className={
                            toggleIconConditionRender
                              ? "fa fa-angle-down"
                              : "fa fa-angle-up"
                          }
                        ></i>
                      </button>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="innerClpse card card-body">
                        <div className="table-responsive">
                          <table>
                            <thead>
                              <tr>
                                <th>{t("contact.Day")}</th>
                                <th>{t("contact.Open")}</th>
                                <th>{t("contact.Close")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {appState.storeInfo.workingHours.map((val, i) => {
                                // console.log(
                                //     "Map Value",
                                //     val
                                // );
                                return (
                                  <React.Fragment>
                                    {!val.close_time || !val.open_time ? (
                                      ""
                                    ) : (
                                      <tr>
                                        <td>{val.day}</td>
                                        <td>
                                          {val.open_time !==
                                          (null || undefined) ? (
                                            val.open_time
                                          ) : (
                                            <>----</>
                                          )}
                                        </td>
                                        <td>
                                          {val.close_time !==
                                          (null || undefined) ? (
                                            val.close_time
                                          ) : (
                                            <>----</>
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <h4 className="text-center text-danger mt-3">
                    {t("contact.Working-hours-is-not-available")}.
                  </h4>
                )}
              </div>

              {/* close button end */}

              <div className="tmBtns text-center mt-4  mx-3 ">
                <div className="row m-0 d-flex justify-content-between ">
                  {appState.socialLinks.Whatsapp !== (null || undefined) ? (
                    <div>
                      <a
                        href={`tel:${appState.socialLinks.Whatsapp}`}
                        className="btn px-2 btn-main btn-main-outline"
                      >
                        {t("contact.Call-Branch")}
                      </a>
                    </div>
                  ) : (
                    " "
                  )}
                  <div>
                    {appState.socialLinks.Map_url !== (null || undefined) && (
                      <a
                        href={`${appState.socialLinks.Map_url}`}
                        className="btn px-2 btn-main btn-main-outline"
                        target="_blank"
                      >
                        {t("contact.Get-Directions")}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!--  --> */}
        </div>
      </div>
    </Page>
  );
}

export default withTranslation()(Contact);
