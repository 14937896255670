/* eslint-disable */
import React, { useEffect, useState, useReducer, useCallback } from "react";
import "./googlePayButton.scss";
import { useHistory } from "react-router-dom";

// material ui
import Button from '@mui/material/Button';

// localizations
import { withTranslation, useTranslation, Trans } from "react-i18next";
// redux
import { useSelector, useDispatch } from 'react-redux';
import GooglePayButton from "@google-pay/button-react";




function GooglePayButtonCompo() {
    const history = useHistory();
    const appDispatch = useDispatch();
    const { t } = useTranslation();
    const appState = useSelector((state) => state);
    const [buttonColor, setButtonColor] = useState("default");
    const [buttonType, setButtonType] = useState("plain");
    const [buttonSizeMode, setButtonSizeMode] = useState("static");
    const [buttonWidth, setButtonWidth] = useState("100%");
    const [buttonHeight, setButtonHeight] = useState(40);


    const paymentRequest = {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
            {
                type: "CARD",
                parameters: {
                    allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                    allowedCardNetworks: ["MASTERCARD", "VISA"]
                },
                tokenizationSpecification: {
                    type: "PAYMENT_GATEWAY",
                    parameters: {
                        gateway: "example"
                    }
                }
            }
        ],
        merchantInfo: {
            merchantId: "17613812255336763067",
            merchantName: "Demo Merchant"
        },
        transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPriceLabel: "Total",
            totalPrice: "1",
            currencyCode: "USD",
            countryCode: "US"
        },
        shippingAddressRequired: false,
        callbackIntents: [ 'PAYMENT_AUTHORIZATION'],
        // 'SHIPPING_ADDRESS',
    };









    return (
        <>
            <div className="demo GooglePayButton w-100 ">
                <GooglePayButton
                    environment="TEST"
                    buttonColor={buttonColor}
                    buttonType={buttonType}
                    buttonSizeMode={buttonSizeMode}
                    paymentRequest={paymentRequest}
                    onLoadPaymentData={paymentRequest => {
                        console.log("load payment data", paymentRequest);
                    }}
                    onPaymentAuthorized={paymentData => {
                        console.log('Payment Authorised Success', paymentData)
                        return { transactionState: 'SUCCESS'}
                      }
                    }
                    // onPaymentDataChanged={paymentData => {
                    //     console.log('On Payment Data Changed', paymentData)
                    //     return { }
                    //   }
                    // }
                    style={{ width: buttonWidth, height: buttonHeight }}
                />
    
            </div>
        </>


    );
}



export default withTranslation()(GooglePayButtonCompo);