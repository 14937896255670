const IMAGES = {
    kuwaitinput: require('./kuwaitinput.svg').default,
    enabill: require('./enabill.svg').default,
    enabillGroupBill: require('./enabillgroupBill.svg').default,
    union: require('./union2.svg').default,
    newsPaper: require('./newsPaper.svg').default,
    giftPaper: require('./gift2.svg').default,
    dialpad: require('./Dialpad2.svg').default,
    time: require('./time2.svg').default,

}

export default IMAGES;

