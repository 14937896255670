/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// Change by sajid from top to bottom
import { Skeleton } from "@material-ui/lab";
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CartItem from "../components/CartItem";
import CartItemv2 from "../components/cartItemv2/cartItemv2";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// Global Fucntions
import { get_lang, get_lang_search } from "../../../global-functions/apiGlobal";

// material ui
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { makeStyles } from "@material-ui/core/styles";
// import Icon from "@material-ui/core/Icon";
// import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Page from "../components/Page";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withTranslation, useTranslation, Trans } from "react-i18next";

// axios import
import axios from "axios";

// React Redux
import { useSelector, useDispatch } from "react-redux";
import {
  freezOn,
  freezOff,
  handleItemRemove,
  handleSetMeta,
  handleError,
} from "../../../redux-store/action";
import { createBill } from "../../../global-functions/addOpenBill";
import { getCookie, setCookie } from "../../../global-functions/cookieFunction";
import CheckoutBtn from "../components/checkoutBtn/CheckoutBtn";
import CircularIndeterminate from "../components/CircularIndeterminate";
import { Backdrop } from "@material-ui/core";
import { parseNumber } from "../../../global-functions/roundFixed";
import { handleOrdersPayload } from "../../../global-functions/addToCartGlobal";
import { handleStoreDiscount } from "./Checkout";
import { compareStartAndEndTime } from "../../../utils";
import { getServiceName } from "../../../global-functions/variables";

function CartOne() {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const { initiator } = appState.groupBillRes;
  const { allowLogin } = appState.storeInfo;
  const [newTotal, setNewTotal] = useState(0);
  // 1 => required, 2 => no-required, 3 => skip
  // console.log("allowLoginCart",allowLogin)
  const appDispatch = useDispatch();
  let history = useHistory();
  const [formData, setFormData] = useState(
    JSON.parse(localStorage.getItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_form`)) !== null
      ? JSON.parse(localStorage.getItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_form`))
      : {}
  );
  const [orders, setOrders] = useState(
    appState.cart !== null
      ? appState.cart
      : JSON.parse(localStorage.getItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_cart`))
      ? JSON.parse(localStorage.getItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_cart`))
      : null
  );
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mealTypeStatus, setMealTypeStatus] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [addOrder, setAddOrder] = useState(false);
  const [checkDineIn, setCheckDineIn] = useState(false);
  const [dineInID, setDineInID] = useState(null);
  const [createLoading, setCreateLoading] = useState(false);
  const [addOrderLoading, setAddOrderLoading] = useState(false);
  const [createOrderLoading, setCreateOrderLoading] = useState(false)
  const handelChange = () => {
    appDispatch({
      type: "SHOW_ALERT",
      alertType: "error",
      message: `Minimum order should be ${
        appState.storeInfo.storeMinimumOrder
      } ${t(`${appState.storeInfo.storecurrency}`)}`,
    });
  };

  const getMetaFromDineIn = async () => {
    if (appState.groupId) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_group/${appState.groupId}`
        )
        .then((response) => {
          if (response.data.success === true) {
            var res = response.data.data.service.meta;
            var amount = response.data.data.service.amount;

            setDineInID(response.data.data.id);
            let allTotal = Number(appState.total) + Number(amount);
            setNewTotal(allTotal);
            var meta = appState.meta;
            if (res) {
              for (let i = 0; i < res.length; i++) {
                meta.push(res[i]);
              }
            }
          }
          setIsLoading(false);
          appDispatch(freezOff());
        });
    } else if (false) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_login/${appState.userInfo.customerId}/${appState.qrBranch}`
        )
        .then((response) => {
          if (response.data.success === true) {
            var res = response.data.data.service.meta;
            setDineInID(response.data.data.id);

            var meta = appState.meta;
            if (res) {
              for (let i = 0; i < res.length; i++) {
                meta.push(res[i]);
              }
            }
          }
          setIsLoading(false);
          appDispatch(freezOff());
        });
    } else {
      let customeLink = !appState.shopMode.includes("share-bill")
        ? `${appState.deviceId}/${appState.qrBranch}`
        : `${appState.shareBill.deviceId}/${appState.shareBill.branchId}`;
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_device/${customeLink}`
        )
        .then((response) => {
          if (response.data.success === true) {
            var res = response.data.data.service.meta;
            var amount = response.data.data.service.amount;
            var meta = appState.meta;
            setDineInID(response.data.data.id);
            let allTotal = Number(appState.total) + Number(amount);
            setNewTotal(allTotal);
            if (res) {
              for (let i = 0; i < res.length; i++) {
                meta.push(res[i]);
              }
            }
          }
          setIsLoading(false);
          appDispatch(freezOff());
        });
    }
  };
  useEffect(() => {
    if (appState.loggedIn && orderPlaced) {
      setCreateLoading(true);
      setCreateOrderLoading(true)
      const checkCreateOrder = () => {
        if (orderPlaced && appState.loggedIn) {
          if (Boolean(appState.openBill)) {
            setCreateLoading(true);
            setCreateOrderLoading(true)
            
              let result = createBill(
                appState,
                appDispatch,
                history,
                orders,
                formData,
                dineInID,
                setCreateOrderLoading
              );
              setOrderPlaced(false);
            
            
          } else {
            history.push("/checkout");
          }
        }
      };
      checkCreateOrder();
    } else if (appState.loggedIn && addOrder && Boolean(appState.openBill)) {
      addOpenBill();
      setAddOrder(false);
      setIsLoading(true);
    }

    // if(appState.loggedIn && checkDineIn){
    //   getDineIn(false)
    //   setCheckDineIn(false)
    // setIsLoading(true);
    // }
  }, [appState.loggedIn]);
  useEffect(() => {
    setCreateLoading(appState.freezScreen)
  }, [appState.freezScreen])
  

  useEffect(() => {
    if (!Boolean(appState.openBill)) {
      let data = {
        ...formData,
        order_invoice: appState.hotelQrCode
          ? "hotel_order"
          : appState.qrPickup
          ? "pick_up"
          : "dine_in",
      };
      appDispatch({
        type: "SET_FORM_DATA",
        formData: data,
      });
      localStorage.setItem(
        `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
        JSON.stringify(data)
      );
    } else if (!appState.formData.openBill && Boolean(appState.openBill)) {
      setOrderPlaced(true);
    }
    appDispatch(handleSetMeta(appState));

    if (appState.formData.openBill && appState.loggedIn && appState.qrTable) {
      getMetaFromDineIn();
    }
  }, []);

  const addOpenBill = async () => {
    if (
      appState.loggedIn ||
      appState.shopMode.includes("share-bill") ||
      appState.groupId
    ) {
      setIsLoading(true);
      setAddOrderLoading(true);
      appDispatch(freezOn());
      setAddOrderLoading(true);
      var meta = appState.meta;
      for (let i = 0; i < orders.length; i++) {
        meta.push(orders[i]);
      }
      await axios
        .post(`${process.env.REACT_APP_API_URL}/order_invoice/add_open_bill`, {
          table_id: appState.qrTable,
          branch_id: appState.qrBranch,
          device_id: appState.shopMode.includes("share-bill")
            ? appState.shareBill.deviceId
            : appState.deviceId,
          customer_id: appState.shopMode.includes("share-bill")
            ? appState.shareBill.customerId
            : appState.loggedIn
            ? appState.userInfo.customerId
            : null,
          group_id: appState.groupId != null ? appState.groupId : null,
          meta: meta ?? null,
          orders: handleOrdersPayload(orders),
          dine_in_id: dineInID,
          discount:
            appState.storeDiscount != null
              ? handleStoreDiscount(appState, appDispatch, newTotal) <
                appState?.storeDiscount?.discount_max
                ? appState?.storeDiscount?.discount_percent
                : appState?.storeDiscount?.discount_max
              : 0,
          discount_type:
            appState.storeDiscount != null
              ? handleStoreDiscount(appState, appDispatch, newTotal) <
                appState?.storeDiscount?.discount_max
                ? "percent"
                : "fixed"
              : "",
        })
        .then((response) => {
          if (response.data.success === true) {
            var products = appState.products;
            for (var i = 0; i < products.length; i++) {
              for (let j = 0; j < orders.length; j++) {
                if (orders[j].product_id === products[i].id) {
                  if (products[i].quantity != null) {
                    products[i].quantity =
                      products[i].quantity - orders[j].quantity;
                  }
                }
              }
            }
            appDispatch({
              type: "setMeta",
              meta: meta,
            });
            appDispatch({ type: "SET_PRODUCTS", products: products });
            localStorage.removeItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_cart`);
            appDispatch({ type: "updateCart", cart: [] });
            appDispatch({ type: "updateSubTotal", subTotal: 0 });
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "success",
              message: response.data.message,
            });
            if (appState.qrTable) {
              getDineIn(true);
            }

            setAddOrderLoading(false);
          } else if (response.data.success === false) {
            appDispatch(freezOff());
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `${response.data.data + response.data.message}`,
            });
            localStorage.setItem(
              `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
              JSON.stringify([])
            );
            appDispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
            setAddOrderLoading(false);
          }
        })

        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "warning",
              message: "Please check your network.",
            });
          } else {
            appDispatch(handleError(error));
          }
          setIsLoading(false);
          setAddOrderLoading(false);

          // appDispatch(freezOff());
        });
    } else {
      appDispatch({ type: "setLoginModal", loginModal: true });
    }
  };

  const getDineIn = async (status) => {
    appDispatch(freezOn());
    if (appState.groupId) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_group/${appState.groupId}`
        )
        .then((response) => {
          if (response.data.success === true) {
            appDispatch({
              type: "SET_OPEN_ORDER",
              openOrder: response.data.data,
            });
            setDineInID(response.data.data.id);
            var amount = response.data.data.service.amount;
            let allTotal = Number(appState.total) + Number(amount);
            setNewTotal(allTotal);
          } else if (response.data.success === false) {
            localStorage.removeItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_form`);
          }
          if (status) {
            history.push("/openorder");
          } else {
            setAddOrder(true);
          }
          setIsLoading(false);
          appDispatch(freezOff());
        })
        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: "Please check your network.",
            });
          }
          setOrderPlaced(true);
          console.log(error);
          setIsLoading(false);
          appDispatch(freezOff());
          localStorage.setItem(
            `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
            JSON.stringify([])
          );
          appDispatch({
            type: "SET_FORM_DATA",
            formData: [],
          });
        });
    } else if (false) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_login/${appState.userInfo.customerId}/${appState.qrBranch}`
        )
        .then((response) => {
          if (response.data.success === true) {
            setDineInID(response.data.data.id);
            appDispatch({
              type: "SET_OPEN_ORDER",
              openOrder: response.data.data,
            });
          } else if (response.data.success === false) {
            localStorage.removeItem("formData");
          }
          if (status) {
            history.push("/openorder");
          } else {
            setAddOrder(true);
          }
          setIsLoading(false);
          appDispatch(freezOff());
        })
        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "warning",
              message: "Please check your network.",
            });
          }
          setOrderPlaced(true);
          console.log(error);
          setIsLoading(false);
          appDispatch(freezOff());
        });
    } else {
      let customeLink = !appState.shopMode.includes("share-bill")
        ? `${appState.deviceId}/${appState.qrBranch}`
        : `${appState.shareBill.deviceId}/${appState.shareBill.branchId}`;
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_device/${customeLink}`
        )
        .then((response) => {
          if (response.data.success === true) {
            var amount = response.data.data.service.amount;
            let allTotal = Number(appState.total) + Number(amount);
            setNewTotal(allTotal);
            setDineInID(response.data.data.id);
            appDispatch({
              type: "SET_OPEN_ORDER",
              openOrder: response.data.data,
            });
          } else if (response.data.success === false) {
            localStorage.removeItem("formData");
          }
          if (status) {
            history.push("/openorder");
          } else {
            setAddOrder(true);
          }
          setIsLoading(false);
          appDispatch(freezOff());
        })
        .catch((error) => {
          if (error == undefined) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "warning",
              message: "Please check your network.",
            });
          }
          // else {
          //   appDispatch(handleError(error));
          // }
          setOrderPlaced(true);
          console.log(error);
          setIsLoading(false);
          appDispatch(freezOff());
          localStorage.setItem(
            `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
            JSON.stringify([])
          );
          appDispatch({
            type: "SET_FORM_DATA",
            formData: [],
          });
        });
    }
  };


  const handlechange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
    appDispatch({
      type: "SET_FORM_DATA",
      formData: formData,
    });
    localStorage.setItem(
      `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
      JSON.stringify(formData)
    );
  };

  const handleCheckStartEndTime = () => {
    var isClosedStore = appState.isClosed;
    if (isClosedStore === 0) {
      if (appState.hotelObj) {
        let days = appState.hotelObj?.days;
        let start = appState.hotelObj?.starting;
        let end = appState.hotelObj?.ending;

        isClosedStore = compareStartAndEndTime(days, start, end) ? 0 : 1;
      }
      if (isClosedStore === 0) {
        history.push("/checkout");
      } else {
        console.log('history.push("/")');
        history.push("/");
      }
      appDispatch({ type: "SET_ISCLOSED", isClosed: isClosedStore });
    }
  };


  const handleClick = () => {
    setCreateLoading(true);
    setCreateOrderLoading(true)
    if ( appState.qrRequired || appState.hotelQrCode || appState.qrPickup) {
      if (appState.loggedIn || appState.groupId) {
        if (Boolean(appState.openBill)) {
          setIsLoading(true);
          createBill(
            appState,
            appDispatch,
            history,
            orders,
            formData,
            setCreateLoading
          );
          setOrderPlaced(false);
        } else {
          handleCheckStartEndTime();
        }
      } else if (Boolean(appState.openBill)) {///
        appDispatch({ type: "setLoginModal", loginModal: true });
        // setCheckDineIn(true);
      } /// reomover
       else {
        handleCheckStartEndTime();
      }
    } else {
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: `${t("custom-msg.scane-qrcode")}`,
      });
    }
  };

  const handleOpenBill = () => {
    if (appState.pickUp || appState.qrRequired) {
      history.push("/openorder");
    } else {
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: "No services are avalaible on this time.",
      });
    }
  };

  const handleOrderAgain = async () => {
    for (var i = 0; i < appState.products.length; i++) {
      if (orderId === appState.products[i].id) {
        var response = await appState.products[i];
      }
    }
  };
  useEffect(() => {
    if (searchParams.get("orderid")) {
      let orderId = searchParams.get("orderid");
      setOrderId(orderId);
    }
    handleOrderAgain();
    // if (appState.qrTable && (appState.openBillId || appState.formData.openBill)) {
    if (appState.qrTable && appState.openBill) {
      getDineIn(false);
    }
    // console.log(orderId)
  }, []);

  const arLanguageHandle = (e) => {
    // console.log("ar change");
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");

    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  useEffect(() => {
    if (!appState.loginModal) {
      // setCreateLoading(false);
    }
  }, [appState.loginModal]);


  

  if (loading)
    return (
      <>
        <div className="row mx-1">
          <div className="col-1 col-md-1 px-2">
            <Skeleton height={50} />
          </div>
          <div className="col-3 col-md-2 px-2">
            <Skeleton height={50} />
          </div>
        </div>
        <div className="row mx-1 d-flex justify-content-between">
          <div className="col-3 col-md-2 px-2">
            <Skeleton height={35} />
            <Skeleton height={35} />
          </div>
          <div className="col-3 col-md-2 px-2">
            <Skeleton height={35} />
            <Skeleton height={35} />
          </div>
        </div>

        {[0, 1, 2].map((a) => (
          <div key={a} className="row m-0 mb-4 mt-3">
            <div className="col-3 col-md-2 px-2">
              <Skeleton variant="rect" className="prRectSkeleton" />
            </div>
            <div className="col-9 col-md-10 px-2">
              <Skeleton
                height={30}
                style={{
                  transform: "translate(2px, 0)",
                  marginBottom: "10px",
                }}
              />
              <Skeleton height={25} />
              <Skeleton height={25} />
            </div>
          </div>
        ))}
      </>
    );

  return (
    <>
      <Page title="Cart">
        <div
          id="wrapper"
          className={`${appState.langValue === "ar" ? "ml-2" : ""} `}
          // style={{ display:'flex',flexDirection:"column",minHeight:"96vh" }}
        >
          <section
            id="topNav"
            className={
              `${appState.langValue === "ar" ? 'pl-2' : ''} row m-0 fixed-top  justify-content-center align-items-center bg-white `
            }
          >
            <span className="col-12  col-xl-6 d-flex align-item-center justify-content-between">

            <div className="d-flex justify-content-between align-items-center">
              <Link to="/">
                <IconButton
                  aria-label="back"
                  className={
                    appState.langValue === "ar"
                      ? "backBtnArBorderLess"
                      : "backBtnBorderLess"
                  }
                  // orderbckBtn"
                >
                  {appState.langValue === "ar" ? (
                    <ArrowForwardIosIcon
                      style={{ padding: "0px !important" }}
                      fontSize="inherit"
                    />
                  ) : (
                    <ArrowBackIosIcon fontSize="inherit" />
                  )}
                </IconButton>
              </Link>
            </div>

            <div className="pl-1 mt-2">
              <h5 className="text-capitalize familyPoppins600">
                <strong> {t("cart.cart")}</strong>
              </h5>
            </div>

            {appState.langValue == "ar" ? (
              <IconButton id="headerBtn" onClick={(e) => enLanguageHandle(e)}>
                <span className={`lang-textScroll p-0`}>EN</span>
              </IconButton>
            ) : (
              <IconButton
                id="headerBtn"
                onClick={(e) => arLanguageHandle(e)}
                className="arabicBtn"
              >
                <span className={`arabicText lang-textScroll p-0 mb-1`}>
                  عربي{" "}
                </span>
              </IconButton>
            )}
            </span>

          </section>

          <section id="cartItems" className="pt-5 mx-2">
            {appState.cart.length > 0 && (
              <div className="py-3">
                <h4
                  className={`text-capitalize familyPoppins700 ${
                    appState.langValue === "ar" ? "text-right" : "text-left"
                  }`}
                >
                  <strong> {t("cart.Cart-summary")}</strong>
                </h4>
              </div>
            )}

            <div className="row m-0">
              {/* {console.log(appState.cart,'cart')} */}
              <div className="col-12 pb-5 px-0">
                {appState.cart.length > 0 ? (
                  appState.cart.map((item, ind) => (
                    <div className="cartItem " key={ind}>
                      <CartItemv2 item={item} />

                      {appState.cart.length > 1 ? (
                        appState.cart.length === ind + 1 ? (
                          ""
                        ) : (
                          <hr />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  ))
                ) : (
                  <div className="d-flex mt-5 align-items-center justify-content-center flex-column">
                    <img className="img-pro" src={`/images/Frame.png`} alt="" />
                    <h3 className="text-center">
                      {" "}
                      {t("cart.sorry-your-cart-is-empty")}!
                    </h3>
                    <p> {t("cart.Return-to-the-menu-for-order")}</p>
                  </div>
                )}
              </div>
            </div>
          </section>
          {appState.cart.length > 0 && (
            <>
              <section className="noteAndTotal w-100 my-5 mx-2">
              </section>
              <div className="row">
                <section
                  id="btnSection"
                  className={`${
                    appState.langValue === "ar" ? "ml-auto" : "mr-auto"
                  } col-12 offset-xl-3 mr-auto col-xl-6 bottomFixedBtn py-2`}
                >
                  <div className="CartTotals py-3 d-flex align-items-center justify-content-between  ">
                    <h5 className="d-flex familyPoppins400">
                      {" "}
                      {t("cart.Total")}
                    </h5>
                    <h5
                      className={`px-2 familyPoppins700 ${
                        appState.langValue === "ar" ? "text-right" : "text-left"
                      }`}
                    >
                      <strong>
                        {parseNumber(
                          appState.total,
                          appState.storeInfo.numberFixed
                        )}{" "}
                        &nbsp;
                        {get_lang(
                          appState.storeInfo.storeCurrency,
                          appState.langValue
                        )}
                      </strong>
                    </h5>
                  </div>

                  <div className="row px-2 pb-3">
                    <div className="col-5 d-none px-1 ">
                      <Button
                        size="small"
                        className="add-more h-100 w-100 text-capitalize"
                        startIcon={<AddIcon />}
                        id="addMore"
                        component={Link}
                        to="/"
                      >
                        <span className="px-1"> {t("cart.Add-More")}</span>
                      </Button>
                    </div>
                    <div className="col-12 px-1 ">
                 
                      {appState.deviceId &&
                      appState.formData.openBill &&
                      appState.qrTable  && appState.openBill ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="btn-main w-100 text-capitalize"
                          id="payNow"
                          onClick={addOpenBill}
                          disabled={addOrderLoading}
                        >
                          {t("cart.Order-Now")}
                        </Button>
                      ) : appState.total >=
                          appState.storeInfo.storeMinimumOrder ||
                        appState.storeInfo.storeMinimumOrder == undefined ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="btn-main w-100 text-capitalize"
                          onClick={handleClick}
                          id="payNow"
                          // disabled={(appState.cart.length > 0 ? false : true) || (isLoading)}
                          disabled={createLoading || isLoading || createOrderLoading}
                        >
                          {t("cart.Order-Now")}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="btn-main w-100 text-capitalize"
                          onClick={handelChange}
                          id="payNow"
                          disabled={appState.cart.length > 0 ? false : true}
                        >
                          {t("cart.Order-Now")}
                        </Button>
                      )}
                    </div>
                    {/* if table id is present so show the open order button */}
                    {appState.storeInfo.benchId > 0 ? (
                      <div className="col-12 align-self-center d-flex my-2">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="btn-main w-100 text-capitalize"
                          id="payNow"
                          onClick={handleOpenBill}
                        >
                          {t("cart.Open-Order")}
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
              </div>
            </>
          )}

          {appState.cart.length <= 0 && (
            <>
              <div className="row">
                <section
                  id="btnSection"
                  className={`${
                    appState.langValue === "ar" ? "ml-auto" : "mr-auto"
                  } col-12 col-xl-6 offset-xl-3 bottomFixedBtn py-2`}
                >
                  <div className="row">
                    <div className="col-12 ">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="btn-main w-100 text-capitalize"
                        component={Link}
                        to="/"
                      >
                        {t("cart.Start-Ordering")}
                      </Button>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
        </div>
      </Page>
    </>
  );
}

export default withTranslation()(CartOne);
