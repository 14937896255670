/* eslint-disable */
import axios from "axios";
import {
  closeGroupBill,
  createGroupBill,
  freezOff,
  freezOn,
  handleError,
  handleSuccess,
  popShopMode,
  pushShopMode,
} from ".";
import { getCookie } from "../../global-functions/cookieFunction";
import e from "cors";
import { isNotEmpty } from "../../utils";
import { dataFromat, stringDateFromate } from "../../utils/genral-functions";
import { loyalityCardCustomLimit } from "../../global-functions/loyalityCardCustomLimit";

export const createGroupBillApi = (state) => {
  return async (dispatch) => {
    dispatch(freezOn());
    let body = {
      customer_id: state.loggedIn
        ? state.userInfo != []
          ? state.userInfo.customerId
          : null
        : null,
      table_id: state.qrTable,
      device_id: state.deviceId,
    };
    let resData = [];
    await axios
      .post(`${process.env.REACT_APP_API_URL}/group_bill/create`, body,{
        headers: {
          ContentType: 'application/json',
        }
      })
      .then((response) => {
        if (response.data.success === true) {
          resData = response.data.data;
          dispatch(
            createGroupBill(response.data.data.id, state.storeInfo.slug)
          );
          dispatch({
            type: "setGroupBillRes",
            groupBillRes: response.data.data,
          });
        }
        dispatch(freezOff());
        dispatch(handleSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(handleError(error));
        dispatch(freezOff());
      });
    return resData;
  };
};

export const addMemeberGroupBillApi = (state, id) => {
  return async (dispatch) => {
    dispatch(freezOn());
    let body = {
      customer_id: state.loggedIn
        ? state.userInfo != []
          ? state.userInfo.customerId
          : null
        : null,
      device_id: state.deviceId,
    };
    let resData = [];
    await axios
      .post(`${process.env.REACT_APP_API_URL}/group_bill/add/${id}`, body)
      .then((response) => {
        if (response.data.success === true) {
          resData = response.data.data;
          // dispatch({
          //   type: "setGroupBillRes",
          //   groupBillRes: response.data.data,
          // });
        }
        dispatch(freezOff());
        dispatch(handleSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(handleError(error));
        dispatch(freezOff());
      });
    return resData;
  };
};

export const getGroupBill = (state, slug, id, handleGroupState) => {
  let groupBranch = "";
  let groupTable = "";
  return async (dispatch) => {
    dispatch(freezOn());
    await axios
      .get(`${process.env.REACT_APP_API_URL}/group_bill/${id}`,{
        headers: {
          ContentType: 'application/json',
        }
      })
      .then((response) => {
        if (response.data.success === true) {
          let res = response.data.data.dine_in;
          let members = response.data.data.members;
          groupTable = response.data.data?.table?.id;
          groupBranch = response.data.data?.branch?.id;
          if (members.length > 0) {
            let findMember = members.some((member) => {
              return member.device_id == state.deviceId;
            });
            if (!findMember) {
              dispatch(addMemeberGroupBillApi(state, id));
            }
          }
          if (res == null) {
            dispatch(createGroupBill(id, slug));
            dispatch(handleSuccess(response));
            dispatch({
              type: "setGroupBillRes",
              groupBillRes: response.data.data,
            });
          } else if (res.isClosed) {
            dispatch(closeGroupBill(slug));
            dispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `Your group is closed.`,
            });
            dispatch({
              type: "setGroupBillRes",
              groupBillRes: [],
            });
          } else if (!res.isClosed) {
            dispatch(createGroupBill(id, slug));
            dispatch(handleSuccess(response));
            dispatch({
              type: "setGroupBillRes",
              groupBillRes: response.data.data,
            });
          }
          // dispatch(createGroupBill(response.data.data.id, state.storeInfo.slug))
        }
        dispatch({
          type: "SET_TABLE",
          qrTable: groupTable,
        });
        dispatch({
          type: "SET_BRANCH",
          qrBranch: groupBranch,
        });
        dispatch(freezOff());

      })

      .catch((error) => {
        console.log(error);
        dispatch(handleError(error));
        dispatch(freezOff());
      });
    return { groupTable: groupTable, groupBranch: groupBranch };
  };
};

export const getDineInOrders = (state, setId, setInvoiceId) => {
  return async (dispatch) => {
    let formData = { ...state.formData, openBill: Boolean(state.openBill) };
    if (state.groupId) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_group/${state.groupId}`,
          {
            headers: {
              ContentType: 'application/json',
            }
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            const res = response.data.data;
            dispatch({
              type: "SET_FORM_DATA",
              formData: { ...state.formData, openBill: true },
            });
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify({ ...state.formData, openBill: true })
            );
          } else if (response.data.success === false) {
            dispatch(closeGroupBill(state.storeInfo.slug));
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        })
        .catch((error) => {
          // dispatch(closeGroupBill(state.storeInfo.slug))

          if (error == undefined) {
            console.log("errorMessage", error);
          } else {
            // dispatch(handleError(error));
            // setIsLoading(false);

            // dispatch(closeGroupBill(slug))
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        });
    } else if (false) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_login/${state.userInfo.customerId}/${state.qrBranch}`,
          {
            headers: {
              ContentType: 'application/json',
            }
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            const res = response.data.data;
            dispatch({
              type: "SET_FORM_DATA",
              formData: formData,
            });
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify(formData)
            );
          } else if (response.data.success === false) {
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        })
        .catch((error) => {
          dispatch(closeGroupBill(state.storeInfo.slug));
          if (error == undefined) {
            console.log("errorMessage", error);
          } else {
            // dispatch(handleError(error));
            // setIsLoading(false);

            // dispatch(closeGroupBill(slug))
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        });
    } else {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/order_invoice/get_dine_in_device/${state.deviceId}/${state.qrBranch}`,{
            headers: {
              ContentType: 'application/json',
            }
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            const res = response.data.data;
            if (setId) {
              setInvoiceId(res.service?.invoice_id);
            }
            dispatch({
              type: "SET_FORM_DATA",
              formData: { ...state.formData, openBill: true, order_invoice: "open_bill" },
            });
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify({ ...state.formData, openBill: true, order_invoice: "open_bill" })
            );
          } else if (response.data.success === false) {
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        })
        .catch((error) => {
          if (error == undefined) {
            console.log("errorMessage", error);
          } else {
            // dispatch(handleError(error));
            // setIsLoading(false);

            // dispatch(closeGroupBill(slug))
            localStorage.setItem(
              `${state.storeInfo.slug}_form`,
              JSON.stringify([])
            );
            dispatch({
              type: "SET_FORM_DATA",
              formData: [],
            });
          }
        });
    }
  };
};


export const getOrderInvoice = async (id, dispatch, state) => {
  let flag = true;
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/order_invoice/${id}`,
      {
        headers: {
          Response: `DineIn`, // geting response same as getdine
          ContentType: 'application/json',
        },
      }
    );
    if (res.data.success) {
      let getRes = res.data.data;
      let data = {};
      if (!getRes?.isClosed) {
        data = {
          invoiceId: getRes?.service?.invoice_id,
          deviceId: getRes?.device_id,
          openBill: getRes?.isOpen,
          groupBill: getRes?.groupBill,
          tableId: getRes?.table_id,
          branchId: getRes?.branch?.id,
          customerId: getRes?.customer_id,
          countryCode: getRes?.country_code,
          email: getRes?.email,
          number: getRes?.number,
          name: getRes?.full_name,
        };
        dispatch({
          type: "SET_FORM_DATA",
          formData: { ...state.formData, openBill: true },
        });
        localStorage.setItem(
          `${state.storeInfo.slug}_form`,
          JSON.stringify({ ...state.formData, openBill: true })
        );
        dispatch({
          type: "SET_TABLE",
          qrTable: data?.tableId,
        });
        dispatch({
          type: "SET_BRANCH",
          qrBranch: data?.branchId,
        });
        dispatch({
          type: "setShareBill",
          shareBill: data,
        });

        if (!state.shopMode.includes("share-bill")) {
          dispatch({
            type: "setShopMode",
            shopMode: [...state.shopMode, "share-bill"],
          });
        }
        return data;
      } else {
        dispatch({
          type: "SET_FORM_DATA",
          formData: [],
        });
        localStorage.removeItem(`${state.storeInfo.slug}_form`);
        dispatch({
          type: "SET_TABLE",
          qrTable: null,
        });
        dispatch({
          type: "SET_BRANCH",
          qrBranch: null,
        });
        dispatch({
          type: "setShareBill",
          shareBill: [],
        });

        dispatch({
          type: "setShopMode",
          shopMode: [],
        });
        return null;
      }
    }
  } catch (error) {
    console.error("Error updating:", error);
    return null;
  }
};

export const getLoyaltyCards = async (state, dispatch, branch) => {
  let flag = false;
  let getBranch = branch  ?  branch : state.qrBranch;

  const helperFunction = (rewards) => {
    let totalPunchs =  rewards?.punch?.total;
    let collectedPunches = rewards?.punch?.collected;
    return  collectedPunches < totalPunchs ? true : false ;
  };


  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/loyalty_card?branch=${getBranch}`,
      {
        headers: {
          Authorization: `Bearer ${getCookie("tokenAuth")}`,
          ContentType: 'application/json',
        },
      }
    );
    let res = response.data.data;
    let customeObj = [];
    if (response.data.success) {
      res.forEach((ele) => {
        if (ele.active && !flag) {
          flag = true
        }
         var isLarger = (ele.start_at !== null && ele.end_at !== null)? dataFromat(ele.start_at, ele.end_at ) : false;
        // var isLarger = stringDateFromate(ele.start_at, ele.end_at );
        var customerLimit = loyalityCardCustomLimit(ele?.customer_limit?.limit,ele?.customer_limit?.count)      
        let rewards = ele?.reward;
        customeObj.push({
          show: isLarger && customerLimit,
          id: ele?.id,
          active: ele?.active,
          punchable: helperFunction(rewards),
          for: ele?.for,
          loyaltyName: ele?.name,
          description: ele?.description != '' ? ele?.description : null,
          loyaltyLogo: ele?.logo,
          loyaltybgImage: ele?.bg_image,
          primaryColor: ele?.primary_color,
          secondaryColor: ele?.secondary_color,
          rewards: ele?.reward,
          redeemable: ele?.reward?.redeemable,  
          conditions: ele?.conditions,
        });
      });
      dispatch({
        type: "setLoyaltyObj",
        loyaltyObj: customeObj,
      });

    }
    dispatch(freezOff())

  } catch (error) {
    console.error("Error updating:", error);
    dispatch({
      type: "setLoyaltyObj",
      loyaltyObj:[],
    });
    return null;
  }
};




export const  getLoyaltyCardDiscount = async (state, dispatch, id, currentOrders,) => {
  let branch = state.qrBranch;
  let arr = [...state.rewardsDetails]

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/loyalty_card/get_reward`, {

      branch_id: branch,
      loyalty_card_id: id,
      orders: currentOrders
    },
      {
        headers: {
          Authorization: `Bearer ${getCookie("tokenAuth")}`,
          ContentType: 'application/json',
        },
      }
    );

    if (response.data.success) {
      let data = state.loyaltyObj.find((item) => item.id == id)
      let discount = await response.data.data
      if(isNotEmpty(discount)){
        data = {...data, rewardDiscount: discount}
        arr.push(data)
        return data      
      }
      else{
        return null
      }
     
    }
  } catch (error) {
    console.warn("Error updating:", error);
    return null;
  }
};


export const  getOpenBillFeaturedProducts = async (state, dispatch,) => {
  let branchId = state.qrBranch;

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/product/open_bill/${branchId}`,{
        headers: {
          ContentType: 'application/json',
        }
      }
    );
     let arr = []
    if (response.data.success) {
      let res = response.data.data;

      if(res.length>0){

        // res = res[0]?.products

        res.forEach(ele => {
          ele?.products.forEach(product => {
            let data = {... product,
              pathUrl: `/${product?.category?.slug}/${product?.slug}/?prodid=${product?.id}`
            }
            arr.push(data)
          });
         
        });
      }
      else{
        arr = null
      }
      dispatch({
        type: "setOpenBillFeatured",
        openBillFeatured: arr,
      });
     
    }
  } catch (error) {
    console.warn("Error updating:", error);
    return null;
  }
};