/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./verifyPhoneNumber.scss";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import enabill from "../Assets/images/enabill.png";
import Backdrop from "@mui/material/Backdrop";
// axios
import axios from "axios";

//  cookie functions
import { setCookie } from "../../global-functions/cookieFunction";
import { withTranslation, useTranslation } from "react-i18next";
// import Redux
import { useSelector, useDispatch } from "react-redux";
import { freezOn, freezOff, getSaveCard } from "../../redux-store/action";

import CircularIndeterminate from "../../layout-one/js/components/CircularIndeterminate";

import OtpInput from 'react-otp-input';
// import OTPInput, { ResendOTP } from "otp-input-react";
// import SignupModal from "../signupModal/signupModal";

function VerifyPhoneNumber({
  check_status,
  Purpose,
  phone,
  setFormStatus,
  handleSubmit
}) {
  const [OTP, setOTP] = useState("");
  const { t } = useTranslation();
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");
  const [loading, setLoading] = useState(false);
  const appDispatch = useDispatch();
  const appState = useSelector((state) => state);
  const [sendAgain, setSendAgain] = useState(true)
  const [timer, setTimer] = useState(60)
  const handleClose = () => {
    appDispatch({ type: "setOtpModal", otpModal: false });
  };





  useEffect(() => {
    if (!sendAgain) {
      var timeleft = 59;
      var downloadTimer = setInterval(function () {
        if (timeleft <= 0) {
          clearInterval(downloadTimer);
          setSendAgain(true)
        }
        setTimer(timeleft)
        timeleft -= 1;
      }
        , 1000);
    }
  }, [sendAgain])

console.log(appState.otpModal)

  useEffect(() => {
    const handleCheckOtp = async () => {
      setLoading(true);
      appDispatch(freezOn());
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/check/guest/otp`,
          {
            country_code: `${appState.fieldValues.phoneCode}`,
            number: `${appState.fieldValues.phoneNumber}`,
            code: OTP,
          }
        );
        if (response.data.success === true) {
          setLoading(false);
          appDispatch(freezOff());
          setOTP("");
          if (check_status) {
            setCookie("tokenAuth", response.data.data.token, 365);
          } else {
            setCookie("tokenAuth", response.data.data.token);
          }
          setCookie("Profile", JSON.stringify(response.data.data));
          appDispatch({
            type: "SET_USERINFO",
            userInfo: response.data.data,
          });
          // console.log(response.data.data);
          // localStorage.setItem(`userInfo`, JSON.stringify(response.data.data));
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "success",
            message: response.data.message,
          });
          let token = await response.data.data.token;

          appDispatch({
            type: "SET_LOGIN",
            loggedIn: true,
            loginToken: token,
          });
          handleClose();
        } else if (response.data.success === false) {
          setLoading(false);
          appDispatch(freezOff());
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: response.data.message,
          });
          appDispatch({ type: "logout", loggedIn: false });
        }
      } catch (e) {
        // // console.log(e);
        setLoading(false);
        appDispatch(freezOff());
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: e.message,
        });
      }
    };
    if (OTP.length === 5) {
      handleCheckOtp();
    }
  }, [OTP]);

useEffect(() =>{
  if(appState.loggedIn === true){
    appDispatch(getSaveCard(appState))
  }
},[appState.loggedIn])

  const handleSendOtp = async () => {
    setLoading(true);
    appDispatch(freezOn());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/send/guest/otp`,
        {
          mobile_no:  `${phone.country_code}${phone.number}`,
        }
      );
      if (response.data.success === true) {
        setLoading(false);
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "success",
          message: response.data.message,
        });
      } else if (response.data.success === false) {
        setLoading(false);
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: response.data.message,
        });
      }
      setSendAgain(false)
    } catch (e) {
      // console.log(e);
      setLoading(false);
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: e.message,
      });
      setSendAgain(false)
      
  }
  };

  const handleChangeNumber = () => {
    if (Purpose === "signIn") {
      appDispatch({ type: "setOtpModal", otpModal: false });
      appDispatch({ type: "setLoginModal", loginModal: true });
    } else if (Purpose === "signUp") {
      appDispatch({ type: "setOtpModal", otpModal: false });
      appDispatch({ type: "setSignUpPhoneModal", signUpPhoneModal: true });
    }
  };

  return (
    <div>
      {/* {appState.signUpModal && <SignupModal signUpForm={signUpForm} handleSignUpForm={handleSignUpForm}/>} */}
      <Dialog
        id="verifyModal"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        // open={appState.otpModal}
        // BackdropProps={{ invisible: true }}
        // onClose={handleClose}

      >
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularIndeterminate />
          </Backdrop>
        )}
       

        <DialogContent>
          <div
            className={`${appState.langValue === "ar" ? "directionRtl" : ""}`}
            id="verifyPhoneNumberMain"
          >
            <div className="row pt-4">
              <div className="col-md-12 d-flex justify-content-between classforPaddinglogo">
                <img src={enabill} alt="enabill-logo" />
              </div>
            </div>
            <h1
              className={`familyManrope800 mt-3 ${
                appState.langValue === "ar" ? "d-flex" : ""
              }`}
            >
              {t("Forms.verify-number")}
            </h1>
            <p
              className={`familyManrope600 loginTitle m-0 ${
                appState.langValue === "ar" ? "d-flex text-right" : ""
              }`}
            >
              {t("Forms.enter-code")}223232
              {/* +{phone.phoneCode} {phone.phoneNumber} */}
            </p>
            <div
              className={`optMain mt-3 ${
                appState.langValue === "ar" ? "classforArabic" : ""
              }`}
            >
              {/* SIKANDAR OTP */}
              {/* <OtpInput
                value={otpValue}
                inputStyle={true}
                onChange={otpFunction}
                className={`${otpValue ? "otpValue" : ""} otpInput`}
                numInputs={5}
                isInputNum={true}
                hasErrored={true}
                shouldAutoFocus={true}
                placeholder={true}
                placeholder="_____"
              /> */}
              {/* SAjid work */}
         
              <OtpInput
               value={OTP}
               inputStyle={true}
               onChange={setOTP}
               className={`${OTP ? "otpValue" : ""} otpInput`}
               numInputs={5}
               isInputNum={true}
               hasErrored={true}
               shouldAutoFocus={true}
              //  placeholder={true}
              placeholder={`_____`}
              />
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <p className="familyManrope700 notRegisterMain m-0">
                  <span className="notRegister d-flex">
                    {t("Forms.did-not-recieve")}?
                    {sendAgain ? <p onClick={handleSendOtp} className="cursor-pointer px-1">Send Again</p> : <p  className="px-1"> {timer} sec </p>}
                  </span>
                </p>
                <p
                  className={`familyManrope700 notRegisterMain mt-2 ${
                    appState.langValue === "ar" ? "d-flex" : ""
                  }`}
                >
                  <a
                    className="createAccountAndforgotPass"
                    onClick={handleChangeNumber}
                  >
                    sfds
                    {t("Forms.differ-number")}
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default withTranslation()(VerifyPhoneNumber);
