export const checkHotelLoginRequired = (getCookie_HotelInfo) => {
    // if (getCookie_HotelInfo != null || undefined) {
    //     let allowLogin = getCookie_HotelInfo?.settings?.allow_login?.id ?? null
    //     return allowLogin
    // } else {
    //     return 2;
    // }
    return 1
};

export const checkNameRequired = (getCookie_HotelInfo) => {
    // if (getCookie_HotelInfo != null || undefined) {
    //     let nameRequired = getCookie_HotelInfo?.settings?.name_required ?? null;
    //     return nameRequired
    // } else {
    //     return null;
    // }
    return true
};