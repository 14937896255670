/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

// Material Ui
import { Button, useMediaQuery } from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import Category from "../components/Category";


// axios for fetching api
import axios from "axios";
// import components
import HomeBanner from "../components/HomeBanner";
import Page from "../components/Page";
import StoreOption from "../components/StoreOption";

import config from "react-reveal/globals";
// Global Fucntions
import { get_lang } from "../../../global-functions/apiGlobal";
// redux
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { checkQrRequired, createGroupBill } from "../../../redux-store/action";
import ReviewModalv2 from "../components/reviewModalv2/reviewModalv2";
import SwipeableEdgeDrawer from "../components/qrCodeOptions/QrCodeOptions";

import Backdrop from "@mui/material/Backdrop";
import ReOrder from "../components/reOrder/reOrder";
import noImg from "../../js/assets/images/no-image.svg";
import PayTablePopUp from "../components/payTablePopup/PayTablePopUp";
import CheckoutBtn from "../components/checkoutBtn/CheckoutBtn";
import StartGroupOrderBtn from "../components/startGroupOrderBtn/startGroupOrderBtn";
import GroupOrderInvite from "../components/groupOrderInvite/groupOrderInvite";

import { v4 as uuidv4 } from "uuid";
import {
  createGroupBillApi,
  getDineInOrders,
  getGroupBill,
} from "../../../redux-store/action/apiCall";
import CopyText from "../components/copyText/copyText";
import SubCategory from "../components/SubCategory";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { IconButton } from "@material-ui/core";
import LoyalitySlider from "../components/loyalitySlider/loyalitySlider";
import MiscBanner from "../components/MiscBanner";
import { getServiceName } from "../../../global-functions/variables";
import TipPopup from "../../../sharedComponent/tipPopup/tipPopup";
import CheckoutShortCutPopup from "../components/checkoutShortCutPopup/checkoutShortCutPopup";

const useStyles = () => ({
  root: {
    background: "black",
  },
});

config({ ssrZoomout: true });

function HomeOne({ oldInvoice }) {
  const classes = useStyles();
  const { t } = useTranslation();

  // Variables
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [table, setTable] = useState("");
  const [pickup, setPickup] = useState('')
  const [formData, setFormData] = useState(
    JSON.parse(localStorage.getItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_form`)) !== null
      ? JSON.parse(localStorage.getItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_form`))
      : ""
  );
  let history = useHistory();
  const [branchName, setBranchName] = useState("");
  const [groupState, setGroupState] = useState(false);
  const matches = useMediaQuery("(min-width:991px)");
  const [subId, setSubId] = useState("Sub id goes here");
  // const [notificationDelay, setNotificationDelay] = useState(5)
  // const [notificationTtl, setNotificationTtl] = useState(0)

  const openBillCheckout = () => {
    localStorage.setItem(
      `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
      JSON.stringify({ ...formData, order_invoice: "open_bill" })
    );
    appDispatch({
      type: "SET_FORM_DATA",
      formData: formData,
      order_invoice: "open_bill",
    });
    history.push(`/checkout${appState.orderQrCode}`);
  };

  const handleGroupBillMode = () => {
    if (!appState.loggedIn) {
      appDispatch({ type: "setLoginModal_Screen", loginModalScreen: true });
      setGroupState(true);
    }
    if (appState.loggedIn) {
      appDispatch(createGroupBillApi(appState));
    }
  };

  useEffect(() => {
    if (appState.qrTable) {
      appDispatch(checkQrRequired(appState, t));
    }
  }, [appState.qrTable]);

  useEffect(() => {
    const getBranchTable = () => {
      if (Boolean(appState.qrBranch)) {
        let flag = true;
        if (appState.storeInfo.branch != null) {
          let branchData = appState.storeInfo.branch;
          if (branchData.id == appState.qrBranch) {
            setBranchName(branchData.name);
            appDispatch({
              type: "setBranch",
              branch: branchData.name,
            });
          }
        }
        if (appState.storeInfo.pickup != null) {
          let pickupData = appState.storeInfo.pickup;
          if (pickupData.id == appState.qrPickup) {
            setPickup(pickupData.name);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } 
        else if (appState.storeInfo.table != null) {
          let tableData = appState.storeInfo.table;
          if (tableData.id == appState.qrTable) {
            setTable(tableData.name);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      }
    };
    if (appState.qrBranch) {
      getBranchTable();
    }
  }, [appState.qrBranch]);

  useEffect(() => {
    // if (appState.qrBranch && appState.qrTable && !appState.payTableMode && (appState.openBillId || appState.formData.openBill)) {
    if (
      appState.qrBranch &&
      appState.qrTable &&
      !appState.payTableMode &&
      !appState.shopMode.includes("share-bill") &&
      appState.openBill
    ) {
      appDispatch(getDineInOrders(appState, false));
    }
  }, [appState.groupId]);

  useEffect(() => {
    if (appState.loggedIn && groupState) {
      appDispatch({ type: "setgroupOrderPopup", groupOrderPopup: true });
      setGroupState(false);
    }
  }, [appState.loggedIn]);

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const scrolling = () => {
      const handleScroll = () => {
        setScroll(window.scrollY > 0);
      };

      window.addEventListener("scroll", handleScroll);

      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    };

    appDispatch({
      type: "setScrollKeyword",
      scrollKeyword: "",
    });

    scrolling();
    appDispatch({
      type: "SET_FEATURED_PRODUCTS",
      featuredProductPopupMode: false,
    });
    appDispatch({
      type: "setOptionalRewardsPopUp",
      optionalRewardsPopUp: false,
    });
  }, []);

  const handleSourceOfMeet = () => {
    appDispatch({
      type: "setSourceOfMeetBanner",
      sourceOfMeetBannerImage: true,
    });
  };

  const handleRedirectCheckout = ()=>{
    history.push("/quickcheckout");
  }

  useEffect(() => {
    if(appState.serviceMode){ 
      console.log('object');
      appDispatch({
        type: "setPayTablePopUp",
        payTablePopUp: appState.qrTable && appState.serviceMode.includes('pay-table') ? true : false,
      });
      
    }
  }, [appState.serviceMode, appState.qrTable] )
  

  return (
    <Page title="Home">
      {/* <Notifications /> */}
      {appState.reviewModal && <ReviewModalv2 />}
      {appState.qrOptionStatus && !appState.payTableMode && (
        <SwipeableEdgeDrawer oldInvoice={oldInvoice} />
      )}
      {appState.payTableMode && appState.payTablePopUp && (

        <PayTablePopUp table={table} />
      )}

      <div
        id="wrapper"
        className={` ${appState.qrOptionStatus ? "pointer-events-non " : ""}`}
      >
        <HomeBanner image={appState.storeInfo.cover} />

        <section id="info">
          {/* <div
            className={scroll > 0 ? `top_nav_bar_scroll` : `payTableBackBtn`}
          >
            {appState.payTableMode &&
              !appState.payTablePopUp &&
              appState.qrTable &&
              matches && (
                <div className="row  m-0 searhIcon" position="static">
                  <div className="d-flex align-items-center col-2 px-1">
                    <IconButton
                      onClick={() =>
                        appDispatch({
                          type: "setPayTablePopUp",
                          payTablePopUp: true,
                        })
                      }
                      aria-label="back"
                      className={`backBtn ${
                        appState.langValue === "ar" ? "backBtnAr" : ""
                      }`}
                    >
                      {appState.langValue == "ar" ? (
                        <ArrowForwardIosIcon
                          style={{ padding: 0 }}
                          fontSize="inherit"
                        />
                      ) : (
                        <ArrowBackIosIcon fontSize="inherit" />
                      )}
                    </IconButton>
                  </div>
                </div>
              )}
          </div> */}

          <div className="infoContent">
         
            
            <div className="row m-0 align-items-center">
              <div className="logoImg px-1">
                <img
                  src={
                    appState.storeInfo.logo
                      ? `${appState.storeInfo.logo}`
                      : { noImg }
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="infoContent pl-2 m-0 p-0">
                <h5 className="m-0 text-capitalize">
                  <b>
                    {get_lang(
                      appState.storeInfo.businessName,
                      appState.langValue
                    )}
                  </b>{" "}
                  {appState.qrBranch != null && (
                    <p
                      className={` text-secondary  display-6 ${
                        appState.langValue === "ar" ? "text-right" : "text-left"
                      }`}
                    >
                      {get_lang(branchName, appState.langValue)}
                    </p>
                  )}
                </h5>

                <p className="m-0 text-muted">
                  {appState.storeInfo.description &&
                    appState.storeInfo.description}
                </p>
              </div>
              <div
                className={` d-flex ${
                  appState.langValue === "ar"
                    ? "infoIcon infoIconOnArabic mr-auto mx-0 p-0"
                    : "infoIcon  ml-auto mx-0 p-0"
                }`}
              >
                {appState.sourceOfMeetBanner && (
                  <Link to="/" className="p-0 m-0">
                    <Button
                      variant="outlined"
                      className="infoBtn mx-2"
                      startIcon={<i className="fa fa-seedling"></i>}
                      onClick={() => handleSourceOfMeet()}
                    >
                      <span className={appState.langValue === "ar" ? "" : ""}>
                        {" "}
                        {t("side-bar.source-of-meet")}
                      </span>
                    </Button>
                  </Link>
                )}
                <Link to="/contact" className="p-0 m-0">
                  <Button
                    variant="outlined"
                    className="infoBtn"
                    startIcon={<InfoIcon />}
                  >
                    <span className={appState.langValue === "ar" ? "" : ""}>
                      {" "}
                      {t("contact.info")}
                    </span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* <Button onClick={getReviewWidget}>Get Review</Button> */}
        {/* Store Options */}
        {appState.qrBranch && <StoreOption isLoading={isLoading} table={table}  pickup={pickup}/>}

        {(
      appState.loyaltyObj.length > 0 ) && <LoyalitySlider callingFrom="home" />}
        {appState.loggedIn &&
          appState.storeInfo.progressive == 1 &&
          !appState.payTableMode && <ReOrder />}
        {appState.serviceMode.includes("group-bill") &&
        appState.groupId == null &&
        !appState.groupBillMode &&
        !appState.formData.openBill ? (
          <StartGroupOrderBtn handleClick={handleGroupBillMode} />
        ) : (
          appState.serviceMode.includes("group-bill") &&
          appState.qrTable &&
          appState.groupBillMode && <GroupOrderInvite />
        )}

        <section id="Products">
          {/* New Changes May-20 */}
          <div className="row m-0 pb-5 mb-5">
            {appState.categories.length > 0 ? (
              <React.Fragment>
                {appState.storeInfo.subCategory
                  ? appState.parentCategories.map((category, index) => (
                      <Category
                        category={category}
                        key={index}
                        urlLink={category.childLink}
                      />
                    ))
                  : appState.categories.map((category, index) => (
                      <Category
                        category={category}
                        key={index}
                        urlLink={category.urlLink}
                      />
                    ))}
              </React.Fragment>
            ) : (
              <div className="text-center w-100 mt-5">
                <h4 className="text-muted text-center">
                  "No product available in store"
                </h4>
              </div>
            )}
          </div>
        </section>
        <div className="selectLocaiton">
        {<>{!appState.payTableMode && appState.isAddToCartEnable ? (
            <section id="reviewOrderButton" className="m-0 mt-5 p-0">
              {appState.formData.openBill &&
              appState.cart.length <= 0 &&
              !appState.hotelQrCode ? (
                <div className="reviewOrdButton   col-md-12 col-xl-6 offset-xl-3 mr-auto">
                  <Button
                    onClick={openBillCheckout}
                    variant="contained"
                    className={`btn btn-block btn-main text-capitalize w-100 py-3 px-4 mt-3 viewMenuBtn d-flex justify-content-between ${
                      appState.langValue === "ar"
                        ? " familyGraphikArabic600"
                        : ""
                    }`}
                  >
                    <span>{t("checkOut.Confirm-and-pay")}</span>
                    <div className="iconsParent d-flex justify-content-center">
                      {appState.acceptedPayments.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {item === "knet" ? (
                              <span className=" iconsBg " key={index}>
                                <img
                                  className="img-pro"
                                  // src={`/images/Knet-small.svg`}
                            // src={`/images/Knet.png`}
                            src={`/images/pat-knet.svg`}


                                  alt=""
                                />
                              </span>
                            ) : (
                              ""
                            )}

                            {item === "card" ? (
                              <span className="iconsBg" key={index}>
                                <img
                                  className="img-pro"
                                  // src={`/images/visa-small.png`}
                            src={`/images/visa-small.png`}

                                  alt=""
                                />
                              </span>
                            ) : (
                              ""
                            )}
                            {item == "apple_pay" ? (
                              <span key={index} className="iconsBg">
                                <img
                                  className="img-pro"
                                  width="50"
                                  // src={`/images/apple-pay-late-2.svg`}
                            src={`/images/apple-pay.png`}

                                  alt=""
                                />
                              </span>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </Button>
                </div>
              ) : appState.cart.length <= 0 ? (
                ""
              ) : (
                <>
                  <div className="reviewOrdButton   col-md-12 offset-xl-3 mr-auto  col-xl-6">
                    <Button
                      variant="contained"
                      className="btn btn-block btn-main d-flex align-items-center justify-content-between text-capitalize"
                      component={Link}
                      to={appState.cart.length > 0 && "/cart"}
                    >
                      <div className="itemCounter2">{appState.cart.length}</div>

                      {t("home.Review-order")}

                      <div className="priceTag2">
                        {appState.total != null && appState.total}
                        {/* {appState.total.toFixed(3)} */}
                        <span>
                          &nbsp;
                          {get_lang(
                            appState.storeInfo.storeCurrency,
                            appState.langValue
                          )}
                        </span>
                      </div>
                    </Button>
                  </div>
                </>
              )}
            </section>
          ) : (
            <>
              {appState.payTableMode && appState.total > 0 && (

                  <CheckoutBtn label={'Pay-the-Bill'}  handleCheckout={()=>history.push(`/checkout${appState.orderQrCode}`)}/>
                  
              )}
            </>
          )}
                </>
         }
        </div>
      </div>
    </Page>
  );
}

export default withTranslation()(HomeOne);
