// hooks
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import noImg from "../assets/images/no-image.svg";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { handleFeaturedProduct } from "../../../redux-store/action";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import {
  handleAddonsQtyByProduct,
  handleStockQuantity,
} from "../../../global-functions/addToCartGlobal";
import { get_lang } from "../../../global-functions/apiGlobal";

export const useFeaturedProductIncDec = (
  type,
  placedBy,
  featuredProducts,
  setfeaturedProduct
) => {
  //States:
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const { mSlug, slug, islug } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  let parent_id = searchParams.get("parent");
  let flag = true;

  ///// handleFunctions:
  const addToCart = async (featuredProduct) => {
    let currentFeaturedProduct = featuredProduct;
    if (appState.cart.length > 0 && currentFeaturedProduct) {
      let newCart = [...appState.cart];
      const itmIndx = newCart.findIndex(
        (el) =>
          // el?.parent_product_id === product?.id &&
          el?.product_id == featuredProduct?.id &&
          el.placed_by == placedBy &&
          el.pGroupId == appState.productGroupId
      );
      let filterCart = appState.cart.find(
        (el, i) =>
          // el?.parent_product_id === product?.id &&
          el?.product_id == featuredProduct?.id &&
          el.placed_by == placedBy &&
          el.pGroupId == appState.productGroupId
      );
      if (filterCart) {
        newCart[itmIndx].maxQuantity = featuredProduct.maxQuantity;
        newCart[itmIndx].quantity = featuredProduct.quantity;
        appDispatch({ type: "updateCart", cart: newCart });
      }
      console.log(newCart);
      handleStockQuantity(newCart, featuredProduct, appDispatch, appState);
      console.log("addTocart");
    }
  };



  const handleIncrement = (featuredProduct) => {
    if (featuredProduct.quantity == 0) {
      featuredProduct.checkedCart = true;
    }
    let currentFeaturedProduct = featuredProduct;
    let products = type == "open-bill" ? appState.openBillFeatured : featuredProducts
    let featuredIndx = products.findIndex(
      (x) => x.id == currentFeaturedProduct.id
    );
    let fProd = [...featuredProducts];

    if (
      currentFeaturedProduct.quantity <= currentFeaturedProduct.maxQuantity ||
      currentFeaturedProduct.maxQuantity === null
    ) {
      currentFeaturedProduct.quantity++;
      fProd[featuredIndx] = currentFeaturedProduct;
      setfeaturedProduct(fProd);
      addToCart(fProd[featuredIndx]);
    } else {
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: `${get_lang(
          currentFeaturedProduct.name,
          appState.langValue
        )} is out of stock!`,
      });
    }
  };

  const handleDecrement = (featuredProduct) => {
    let currentFeaturedProduct = featuredProduct;
    let products = type == "open-bill" ? appState.openBillFeatured : featuredProducts
    let featuredIndx = products.findIndex(
      (x) => x.id == currentFeaturedProduct.id
    );
    console.log("object,", featuredIndx);
    // console.log(featuredIndx, currentFeaturedProduct,featuredProducts);
    let fProd = [...featuredProducts];

    if (currentFeaturedProduct.quantity > 0) {
      currentFeaturedProduct.quantity--;
      if (currentFeaturedProduct.quantity == 1) {
        currentFeaturedProduct.checkedCart = false;
      }
      fProd[featuredIndx] = currentFeaturedProduct;
      setfeaturedProduct(fProd);
      addToCart(fProd[featuredIndx]);
    }
  };

  const handleItemRemove = (featuredProduct) => {
    // handleDecrement(featuredProduct);
	featuredProduct.checkedCart = false;
  featuredProduct.addOnsTotal = 0
    let newCart = [...appState.cart];
    const itmIndx = newCart.findIndex(
      (el) =>
        // el?.parent_product_id === product?.id &&
        el?.product_id == featuredProduct?.id &&
        el.placed_by == placedBy &&
        el.pGroupId == appState.productGroupId
    );
    newCart.splice(itmIndx, 1);
    appDispatch({ type: "updateCart", cart: newCart });
    // setCheckCart(false)
    console.log(newCart, "handle remove", itmIndx);
  };

  ///// useffects
  // useEffect(() => {
  // 	if(flag){
  // 		handleOnLoad()
  // 		flag = !flag
  // 	}
  // }, [appState.cart]);
  // useEffect(() => {
  // 	addToCart();
  // 	// console.log(quantityValue);
  // }, [quantityValue, featuredProduct]);

  // return { featuredProduct, handleIncrement, handleDecrement, handleItemRemove };
  return { handleIncrement, handleDecrement, handleItemRemove };
};
