/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// Material Ui
import { Button } from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import Category from "../components/Category";

// axios for fetching api
import axios from "axios";
// import components
import HomeBanner from "../components/HomeBanner";
import Page from "../components/Page";
import StoreOption from "../components/StoreOption";

import config from "react-reveal/globals";
// Global Fucntions
import { get_lang } from "../../../global-functions/apiGlobal";
// redux
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { checkQrRequired, createGroupBill } from "../../../redux-store/action";
import ReviewModalv2 from "../components/reviewModalv2/reviewModalv2";
import SwipeableEdgeDrawer from "../components/qrCodeOptions/QrCodeOptions";

import Backdrop from "@mui/material/Backdrop";
import ReOrder from "../components/reOrder/reOrder";
import noImg from "../../js/assets/images/no-image.svg";
import PayTablePopUp from "../components/payTablePopup/PayTablePopUp";
import CheckoutBtn from "../components/checkoutBtn/CheckoutBtn";
import StartGroupOrderBtn from "../components/startGroupOrderBtn/startGroupOrderBtn";
import GroupOrderInvite from "../components/groupOrderInvite/groupOrderInvite";

import { v4 as uuidv4 } from "uuid";
import {
  createGroupBillApi,
  getDineInOrders,
  getGroupBill,
} from "../../../redux-store/action/apiCall";
import CopyText from "../components/copyText/copyText";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import MiscBanner from "./MiscBanner";
import { Tab } from "bootstrap";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { AppBar } from "@mui/material";
import { getServiceName } from "../../../global-functions/variables";


const useStyles = () => ({
  root: {
    background: "black",
  },
});

config({ ssrZoomout: true });

function SubCategory({  }) {
  const classes = useStyles();
  const { t } = useTranslation();
  var { slug } = useParams();
  var slug = slug.trim();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [id, setId] = useState(0)
  const [categoryImg, setCategoryImg] = useState('')
  let parent_id = searchParams.get('parent')
  const [parentValue, setParentValue] = useState(0)
const [activeVTabs, setActiveVTabs] = useState(0)
  // Variables
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [table, setTable] = useState("");
  const [parentSlug, setParentSlug] = useState('')
  const [formData, setFormData] = useState(
    JSON.parse(localStorage.getItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_form`)) !== null
      ? JSON.parse(localStorage.getItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_form`))
      : ""
  );
  let history = useHistory();
  const [branchName, setBranchName] = useState("");
  const [groupState, setGroupState] = useState(false);
  let pSlug = ''
  const openBillCheckout = () => {
    localStorage.setItem(
      `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
      JSON.stringify({ ...formData, order_invoice: "open_bill" })
    );
    appDispatch({
      type: "SET_FORM_DATA",
      formData: formData,
      order_invoice: "open_bill",
    });
    history.push(`/checkout${appState.orderQrCode}`);
  };

  const handleGroupBillMode = () => {
    if (!appState.loggedIn) {
      appDispatch({ type: "setLoginModal_Screen", loginModalScreen: true });
      setGroupState(true);
    }
    if (appState.loggedIn) {
      appDispatch(createGroupBillApi(appState));
    }
    // if (!appState.groupId) {
    //   console.log("object home", appState.groupId);
    //   let group_id = uuidv4();
    //   let id = `${group_id}gb`;
    //   appDispatch(createGroupBill(id, appState.storeInfo.slug));
    // }
  };

  useEffect(() => {
    appDispatch(checkQrRequired(appState, t));
  }, [appState.qrTable]);

  useEffect(() => {
    const getBranchTable = () => {
      if (Boolean(appState.branchName)) {
        let flag = true;
        if (appState.storeInfo.branch != null) {
          let branchData = appState.storeInfo.branch;
          if (branchData.id == appState.qrBranch) {
            setBranchName(branchData.name);
            appDispatch({
              type: "setBranch",
              branch: branchData.name,
            });
          }
        }
        if (appState.storeInfo.table != null) {
          let tableData = appState.storeInfo.table;
          if (tableData.id == appState.qrTable) {
            setTable(tableData.name);
            setIsLoading(false);
          }
        }
      }
    };
    getBranchTable();
  }, []);

  useEffect(() => {
    if(appState.groupId){
      appDispatch(getDineInOrders(appState, false));
    }
  }, [appState.groupId]);

  useEffect(() => {
    if (appState.loggedIn && groupState) {
      appDispatch({ type: "setgroupOrderPopup", groupOrderPopup: true });
      setGroupState(false);
    }
  }, [appState.loggedIn]);
  const getIdRes = async () => {
    for (var i = 0; i < appState.categories.length; i++) {
      if (slug === appState.categories[i].slug) {
        var idValue = await appState.categories[i].id;
        setId(idValue);
        setActiveVTabs(i);
      }
    }
    if(appState.storeInfo.subCategory)
    appState.parentCategories.length > 0 && appState.parentCategories.forEach((category,ind) => {
     
      if(category.id == parent_id)    {
        setCategoryImg(category.image);
        setParentSlug(category.slug)
        pSlug = category.slug
        setParentValue(ind)
      }  
    });
  };

  useEffect(() => {
    getIdRes();
  }, [slug]); 

    // Tabs work
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      // Tabs work
      const handleParentsChange = (event, newValue) => {
        setParentValue(newValue);
        setValue(0)
        // // console.log("handlechageValue", value);
      };
      const handleClick = (e, Link, id) => {
        e.preventDefault();
        setIsLoading(true);
        history.push(
          Link
        );
      };
  return (
    <Page title="Home">
      {appState.reviewModal && <ReviewModalv2 />}
    
      {appState.payTableMode && appState.payTablePopUp && (
        <PayTablePopUp table={table} />
      )}

      <div
        id="wrapper"
        className={` ${appState.qrOptionStatus ? "pointer-events-non " : ""}`}
      >
          <MiscBanner image={categoryImg} urlLink={`/`}  ></MiscBanner>

       
       
          {/* <div id="tabs_sec" className="tabsSec">
            <AppBar className="appBar" position="static">

              {appState.storeInfo.subCategory  &&     <Tabs
                value={parentValue}
                onChange={handleParentsChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
                aria-label="scrollable auto tabs example"
                className={`pt-4 layoutFourtabs ${appState.langValue === "ar"
                  ? "direction-ltr"
                  : ""
                  }`}
              >


                {appState.parentCategories.map((category, index) =>
                  category.has_products || category.products.length > 0 > 0 ? (
                    <Tab
                      label={category.name != null ? get_lang(category.name, appState.langValue) : "Not Available"}
                      onClick={(e) => handleClick(e, category.urlLink)}
                      key={index}
                      id={`tab_section_${index}`}
                      className="familyManrope700 text-capitalized"
                      disabled={index == parentValue}
                      icon={<img src={category.image?category.image:noImg} />}

                    />
                  ) : (
                    ""
                  )
                )}
              </Tabs>}
          
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  style: { backgroundColor: "#00acee" },
                }}
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
                className={`pt-2 layoutonetabs ${appState.langValue === "ar" ? "direction-ltr" : ""
                  }`}
              >
                {appState.categories.map((category, index) =>
                
                  category.has_products || category.products.length > 0  ? (
                    appState.storeInfo.subCategory ? 
                    category?.parentId == parent_id &&
                    <Tab
                      label={
                        category.name != null
                          ? get_lang(category.name, appState.langValue)
                          : "Not Available"
                      }
                      className="category_tabs text-uppercase"
                      onClick={(e) =>
                        handleClick(e, category.urlLink)
                      }
                      key={index}
                      id={`tab_section_${index}`}
                      disabled={index == activeVTabs}
                    >
                      </Tab>
                    
                    :
                    <Tab
                    label={
                      category.name != null
                        ? get_lang(category.name, appState.langValue)
                        : "Not Available"
                    }
                    className="category_tabs text-uppercase"
                    onClick={(e) =>
                      handleClick(e, category.urlLink)
                    }
                    key={index}
                    id={`tab_section_${index}`}
                    disabled={index == value}
                  />
                    
                  ) : (
                    ""
                  )
                )}
              </Tabs>
            </AppBar>
          </div> */}
        <section id="Products">
          {/* New Changes May-20 */}
          <div className="row m-0 pb-5 mb-5">
            {appState.categories.length > 0 ? (
              <React.Fragment>
                {appState.storeInfo.subCategory ? 
                appState.categories.map((category, index) => (
                    category?.parentId == parent_id &&
                  <Category category={category} key={index}  urlLink={category.urlLink}  />
                ))
                :
                 appState.categories.map((category, index) => (
                  <Category category={category} key={index} urlLink={category.urlLink}/>
                ))
                }
              </React.Fragment>
            ) : (
              <div className="text-center w-100 mt-5">
                <h4 className="text-muted text-center">
                  "No product available in store"
                </h4>
              </div>
            )}
          </div>
        </section>

        <div className="selectLocaiton">
          {!appState.payTableMode && appState.isAddToCartEnable ? (
            <section id="reviewOrderButton" className="m-0 mt-5 p-0">
              {appState.formData.openBill &&
              appState.cart.length <= 0 &&
              appState.isAddToCartEnable ? (
                <div className="reviewOrdButton   offset-xl-3 mr-auto col-xl-6">
                  <Button
                    onClick={openBillCheckout}
                    variant="contained"
                    className={`btn btn-block btn-main text-capitalize w-100 py-3 px-4 mt-3 viewMenuBtn d-flex justify-content-between ${
                      appState.langValue === "ar"
                        ? " familyGraphikArabic600"
                        : ""
                    }`}
                  >
                    <span>{t("checkOut.Confirm-and-pay")}</span>
                    <div className="iconsParent d-flex justify-content-center">
                      {appState.acceptedPayments.map((item, index) => {
                        return (
                          <>
                            {item === "knet" ? (
                              <span className=" iconsBg ">
                                <img
                                  className="img-pro"
                                  src={`/images/Knet-small.svg`}
                                  alt=""
                                />
                              </span>
                            ) : (
                              ""
                            )}

                            {item === "card" ? (
                              <span className="iconsBg">
                                <img
                                  className="img-pro"
                                  src={`/images/visa-small.png`}
                                  alt=""
                                />
                              </span>
                            ) : (
                              ""
                            )}
                            {item == "apple_pay" ? (
                              <span key={index} className="iconsBg">
                                <img
                                  className="img-pro"
                                  width="50"
                                  src={`/images/apple-pay-late-2.svg`}
                                  alt=""
                                />
                              </span>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </div>
                  </Button>
                </div>
              ) : appState.cart.length <= 0 ? (
                ""
              ) : (
                <>
                  <div className="reviewOrdButton   offset-xl-3 mr-auto col-xl-6">
                    <Button
                      variant="contained"
                      className="btn btn-block btn-main d-flex align-items-center justify-content-between text-capitalize"
                      component={Link}
                      to={appState.cart.length > 0 && "/cart"}
                    >
                      <div className="itemCounter2">{appState.cart.length}</div>

                      {t("home.Review-order")}

                      <div className="priceTag2">
                        {appState.total != null && appState.total}
                        {/* {appState.total.toFixed(3)} */}
                        <span>
                          &nbsp;
                          {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                        </span>
                      </div>
                    </Button>
                  </div>
                </>
              )}
            </section>
          ) : (
            <>
              {appState.payTableMode && appState.total > 0 && (
                <section
                  id="reviewOrderButton"
                  className="m-0 p-0 mt-5 col-12 col-xl-5"
                >
                  <CheckoutBtn />
                </section>
              )}
            </>
          )}
        </div>
      </div>
    </Page>
  );
}

export default withTranslation()(SubCategory);
