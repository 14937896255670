/* eslint-disable no-useless-escape */
/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import StateContext from "../../../../store/StateContext";
import noImg from "../../assets/images/no-image.svg";
import usermar from "../../assets/images/usermar2.svg";
import clockImage from "../../assets/images/clockOpenBill.svg";

// Redux
import { useSelector, useDispatch } from "react-redux";
// global funtion for store
import { get_lang } from "../../../../global-functions/apiGlobal";
import { withTranslation, useTranslation } from "react-i18next";
import "./OrderProductv2.scss";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import { parseNumber } from "../../../../global-functions/roundFixed";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

const OrderProductv2 = ({ item, index, groupBillDetails }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const appState = useSelector((state) => state);
  const isAddons = item.sum_addons;
  return (
    <div className="orderProductv2Parent" id={item.prod_id}>
      {appState.serviceMode.includes("group-bill") &&
        appState.qrTable &&
        appState.groupBillMode && (
          <div className="row d-flex justify-content-end m-0">
            {item.orderOwner == "my_order" ? (
              <div
                className={` familyPoppins my-1    ${
                  appState.langValue === "ar"
                    ? "d-flex justify-content-end  direction-ltr pl-1 "
                    : "pr-1"
                }`}
              >
                <Chip
                  className={` ChipClassDanamic familyPoppins600   ${
                    index == 0
                      ? "chipOne"
                      : index % 2 == 0
                      ? "chipTwo"
                      : "chipThree"
                  }`}
                  label={`your order`}
                  icon={<img src={usermar} />}
                  variant="outlined"
                />
              </div>
            ) : item.orderOwner == null ? (
              <div
                className={` familyPoppins my-1    ${
                  appState.langValue === "ar"
                    ? "d-flex justify-content-end  direction-ltr pl-1 "
                    : "pr-1"
                }`}
              >
                <Chip
                  className={` ChipClassDanamic familyPoppins600   ${
                    index == 0
                      ? "chipOne"
                      : index % 2 == 0
                      ? "chipTwo"
                      : "chipThree"
                  }`}
                  label={`Anonimus`}
                  icon={<img src={usermar} />}
                  variant="outlined"
                />
              </div>
            ) : (
              <div
                className={` familyPoppins my-1    ${
                  appState.langValue === "ar"
                    ? "d-flex justify-content-end  direction-ltr pl-1 "
                    : "pr-1"
                }`}
              >
                <Chip
                  className={` ChipClassDanamic familyPoppins600   ${
                    index == 0
                      ? "chipOne"
                      : index % 2 == 0
                      ? "chipTwo"
                      : "chipThree"
                  }`}
                  label={item.orderOwner}
                  avatar={<Avatar>{item.orderOwner[0]}</Avatar>}
                  variant="outlined"
                />
              </div>
            )}
          </div>
        )}

      <div className="row m-0 d-flex align-items-start">
        <div className="px-0">
          <img
            src={item?.product?.image ? `${item?.product?.image}` : noImg}
            alt=""
            className="orderImage"
          />
        </div>
        <div
          className={` col-8 ${
            appState.langValue === "ar" ? "pr-3 pl-0 " : "pl-3 pr-0"
          }`}
        >
          <div className="row m-0 justify-content-between">
            <div className="col-10 col-sm-11 px-1 d-flex justify-content-between">
              <Typography gutterBottom variant="h5" component="h2">
                <strong className="toCapitialize d-flex familyPoppins600">
                  {item.product != null
                    ? get_lang(item.product?.name, appState.langValue)
                    : "Name is not available"}
                </strong>
              </Typography>
            </div>
            {/* <div className="col-2 col-sm-1 px-1">
                                <IconButton
                                    size="small"
                                    onClick={() => handleItemRemove(item)}
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </div> */}
          </div>
          <div className="row m-0 align-items-center justify-content-between">
            <div className="row m-0 align-items-center">
              <h5 className="text-muted m-0 familyPoppins400">
                {item.price
                  ? item.price !== ""
                    ? `${parseNumber(
                        Number(item.price),
                        appState.storeInfo.numberFixed
                      )}  ${get_lang(appState.storeInfo.storeCurrency, appState.langValue)}`
                    : `""  ${get_lang(appState.storeInfo.storeCurrency, appState.langValue)}`
                  : ""}
              </h5>
              <div className="mx-2 ">
                <span className="familyPoppins400 text-muted ">Qty</span>{" "}
                <span className="familyPoppins400 text-muted ">
                  {item.quantity}
                </span>
              </div>
            </div>
          </div>

          <div className="row m-0">
            <div
              className={` familyPoppins my-1    ${
                appState.langValue === "ar"
                  ? "d-flex justify-content-end  direction-ltr pl-1 "
                  : "pr-1"
              }`}
            >
              {item.status.includes("ROS") ? (
                <Chip
                  className="ChipClass ready familyPoppins600"
                  label={t("open-order.Ready")}
                  icon={
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 4.33333C7.5 4.05719 7.27614 3.83333 7 3.83333C6.72386 3.83333 6.5 4.05719 6.5 4.33333V7C6.5 7.16718 6.58355 7.32329 6.72265 7.41603L8.72265 8.74936C8.95241 8.90254 9.26285 8.84045 9.41603 8.61068C9.5692 8.38092 9.50711 8.07048 9.27735 7.91731L7.5 6.73241V4.33333Z"
                        fill="#4ac53b"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
                        fill="#4ac53b"
                      />
                    </svg>
                  }
                  variant="outlined"
                />
              ) : item.status.includes("Processing") ? (
                <Chip
                  className="ChipClass process familyPoppins600"
                  label={t("open-order.Processed")}
                  icon={
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 4.33333C7.5 4.05719 7.27614 3.83333 7 3.83333C6.72386 3.83333 6.5 4.05719 6.5 4.33333V7C6.5 7.16718 6.58355 7.32329 6.72265 7.41603L8.72265 8.74936C8.95241 8.90254 9.26285 8.84045 9.41603 8.61068C9.5692 8.38092 9.50711 8.07048 9.27735 7.91731L7.5 6.73241V4.33333Z"
                        fill="#61adff"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
                        fill="#61adff"
                      />
                    </svg>
                  }
                  variant="outlined"
                />
              ) : item.status.includes("Pending") ? (
                <Chip
                  className="ChipClass pending familyPoppins600"
                  label={t("open-order.Placed")}
                  icon={
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 4.33333C7.5 4.05719 7.27614 3.83333 7 3.83333C6.72386 3.83333 6.5 4.05719 6.5 4.33333V7C6.5 7.16718 6.58355 7.32329 6.72265 7.41603L8.72265 8.74936C8.95241 8.90254 9.26285 8.84045 9.41603 8.61068C9.5692 8.38092 9.50711 8.07048 9.27735 7.91731L7.5 6.73241V4.33333Z"
                        fill="#FFBA00"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
                        fill="#FFBA00"
                      />
                    </svg>
                  }
                  variant="outlined"
                />
              ) : item.status.includes("Completed") ? (
                <Chip
                  className="ChipClass ready familyPoppins600"
                  label={t("open-order.Ready")}
                  icon={
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 4.33333C7.5 4.05719 7.27614 3.83333 7 3.83333C6.72386 3.83333 6.5 4.05719 6.5 4.33333V7C6.5 7.16718 6.58355 7.32329 6.72265 7.41603L8.72265 8.74936C8.95241 8.90254 9.26285 8.84045 9.41603 8.61068C9.5692 8.38092 9.50711 8.07048 9.27735 7.91731L7.5 6.73241V4.33333Z"
                        fill="#4ac53b"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
                        fill="#4ac53b"
                      />
                    </svg>
                  }
                  variant="outlined"
                />
              ) : (
                <Chip
                  className="ChipClass rejected familyPoppins600"
                  label={t("open-order.Rejected")}
                  icon={
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 4.33333C7.5 4.05719 7.27614 3.83333 7 3.83333C6.72386 3.83333 6.5 4.05719 6.5 4.33333V7C6.5 7.16718 6.58355 7.32329 6.72265 7.41603L8.72265 8.74936C8.95241 8.90254 9.26285 8.84045 9.41603 8.61068C9.5692 8.38092 9.50711 8.07048 9.27735 7.91731L7.5 6.73241V4.33333Z"
                        fill="#f74e4e"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
                        fill="#f74e4e"
                      />
                    </svg>
                  }
                  variant="outlined"
                />
              )}
            </div>
          </div>
          {item?.note && (
            <div
              className={` addonsh4  ${
                appState.langValue === "ar"
                  ? "text-right "
                  : "text-left familyPoppins600 familyManrope40"
              }  text-capitalize`}
            >
              {t("checkOut.Notes")} :{" "}
              <span className="text-muted">
                {" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: item.note.replace(/\n/g, "<br>"),
                  }}
                />
              </span>
            </div>    
          )}
          <div
            className={` ${
              appState.langValue === "ar" ? "addonsWrapAr" : "addonsWrapEn"
            }`}
          >
            {!item.addons.length ? (
              ""
            ) : (
              <h5
                className={` pt-2 ${
                  appState.langValue == "ar" ? "text-right" : "text-left"
                }`}
              >
                {" "}
                {t("single-product.Extras")}
              </h5>
            )}

            {item.addons
              ? item.addons.map((itemAddon, ind) => {
                  return (
                    <div className="addonsMainDiv d-flex  pb-2" key={ind}>
                      <div className="d-flex">
                        <div className=" d-flex flex-column">
                          <span className="d-flex align-items-center addonsParent flex-wrap">
                            <h3
                              className={`addonsh3  ${
                                appState.langValue === "ar"
                                  ? "text-right"
                                  : "text-left familyPoppins600 familyManrope40"
                              }  text-capitalize`}
                            >
                              {get_lang(
                                itemAddon.product_addon.addon.name,
                                appState.langValue
                              )}
                            </h3>
                            {itemAddon.price > 0 && (
                              <FiberManualRecordIcon
                                className={`  ${
                                  appState.langValue === "ar" ? "" : ""
                                }`}
                              />
                            )}
                            <h3
                              className={`addonsh3   ${
                                appState.langValue === "ar"
                                  ? "text-right"
                                  : "text-left familyPoppins400 familyManrope40"
                              } `}
                            >
                              {itemAddon.price > 0 && (
                                <>
                                  {itemAddon.price != ""
                                    ? parseNumber(
                                        Number(itemAddon.price) *
                                          Number(itemAddon.quantity),
                                        appState.storeInfo.numberFixed
                                      )
                                    : "0"}{" "}
                                 {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                                </>
                              )}
                            </h3>
                            {itemAddon.price > 0 && (
                              <FiberManualRecordIcon
                                className={`  ${
                                  appState.langValue === "ar" ? "" : ""
                                }`}
                              />
                            )}
                            <h4
                              className={` addonsh4  ${
                                appState.langValue === "ar"
                                  ? "text-right "
                                  : "text-left familyPoppins600 familyManrope40"
                              }  text-capitalize`}
                            >
                              Qty {itemAddon.quantity}
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
        {/* <div className="col-2 px-0 statusContent">
          <span className={`${item.status == "Pending"
            ? "pendingColor-L1"
            : item.status == "Rejected"
              ? "rejectedColor-L1"
              : item.status == "Processing"
                ? "processColor-L1"
                : item.status == "ROS"
                  ? "readyColor-L1"
                  : ""
            } `}>
         
            {item.status.includes("Pending") && <i className="fas fa-exclamation-circle"></i>}
            {item.status.includes("ROS") && <i className="fas fa-check-circle"></i>}
            {item.status.includes("Processing") && <i className="fas fa-spinner"></i>}
            {item.status.includes("Rejected") && <i className="fas fa-times-circle"></i>}
            <p>
              {item.status.includes("ROS") ? t("open-order.Ready")
                : item.status.includes("Processing") ? t("open-order.Processed")
                  : item.status.includes("Pending") ? t("open-order.Placed")
                    : t("open-order.Rejected")
              }
            </p>
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default withTranslation()(OrderProductv2);
