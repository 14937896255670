
import React, {  useState, useEffect } from "react";
import {  Link, Redirect  } from "react-router-dom";
import LoginDialog from "../components/LoginModal";
import SignUpDialog from "../components/SignUpModal"
import axios from "axios"

// Material Ui
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from '@material-ui/core/InputAdornment';

//redux
import {useSelector, useDispatch} from 'react-redux';

// Styles
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    },
    button: {
        // margin: theme.spacing(1),
        // width: '100%'
    }
}));
const mystyle = {
    textAlign: "center",
    // padding: "40px 0"
};

function Detail() {
    // const inputProps = {
    //     step: 3,
    //   };
    const classes = useStyles();
    const appState = useSelector((state) => state);
    const appDispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const handleClickOpen = () => {
        console.log("consoling");
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpenRegister = () => {
        // console.log("consoling");
        handleClose()
        setOpenRegister(true);
    };
    const handleCloseRegister = () => {
        setOpenRegister(false);
    };

    const [name, setName] = useState(
        JSON.parse(localStorage.getItem("fields"))
            ? JSON.parse(localStorage.getItem("fields")).name
                ? JSON.parse(localStorage.getItem("fields")).name
                : ""
            : ""
    );
    const [email, setEmail] = useState(
        JSON.parse(localStorage.getItem("fields"))
            ? JSON.parse(localStorage.getItem("fields")).email
                ? JSON.parse(localStorage.getItem("fields")).email
                : ""
            : ""
    );
    const [phone, setPhone] = useState(
        JSON.parse(localStorage.getItem("fields"))
            ? JSON.parse(localStorage.getItem("fields")).phone
                ? JSON.parse(localStorage.getItem("fields")).phone
                : ""
            : ""
    );
    useEffect(() => {
        if (name || email || phone) {
            let fields = {
                ...appState.fields,
                name: name,
                email: email,
                phone: phone
            };
            appDispatch({ type: "UPDATE_FIELDS", fields: fields });
        }
    }, [name, phone, email]);
   
    return (
        <React.Fragment>
         <LoginDialog
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
            />

        <SignUpDialog
                open={openRegister}
                handleClickOpen={handleClickOpenRegister}
                handleClose={handleCloseRegister}

            />
                {/* <cart-component></cart-component>
    <authentication-component></authentication-component> */}
            {  !appState.loggedIn   ?  isLoading !== true ?
                            <div id="wrapper">
                                <div id="detail-page">
                <section id="topNav" className="fixed-top">
                    <nav className="bckNavbar offset-xl-3 mr-auto col-xl-6 d-flex align-items-center justify-content-between">


                    <Link to="/cart">
                                <IconButton aria-label="back" className='backBtn2'>
                                <ArrowBackIosIcon fontSize="inherit" />
                                </IconButton>
                            </Link>

                    <div></div>

                    </nav>

                </section>





                    <section id="details_section">
                        <div style={mystyle}>
                            <svg
                                height="100"
                                viewBox="0 -20 440.4 440"
                                width="100"
                            >
                                <path
                                    d="m440.398438 90.199219v290.859375c.003906 11.046875-8.953126 20.003906-20 20h-400.398438c-11.046875.003906-20.00390625-8.953125-20-20v-290.859375zm0 0"
                                    fill="#00efd1"
                                ></path>
                                <path
                                    d="m440.398438 20.199219v70h-440.398438v-70c-.00390625-11.046875 8.953125-20.003907 20-20h400.398438c11.046874-.003907 20.003906 8.953125 20 20zm0 0"
                                    fill="#00acea"
                                ></path>
                                <path
                                    d="m390.199219 29.171875h30v30h-30zm0 0"
                                    fill="#fedb41"
                                ></path>
                                <path
                                    d="m338.199219 29.171875h30v30h-30zm0 0"
                                    fill="#fedb41"
                                ></path>
                                <path
                                    d="m20.199219 29.171875h290v30h-290zm0 0"
                                    fill="#fedb41"
                                ></path>
                                <path
                                    d="m169.410156 253.28125c9.964844 0 19.464844 4.21875 26.144532 11.613281 6.683593 7.394531 9.921874 17.269531 8.914062 27.183594v56.480469h-149.570312v-56.480469c-1.003907-9.914063 2.234374-19.792969 8.917968-27.1875s16.1875-11.613281 26.152344-11.609375h26.890625v.039062l4.78125 19.660157h-.519531l-7.019532 44.550781v.007812l15.589844 15 16.578125-15-8.070312-44.550781-16.558594-.007812h16.089844l4.789062-19.660157v-.039062zm0 0"
                                    fill="#00acea"
                                ></path>
                                <path
                                    d="m162.429688 178.28125v26.207031c0 18.742188-15.191407 33.933594-33.929688 33.933594s-33.929688-15.191406-33.929688-33.933594v-25.648437c13.441407 2.238281 22.269532-4.589844 27.628907-12.410156h.011719c7.097656 10.410156 19.757812 20.628906 40.21875 11.851562zm0 0"
                                    fill="#f7caa5"
                                ></path>
                                <path
                                    d="m162.429688 170.699219v7.582031c-20.460938 8.777344-33.121094-1.441406-40.21875-11.851562-3.273438-4.84375-5.816407-10.144532-7.539063-15.730469h27.757813c11.042968.003906 19.996093 8.957031 20 20zm0 0"
                                    fill="#4b5d63"
                                ></path>
                                <path
                                    d="m138.199219 272.988281 8.070312 44.550781-16.578125 15-15.589844-15v-.007812l7.019532-44.550781h.519531zm0 0"
                                    fill="#fedb41"
                                ></path>
                                <path
                                    d="m142.519531 253.28125v.039062l-4.789062 19.660157h-16.089844l-4.78125-19.660157v-.039062zm0 0"
                                    fill="#fedb41"
                                ></path>
                                <path
                                    d="m122.210938 166.429688h-.011719c-5.359375 7.820312-14.1875 14.648437-27.628907 12.410156v-8.140625c.003907-11.042969 8.957032-19.996094 20-20h.101563c1.722656 5.585937 4.265625 10.886719 7.539063 15.730469zm0 0"
                                    fill="#4b5d63"
                                ></path>
                                <g fill="#fedb41">
                                    <path d="m243.199219 195.628906h82c3.316406 0 6-2.683594 6-6 0-3.3125-2.683594-6-6-6h-82c-3.3125 0-6 2.6875-6 6 0 3.316406 2.6875 6 6 6zm0 0"></path>
                                    <path d="m243.199219 225.628906h142c3.316406 0 6-2.683594 6-6 0-3.3125-2.683594-6-6-6h-142c-3.3125 0-6 2.6875-6 6 0 3.316406 2.6875 6 6 6zm0 0"></path>
                                    <path d="m385.199219 243.628906h-142c-3.3125 0-6 2.6875-6 6 0 3.316406 2.6875 6 6 6h142c3.316406 0 6-2.683594 6-6 0-3.3125-2.683594-6-6-6zm0 0"></path>
                                    <path d="m385.199219 273.628906h-142c-3.3125 0-6 2.6875-6 6 0 3.316406 2.6875 6 6 6h142c3.316406 0 6-2.683594 6-6 0-3.3125-2.683594-6-6-6zm0 0"></path>
                                    <path d="m385.199219 303.628906h-142c-3.3125 0-6 2.6875-6 6 0 3.316406 2.6875 6 6 6h142c3.316406 0 6-2.683594 6-6 0-3.3125-2.683594-6-6-6zm0 0"></path>
                                </g>
                            </svg>
                            <div className="Contactcontent">
                                <h3>Contact Information</h3>
                                <p className="ml-2">
                                    We need this information to contact you and
                                    get the order delivered
                                </p>
                            </div>
                        </div>
                        <div className="contactDetailsForm mt-5">
                            <form
                                id="form-details"
                                className="needs-validation"
                                novalidate
                            >
                                <div className="row m-0">
                                    <div className="col-md-12 mb-3">
                                        {/* <!-- <div className="form-group">
                <div className="custom_inp_group"> --> */}
                                        <TextField
                                            fullwidth="true"
                                            type="text"
                                            required
                                            value={name}
                                            className={appState.langValue  === 'ar' ? "ml-3 required contact-field" : "mr-3 required contact-field"}
                                            onChange={e =>
                                                setName(e.target.value)
                                            }
                                            name="name"
                                            id="name"
                                            label="Name"
                                            style={{ width: "100%" }}
                                        />

                                        {/* <!-- </div>
              </div> --> */}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <TextField
                                            type="email"
                                            label="Email"
                                            required
                                            value={email}
                                            className={appState.langValue  === 'ar' ? "ml-3 required contact-field" : "mr-3 required contact-field"}

                                            name="email"
                                            onChange={e =>
                                                setEmail(e.target.value)
                                            }
                                            id="email"
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <TextField
                                            type="number"
                                            prefix="+965"
                                            // inputProps={{ maxLength: 12 }}
                                            inputProps={{
                                                maxLength: 8,
                                              }}
                                            required
                                            className={appState.langValue  === 'ar' ? "ml-3 required contact-field" : "mr-3 required contact-field"}

                                            value={phone}
                                            onChange={e =>
                                                setPhone(e.target.value)
                                            }
                                            label="Phone No"
                                            name="phone_no"
                                            id="phone_no"
                                            // v-model="phone_no"
                                            style={{ width: "100%" }}
                                            InputProps={{
                                            
                                              }}
                                        />
                                        {/* <!-- <div className="form-group"> -->
              <!-- <div className="custom_inp_group"> -->
              <!-- <span>Phone *</span> -->
              <!-- <div className="countryInput">
                    <button className="countryFlag">
                      <img
                        src="images/kuwatiFlag.png"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                    <span>+965</span>
                  </div> -->

              <!-- <input
                      type="number"
                      required
                      className="inpGro phone__input required"
                      name="phone_no"
                      id="phone_no"
                      v-model="phone_no"
                    /> -->

              <!-- </div> -->
              <!-- </div> --> */}
                                    </div>
                                    <div id="process-btn" className="nextBtn  col-md-12 col-lg-12">


                                        <Button
                                   variant="contained"
                                   color="primary"
                                    disabled={loading}
                                    className="btn btn-main btn-block mx-3 col-12 "
                                >
                                    {loading ? <React.Fragment> <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    /> Redirecting </React.Fragment>: "Next"}
                                </Button>
                                    </div>
                                </div>

                                <div className="row mx-3 mt-2 or_line  d-flex justify-content-center ">
                                <p>
                                    <span>OR</span>
                                </p>
                            </div>
                            <div className="row mx-3 text-center">
                                {/* <div className="col-12 px-4"> */}
                                <Button
                                    onClick={handleClickOpen}
                                    className="already-btn text-capitalize col-12 text-primary "
                                >
                                       Already a member?
                                </Button>
                                {/* </div> */}
                            </div>
                            </form>



                        </div>
                    </section>
                </div>
                            </div>
                            :
                            <Redirect to='/checkout'  />
                            :

                             <Redirect to='/checkout'  />
                        }
        </React.Fragment>
    );
}

export default Detail;
