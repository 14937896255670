export const handleMealType = (mealTypes, qrBranch, id = null) => {
  let show = false;
  const weekday = [ "Sunday","Monday","Tuesday","Wednesday","Thursday","Friday", "Saturday" ];
  let getMealType = mealTypes.filter(
    (mealType) => mealType?.branch_id == qrBranch
  );
  
  var current = new Date();
  
  var currentDay = weekday[current.getDay()];
  let flag = false;

  if(getMealType?.length > 0){
    getMealType.forEach((item, ind) => {
      if(!show){
      if(item?.days?.length > 0){
        flag = item?.days.includes(currentDay);
      }else{
        flag = true
      }

      if (flag) {
        var starting = new Date();
        starting.setHours(item.starting.split(":")[0]);
        starting.setMinutes(item.starting.split(":")[1]);
        var ending = new Date();
        ending.setHours(item.ending.split(":")[0]);
        ending.setMinutes(item.ending.split(":")[1]);
        if (starting != ending) {
          if (starting > ending) {
            if (current < starting && current < ending) {
              starting.setDate(starting.getDate() - 1);
            } else {
              ending.setDate(ending.getDate() + 1);
            }
          }
          if (starting <= current && current <= ending) {
            show = true;
          } else {
            show = false;
          }
        }
      }
    
    }
    });
  } else {
    show = true;
  }

  return show;
};


  // mealTypes.forEach((mealType) => {

  //   if(mealType?.branch_id == 27424){
  //     if (mealType?.days.length > 0) {
  //       flag = mealType?.days.includes(currentDay);
  //       console.log(mealType?.branch_id, qrBranch, flag);
  //     } else {
  //       flag = true;
  //     }

  //     if (flag) {
  //       var starting = new Date();
  //       starting.setHours(mealType.starting.split(":")[0]);
  //       starting.setMinutes(mealType.starting.split(":")[1]);
  //       var ending = new Date();
  //       ending.setHours(mealType.ending.split(":")[0]);
  //       ending.setMinutes(mealType.ending.split(":")[1]);
  //       if(starting != ending){
  //         console.log(starting);
  //         console.log(current);
  //         console.log(ending);
  //         if (starting > ending) {
  //           console.log("-1", mealType);

  //           if (current < starting && current < ending) {
  //           console.log("-1", mealType);

  //             starting.setDate(starting.getDate() - 1);
  //           } else {
  //             ending.setDate(ending.getDate() + 1);
  //           console.log("-1", mealType);

  //           }
  //         }
  //         if (starting <= current && current <= ending) {
  //           show = true;
  //           console.log(starting <= current, `starting <= current && current <= ending`, current <= ending);
  //           console.log("true", mealType);

  //         }else{
  //           show= false
  //         }
  //       }

  //     }
  //   }
  //   else{
  //     show = true
  //   }

  // });
