/* eslint-disable */
import React from 'react'
import { Link } from "react-router-dom";


function status() {
    return (
        <div>
             <div id="status">
        {/* <cart-component></cart-component>
        <authentication-component></authentication-component> */}
          <div className="accepted_alert d-flex p-1">
            <div>
                Your order has been accepted <b> order_no #</b>
            </div>
            <div className="ml-auto">
                <Link to="/"> Open </Link >
            </div>
        </div>

        <div className="text-center">
            <div
                className="swal2-icon swal2-success swal2-animate-success-icon"
                style={{display: "flex"}}
            >
                <div
                    className="swal2-success-circular-line-left"
                    style={{backgroundColor: "rgb(255, 255, 255)"}}
                ></div>
                <span className="swal2-success-line-tip"></span>
                <span className="swal2-success-line-long"></span>
                <div className="swal2-success-ring"></div>
                <div
                    className="swal2-success-fix"
                    style={{backgroundColor: "rgb(255, 255, 255)"}}
                ></div>
                <div
                    className="swal2-success-circular-line-right"
                    style={{backgroundColor: "rgb(255, 255, 255)"}}
                ></div>
            </div>
        </div>


    {/* <!-- Success Icon --> */}
    <div className="text-center">
            <h2>Thankyou !</h2>
            <p className="text-muted">Your order has been placed</p>
            <div className="mt-4" role="alert">
                <span className="alert alert-secondary">
                    Order Code <b>order_no</b>
                </span>
            </div>
            <div className="alert alert-success mt-5 p-1" role="alert">
                <div className="row m-0 align-items-center">
                    <div className="col-2">
                        <svg
                            enable-background="new 0 0 1000 1000"
                            version="1.1"
                            viewBox="0 0 1e3 1e3"
                            style={{fill: "green", width: "50px"}}
                            // xml:space="preserve"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g transform="translate(0 431) scale(.1 -.1)">
                                <path
                                    d="m6630 3413.2c-46-24.9-193.4-220.3-476.9-635.9-122.6-180-139.8-216.5-130.2-268.2 15.3-69 74.7-137.9 137.9-153.2 105.3-26.8 159 23 434.8 417.6 145.6 206.9 272 398.4 281.6 425.2 53.5 137.9-115 283.5-247.2 214.5z"
                                />
                                <path
                                    d="m5632.1 3392.2c-55.5-28.7-586.1-766.2-603.4-840.9-17.2-74.7 26.8-153.2 107.3-193.4 126.4-61.3 157.1-36.4 457.8 390.7 147.5 208.8 277.7 402.2 287.3 431 26.8 69 5.7 134.1-61.3 189.6-63.2 53.6-115 59.4-187.7 23z"
                                />
                                <path
                                    d="m6978.6 2513c-24.9-11.5-168.5-199.2-321.8-417.6-295-419.5-316-471.2-249-568.9 38.3-53.6 143.7-88.1 203-65.1 63.2 23 78.5 42.1 371.6 461.6 264.3 377.3 287.3 429.1 239.4 519.1-23 42.1-116.8 93.9-166.6 91.9-19.1 0.1-53.6-9.5-76.6-21z"
                                />
                                <path
                                    d="m5620.6 1982.4c-23-9.6-141.7-162.8-264.3-339-176.2-254.7-222.2-333.3-222.2-385 0-74.7 63.2-149.4 143.7-170.5 97.7-24.9 151.3 26.8 383.1 354.4 241.4 342.9 260.5 394.6 178.1 490.3-48 53.7-151.4 76.6-218.4 49.8z"
                                />
                                <path
                                    d="m3218.6 1955.6c-51.7-15.3-356.3-308.4-1317.8-1266.1-687.6-685.7-1291-1298.6-1336.9-1359.9-281.6-371.6-432.9-777.7-459.7-1239.3-65.2-1063.1 639.7-2011.2 1704.7-2292.7l193.5-49.8 1980.5-7.6c2233.4-5.7 2099.3-15.3 2252.5 139.8 235.6 235.6 157.1 630.2-153.2 770-70.9 30.6-162.8 34.5-1095.6 40.2l-1020.9 5.7v44.1c0 24.9 13.4 51.7 30.6 57.5s651.2 11.5 1411.7 11.5c1354.2 0 1381 0 1461.5 40.2 176.2 84.3 295 300.7 266.3 482.7-17.2 107.3-99.6 256.7-172.4 312.2-137.9 103.4-130.2 103.4-1398.3 103.4-1019 0-1176.1 3.8-1197.1 28.7-19.2 23-19.2 34.5 0 57.5s216.4 28.7 1425.1 32.6c1400.2 5.7 1400.2 5.7 1478.7 47.9 172.4 91.9 270.1 272 250.9 461.6-11.5 130.2-44.1 205-132.2 291.1-139.8 139.8-47.9 134.1-1695.1 134.1-812.1 0-1490.2 5.7-1507.4 11.5s-30.6 32.6-30.6 57.5v46h1235.4c816 0 1266.1 7.7 1331.2 21.1 442.5 93.9 522.9 670.4 124.5 884.9l-76.6 42.1-3919 19.2 473.1 699.1c258.6 385 484.6 727.9 499.9 764.3 95.8 224.1-40.2 522.9-279.7 607.2-82.4 30.4-233.7 30.4-327.6 1.7z"
                                />
                                <path
                                    d="m4613.1 1959.4c-26.8-15.3-233.7-296.9-425.2-580.4-86.2-128.3-55.5-254.8 72.8-298.8 124.5-40.2 149.4-17.2 431 381.2 153.2 220.3 193.5 289.2 193.5 340.9-0.2 130.3-161 222.3-272.1 157.1z"
                                />
                                <path
                                    d="m7216.1 1633.8c-82.4-47.9-70.9-185.8 17.2-226 32.6-13.4 256.7-21.1 710.6-21.1h662.7l67-49.8c128.3-99.6 172.4-287.3 99.6-429.1-78.5-155.2-72.8-153.2-1103.3-354.4-515.3-99.6-944.3-182-955.8-182-9.6 0 632.1-653.2 1428.9-1450 798.7-798.7 1461.5-1473 1473-1499.8 34.5-65.1 30.6-229.8-5.7-300.7-90-170.5-302.6-237.5-473.1-147.5-26.8 13.4-337.1 314.1-689.6 666.6-352.4 354.4-647.4 643.6-655.1 643.6-9.6 0-15.3-19.2-15.3-44.1s-13.4-86.2-30.6-136l-32.6-91.9 748.9-750.8c760.4-758.5 808.3-816 808.3-950 0-69-44.1-183.9-91.9-237.5-46-51.7-174.3-107.3-249-107.3-136 0-191.5 46-869.6 724l-666.6 664.7v-86.2c0-47.9-9.6-120.7-21.1-160.9l-21.1-74.7 459.7-461.6c252.8-254.7 471.2-482.7 482.7-509.5 32.6-59.4 30.6-227.9 0-287.3-34.5-70.9-97.7-134.1-162.8-166.7-69-36.4-233.7-40.2-296.9-5.7-26.8 11.5-258.6 233.7-517.2 490.3l-469.3 467.4-147.5-11.5c-141.7-13.4-147.5-15.3-126.4-53.6 11.5-23 126.4-147.5 256.7-279.6 128.3-132.2 245.2-264.3 254.8-293.1 88.1-227.9-72.8-471.2-310.3-473.1-118.8 0-197.3 34.5-291.1 132.2-40.2 42.1-80.4 78.5-90 78.5-7.6 0-51.7-24.9-97.7-57.5-47.9-30.6-97.7-57.5-111.1-57.5-49.8 0 222.2-250.9 327.5-300.7 216.4-105.4 505.7-55.5 680 118.8 69 68.9 162.8 239.4 162.8 295 0 15.3 63.2-34.5 141.7-109.2 159-155.2 262.4-208.8 427.1-226 272-28.7 549.7 151.3 622.5 402.2 11.5 44.1 24.9 80.4 26.8 84.3 1.9 3.8 42.1-13.4 88.1-38.3 69-36.4 111.1-44.1 264.3-44.1 151.3 0 195.4 7.7 262.4 44.1 128.3 67 210.7 151.3 273.9 275.8 49.8 99.6 57.5 137.9 57.5 264.3s-7.7 164.7-57.5 260.5c-30.6 61.3-53.6 113-51.7 113 1.9 1.9 38.3 13.4 82.4 24.9 249 70.9 429.1 348.6 404.2 620.6-21.1 227.9-3.8 208.8-1382.9 1586-700.9 699.2-1273.6 1277.6-1273.6 1283.4s308.4 70.9 685.7 143.7c440.5 84.3 722.1 147.5 794.9 178.1 130.2 55.6 227.9 157.1 295 304.6 42.1 91.9 47.9 124.5 40.2 262.4-11.5 239.4-114.9 400.3-327.5 513.3-78.5 44.1-95.8 44.1-779.6 47.9-475 3.8-710.6-2-733.6-15.4z"
                                />
                                <path
                                    d="m5951.9 1061.1c-76.6-59.4-455.9-612.9-467.4-680-11.5-74.7 63.2-172.4 147.5-191.5 97.7-21.1 153.2 28.7 381.2 352.4 182 258.6 216.4 319.9 216.4 379.3 0.1 141.7-168.5 226-277.7 139.8z"
                                />
                                <path
                                    d="m4718.4 708.7c-86.2-72.8-178.1-224.1-178.1-296.9 0-114.9 137.9-201.1 247.1-155.2 80.4 32.6 212.6 227.9 212.6 314.1 0 88.1-65.1 162.8-155.2 176.2-53.6 9.7-78.5 2-126.4-38.2z"
                                />
                            </g>
                        </svg>
                    </div>
                    <div className="col-10 text-left">
                        Remember to wash your hands at all times to lower health
                        risks
                    </div>
                </div>
            </div>
        </div>

        <div className="row m-0 justify-content-between px-2 py-4">
            <div>
                <h5>Estimated Time</h5>
            </div>
            <div>
                <h5>Sat, 17 Oct 2020 5:52 PM</h5>
            </div>
        </div>
        <div className="p-2">
            <p className="text-muted">Preparing your order</p>
            <div className="status_comp_buttons">
                <Link to="/"
                    className="view_reciept btn btn-main btn-main-outline btn-block mb-2 mt-4"
                    >View Reciept</Link>
                <div className="Get_over_button">
                    <Link
                        to="/home"
                        className="btn btn-main btn-block p-2 my-4">Start Over
                        </Link>
                </div>
            </div>

        </div>


        </div>
        </div>
    )
}

export default status
