import axios from 'axios';
import { freezOn, freezOff, handleError } from '../redux-store/action';
import { handleOrdersPayload } from "../global-functions/addToCartGlobal"
import { handleStoreDiscount } from '../layout-one/js/Pages/Checkout';
import { getServiceName } from './variables';


export const createBill = async (appState, appDispatch, history, orders, data, dineInID, setCreateOrderLoading) => {
  let formData = { ...data, openBill: Boolean(appState.openBill), order_invoice: "open_bill" };
  appDispatch(freezOn());
  let mainOrder = appState.cart;

  if (mainOrder == null || mainOrder == []) {
    mainOrder = JSON.parse(localStorage.getItem(`${appState.storeInfo.slug}_${getServiceName(appState)}_cart`))
  }
  // console.log('create bill',appState.pushSubscription);

  await axios
    .post(`${process.env.REACT_APP_API_URL}/order_invoice/dine_ins`,
     {
      full_name: `${appState.loggedIn
        && await appState.userInfo.userFirstName != null ? appState.userInfo.userFirstName
        : `Customer${Date.now()}`
        }`,
      email: appState.loggedIn ? appState.userInfo.email : null
      ,
      number: appState.loggedIn ? appState.userInfo.number : null
      ,
      customer_id: appState.loggedIn
        ? appState.userInfo.customerId
        : null,
      user_id: appState.loggedIn ? appState.userInfo.userId : null
      ,
      country_code: "965",
      table_id: appState.qrTable,
      branch_id: appState.qrBranch,
      store_id: appState.storeInfo.storeId,
      device_id: appState.deviceId,
      group_id: appState.groupId != null ? appState.groupId : null,
      orders: handleOrdersPayload(mainOrder),
      isOpen: true,
      meta: appState.cart ?? null,
      note: appState.formData.note ? appState.formData.note : "N/A",
      isGroup: 0,
      dine_in_id: dineInID,
      discount: appState.storeDiscount != null ? (handleStoreDiscount(appState, appDispatch, appState.total)  < appState?.storeDiscount?.discount_max) ? appState?.storeDiscount?.discount_percent : appState?.storeDiscount?.discount_max : 0,
      discount_type:  appState.storeDiscount != null ? (handleStoreDiscount(appState, appDispatch, appState.total)  < appState?.storeDiscount?.discount_max) ? 'percent' : 'fixed' : '',
      webpush: appState.pushSubscription != null ?  {
        endpoint: appState?.pushSubscription?.endpoint,
        keys: appState?.pushSubscription?.keys,
      }: null
    },{
      headers: {
        ContentType: 'application/json',
      }
    })
    .then((response) => {
      if (response.data.success === true) {

        appDispatch({
          type: "setMeta",
          meta: appState.cart
        });

        appDispatch({
          type: "SET_FORM_DATA",
          formData: formData,
        });
        localStorage.setItem(
          `${appState.storeInfo.slug}_${getServiceName(appState)}_form`,
          JSON.stringify(formData)
        );
        appDispatch({
          type: "setOrderInvoice",
          orderInvoice: response.data.data.service,
        });
        history.push("/openorder");
        localStorage.removeItem("carts");
        appDispatch({ type: "updateCart", cart: [] });
        appDispatch({ type: "updateSubTotal", subTotal: 0 });
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "success",
          message: response.data.message,
        });
        
        return;
      } else {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `${response.data.message} : ${response.data.data} `,
        });
        appDispatch(freezOff());
      }
    })

    .catch((error) => {
      if (error == undefined) {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "warning",
          message: "Please check your network.",
        });
      } else {
        appDispatch(handleError(error));
      }
      appDispatch(freezOff());
    });
};

export const updateOpenBill = () => {

}