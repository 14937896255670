export const CountryCode = () => {
const countryListAllData = [
    
{"name":"BD",
"code": "880"},
{"name":"BE",
"code": "32"},
{"name":"BF",
"code": "226"},
{"name":"BG",
"code": "359"},
{"name":"BA",
"code": "387"},

{"name":"WF",
"code": "681"},
{"name":"BL",
"code": "590"},

{"name":"BN",
"code": "673"},
{"name":"BO",
"code": "591"},
{"name":"BH",
"code": "973"},
{"name":"BI",
"code": "257"},
{"name":"BJ",
"code": "229"},
{"name":"BT",
"code": "975"},

{"name":"BV",
"code": ""},
{"name":"BW",
"code": "267"},
{"name":"WS",
"code": "685"},
{"name":"BQ",
"code": "599"},
{"name":"BR",
"code": "55"},


{"name":"BY",
"code": "375"},
{"name":"BZ",
"code": "501"},
{"name":"RU",
"code": "7"},
{"name":"RW",
"code": "250"},
{"name":"RS",
"code": "381"},
{"name":"TL",
"code": "670"},
{"name":"RE",
"code": "262"},
{"name":"TM",
"code": "993"},
{"name":"TJ",
"code": "992"},
{"name":"RO",
"code": "40"},
{"name":"TK",
"code": "690"},
{"name":"GW",
"code": "245"},

{"name":"GT",
"code": "502"},
{"name":"GS",
"code": ""},
{"name":"GR",
"code": "30"},
{"name":"GQ",
"code": "240"},
{"name":"GP",
"code": "590"},
{"name":"JP",
"code": "81"},
{"name":"GY",
"code": "592"},

{"name":"GF",
"code": "594"},
{"name":"GE",
"code": "995"},

{"name":"GB",
"code": "44"},
{"name":"GA",
"code": "241"},
{"name":"SV",
"code": "503"},
{"name":"GN",
"code": "224"},
{"name":"GM",
"code": "220"},
{"name":"GL",
"code": "299"},
{"name":"GI",
"code": "350"},
{"name":"GH",
"code": "233"},
{"name":"OM",
"code": "968"},
{"name":"TN",
"code": "216"},
{"name":"JO",
"code": "962"},
{"name":"HR",
"code": "385"},
{"name":"HT",
"code": "509"},
{"name":"HU",
"code": "36"},
{"name":"HK",
"code": "852"},
{"name":"HN",
"code": "504"},
{"name":"HM",
"code": " "},
{"name":"VE",
"code": "58"},
{"name":"PR",
"code": "1-939"},
{"name":"PS",
"code": "970"},
{"name":"PW",
"code": "680"},
{"name":"PT",
"code": "351"},
{"name":"SJ",
"code": "47"},
{"name":"PY",
"code": "595"},
{"name":"IQ",
"code": "964"},
{"name":"PA",
"code": "507"},
{"name":"PF",
"code": "689"},
{"name":"PG",
"code": "675"},
{"name":"PE",
"code": "51"},
{"name":"PK",
"code": "92"},
{"name":"PH",
"code": "63"},
{"name":"PN",
"code": "870"},
{"name":"PL",
"code": "48"},
{"name":"PM",
"code": "508"},
{"name":"ZM",
"code": "260"},
{"name":"EH",
"code": "212"},
{"name":"EE",
"code": "372"},
{"name":"EG",
"code": "20"},
{"name":"ZA",
"code": "27"},
{"name":"EC",
"code": "593"},
{"name":"IT",
"code": "39"},
{"name":"VN",
"code": "84"},
{"name":"SB",
"code": "677"},
{"name":"ET",
"code": "251"},
{"name":"SO",
"code": "252"},
{"name":"ZW",
"code": "263"},
{"name":"SA",
"code": "966"},
{"name":"ES",
"code": "34"},
{"name":"ER",
"code": "291"},
{"name":"ME",
"code": "382"},
{"name":"MD",
"code": "373"},
{"name":"MG",
"code": "261"},
{"name":"MF",
"code": "590"},
{"name":"MA",
"code": "212"},
{"name":"MC",
"code": "377"},
{"name":"UZ",
"code": "998"},
{"name":"MM",
"code": "95"},
{"name":"ML",
"code": "223"},
{"name":"MO",
"code": "853"},
{"name":"MN",
"code": "976"},
{"name":"MH",
"code": "692"},
{"name":"MK",
"code": "389"},
{"name":"MU",
"code": "230"},
{"name":"MT",
"code": "356"},
{"name":"MW",
"code": "265"},
{"name":"MV",
"code": "960"},
{"name":"MQ",
"code": "596"},


{"name":"MR",
"code": "222"},

{"name":"UG",
"code": "256"},
{"name":"TZ",
"code": "255"},
{"name":"MY",
"code": "60"},
{"name":"MX",
"code": "52"},
{"name":"IL",
"code": "972"},
{"name":"FR",
"code": "33"},
{"name":"IO",
"code": "246"},
{"name":"SH",
"code": "290"},
{"name":"FI",
"code": "358"},
{"name":"FJ",
"code": "679"},
{"name":"FK",
"code": "500"},
{"name":"FM",
"code": "691"},
{"name":"FO",
"code": "298"},
{"name":"NI",
"code": "505"},
{"name":"NL",
"code": "31"},
{"name":"NO",
"code": "47"},
{"name":"NA",
"code": "264"},
{"name":"VU",
"code": "678"},
{"name":"NC",
"code": "687"},
{"name":"NE",
"code": "227"},
{"name":"NF",
"code": "672"},
{"name":"NG",
"code": "234"},
{"name":"NZ",
"code": "64"},
{"name":"NP",
"code": "977"},
{"name":"NR",
"code": "674"},
{"name":"NU",
"code": "683"},
{"name":"CK",
"code": "682"},
{"name":"XK",
"code": ""},
{"name":"CI",
"code": "225"},
{"name":"CH",
"code": "41"},
{"name":"CO",
"code": "57"},
{"name":"CN",
"code": "86"},
{"name":"CM",
"code": "237"},
{"name":"CL",
"code": "56"},
{"name":"CC",
"code": "61"},
{"name":"CA",
"code": "1"},
{"name":"CG",
"code": "242"},
{"name":"CF",
"code": "236"},
{"name":"CD",
"code": "243"},
{"name":"CZ",
"code": "420"},
{"name":"CY",
"code": "357"},
{"name":"CX",
"code": "61"},
{"name":"CR",
"code": "506"},
{"name":"CW",
"code": "599"},
{"name":"CV",
"code": "238"},
{"name":"CU",
"code": "53"},
{"name":"SZ",
"code": "268"},
{"name":"SY",
"code": "963"},
{"name":"SX",
"code": "599"},
{"name":"KG",
"code": "996"},
{"name":"KE",
"code": "254"},
{"name":"SS",
"code": "211"},
{"name":"SR",
"code": "597"},
{"name":"KI",
"code": "686"},
{"name":"KH",
"code": "855"},

{"name":"KM",
"code": "269"},
{"name":"ST",
"code": "239"},
{"name":"SK",
"code": "421"},
{"name":"KR",
"code": "82"},
{"name":"SI",
"code": "386"},
{"name":"KP",
"code": "850"},
{"name":"KW",
"code": "965"},
{"name":"SN",
"code": "221"},
{"name":"SM",
"code": "378"},
{"name":"SL",
"code": "232"},
{"name":"SC",
"code": "248"},
{"name":"KZ",
"code": "7"},

{"name":"SG",
"code": "65"},
{"name":"SE",
"code": "46"},
{"name":"SD",
"code": "249"},
{"name":"DO",
"code": "1-829"},

{"name":"DJ",
"code": "253"},
{"name":"DK",
"code": "45"},

{"name":"DE",
"code": "49"},
{"name":"YE",
"code": "967"},
{"name":"DZ",
"code": "213"},
{"name":"US",
"code": "1"},
{"name":"UY",
"code": "598"},
{"name":"YT",
"code": "262"},
{"name":"UM",
"code": "1"},
{"name":"LB",
"code": "961"},

{"name":"LA",
"code": "856"},
{"name":"TV",
"code": "688"},
{"name":"TW",
"code": "886"},

{"name":"TR",
"code": "90"},
{"name":"LK",
"code": "94"},
{"name":"LI",
"code": "423"},
{"name":"LV",
"code": "371"},
{"name":"TO",
"code": "676"},
{"name":"LT",
"code": "370"},
{"name":"LU",
"code": "352"},
{"name":"LR",
"code": "231"},
{"name":"LS",
"code": "266"},
{"name":"TH",
"code": "66"},
{"name":"TF",
"code": ""},
{"name":"TG",
"code": "228"},
{"name":"TD",
"code": "235"},

{"name":"LY",
"code": "218"},
{"name":"VA",
"code": "379"},

{"name":"AE",
"code": "971"},
{"name":"AD",
"code": "376"},

{"name":"AF",
"code": "93"},


{"name":"IS",
"code": "354"},
{"name":"IR",
"code": "98"},
{"name":"AM",
"code": "374"},
{"name":"AL",
"code": "355"},
{"name":"AO",
"code": "244"},
{"name":"AQ",
"code": ""},

{"name":"AR",
"code": "54"},
{"name":"AU",
"code": "61"},
{"name":"AT",
"code": "43"},
{"name":"AW",
"code": "297"},
{"name":"IN",
"code": "91"},

{"name":"AZ",
"code": "994"},
{"name":"IE",
"code": "353"},
{"name":"ID",
"code": "62"},
{"name":"UA",
"code": "380"},
{"name":"QA",
"code": "974"},
{"name":"MZ",
"code": "258"}
];
        return  countryListAllData


  
}