import React, { useState, useContext, useEffect } from "react";
import "./verifyPhoneNumberv2Popup.scss";
// material ui
import Button from "@mui/material/Button";
// import CircularIndeterminate from "../../../components/CircularIndeterminate";
//  cookie functions
// import { erase, setCookie } from "../../../helper/cookieFunction";
import { withTranslation, useTranslation } from "react-i18next";
// import Redux
import { useDispatch, useSelector } from 'react-redux';
// svg icons
import IMAGES from "../Assets/images2"
// import { Link, useNavigate } from "react-router-dom";
// import { authService } from "../../../services";
import InputMaskField from "../../sharedComponent/react-input-mask/inputfield"
import axios from "axios"
import { freezOff, freezOn, getSaveCard, handleError } from "../../redux-store/action";
import { setCookie } from "../../global-functions/cookieFunction";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { handleUserInfo } from "../../global-functions/customeObj";
import { Backdrop } from "@material-ui/core";
import CircularIndeterminate from "../../layout-one/js/components/CircularIndeterminate";
const renderButton = (t) => (buttonProps) => {
  return (
    <button className="createAccountAndforgotPass px-1" {...buttonProps}>
      {buttonProps.remainingTime !== 0 ? (
        <span className="text-muted">
          {t("Forms.send-again")}
          {/* Send again */}
        </span>
      ) : (
        <span>
          {/* {t("Forms.send-again")}  */}
          Send again
        </span>
      )}
    </button>
  );
};


function VerifyPhoneNumberv2Popup() {
  const handlechangeSetOtp = (e) => {
    if (e.target.value.length < 6 || e.nativeEvent.data === null) {
      setOTP(e.target.value)
    }

  }
  const [OTP, setOTP] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const appDispatch = useDispatch();
  const appState = useSelector((state) => state);
  const [sendAgain, setSendAgain] = useState(false)
  const [timer, setTimer] = useState(60)

  const handleSkip = () => {
    appDispatch({ type: "setLoginSkip", loginSkip: true });
  }

  const handleClose = () => {
    appDispatch({ type: "setOtpModal", otpModal: false });
    appDispatch({ type: 'setLoginModal', loginModal: false });
  };

  useEffect(() => {
    const hanndleSendAgain = () => {
      if (!sendAgain) {
        var timeleft = 59;
        var downloadTimer = setInterval(function () {
          if (timeleft <= 0) {
            clearInterval(downloadTimer);
            setSendAgain(true)
          }
          setTimer(timeleft)
          timeleft -= 1;
        }
          , 1000);
      }
    }
    hanndleSendAgain();
  }, [sendAgain])


  useEffect(() => {
    const handleCheckOtp = async () => {
      setLoading(true);
      appDispatch(freezOn());
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/check/guest/otp`,
          {
            country_code: appState.loginValues.phoneCode,
            number: appState.loginValues.phoneNumber,
            code: OTP,
            first_name: appState.loginValues.fullName ? appState.loginValues.fullName : null,
          },{
            headers: {
              ContentType: 'application/json',
            }
          }
        );
        if (response.data.success === true && response.data.data != false) {
          setLoading(false);
          appDispatch(freezOff());
          setOTP("");
          setCookie("tokenAuth", response.data.data.token, 365);
          // if (check_status) {
          // } else {
          //   setCookie("tokenAuth", response.data.data.token);
          // }
          sessionStorage.setItem("token", response.data.data.token)
          let userInfo = handleUserInfo(response.data.data)
          // handleSkip();
          setCookie("Profile", JSON.stringify(userInfo));
          appDispatch({
            type: "SET_USERINFO",
            userInfo: userInfo,
          });
          // console.log(response.data.data);
          // localStorage.setItem(`userInfo`, JSON.stringify(response.data.data));
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "success",
            message: response.data.message,
          });
          let token = await response.data.data.token;

          appDispatch({
            type: "SET_LOGIN",
            loggedIn: true,
            loginToken: token,
          });
          appDispatch({ type: "setLoginValues", loginValuesState: {} });

          handleClose();
        } else
        // if (response.data.success === false) 
        {
          setLoading(false);
          appDispatch(freezOff());
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: response.data.message,
          });
          appDispatch({ type: "logout", loggedIn: false });
        }
      } catch (e) {
        if (e == undefined) {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "warning",
            message: "Please check your network.",
          });
        } else {
          appDispatch(handleError(e));
        }
        setLoading(false);
        appDispatch(freezOff());
      }
    };
    if (OTP.length === 5) {
      handleCheckOtp();
    }
  }, [OTP]);

  const handleSendOtp = async () => {
    setLoading(true);
    appDispatch(freezOn());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/send/guest/otp`,
        {
          country_code: `${appState.loginValues.phoneCode}`,
          number: `${appState.loginValues.phoneNumber}`,

        }
      );
      if (response.data.success === true) {
        setLoading(false);
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "success",
          message: response.data.message,
        });
      } else if (response.data.success === false) {
        setLoading(false);
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: response.data.message,
        });
      }
      setSendAgain(false)
    } catch (e) {
      // console.log(e);
      setLoading(false);
      if (e == undefined) {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      } else {
        appDispatch(handleError(e));
      }
      setSendAgain(false)

    }
  };

  const handleChangeNumber = () => {
    appDispatch({ type: "setOtpModal", otpModal: false });
    appDispatch({ type: "setLoginModal", loginModal: true });
    setOTP("")
  };

  const arLanguageHandle = (e) => {
    // console.log("ar change");
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
    //   i18n.changeLanguage('ar')
  };
  const enLanguageHandle = (e) => {
    // console.log("en change");

    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  return (
    <>
        
    <div
      id="mySidenav"
      className={`verifyPhoneNumberv2Popup col-12   ${appState.langValue == "ar" ? "verifyPhoneNumberv2PopupArabic" : " "
        }`}
    > { loading &&  <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 5,
      }}
      open={loading}
    >
      <CircularIndeterminate />
    </Backdrop>}
  
      <div className={`${appState.langValue === "ar" ? "directionRtl" : ""}`}>
        <div className="row pt-2 mx-0 ">
          <div className="col-md-12 px-0 py-3 d-flex justify-content-between classforPaddinglogo ">
            <Button
              onClick={handleClose}
              variant="contained"
              className="BackBtn"
            >
              {appState.langValue === "ar" ? (
                <ArrowForwardIcon />
              ) : (
                <ArrowBackIcon />
              )}
            </Button>
            <div>
              {appState.langValue === "ar" ? (
                <Button
                  className="px-5  arabicBtn"
                  variant="contained"
                  onClick={(e) => enLanguageHandle(e)}
                >
                  English
                </Button>
              ) : (
                <Button
                  className="px-5  arabicBtn"
                  variant="contained"
                  onClick={(e) => arLanguageHandle(e)}
                >
                  العربيه
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-3 mx-0 ">
          <div className="col-md-12 px-0 d-flex  classforPaddinglogo ">
            <img src={IMAGES.enabill} alt="enabill-logo" />
          </div>
        </div>
        <h1
          className={`familyManrope800 mt-3 ${appState.langValue === "ar" ? "d-flex" : ""
            }`}
        >
          {t("Forms.verify-number")}
        </h1>
        <div
          className={`familyManrope600 d-flex flex-wrap loginTitle m-0 ${appState.langValue === "ar" ? "d-flex text-right" : ""
            }`}
        >
          <div>{t("Forms.enter-code")}</div>
          <div className="textEB5B5E direction-ltr">
            +{appState.loginValues.phoneCode} {appState.loginValues.phoneNumber}
          </div>
          {/* +{phone.phoneCode} {phone.phoneNumber} */}
        </div>

        <div
          className={`optMain mt-3 ${appState.langValue === "ar" ? "classforArabic" : ""
            }`}
        >
          <InputMaskField
            value={OTP}
            onChange={handlechangeSetOtp}
            inputProps={{ maxLength: 5, inputMode: "numeric", autoComplete: "nope" }}
            label={null}
            variant="outlined"
            type="tel"
            mask="99999"
            id="outlined-basic"
            name="phoneNumber"
            autoFocus={true}
            autoComplete={"off"}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
          />
        </div>
        <div className="row mt-3 pb-2">
          <div className={`col-12 ${appState.langValue === "ar" ? "" : ""}`}>
            <hr />
            <div className="notRegisterMain py-2">
              {" "}
              <table>
                <tbody>
                  <tr className="familyManrope600 ">
                    <td>
                      <img className="img-fluid" src={IMAGES.time} alt="" />
                    </td>
                    <td>
                      {" "}
                      <span className="px-2 ">
                        <span className="textBlack">
                          {" "}
                          {t("Forms.did-not-recieve")} ?{" "}
                        </span>
                      </span>
                    </td>
                    {sendAgain ? (
                      <td
                        onClick={handleSendOtp}
                        className="cursorPointer btnEffect px-2 familyManrope700 notRegister"
                      >
                        {t("Forms.send-again")}
                      </td>
                    ) : (
                      <td className="px-1"> ({timer} sec )</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <div>
              <table>
                <tbody>
                  <tr className="familyManrope700  notRegisterMain">
                    <td>
                      <img className="img-fluid" src={IMAGES.dialpad} alt="" />
                    </td>
                    <td
                      className="notRegister  btnEffect cursorPointer px-2"
                      // onClick={() => navigate('/login')}
                      onClick={handleChangeNumber}
                    >
                      {t("Forms.differ-number")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}



export default withTranslation()(VerifyPhoneNumberv2Popup);