import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Backdrop } from "@mui/material";
import "./FeaturedProduct2.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AskDailog = (props) => {
  const { t } = useTranslation();

  const { addOpenBill } = props;
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();

  const handleClose = () => {
    appDispatch({
      type: "setConfirmPopup",
      confirmPopup: false,
    });
  };

  return (
    <React.Fragment>
      <Dialog
        id="AskConfirm"
        open={appState.confirmPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="px-5 pt-4" style={{ textAlign: "center" }}>
          <p>{t("featured.You-are-adding-a-new-product")}</p>

          <h5> {t("featured.Are-you-sure")}</h5>
        </div>

        <DialogActions className="d-flex align-items-center justify-content-center mb-4 mt-2 px-2">
          <Button className=" cancleBtn  py-2" onClick={handleClose}>
            {t("featured.Cancel")}
          </Button>
          <Button className="addBtn  py-2" onClick={addOpenBill} >
            {t("featured.Add")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withTranslation()(AskDailog);
