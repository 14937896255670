/* eslint-disable  */
import React, { useEffect} from "react";
import { useSelector } from "react-redux";
import { get_lang } from "../../../global-functions/apiGlobal";


const Page = ({ children, title }) => {
    const appState = useSelector((state) => state)
    useEffect(() => {
        document.title = `${title} |  ${ get_lang(appState.storeInfo.businessName, appState.langValue)}`;
        window.scrollTo(0, 0);
    }, [title]);
    return <div id={title}>{children}</div>;
};

export default Page;
