/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
// redux 
import { useSelector, useDispatch } from "react-redux"
const CustomAlert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Alert = ({ show, message, type }) => {
    const appDispatch = useDispatch();
    const appState = useSelector((state) => state)
    const handleClose = () => {
        appDispatch({ type: "HIDE_ALERT" });
    };
    const [routeName, setRouteName] = useState("")

    useEffect(() => {
        let windowRes = window.location.pathname.split("/");
        if (windowRes[2] == "checkout") {
            setRouteName("checkout")
        }
    }, [])


    setTimeout(() => {
        handleClose()
    }, 2000);
    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={true}
            onClose={handleClose}
            style={
                appState.langValue === "ar"
                    ? { direction: "rtl" }
                    : { direction: "ltr" }
            }

            id={
                `${routeName === "checkout" && appState.payTableMode
                    ? "message-alert-checkout"
                    : "message-alert"} shop-alerts`
            }
        >
            <CustomAlert onClose={handleClose} severity={type ? type : ""}  >
                {message}
            </CustomAlert>
        </Snackbar>
    );
};

export default Alert;
