// hooks
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import noImg from "../assets/images/no-image.svg";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { handleFeaturedProduct } from "../../../redux-store/action";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { handleAddonsQtyByProduct, handleStockQuantity } from "../../../global-functions/addToCartGlobal";


export const useEditFeaturedProductCart = (product,quantityValue, setQuantityValue, featuredProduct, addons, setCheckCart,placedBy,addNote) => {

	//States:
	const { t } = useTranslation();
	const appState = useSelector((state) => state);
	const appDispatch = useDispatch();
	const { mSlug, slug, islug } = useParams();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	let parent_id = searchParams.get("parent");

	

	///// handleFunctions:

	const handleOnLoad = () => {
		let newCart = [...appState.cart];
		const itmIndx = newCart.findIndex((el) => el?.product_id == featuredProduct?.id && el.placed_by == placedBy);
		let filterCart = appState.cart.find(
			(el, i) =>  el?.product_id == featuredProduct?.id && el.placed_by == placedBy
		);
		if (filterCart) {
			setQuantityValue(newCart[itmIndx].quantity)
		} else {
			setQuantityValue(1)
		}
	}


	const handleIncrement = (e) => {
		const qtyFlag = handleAddonsQtyByProduct(
			addons,
			quantityValue,
			appDispatch,
			appState
			);
			if (qtyFlag) {
			setQuantityValue(quantityValue + 1);
		}


	};

	const handleDecrement = (e) => {
		if (quantityValue > 1) {
			setQuantityValue(quantityValue - 1);
		}
	};

	const handleItemRemove = (item) => {
		let newCart = [...appState.cart];
		const itmIndx = newCart.findIndex((el) => el?.product_id == featuredProduct?.id && el.placed_by == placedBy);
		newCart.splice(itmIndx, 1);
		appDispatch({ type: "updateCart", cart: newCart });
		setCheckCart(false)

	}

	const handleEditCart = (item,addons,addonLimit,addOnsTotal)=>{
		if (appState.cart.length > 0 && featuredProduct) {
			let newCart = [...appState.cart];
			const itmIndx = newCart.findIndex((el) =>  el?.product_id == featuredProduct?.id && el.placed_by == placedBy);
			let filterCart = appState.cart.find(
				(el, i) => el?.product_id == featuredProduct?.id && el.placed_by == placedBy
				);
			if (filterCart) {
				newCart[itmIndx].quantity = quantityValue;
				newCart[itmIndx].addons = addons;
				newCart[itmIndx].addons_limit = addonLimit;
				newCart[itmIndx].sum_addons = addOnsTotal;
				newCart[itmIndx].note = addNote;
				appDispatch({ type: "updateCart", cart: newCart });
				appDispatch({
					type: "SET_FEATURED_PRODUCTS",
					featuredProductPopupMode: false,
					featuredProducts:[]
				  })
			}

		}
	}


	const addToCart = async () => {
		if (appState.cart.length > 0 && featuredProduct) {
			let newCart = [...appState.cart];
			const itmIndx = newCart.findIndex((el) =>  el?.product_id == featuredProduct?.id && el.placed_by == placedBy);
			let filterCart = appState.cart.find(
				(el, i) => el?.product_id == featuredProduct?.id && el.placed_by == placedBy
			);
			if (filterCart) {
				newCart[itmIndx].quantity = quantityValue;
				appDispatch({ type: "updateCart", cart: newCart });
			}

		}

	};
	///// useffects
	
	useEffect(() => {
		// handleOnLoad()
	}, []);
	useEffect(() => {
		// addToCart();
	}, [quantityValue, featuredProduct]);



	return { featuredProduct, handleIncrement, handleDecrement, handleItemRemove,handleEditCart, };
};