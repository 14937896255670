/* eslint-disable no-unused-vars */
/* eslint-disable  */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable */
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import UserInfo from "../components/UserInfo";
import noImg from "../assets/images/no-image.svg";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Page from "../components/Page";

// material ui
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import {
  IconButton,
  Badge,
  Button,
  Checkbox,
  TextareaAutosize,
  Radio,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Skeleton } from "@material-ui/lab";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import LoginDialog from "../components/LoginModal";
import Chip from "@mui/material/Chip";

// React Redux
import { useSelector, useDispatch } from "react-redux";
import { shadows } from "@mui/system";

// global funtion for store
import { get_lang } from "../../../global-functions/apiGlobal";
import {
  handleAddonsIncrement,
  handleAddonsDecrement,
  globalHandleCheck,
  globalHandleRadio,
  handleStockQuantity,
  handleAddonsQtyByProduct,
  handleGroupId,
} from "../../../global-functions/addToCartGlobal";
import { v4 as uuidv4 } from "uuid";
import { handleAllAddonsWithOld } from "../../../global-functions/productFunction";
import { handleProduct } from "../../../redux-store/action";
import CheckoutBtn from "../components/checkoutBtn/CheckoutBtn";
import e from "cors";
import FeaturedProductSlider from "../components/FeaturedProductSlider/FeaturedProductSlider";
import FeaturedProductPopup from "../components/FeaturedProductPopup/FeaturedProductPopup";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function SingleProduct() {
  const { t } = useTranslation();
  const classes = useStyles();
  const appState = useSelector((state) => state);
  const storeInfo = useSelector((state) => state.storeInfo);
  const langValue = useSelector((state) => state.langValue);
  const loggedIn = useSelector((state) => state.loggedIn);
  const categories = useSelector((state) => state.categories);
  const cart = useSelector((state) => state.cart);
  const total = useSelector((state) => state.total);
  const qrTable = useSelector((state) => state.qrTable);
  const products = useSelector((state) => state.products);
  const { mSlug, slug, islug } = useParams();
  const matches = useMediaQuery("(min-width:769px)");
  const history = useHistory();
  const appDispatch = useDispatch();
  const [errorStatus, setErrorStatus] = useState(false);
  const [product, setProduct] = useState([]);

  const [prodMeta, setProdMeta] = useState({});
  const [addOnsCart, setAddOnsCart] = useState([]);
  const [allAddonCategories, setAllAddonCategories] = useState([]);
  const [addons, setAddons] = useState([]);
  const [catId, setCatId] = useState(null);
  const [quantityValue, setQuantityValue] = useState(1);
  const [submitCount, setSubmitCount] = useState(0);
  const [addOnsTotal, setAddOnsTotal] = useState(0);
  const [prodVariation, setProdVariation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(
    JSON.parse(localStorage.getItem("formData")) !== null
      ? JSON.parse(localStorage.getItem("formData"))
      : {}
  );
  const [featuredProductsAvailable, setFeaturedProductsAvailable] =
    useState(false);
  const [addonsCount, setAddonsCount] = useState(1);
  const [addonLimit, setAddonLimit] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subTotal, setSubTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [textOver, setTextOver] = useState(true);
  const [addNote, setAddNote] = useState("");
  const handleClickOpen = () => {
    appDispatch({ type: "setLoginModal", loginModal: true });
    // var shop_url = window.location.href
    // var auth_url = `${process.env.REACT_APP_AUTH_URL}`
    // setCookie(`redirect`, shop_url);
    // window.location.replace(auth_url);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [currentVariations, setCurrentVariations] = React.useState([]);
  const [children, setChildren] = useState([{}]);
  const [cartId, setCartId] = useState(null);
  const [prodId, setProdId] = useState(null);
  const { search } = useLocation();
  const myRef = useRef(null);
  const searchParams = new URLSearchParams(search);
  let parent_id = searchParams.get("parent");
  let prodTotalQty = 0;
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const scrolling = () => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    };
    scrolling();
  }, []);
  const compareArrays = (array1, array2) => {
    return (
      array1.length === array2.length &&
      array1.every((el) => array2.includes(el))
    );
  };

  const handleChangeVariation = (event, index) => {
    var flag = true;
    currentVariations[index] = event.target.value;
    setCurrentVariations(currentVariations);

    children.forEach((child, index) => {
      if (compareArrays(child.meta.values, currentVariations)) {
        setProduct(child);
        setAllAddonCategories(child.product_addon_categories);
        if (searchParams.get("cart_id")) {
          var cart = appState.cart.filter(
            (cart) => cart.id === searchParams.get("cart_id")
          )[0];
          if (cart) {
            if (cart.product_id == child.id) {
              setQuantityValue(cart.quantity);
              setAllAddonsWithOld(child);
              flag = false;
            }
          }
        }
      }
    });
    if (flag) {
      setQuantityValue(1);
      setAllAddonsWithOld(product);
    }
  };

  const handleIncrement = (e) => {
    e.preventDefault();
    const qtyFlag = handleAddonsQtyByProduct(
      addons,
      quantityValue,
      appDispatch,
      appState
    );
    if (product.quantity == null && qtyFlag) {
      setQuantityValue(quantityValue + 1);
    } else if (quantityValue < product.quantity && qtyFlag) {
      setQuantityValue(quantityValue + 1);
    }


  };

  const handleDecrement = (e) => {
    e.preventDefault();

    if (quantityValue <= 1) {
      return;
    }
    setQuantityValue(quantityValue - 1);
  };

  const handleAddonsLimit = () => {
    let newArry = [...addonLimit];
    for (let i = 0; i < newArry.length; i++) {
      var arr = [];
      let sum = 0;
      for (let k = 0; k < addons.length; k++) {
        if (addons[k].category_addon_id == newArry[i].id) {
          arr.push(addons[k].quantity);
        }
      }
      for (let j = 0; j < arr.length; j++) {
        sum += arr[j];
        newArry[i].addons_qty = sum;
      }
    }
    setAddonLimit(newArry);
    setIsLoading(false);
  };

  const handleCheck = (addon_id, category_id) => () => {
    let res = globalHandleCheck(
      addonLimit,
      addons,
      addon_id,
      category_id,
      appDispatch,
      appState,
      quantityValue
    );
    setAddons(res);
    getTotal();
  };

  const handleRadio = (addon_id, category_id, ind) => () => {
    let res = globalHandleRadio(
      addonLimit,
      addons,
      addon_id,
      category_id,
      appDispatch,
      appState,
      quantityValue
    );
    setAddons(res);

    getTotal();
  };

  // addon Quantity Funtion
  const handleIncrementAddons = (addon_id, category_id) => {
    let res = handleAddonsIncrement(
      addonLimit,
      addons,
      addon_id,
      category_id,
      appDispatch,
      appState,
      quantityValue
    );
    setAddons(res);
    getTotal();
    // handleAddonsLimit();
  };

  const handleDecrementAddons = (addon_id, category_id) => {
    // e.preventDefault();
    let res = handleAddonsDecrement(addonLimit, addons, addon_id, category_id);
    setAddons(res);
    getTotal();
    // handleAddonsLimit();
  };

  const setAllAddonsWithOld = (item = product) => {
    let res = handleAllAddonsWithOld(
      allAddonCategories,
      appState,
      product,
      cartId
    );
    setAddOnsTotal(res.total);
    setAddonLimit(res.arryAddonLimit);
    setAddons(res.addonsInfo);
  };
  useEffect(() => {
    if (addons.length > 0) {
      handleAddonsLimit();
    } else {
      setIsLoading(false);
    }
  }, [addons]);
  // Cart Function
  const handleAddToCart = (item, addQuantity) => {
    if ((product.price - product.discount + addOnsTotal > 0) || appState.storeInfo.buffetMode) {
      if (
        appState.qrRequired ||
        appState.qrPickup != null ||
        appState.hotelQrCode
      ) {
        let flag = true;
        let error = "";
        addonLimit.forEach((ele, ind) => {
          if (
            ele.min != null &&
            ele.addons_length > 0 &&
            ele.addons_qty < ele.min
          ) {
            flag = false;
            if (ele.min != null) {
              error = `${t("custom-msg.Addons-quantity-required")} (${
                ele.min
              })`;
              setErrorStatus(true);
              setTimeout(() => {
                setErrorStatus(false);
              }, 2000);

              const id = ele.id;
              const yOffset = -10;
              const element = document.getElementById(id);
              const y =
                element.getBoundingClientRect().top +
                window.pageYOffset +
                yOffset;

              window.scrollTo({ top: y, behavior: "smooth" });
            }

            return;
          }
        });
        if (flag) {
          var arr = [];
          addons.forEach((addonItem, index) => {
            if (addonItem.check_status) {
              let data = {
                ...addonItem,
                product_addon: {
                  addon: addonItem.addon,
                  description: addonItem.description,
                },
              };
              arr.push(data);
            }
          });

          setAddOnsCart(arr);
          if (item.quantity <= 0 && item.quantity != null) {
            appDispatch({
              type: "SHOW_ALERT",
              alertType: "error",
              message: `${t("custom-msg.out-of-stock")}`,
            });
            return;
          }
          var localcart = [...appState.cart];
          if (cartId) {
            for (let i = 0; i < localcart.length; i++) {
              if (localcart[i].id === cartId) {
                localcart[i].quantity = quantityValue;
                localcart[i].addons = arr;
                localcart[i].note = addNote;
              }
            }
          } else {
      let orderTypeName =  appState.hotelQrCode > 0 ? `hotel` : appState.qrPickup > 0 ? `pickUp` : appState.qrTable > 0 ? `dineIn` : `menu`
            let cart_id = uuidv4();
            var currentCart = {
              id: cart_id,
              orderType: orderTypeName,
              pGroupId: appState.productGroupId,
              product_id: item.id,
              product_type: item.type,
              maxQuantity: item.maxQuantity,
              category_id: item.category.id,
              prod_title: item.name,
              prod_slug: item.slug,
              prod_desc: item?.description,
              quantity: quantityValue,
              price: item.price - item.discount,
              discount: item.discount,
              price_after_discount: item.price - item.discount,
              image: item.image != null ? item.image : noImg,
              category_name: item.category_name,
              redirect_url: appState.storeInfo.progressive
                ? `${slug}/${islug}?cart_id=${cart_id}`
                : `${slug}/${islug}?parent=${parent_id}&prodid=${item.id}&cart_id=${cart_id}&`,
              addons: arr,
              addons_limit: addonLimit,
              sum_addons: addOnsTotal,
              overAllSum: Number(((item.price - item.discount) * quantityValue) + (addOnsTotal *quantityValue)),
              note:
                addNote != ""
                  ? addNote
                      .replace(/(\r\n|\n|\r)/gm, "<br>")
                      .replace(/["'()]/g, "")
                  : "",
              meta: cart_id,
              // placed_by:  1,
              product: {
                name: item.name,
                image: item.image != null ? item.image : noImg,
              },
            };
            localcart = [...localcart, currentCart];
          }

          appDispatch({
            type: "updateCart",
            cart: localcart,
          });
          handleStockQuantity(localcart, item, appDispatch, appState);
          history.push(
            appState.storeInfo.progressive
              ? `/category/${slug}`
              : `/category/${slug}?parent=${parent_id}&catid=${catId}`
          );
          appDispatch({
            type: "showAddToCartPopup",
            addToCartPopup: true,
            cartProd: item,
          });
        } else {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: error,
          });
        }
      } else {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `${t("custom-msg.scane-qrcode")}`,
        });
      }
    } else {
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: `Total Amount must be grater than 0.`,
      });
    }
  };

  const fetchProduct = async () => {
    setIsLoading(true);
    for (var i = 0; i < products.length; i++) {
      if (islug === products[i].slug) {
        var response = await products[i];
        let filterResult = "";
        let filterProductQuantity = cart.find(
          (item, i) => item.product_id == response.id
        );

        appDispatch({
          type: "setProductGroupId",
          productGroupId: searchParams.get("cart_id") ?  handleGroupId(searchParams.get("cart_id"), cart) : appState.productGroupId ??  uuidv4(),
        });

        let filterCart = cart.find(
          (item, i) => item.id == searchParams.get("cart_id")
        );

        if (filterCart == undefined) {
          filterResult = response;
        } else {
          filterResult = response;
          setQuantityValue(filterCart.quantity);
          setAddNote(filterCart.note);
        }
        if (filterResult.branches != undefined) {
          if (appState.qrBranch != null) {
            let getBranch = filterResult.branches.find(
              (item) => item?.branch?.id == appState.qrBranch
            );
            if (getBranch != [] && getBranch != undefined) {
              // console.log('got branch',getBranch);
              if (getBranch.quantity != undefined) {
                filterResult.quantity = parseInt(getBranch.quantity);
              }
              if (getBranch.price != undefined) {
                filterResult.price = getBranch.price;
              }
              if (getBranch.discount != undefined) {
                filterResult.discount = getBranch.discount;
              }
            }
          }
        }
        filterResult = {
          ...filterResult,
          maxQuantity: filterResult.quantity,
        };
        prodTotalQty = await handleStockQuantity(
          cart,
          filterResult,
          appDispatch,
          appState
        );
        if (catId) {
        }
        if (prodTotalQty > 0) {
          if (searchParams.get("cart_id")) {
            if (filterResult.quantity != null) {
              let sub = filterResult.quantity - prodTotalQty;
              filterResult.quantity = sub == 0 ? 1 : sub;
            }
          } else if (filterResult.quantity != null) {
            filterResult.quantity = filterResult.quantity - prodTotalQty;
          }
        }
        setProduct(filterResult);
        setIsLoading(false);
        setProdMeta(filterResult.meta);
        setCatId(response.category.id);
        setChildren(response.children);
        setAllAddonCategories(filterResult.product_addon_categories);
      }
    }
  };

  const handleCampaign = () => {
    let { campaigns, id } = appState.currentCategory;
    let campaign = appState.currentCategory?.length > 0 ? campaigns[0] : [];
    let arr = [...appState.campaignState];
    if (campaign?.length > 0 && campaign != undefined) {
      if (appState.campaignState.length > 0) {
        let flag = true;
        appState.campaignState.forEach((item) => {
          if (flag) {
            if (item.id == campaign.id) {
              flag = false;
            }
          }
        });
        if (flag) {
          let data = {
            id: campaign.id,
            type: "category",
            assigner_id: id,
          };
          arr.push(data);
        }
      } else {
        let data = {
          id: campaign.id,
          type: "category",
          assigner_id: id,
        };
        arr.push(data);
      }
      appDispatch({
        type: "setCampaignState",
        campaignState: arr,
      });
    }
  };

  useEffect(() => {
    if (
      products != undefined &&
      !appState.storeInfo.progressive && !searchParams.get("prodid") &&
      products != []
    ) {
      fetchProduct();
    } else {
      let cat_id = searchParams.get("catid");
      let prod_id = searchParams.get("prodid");
      setIsLoading(true);
      const res = appDispatch(handleProduct(prod_id));

      setProdId(searchParams.get("prodid"));

    }

    handleCampaign();
    // console.log(appState.orderProdQty);
  }, []);

  useEffect(() => {
 
    if (
      appState.products != undefined &&
      (!appState.storeInfo.progressive || searchParams.get("prodid")  ) &&
      appState.products != []
    ) {
      fetchProduct();
    }
  }, [appState.products]);

  useEffect(() => {
    if (allAddonCategories != null && allAddonCategories.length != 0) {
      setAllAddonsWithOld();
    }
  }, [allAddonCategories]);

  const getTotal = () => {
    var total = 0;
    addons.forEach((element) => {
      if (element.check_status) total += element.total();
    });
    setAddOnsTotal(total);
  };

  useEffect(() => {
    if (searchParams.get("cart_id")) {
      setCartId(searchParams.get("cart_id"));
    }
  }, []);

  // useEffect(() => {
  //   appDispatch({
  //     type: "SET_FEATURED_PRODUCTS",
  //     featuredProductPopupMode: false,
  //     featuredProducts:[]
  //   })
  // }, [])

  const handleChangeNote = (e) => {
    
    setAddNote(e.target.value);
  };

  // langugange change components
  const enLanguageHandle = (e) => {
    appDispatch({
      type: "LangChange",
      langValue: "en",
    });
  };

  const arLanguageHandle = (e) => {
    appDispatch({
      type: "LangChange",
      langValue: "ar",
    });
    //   i18n.changeLanguage('ar')
  };
  // lanaguage change function done

  const handleAlert = () => {
    // appDispatch({
    //   type: "SHOW_ALERT",
    //   alertType: "error",
    //   message: `${t("custom-msg.select-variation")}`,
    // });
  };

  useEffect(() => {
    if (product.featured_products != [] || product.featured_products != null) {
      let flag = true;
      product.featured_products?.forEach((ele, ind) => {
        if (flag) {
          if (ele?.quantity > 0 || ele?.quantity == null) {
            flag = false;
            setFeaturedProductsAvailable(true);
          }
        }
      });
    }
  }, [product.featured_products]);

  if (isLoading && product.length == 0) {
    return (
      <>
        <div className="row d-flex justify-content-center">
          <div className="col-md-12">
            <Skeleton height={50} />
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-7">
            <Skeleton height={300} />
          </div>
        </div>
        <div className="row m-0 mb-4 mt-3 d-flex flex-row">
          <div className="col-6  ">
            <Skeleton height={50} />
          </div>
          <div className="col-10 ">
            <Skeleton height={50} />
          </div>
          <div className="col-3 ">
            <Skeleton height={50} />
          </div>
        </div>
        <div className="col-5 ">
          <Skeleton height={50} />
        </div>
        <div className="col-10 ">
          <Skeleton height={300} />
        </div>
      </>
    );
  } else {
    return (
      <Page title="Product">
        <div id="wrapper">
          <div className="row mx-0 signleImg">
            {matches ? (
              <div className={`top_nav_singlePro_scroll`}>
                <div className="row m-0 searhIcon px-1">
                  <div className="d-flex align-items-center px-1 col-4">
                    <IconButton
                      aria-label="back"
                      component={Link}
                      to={`${
                        appState.storeInfo.progressive
                          ? `/category/${slug}?parent=${parent_id}&catid=${catId}`
                          : `/category/${slug}?parent=${parent_id}&catid=${catId}`
                      } `}
                      // to={`${product.urlLink} `}
                      className={`backBtn ${
                        appState.langValue === "ar" ? "backBtnAr" : ""
                      }`}
                    >
                      {langValue == "ar" ? (
                        <ArrowForwardIosIcon
                          style={{ padding: 0 }}
                          fontSize="inherit"
                        />
                      ) : (
                        <ArrowBackIosIcon fontSize="inherit" />
                      )}
                    </IconButton>
                  </div>

                  <div className="d-flex justify-content-end col-6 align-items-center">
                    <div>
                      {appState.deviceId != "" &&
                      appState.formData.openBill &&
                      appState.qrBranch &&
                      appState.loggedIn &&
                      !appState.hotelQrCode &&
                      !appState.payTableMode ? (
                        <IconButton
                          component={Link}
                          className="classForSvgColor mb-2"
                          to="/openOrder"
                          id="headerBtn"
                        >
                          <span className="parentRing">
                            <div className="ring-container-open-bill-single">
                              <div className="ringring"></div>
                              <div className="circle"></div>
                            </div>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M4.25 7C4.25 6.58579 4.58579 6.25 5 6.25H9C9.41421 6.25 9.75 6.58579 9.75 7C9.75 7.41421 9.41421 7.75 9 7.75H5C4.58579 7.75 4.25 7.41421 4.25 7Z" />
                              <path d="M5 10.25C4.58579 10.25 4.25 10.5858 4.25 11C4.25 11.4142 4.58579 11.75 5 11.75H11C11.4142 11.75 11.75 11.4142 11.75 11C11.75 10.5858 11.4142 10.25 11 10.25H5Z" />
                              <path d="M4.25 15C4.25 14.5858 4.58579 14.25 5 14.25H11C11.4142 14.25 11.75 14.5858 11.75 15C11.75 15.4142 11.4142 15.75 11 15.75H5C4.58579 15.75 4.25 15.4142 4.25 15Z" />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.623556 0.351317C0.854792 0.217126 1.13998 0.216174 1.37211 0.348818L4.5 2.13619L7.6279 0.348818C7.85847 0.217061 8.14153 0.217061 8.37211 0.348818L11.5 2.13619L14.6279 0.348818C14.86 0.216174 15.1452 0.217126 15.3764 0.351317C15.6077 0.485509 15.75 0.732647 15.75 1V9.25H19C19.4142 9.25 19.75 9.58579 19.75 10V17C19.75 18.5188 18.5188 19.75 17 19.75H6.94513C5.57754 19.75 4.47522 19.75 3.60825 19.6335C2.70814 19.5125 1.95027 19.2536 1.34835 18.6517C0.746434 18.0497 0.48754 17.2919 0.366524 16.3918C0.249963 15.5248 0.24998 14.4225 0.250001 13.0549V1C0.250001 0.732647 0.39232 0.485509 0.623556 0.351317ZM17 18.25C17.6904 18.25 18.25 17.6904 18.25 17V10.75H15.75V17C15.75 17.6904 16.3096 18.25 17 18.25ZM14.5499 18.25H7C5.56459 18.25 4.56347 18.2484 3.80812 18.1469C3.07435 18.0482 2.68577 17.8678 2.40901 17.591C2.13225 17.3142 1.9518 16.9257 1.85315 16.1919C1.75159 15.4365 1.75 14.4354 1.75 13V2.29238L4.1279 3.65118C4.35847 3.78294 4.64153 3.78294 4.87211 3.65118L8 1.86381L11.1279 3.65118C11.3585 3.78294 11.6415 3.78294 11.8721 3.65118L14.25 2.29239V17C14.25 17.4501 14.3581 17.875 14.5499 18.25Z"
                              />
                            </svg>
                          </span>
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </div>

                    <div>
                      {!appState.payTableMode && appState.qrBranch && (
                        <IconButton
                          id="headerBtn"
                          component={Link}
                          className="classForSvgColor mx-2"
                          to="/cart"
                        >
                          {cart.lenght !== 0 ? (
                            <Badge
                              badgeContent={appState.cart.length}
                              color="primary"
                              overlap="rectangular"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M1.50007 2.75C1.78476 2.75001 1.88363 2.75062 1.96779 2.75751C2.81868 2.82715 3.55687 3.37236 3.87364 4.16516C3.90498 4.24358 3.93463 4.3379 4.01838 4.61008L5.99316 11.0281C6.29594 12.0122 6.54213 12.8124 6.81597 13.4359C7.10167 14.0864 7.44679 14.6169 7.98784 15.0165C8.52889 15.4161 9.13745 15.59 9.84321 15.6717C10.5197 15.7501 11.3569 15.7501 12.3865 15.7501H14.581C15.6178 15.7501 16.4607 15.7501 17.1414 15.6709C17.8515 15.5883 18.4635 15.4126 19.0064 15.0087C19.5493 14.6047 19.8934 14.069 20.1766 13.4126C20.4481 12.7834 20.6902 11.9761 20.9881 10.983L21.1909 10.3072C21.5299 9.17741 21.8081 8.2499 21.918 7.50536C22.0325 6.72982 21.9907 5.99351 21.5182 5.35851C21.0458 4.7235 20.3525 4.47191 19.5768 4.35869C18.8321 4.25001 17.8638 4.25003 16.6842 4.25006L5.5 4.25006C5.49234 4.25006 5.48471 4.25018 5.47711 4.25041L5.44241 4.13763C5.37194 3.90851 5.32359 3.7513 5.26657 3.60859C4.73861 2.28727 3.5083 1.37857 2.09014 1.26251C1.93697 1.24997 1.77251 1.24998 1.53278 1.25L1.50002 1.25L0.999908 1.25006C0.585695 1.25011 0.249949 1.58594 0.25 2.00016C0.250051 2.41437 0.585878 2.75011 1.00009 2.75006L1.50007 2.75ZM7.41361 10.544L5.93855 5.75006H16.6239C17.8788 5.75006 18.7367 5.75197 19.3602 5.84297C19.9654 5.93129 20.1875 6.08276 20.3148 6.2539C20.4421 6.42504 20.5234 6.68131 20.4341 7.28634C20.3421 7.90972 20.0974 8.7319 19.7368 9.93394L19.5644 10.5086C19.2504 11.5555 19.0322 12.2787 18.7993 12.8185C18.5742 13.3402 18.3653 13.616 18.111 13.8052C17.8567 13.9944 17.5325 14.1153 16.9681 14.1809C16.3841 14.2489 15.6287 14.2501 14.5358 14.2501H12.4314C11.346 14.2501 10.596 14.2489 10.0158 14.1817C9.45511 14.1167 9.13249 13.9972 8.87899 13.8099C8.6255 13.6227 8.41633 13.3495 8.18936 12.8327C7.95451 12.298 7.73281 11.5814 7.41361 10.544Z"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M7.25 20C7.25 18.4812 8.48122 17.25 10 17.25C11.5188 17.25 12.75 18.4812 12.75 20C12.75 21.5188 11.5188 22.75 10 22.75C8.48122 22.75 7.25 21.5188 7.25 20ZM10 18.75C9.30964 18.75 8.75 19.3096 8.75 20C8.75 20.6904 9.30964 21.25 10 21.25C10.6904 21.25 11.25 20.6904 11.25 20C11.25 19.3096 10.6904 18.75 10 18.75Z"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M15.25 20C15.25 18.4812 16.4812 17.25 18 17.25C19.5188 17.25 20.75 18.4812 20.75 20C20.75 21.5188 19.5188 22.75 18 22.75C16.4812 22.75 15.25 21.5188 15.25 20ZM18 18.75C17.3096 18.75 16.75 19.3096 16.75 20C16.75 20.6904 17.3096 21.25 18 21.25C18.6904 21.25 19.25 20.6904 19.25 20C19.25 19.3096 18.6904 18.75 18 18.75Z"
                                />
                              </svg>
                            </Badge>
                          ) : (
                            ""
                          )}
                        </IconButton>
                      )}
                    </div>

                    <div>
                      {langValue == "ar" ? (
                        <IconButton
                          id="headerBtn"
                          onClick={(e) => enLanguageHandle(e)}
                        >
                          <span className={`lang-textScroll p-0 `}>EN</span>
                        </IconButton>
                      ) : (
                        <IconButton
                          id="headerBtn"
                          onClick={(e) => arLanguageHandle(e)}
                        >
                          <span
                            className={`lang-textScroll classForPositionAR p-0 `}
                          >
                            عربي{" "}
                          </span>
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`mobileProduct`} sx={{ boxShadow: 0 }}>
                <div className="row m-0 searhIcon px-1">
                  <div className="d-flex align-items-center px-1 col-4">
                    <IconButton
                      aria-label="back"
                      component={Link}
                      to={`${
                        appState.storeInfo.progressive
                          ? `/category/${slug}?parent=${parent_id}&catid=${catId}`
                          : `/category/${slug}?parent=${parent_id}&catid=${catId}`
                      } `}
                      // to={`${product.urlLink} `}
                      className={`backBtn ${
                        appState.langValue === "ar" ? "backBtnAr" : ""
                      }`}
                    >
                      {langValue == "ar" ? (
                        <ArrowForwardIosIcon
                          style={{ padding: 0 }}
                          fontSize="inherit"
                        />
                      ) : (
                        <ArrowBackIosIcon fontSize="inherit" />
                      )}
                    </IconButton>
                  </div>

                  {/* <div className="d-flex justify-content-end col-6 align-items-center">
                  <div>
                    {appState.deviceId != "" &&
                      appState.formData.openBill &&
                      appState.qrBranch &&
                      appState.loggedIn &&
                      !appState.hotelQrCode &&
                      !appState.payTableMode ? (
                      <IconButton
                        component={Link}
                        className="classForSvgColor mb-2"
                        to="/openOrder"
                        id="headerBtn"
                      >
                        <span className="parentRing">
                          <div className="ring-container-open-bill-single">
                            <div className="ringring"></div>
                            <div className="circle"></div>
                          </div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M4.25 7C4.25 6.58579 4.58579 6.25 5 6.25H9C9.41421 6.25 9.75 6.58579 9.75 7C9.75 7.41421 9.41421 7.75 9 7.75H5C4.58579 7.75 4.25 7.41421 4.25 7Z" />
                            <path d="M5 10.25C4.58579 10.25 4.25 10.5858 4.25 11C4.25 11.4142 4.58579 11.75 5 11.75H11C11.4142 11.75 11.75 11.4142 11.75 11C11.75 10.5858 11.4142 10.25 11 10.25H5Z" />
                            <path d="M4.25 15C4.25 14.5858 4.58579 14.25 5 14.25H11C11.4142 14.25 11.75 14.5858 11.75 15C11.75 15.4142 11.4142 15.75 11 15.75H5C4.58579 15.75 4.25 15.4142 4.25 15Z" />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.623556 0.351317C0.854792 0.217126 1.13998 0.216174 1.37211 0.348818L4.5 2.13619L7.6279 0.348818C7.85847 0.217061 8.14153 0.217061 8.37211 0.348818L11.5 2.13619L14.6279 0.348818C14.86 0.216174 15.1452 0.217126 15.3764 0.351317C15.6077 0.485509 15.75 0.732647 15.75 1V9.25H19C19.4142 9.25 19.75 9.58579 19.75 10V17C19.75 18.5188 18.5188 19.75 17 19.75H6.94513C5.57754 19.75 4.47522 19.75 3.60825 19.6335C2.70814 19.5125 1.95027 19.2536 1.34835 18.6517C0.746434 18.0497 0.48754 17.2919 0.366524 16.3918C0.249963 15.5248 0.24998 14.4225 0.250001 13.0549V1C0.250001 0.732647 0.39232 0.485509 0.623556 0.351317ZM17 18.25C17.6904 18.25 18.25 17.6904 18.25 17V10.75H15.75V17C15.75 17.6904 16.3096 18.25 17 18.25ZM14.5499 18.25H7C5.56459 18.25 4.56347 18.2484 3.80812 18.1469C3.07435 18.0482 2.68577 17.8678 2.40901 17.591C2.13225 17.3142 1.9518 16.9257 1.85315 16.1919C1.75159 15.4365 1.75 14.4354 1.75 13V2.29238L4.1279 3.65118C4.35847 3.78294 4.64153 3.78294 4.87211 3.65118L8 1.86381L11.1279 3.65118C11.3585 3.78294 11.6415 3.78294 11.8721 3.65118L14.25 2.29239V17C14.25 17.4501 14.3581 17.875 14.5499 18.25Z"
                            />
                          </svg>
                        </span>
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </div>

                  <div>
                    {!appState.payTableMode && appState.qrBranch && (
                      <IconButton
                        id="headerBtn"
                        component={Link}
                        className="classForSvgColor mx-2"
                        to="/cart"
                      >
                        {cart.lenght !== 0 ? (
                          <Badge
                            badgeContent={appState.cart.length}
                            color="primary"
                            overlap="rectangular"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.50007 2.75C1.78476 2.75001 1.88363 2.75062 1.96779 2.75751C2.81868 2.82715 3.55687 3.37236 3.87364 4.16516C3.90498 4.24358 3.93463 4.3379 4.01838 4.61008L5.99316 11.0281C6.29594 12.0122 6.54213 12.8124 6.81597 13.4359C7.10167 14.0864 7.44679 14.6169 7.98784 15.0165C8.52889 15.4161 9.13745 15.59 9.84321 15.6717C10.5197 15.7501 11.3569 15.7501 12.3865 15.7501H14.581C15.6178 15.7501 16.4607 15.7501 17.1414 15.6709C17.8515 15.5883 18.4635 15.4126 19.0064 15.0087C19.5493 14.6047 19.8934 14.069 20.1766 13.4126C20.4481 12.7834 20.6902 11.9761 20.9881 10.983L21.1909 10.3072C21.5299 9.17741 21.8081 8.2499 21.918 7.50536C22.0325 6.72982 21.9907 5.99351 21.5182 5.35851C21.0458 4.7235 20.3525 4.47191 19.5768 4.35869C18.8321 4.25001 17.8638 4.25003 16.6842 4.25006L5.5 4.25006C5.49234 4.25006 5.48471 4.25018 5.47711 4.25041L5.44241 4.13763C5.37194 3.90851 5.32359 3.7513 5.26657 3.60859C4.73861 2.28727 3.5083 1.37857 2.09014 1.26251C1.93697 1.24997 1.77251 1.24998 1.53278 1.25L1.50002 1.25L0.999908 1.25006C0.585695 1.25011 0.249949 1.58594 0.25 2.00016C0.250051 2.41437 0.585878 2.75011 1.00009 2.75006L1.50007 2.75ZM7.41361 10.544L5.93855 5.75006H16.6239C17.8788 5.75006 18.7367 5.75197 19.3602 5.84297C19.9654 5.93129 20.1875 6.08276 20.3148 6.2539C20.4421 6.42504 20.5234 6.68131 20.4341 7.28634C20.3421 7.90972 20.0974 8.7319 19.7368 9.93394L19.5644 10.5086C19.2504 11.5555 19.0322 12.2787 18.7993 12.8185C18.5742 13.3402 18.3653 13.616 18.111 13.8052C17.8567 13.9944 17.5325 14.1153 16.9681 14.1809C16.3841 14.2489 15.6287 14.2501 14.5358 14.2501H12.4314C11.346 14.2501 10.596 14.2489 10.0158 14.1817C9.45511 14.1167 9.13249 13.9972 8.87899 13.8099C8.6255 13.6227 8.41633 13.3495 8.18936 12.8327C7.95451 12.298 7.73281 11.5814 7.41361 10.544Z"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.25 20C7.25 18.4812 8.48122 17.25 10 17.25C11.5188 17.25 12.75 18.4812 12.75 20C12.75 21.5188 11.5188 22.75 10 22.75C8.48122 22.75 7.25 21.5188 7.25 20ZM10 18.75C9.30964 18.75 8.75 19.3096 8.75 20C8.75 20.6904 9.30964 21.25 10 21.25C10.6904 21.25 11.25 20.6904 11.25 20C11.25 19.3096 10.6904 18.75 10 18.75Z"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.25 20C15.25 18.4812 16.4812 17.25 18 17.25C19.5188 17.25 20.75 18.4812 20.75 20C20.75 21.5188 19.5188 22.75 18 22.75C16.4812 22.75 15.25 21.5188 15.25 20ZM18 18.75C17.3096 18.75 16.75 19.3096 16.75 20C16.75 20.6904 17.3096 21.25 18 21.25C18.6904 21.25 19.25 20.6904 19.25 20C19.25 19.3096 18.6904 18.75 18 18.75Z"
                              />
                            </svg>
                          </Badge>
                        ) : (
                          ""
                        )}
                      </IconButton>
                    )}
                  </div>

                  
                </div> */}
                </div>
              </div>
            )}
            {appState.storeInfo?.categoryProductImage &&
              ((product.image != null && product.image != "") ? (
                <img
                  src={`${(product.image != null && product.image != "") ? product.image : noImg}`}
                  className={`prodImage ${matches ? `mt-3` : ``}`}
                  alt=""
                />
              ) : (
                <img
                  src={`${(product.image != null && product.image != "") ? product.image : noImg}`}
                  className=" noImage mt-3"
                  alt=""
                />
              ))}
          </div>

          <main>
            <section id="single">
              <div className="row m-0 mt-4">
                <div className="col-12 p-0">
                  <div className="row m-0">
                    {product.name != null ? (
                      <div className="col-12 p-0 mt-3 ">
                        <h4 className="d-flex text-capitalize">
                          {product.name != null
                            ? get_lang(product.name, appState.langValue)
                            : "Name is not available"}
                        </h4>

                        <div className="d-flex flex-wrap">
                          {product.food_preferences != [] &&
                            product.food_preferences != undefined &&
                            product.food_preferences.map(
                              (food_preference, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={` familyPoppins my-1  ${
                                      index >= 1 ? "" : ""
                                    } ${
                                      appState.langValue === "ar"
                                        ? "d-flex justify-content-end  direction-ltr pl-1 "
                                        : "pr-1"
                                    }`}
                                  >
                                    <Chip
                                      style={{
                                        color: food_preference.color
                                          ? food_preference.color
                                          : "#0000",
                                      }}
                                      icon={
                                        food_preference.iconPath != null ? (
                                          <img src={food_preference.iconPath} />
                                        ) : (
                                          ""
                                        )
                                      }
                                      className="ChipClass"
                                      label={`${get_lang(
                                        food_preference.name,
                                        appState.langValue
                                      )}`}
                                      variant="outlined"
                                    />
                                  </div>
                                );
                              }
                            )}
                        </div>

                        {product.description != null &&
                        get_lang(product.description, appState.langValue) !=
                          null
                          ? get_lang(product.description, appState.langValue) !=
                            "N/A"
                            ? get_lang(product.description, appState.langValue)
                                .split("<br>")
                                .map((item, i) => (
                                  <p
                                    key={i}
                                    className={`d-flex m-0 ${
                                      appState.langValue === "ar"
                                        ? "text-right"
                                        : "text-left"
                                    }`}
                                  >
                                    {item}
                                  </p>
                                ))
                            : ""
                          : ""}
                      </div>
                    ) : (
                      <div className="row m-0 mb-4 mt-3 d-flex flex-row">
                        <div className="col-6  ">
                          <Skeleton height={50} />
                        </div>
                        <div className="col-10 ">
                          <Skeleton height={50} />
                        </div>
                        <div className="col-3 ">
                          <Skeleton height={50} />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row m-0 mt-5">
                    <div className="prod-single col-12 p-0">
                      {/* <div className="single-prod-qty">
                                      <span id="btn">-</span>
                                      <span style={{ padding: "30px" }}>1</span>
                                      <span id="btn">+</span>
                                  </div> */}
                      {!appState.payTableMode &&
                      appState.qrBranch &&
                      !isLoading ? (
                        <div
                          className={
                            langValue == "ar"
                              ? "d-flex flex-row-reverse justify-content-end ShadowboxIncDecAr"
                              : "d-flex justify-content-end ShadowboxIncDec"
                          }
                        >
                          {product.type == "Simple" && (
                            <React.Fragment>
                              {" "}
                              {product.quantity <= 0 &&
                              product.quantity != null ? (
                                <p
                                  style={{ color: "red", fontSize: "14px" }}
                                  className=" d-flex m-0"
                                >
                                  {t("single-product.Out-of-stock")}
                                </p>
                              ) : (
                               appState.isAddToCartEnable && <div
                                  className={
                                    langValue === "ar"
                                      ? "d-flex flex-row-reverse"
                                      : ""
                                  }
                                >
                                  <span
                                    className={`${
                                      appState.langValue === "ar"
                                        ? "d-flex flex-column justify-content-center"
                                        : ""
                                    }`}
                                  >
                                    {quantityValue > 1 ? (
                                      <IconButton
                                        id="IncDecItemQuan"
                                        size="small"
                                        className="qtyBtn"
                                        onClick={handleDecrement}
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        id="IncDecItemQuan"
                                        size="small"
                                        disabled
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                    )}
                                  </span>

                                  <span
                                    style={{
                                      fontSize: "1rem",
                                      padding: "15px",
                                    }}
                                  >
                                    {quantityValue}
                                  </span>

                                  <span
                                    className={`${
                                      appState.langValue === "ar"
                                        ? "d-flex flex-column justify-content-center"
                                        : ""
                                    }`}
                                  >
                                    {quantityValue != product.quantity ? (
                                      <IconButton
                                        id="IncDecItemQuan"
                                        size="small"
                                        className="qtyBtn"
                                        onClick={handleIncrement}
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        id="IncDecItemQuan"
                                        size="small"
                                        disabled
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    )}
                                  </span>
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      ) : (
                        <span></span>
                      )}

                      <div className="single-prod-price">
                        <h4>
                          <strong>
                            {
                              <React.Fragment>
                                {(
                                  (product.price - product.discount) *
                                  quantityValue
                                ).toFixed(3)}
                                <span className="thinBold ">
                                  &nbsp;
                                  {get_lang(
                                    appState.storeInfo.storeCurrency,
                                    appState.langValue
                                  )}
                                </span>
                              </React.Fragment>
                            }
                          </strong>{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {addonLimit.length > 0 &&
              addonLimit.map((main, i) => {
                return (
                  <>
                    {main.addons_length > 0 ? (
                      main.addons_length > 0 &&
                      main.min == 1 &&
                      main.max == 1 ? (
                        <span id={`${main.id}`} key={i}>
                          <section key={i} id="single-pro" className="row m-0">
                            <div
                              // ref={myRef}
                              id={`single-pro${main.id}`}
                              className="col-12 p-0"
                            >
                              <div className="row m-0">
                                <div className="col-12 p-0">
                                  <h5
                                    className="d-flex m-0"
                                    style={
                                      main.min != null &&
                                      main.addons_length > 0 &&
                                      main.min > main.addons_qty &&
                                      errorStatus
                                        ? { color: "red" }
                                        : { color: "black" }
                                    }
                                  >
                                    {get_lang(main.title, appState.langValue)}
                                    {main.min != null && (
                                        <span className="text-danger">*</span>
                                      )}
                                  </h5>
                                  {!appState.payTableMode && appState.qrBranch
                                    ? main.min != null && (
                                        <p
                                          className={`d-flex text-muted choosecontent p-0 m-0 mb-2 `}
                                        >
                                          {main.min == 1 &&
                                            main.max == 1 &&
                                            t(
                                              "single-product.selection-is-required"
                                            )}
                                          {main.min == 1 &&
                                            main.max == null &&
                                            t(
                                              "single-product.at-least-select "
                                            ) + main.min}
                                          {/* single-product.Out-of-stock */}
                                          {/* t("checkOut.Payment-Summary") */}
                                          {/* Min {main.min} to{" "}
                                    {` ${main.max != null
                                      ? `Max ${main.max}`
                                      : `Unlimited`
                                      } `} */}
                                        </p>
                                      )
                                    : ""}

                                  {addons.length > 0 &&
                                    addons.map((item, ind) => {
                                      return (
                                        item.category_addon_id == main.id && (
                                          <div
                                            key={ind}
                                            className="row m-0 p-0 mb-3 chooseOne-content familyManrope600 text-capitalize"
                                          >
                                            <span className=" col-md-12  m-0 p-0 d-flex align-items-center justify-content-between" onClick={handleRadio(
                                                              item.product_addon_id,
                                                              main.id,
                                                              ind
                                                            )}>
                                              <span
                                                className={` ${
                                                  main.max == main.addons_qty
                                                    ? `pointer-events-none`
                                                    : `cursor-pointer`
                                                }`}
                                                onClick={
                                                  item.maxQuantity > 0 ||
                                                  item.maxQuantity == null
                                                    ? () =>
                                                        handleCheck(
                                                          item.product_addon_id,
                                                          main.id
                                                        )
                                                    : undefined
                                                }
                                                id="prodAddonCheck"
                                              >
                                                {item.addon.name != null
                                                  ? get_lang(
                                                      item.addon.name,
                                                      appState.langValue
                                                    )
                                                  : "Name is not available"}
                                                {/* {dumyText} */}
                                              </span>

                                              <span className="d-flex align-items-center ">
                                                <span
                                                  className={`d-flex px-1 ${
                                                    main.max == main.addons_qty
                                                      ? `pointer-events-none`
                                                      : `cursor-pointer`
                                                  }`}
                                                  onClick={
                                                    item.maxQuantity > 0 ||
                                                    item.maxQuantity == null
                                                      ? handleCheck(
                                                          item.product_addon_id,
                                                          main.id
                                                        )
                                                      : undefined
                                                  }
                                                  id="prodAddonCheck"
                                                >
                                                  {item.price > 0 && (
                                                    <>
                                                      {item.price > 0 &&
                                                      item.quantity !== 0
                                                        ? parseFloat(
                                                            item.price *
                                                              item.quantity
                                                          ).toFixed(3)
                                                        : parseFloat(
                                                            item.price
                                                          ).toFixed(3)}
                                                      &nbsp;
                                                      {get_lang(
                                                        appState.storeInfo
                                                          .storeCurrency,
                                                        appState.langValue
                                                      )}
                                                    </>
                                                  )}
                                                </span>

                                                {!appState.payTableMode &&
                                                  appState.qrBranch &&
                                                  (item.maxQuantity > 0 ||
                                                  item.maxQuantity == null ? (
                                                    appState.isAddToCartEnable && <div>
                                                      <div className=" d-flex  justify-content-end  ">
                                                        <>
                                                          <Checkbox
                                                            // value={item.name}
                                                            id="prodAddonCheck"
                                                            onClick={handleRadio(
                                                              item.product_addon_id,
                                                              main.id,
                                                              ind
                                                            )}
                                                            checked={
                                                              item.check_status
                                                                ? true
                                                                : false
                                                            }
                                                            // id={item.id}
                                                            color="default"
                                                            inputProps={{
                                                              "aria-label":
                                                                "checkbox with default color",
                                                              type: "radio",
                                                            }}
                                                            icon={
                                                              <CircleUnchecked />
                                                            }
                                                            checkedIcon={
                                                              <CircleCheckedFilled />
                                                            }
                                                            name={`string`}
                                                          />
                                                        </>
                                                      </div>
                                                    </div> // ""
                                                  ) : (
                                                    <div
                                                      className={`d-flex ${
                                                        appState.langValue ===
                                                        "ar"
                                                          ? "justify-content-start flex-row-reverse"
                                                          : "justify-content-end"
                                                      }`}
                                                    >
                                                      <p
                                                        style={{
                                                          color: "red",
                                                          fontSize: "14px",
                                                        }}
                                                        className=" d-flex py-2 flex-end m-0"
                                                      >
                                                        {t(
                                                          "single-product.Out-of-stock"
                                                        )}
                                                      </p>
                                                    </div>
                                                  ))}
                                              </span>
                                            </span>
                                          </div>
                                        )
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </section>
                        </span>
                      ) : (
                        <span id={`${main.id}`} key={i}>
                          <section key={i} id="single-pro" className="row m-0">
                            <div
                              ref={myRef}
                              id={`single-pro${main.id}`}
                              className="col-12 p-0"
                            >
                              <div
                                id={`single-pro${main.id}`}
                                className="col-12 p-0"
                              >
                                <div className="row m-0">
                                  <div className="col-12 p-0">
                                    <h5
                                      className={`m-0 text-capitalize ${
                                        appState.langValue == "ar"
                                          ? "text-right"
                                          : "text-left"
                                      }`}
                                      style={
                                        main.min != null &&
                                        main.addons_length > 0 &&
                                        main.min > main.addons_qty &&
                                        errorStatus
                                          ? { color: "red" }
                                          : { color: "black" }
                                      }
                                    >
                                      {get_lang(main.title, appState.langValue)}{" "}
                                      {main.min != null && (
                                        <span className="text-danger">*</span>
                                      )}
                                    </h5>
                                    {!appState.payTableMode && appState.qrBranch
                                      ? main.min != null && (
                                          <p
                                            className={`d-flex text-muted choosecontent p-0 m-0 mb-2 `}
                                          >
                                            Select at least {main.min}{" "}
                                            {` ${
                                              main.max != null
                                                ? `up to ${main.max}`
                                                : ``
                                            } `}
                                          </p>
                                        )
                                      : ""}

                                    {addons.length > 0 &&
                                      addons.map((item, ind) => {
                                        // console.log(addons)
                                        return (
                                          item.category_addon_id == main.id && (
                                            <div
                                              key={ind}
                                              className="row m-0 p-0 mb-3 chooseOne-content familyManrope600 text-capitalize"
                                            >
                                              {/* {console.log(item.maxQuantity)} */}
                                              <span className=" col-md-12  m-0 p-0 d-flex align-items-center justify-content-between">
                                                <span
                                                  className={` ${
                                                    main.max == main.addons_qty
                                                      ? `pointer-events-none`
                                                      : `cursor-pointer`
                                                  }`}
                                                  onClick={
                                                    item.maxQuantity > 0 ||
                                                    item.maxQuantity == null
                                                      ? handleCheck(
                                                          item.product_addon_id,
                                                          main.id
                                                        )
                                                      : undefined
                                                  }
                                                  id="prodAddonCheck"
                                                >
                                                  {item.addon.name != null
                                                    ? get_lang(
                                                        item.addon.name,
                                                        appState.langValue
                                                      )
                                                    : "Name is not available"}
                                                  {/* {dumyText} */}
                                                </span>

                                                <span className="d-flex align-items-center ">
                                                  <span
                                                    className={`d-flex px-1 ${
                                                      main.max ==
                                                      main.addons_qty
                                                        ? `pointer-events-none`
                                                        : `cursor-pointer`
                                                    }`}
                                                    onClick={
                                                      item.maxQuantity > 0 ||
                                                      item.maxQuantity == null
                                                        ? handleCheck(
                                                            item.product_addon_id,
                                                            main.id
                                                          )
                                                        : undefined
                                                    }
                                                    id="prodAddonCheck"
                                                  >
                                                    {item.price > 0 && (
                                                      <span>
                                                        {item.price > 0 &&
                                                        item.quantity !== 0
                                                          ? parseFloat(
                                                              item.price *
                                                                item.quantity
                                                            ).toFixed(3)
                                                          : parseFloat(
                                                              item.price
                                                            ).toFixed(3)}
                                                        &nbsp;
                                                        {get_lang(
                                                          appState.storeInfo
                                                            .storeCurrency,
                                                          appState.langValue
                                                        )}
                                                      </span>
                                                    )}
                                                  </span>

                                                  {!appState.payTableMode &&
                                                    appState.qrBranch &&
                                                    (item.maxQuantity > 0 ||
                                                    item.maxQuantity == null ? (
                                                      item.check_status ==
                                                        true &&
                                                      item.unique == 0 ? (
                                                        <div
                                                          className={`d-flex ${
                                                            appState.langValue ===
                                                            "ar"
                                                              ? "justify-content-start flex-row-reverse"
                                                              : "justify-content-end"
                                                          }`}
                                                        >
                                                          <IconButton
                                                            size="small"
                                                            className="qtyBtn"
                                                            onClick={() => {
                                                              handleDecrementAddons(
                                                                item.product_addon_id,
                                                                main.id
                                                              );
                                                            }}
                                                            id="prodAddonIncDec"
                                                          >
                                                            <RemoveIcon />
                                                          </IconButton>

                                                          <span
                                                            style={{
                                                              fontSize: "1rem",
                                                              padding:
                                                                "10px 5px",
                                                            }}
                                                          >
                                                            {item.quantity}
                                                          </span>

                                                          {main.max ==
                                                          main.addons_qty ? (
                                                            <IconButton
                                                              size="small"
                                                              //  className="qtyBtn"
                                                              onClick={() => {
                                                                handleIncrementAddons(
                                                                  item.product_addon_id,
                                                                  main.id
                                                                );
                                                              }}
                                                              id="prodAddonIncDec"
                                                              disabled={
                                                                item.quantity ===
                                                                  item.maxQuantity ||
                                                                main.max ==
                                                                  main.addons_qty
                                                              }
                                                            >
                                                              <AddIcon />
                                                            </IconButton>
                                                          ) : (
                                                            <IconButton
                                                              size="small"
                                                              className="qtyBtn"
                                                              id="prodAddonIncDec"
                                                              onClick={() => {
                                                                handleIncrementAddons(
                                                                  item.product_addon_id,
                                                                  main.id
                                                                );
                                                              }}
                                                              disabled={
                                                                item.quantity ===
                                                                item.maxQuantity
                                                              }
                                                            >
                                                              <AddIcon />
                                                            </IconButton>
                                                          )}
                                                        </div>
                                                      ) : (
                                                    appState.isAddToCartEnable &&
                                                    <div className=" d-flex  justify-content-end  ">
                                                          <Checkbox
                                                            onChange={handleCheck(
                                                              item.product_addon_id,
                                                              main.id
                                                            )}
                                                            disabled={
                                                              main.unique == 0
                                                                ? main.max ==
                                                                  main.addons_qty
                                                                : main.max ==
                                                                    main.addons_qty &&
                                                                  item.check_status ==
                                                                    false
                                                            }
                                                            checked={
                                                              item.check_status
                                                            }
                                                            color="primary"
                                                            inputProps={{
                                                              "aria-label":
                                                                "secondary checkbox",
                                                            }}
                                                            className={` ${
                                                              main.max ==
                                                                main.addons_qty &&
                                                              item.check_status ==
                                                                false
                                                                ? "invisible"
                                                                : "visible"
                                                            }`}
                                                          />
                                                        </div>
                                                      )
                                                    ) : (
                                                      // ""
                                                      <div
                                                        className={`d-flex ${
                                                          appState.langValue ===
                                                          "ar"
                                                            ? "justify-content-start flex-row-reverse"
                                                            : "justify-content-end"
                                                        }`}
                                                      >
                                                        <p
                                                          style={{
                                                            color: "red",
                                                            fontSize: "14px",
                                                          }}
                                                          className=" d-flex py-2 flex-end m-0"
                                                        >
                                                          {t(
                                                            "single-product.Out-of-stock"
                                                          )}
                                                        </p>
                                                      </div>
                                                    ))}
                                                </span>
                                              </span>
                                            </div>
                                          )
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </span>
                      )
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            {/* (product != null || undefined) && */}
            {(product != null || undefined) &&
              product.featured_products != [] &&
              featuredProductsAvailable && (
                <FeaturedProductSlider product={product.featured_products} placedBy={2} type='product' />
              )}

            {/* <div class="letter-image">
              <div class="animated-mail">
                <div class="letter">
                  helooo
                </div>
              </div>
            </div> */}

            {(!appState.payTableMode && appState.qrBranch && appState.isAddToCartEnable) && (
              <section id="addNoteSection" className="row  m-0">
                <div className="col-12 noteCoontent mb-5">
                  <h4 className="d-flex">{t("single-product.Add-a-Note")} </h4>
                  <TextareaAutosize
                    className="textArea"
                    aria-label="minimum height"
                    width="100%"
                    minRows={5}
                    placeholder={t("single-product.Add-Your-Note-Here")}
                    value={addNote.replace(/<br>/g, "\n")}
                    onChange={handleChangeNote}
                  />
                </div>
              </section>
            )}

            {
              <section
                id="reviewOrderButtonSingleProduct"
                className="col-md-12 offset-xl-3 mr-auto col-xl-6 z-index-9999"
              >
                {!appState.payTableMode && appState.isAddToCartEnable ? (
                  <div id="process-btn" className=" col-md-12">
                    <Button
                      variant="contained"
                      className="col-12  btn-main ProcessBtn"
                      onClick={() =>
                        product.type == "Simple"
                          ? handleAddToCart(product, quantityValue)
                          : handleAlert()
                      }
                      disabled={isLoading}
                      id="prodAddToCart"
                    >
                      <strong>{t("single-product.Add-to-cart")} </strong>
                      <span>
                        {product.type == "Variable" ? (
                          <b>
                            {product.min} - {product.max}
                            <span className="thinBold ">
                              &nbsp;
                              {get_lang(
                                appState.storeInfo.storeCurrency,
                                appState.langValue
                              )}
                            </span>
                          </b>
                        ) : (
                          <b>
                            {quantityValue > 0 &&
                              (
                                (product.price - product.discount) *
                                  quantityValue +
                                addOnsTotal * quantityValue
                              ).toFixed(3)}
                            <span>
                              {" "}
                              &nbsp;
                              {get_lang(
                                appState.storeInfo.storeCurrency,
                                appState.langValue
                              )}
                            </span>
                          </b>
                        )}
                      </span>
                    </Button>
                  </div>
                ) : (
                  <>{appState.oldInvoice != null && <CheckoutBtn />} </>
                )}
              </section>
            }
          </main>
          {appState.featuredProductPopupMode && (
            <FeaturedProductPopup product={product.featured_products} placedBy={2} />
          )}
        </div>
      </Page>
    );
  }
}

export default withTranslation()(SingleProduct);
