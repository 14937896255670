/* eslint-disable */
import React from "react";
import "./promotionBanner.scss";
// localizations
import { withTranslation, useTranslation } from "react-i18next";
// import Redux
import { useDispatch, useSelector } from "react-redux";
// owl carasoul work start
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// owl carasoul work end
import Button from "react-bootstrap/Button";
import CloseIcon from "../../layout-one/js/assets/images/closeBtn.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
function PromotionBannerPopup(props) {
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    appDispatch({ type: "setPromotionBanner", promotionBanner: false });
  };

  const onLoad = (i) => {
    if (i === appState.storeInfo.banners.length - 1) {
      setOpen(true);
    }
      
  };

  const handleRedirect = (banner)=>{
    if(banner?.link){
      window.location.replace(banner?.link)
    }
  }

  return (
    appState.storeInfo.banners != [] && (
      <>
        <div
          id="mySidenav"
          className={`promotionBannerPopup col-xl-5 py-3   ${
            appState.langValue == "ar" ? "promotionBannerPopupArabic" : " "
          }`} 
        >    
          {
            <Dialog
              style={open ? {} : { display: "none" }}
              className="classToTargetPBModal"
              open={appState.promotionBanner}
              onClose={handleClose}
            >
              <DialogContent className="p-0">
                <button
                  type="button"
                  onClick={handleClose}
                  className="close pb-1"
                >
                  <span aria-hidden="true">
                    <img src={CloseIcon} alt="CloseIcon" />
                  </span>
                </button>
                <OwlCarousel
                  className="owl-theme"
                  items="1"
                  margin={0}
                  customtransition="2000ms"
                  transitionduration={500}
                  loop
                  autoplay={true}
                >
                  {" "}
                  {appState.storeInfo.banners != [] &&
                    appState.storeInfo.banners.map((banner, i) => {
                      return (
                        <div
                          className={`promotionBannerParent  ${
                            appState.langValue === "ar" ? "directionRtl" : ""
                          }`}
                          key={i}
                          onClick={()=>handleRedirect(banner)}
                        >
                          <img
                            className=" promotionBannerImage"
                            src={banner.image} 
                            alt=""
                            onLoad={() => onLoad(i)}
                            key={banner}
                          />
                        </div>
                      );
                    })}
                </OwlCarousel>
                <div className="bottomBtn px-3 py-3">
                  <Button
                    onClick={handleClose}
                    className={` py-3 px-4 w-100  justify-content-center confirmBtn ${
                      appState.langValue === "ar"
                        ? " familyGraphikArabic600"
                        : ""
                    }`}
                  >
                    {" "}
                    <span>{t("checkOut.close")}</span>{" "}
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          }
        </div>
      </>
    )
  );
}

export default withTranslation()(PromotionBannerPopup);
