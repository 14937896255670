/* eslint-disable no-unused-vars */
/* eslint-disable  */
/* eslint-disable eqeqeq */
/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import CircularIndeterminate from "../components/CircularIndeterminate";
import Product from "../components/Product";
import { Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import StateContext from "../../../store/StateContext";
import AppBar from "@material-ui/core/AppBar";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@material-ui/core/Tab";
import MiscBanner from "../components/MiscBanner";
import Page from "../components/Page";
import { withTranslation, useTranslation, Trans } from "react-i18next";

// global funtion for store
import { get_lang } from "../../../global-functions/apiGlobal";

// redux
import { useSelector, useDispatch } from "react-redux";
import AddToCartPopup from "../components/addToCartPopup/addToCartPopup";
import { handleCategory } from "../../../redux-store/action";
import CheckoutBtn from "../components/checkoutBtn/CheckoutBtn";
import Images from "../assets/images/images";
import noImg from "../../js/assets/images/no-image.svg";
import {
  filterTagDiet,
  iconsTagDiet,
} from "../../../global-functions/filterTagDiet";
import { handleMealType } from "../../../global-functions/mealType";
import { sortedByNumber } from "../../../utils";
const Products = () => {
  const { t } = useTranslation();
  var { slug } = useParams();
  var slug = slug.trim();
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [category, setCategory] = useState([]);
  const [categoryImg, setCategoryImg] = useState("");
  const [products, setProducts] = useState([]);
  const [activeVTabs, setActiveVTabs] = useState(0);
  const [value, setValue] = React.useState(0);
  const [parentValue, setParentValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState(0);
  const [catId, setCatId] = useState("");
  const [parentSlug, setParentSlug] = useState("");
  let parent_id = searchParams.get("parent");
  let cat_id = searchParams.get("catid");
  let pSlug = "";
  let initial_State = true;
  const [formData, setFormData] = useState(
    JSON.parse(localStorage.getItem("formData")) !== null
      ? JSON.parse(localStorage.getItem("formData"))
      : ""
  );
  useEffect(() => {
    appDispatch({
      type: "setProductGroupId",
      productGroupId: null,
    });
    appDispatch({
      type: "SET_FEATURED_PRODUCTS",
      featuredProducts: null,
    });
  }, [])
  

  const getIdRes = async () => {
    if (appState.storeInfo.subCategory) {
      appState.parentCategories.length > 0 &&
        appState.parentCategories.forEach((category, ind) => {
          if (category.id == parent_id) {
            setParentValue(ind);
            setParentSlug(category.slug);
            pSlug = category.slug;
            category.children.forEach((child, ind) => {
              if (child.id == cat_id) {
                setValue(ind);
              }
            });
          }
        });
      for (var i = 0; i < appState.categories.length; i++) {
        if (slug === appState.categories[i].slug) {
          var idValue = await appState.categories[i].id;
          setId(idValue);
          setCategoryImg(appState.categories[i].image);
          setActiveVTabs(idValue);
          if (appState.categories[i] == cat_id) {
                setValue(i);
              }
        }
      }
    } else {
      for (var i = 0; i < appState.categories.length; i++) {
        if (slug === appState.categories[i].slug) {
          var idValue = await appState.categories[i].id;
          setId(idValue);
          setCategoryImg(appState.categories[i].image);
          setActiveVTabs(idValue);
          setValue(i);
        }
      }
    }
  };
  const toCart = () => {
    history.push("/cart");
  };
  useEffect(() => {
    getIdRes();
  }, [slug]);

  const fetchProudct = async () => {
    // var prodArry = "";
 
    if (appState.storeInfo.progressive) {
      if (appState.categories.length !== 0) {
        for (var i = 0; i < appState.categories.length; i++) {
          if (id === appState.categories[i].id) {
            appDispatch({
              type: "setCurrentCategory",
              currentCategory: appState.categories[i],
            });
            // console.log(appState.categories[i],"category");

            var prodVal = await appState.categories[i].products;
            var prodArr = [];
            // if (appState.qrBranch != null) {
            prodVal.forEach((ele) => {
              let inActive = ele?.inactive_branches ?? [];
              let prodShow = !inActive.includes(appState.qrBranch);
              // console.log(ele.description,"produtc");
              let mealTypes = ele?.mealTypes;
              // let prodShow = true;
              if (mealTypes?.length > 0 && appState.qrBranch && prodShow) {
                prodShow = handleMealType(mealTypes, appState.qrBranch);
              }
              
              if (prodShow) {
                if (
                  ele.branches.length > 0 &&
                  prodShow &&
                  appState.qrBranch != null
                ) {
                  let getBranch = ele.branches.find(
                    (item) => item.branch.id == appState.qrBranch
                  );
                  if (getBranch != [] && getBranch != undefined) {
                    if (getBranch.quantity != undefined) {
                      ele.quantity = parseInt(getBranch.quantity);
                    }
                    if (getBranch.price != undefined) {
                      ele.price = getBranch.price;
                    }
                    if (getBranch.discount != undefined) {
                      ele.discount = getBranch.discount;
                    }
                  }
                }
                ele.order = ele?.order_list ?? null;
                prodArr.push(ele);
              }
            });
            prodArr = sortedByNumber(prodArr)  
            // }
            let arr = [];
            const tagDietResponse = await iconsTagDiet(
              arr,
              prodArr,
              Images,
              appDispatch
            );
            arr = tagDietResponse;
            if (
              appState.productTags.length > 0 ||
              appState.productDietaries.length > 0
            ) {
              const filterRes = await filterTagDiet(arr, appState, appDispatch);
              setProducts(filterRes);
            } else {
              setProducts(arr);
            }
          }
        }
      }
    } else {
      if (appState.products.length > 0) {
        setProducts(appState.products);
      }
    }
    setIsLoading(false);
  };

  const filterByReference = (arr, globalTags) => {
    let res = [];
    res = arr.filter((el) => {
      return !globalTags.find((element) => {
        return element.id === el.id;
      });
    });
    return res;
  };
  useEffect( () => {
    const getAllProducts = async  () => {
      if (id != 0) {
        if (appState.storeInfo.progressive) {
          fetchProudct();
        } else {
          if (cat_id != catId) {
            let findCategory = undefined
              // (await appState.categoryProducts) != []
              //   ? appState.categoryProducts.find(
              //       (val, ind) => val.category_id == cat_id
              //     )
              //   : undefined;
  
            if (findCategory != undefined) {
              let arr = [];
              let product = findCategory.data;
              const tagDietResponse = await iconsTagDiet(
                arr,
                product,
                Images,
                appDispatch
              );
              arr = tagDietResponse;
              if (
                appState.productTags.length > 0 ||
                appState.productDietaries.length > 0
              ) {
                const filterRes = await filterTagDiet(arr, appState, appDispatch);
                setProducts(filterRes);
              } else {
                setProducts(arr);
              }
            } else {
              // let catID = cat_id;
              const res = await appDispatch(
                handleCategory(cat_id, appState, parent_id)
              );
  
              let findCategory = res;
              let product = findCategory;
              let arr = [];
              const tagDietResponse = await iconsTagDiet(
                arr,
                product,
                Images,
                appDispatch
              );
              arr = tagDietResponse;
              if (
                appState.productTags.length > 0 ||
                appState.productDietaries.length > 0
              ) {
                const filterRes = await filterTagDiet(arr, appState, appDispatch);
                setProducts(filterRes);
              } else {
                setProducts(arr);
              }
              let data = [...appState.categoryProducts];
              let catData = {
                category_id: cat_id,
                data: res,
              };
              data.push(catData);
              appDispatch({
                type: "setCategoryProduct",
                categoryProducts: data,
              });
            }
          }
          appState.categories.forEach((cat) => {
            if (cat.id == cat_id) {
              appDispatch({
                type: "setCurrentCategory",
                currentCategory: cat,
              });
            }
          });
          setIsLoading(false);
        }
      }
    }
    getAllProducts()
  }, [id, appState.productTags]);

  // Tabs work
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // Tabs work
  const handleParentsChange = (event, newValue) => {
    setParentValue(newValue);
  };
  const handleClick = (e, Link, id) => {
    e.preventDefault();
    setIsLoading(true);
    history.push(Link);

  };

  return (
    <Page title="Category">
      <AddToCartPopup />
      <div id="wrapper">
        <div id="product-page">
          <MiscBanner image={categoryImg} urlLink={appState?.qrBranch ? `/${appState.orderQrCode}` : "/"}></MiscBanner>

          <div id="tabs_sec" className="tabsSec">
            <AppBar className="appBar" position="static">
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {appState.storeInfo.subCategory && (
                  <Tabs
                    value={parentValue}
                    onChange={handleParentsChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile={true}
                    aria-label="scrollable auto tabs example"
                    className={`pt-4 layoutFourtabs ${
                      appState.langValue === "ar" ? "direction-ltr" : ""
                    }`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}   
                  >
                    {appState.parentCategories.map((category, index) =>
                      category?.children?.length > 0 ? (
                        <Tab
                          label={
                            category.name != null
                              ? get_lang(category.name, appState.langValue)
                              : "Not Available"
                          }
                          onClick={(e) => handleClick(e, category.urlLink)}
                          key={index}
                          id={`tab_section_${index}`}
                          className="familyManrope700 text-capitalized"
                          disabled={index == parentValue}
                          icon={
                            <img
                              src={category.image ? category.image : noImg}
                            />
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        />
                      ) : (
                        ""
                      )
                    )}
                  </Tabs>
                )}
              </span>
              {/* <span  style={{ display: "flex", alignItems: "center", justifyContent: "center" }}> */}

              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  style: { backgroundColor: "#00acee" },
                }}
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
                className={`pt-2 layoutonetabs ${
                  appState.langValue === "ar" ? "direction-ltr" : ""
                }`}
              >
                {appState.categories.map((category, index) =>
                  category.has_products || category?.products?.length > 0
                    ? appState.storeInfo?.subCategory
                      ? category?.parentId == parent_id && (
                          <Tab
                            label={
                              category.name != null
                                ? get_lang(category.name, appState.langValue)
                                : "Not Available"
                            }
                            className="category_tabs text-uppercase"
                            onClick={(e) => handleClick(e, category.urlLink)}
                            key={`${index}-1`}
                            id={`tab_section_${index}`}
                            disabled={category.id == activeVTabs}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />
                        )
                      : (category.has_products ||
                          category?.products?.length) && (
                          <Tab
                            label={
                              category.name != null
                                ? get_lang(category.name, appState.langValue)
                                : "Not Available"
                            }
                            className="category_tabs text-uppercase"
                            onClick={(e) => handleClick(e, category.urlLink)}
                            key={index}
                            id={`tab_section_${index}`}
                            disabled={category.id == activeVTabs}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />
                        )
                    : ""
                )}
              </Tabs>
              {/* </span> */}
            </AppBar>
          </div>

          <section
            id="Products"
            className={`products ${products.length > 0 ? "" : "noProducts"} `}
          >
            {isLoading
              ? [0, 1, 2, 3, 4].map((a) => (
                  <div key={a} className="row m-0 mb-4 mt-3">
                    <div className="col-3 col-md-2 px-2">
                    <Skeleton variant="rect"  height={90}  style={{ borderRadius: '5px'}}/>
                    </div>
                    <div className="col-9 col-md-10 px-2">
                      <Skeleton
                        height={30}
                        style={{
                          transform: "translate(2px, 0)",
                          marginBottom: "10px",
                        }}
                      />
                      <Skeleton height={25} />
                      <Skeleton height={25} />
                    </div>
                  </div>
                ))
              : products &&
                (products.length == 0 ? (
                  <div className="text-center mt-5">
                    <h4 className="text-muted">"No product found"</h4>
                  </div>
                ) : (
                  <div className={`row m-0 ${appState.loginValue == 'ar' ? "" : ""}`}>
                    {products.map((item, ind) =>
                      products.length > 1 ? (
                        <div
                          className="prodContainer ani-boxes w-100 px-2"
                          key={ind}
                        >
                          <Product
                            item={item}
                            ind={ind}
                            parent_id={parent_id}
                            myRef=""
                          />
                          {products.length === ind + 1 ? "" : <hr />}
                        </div>
                      ) : (
                        <div
                          className="prodContainer ani-boxes w-100 px-2"
                          key={ind}
                        >
                          <Product
                            item={item}
                            ind={ind}
                            parent_id={parent_id}
                            myRef=""
                          />
                        </div>
                      )
                    )}
                  </div>
                ))}
            {/* {} */}
          </section>
          {(!appState.payTableMode && appState.isAddToCartEnable) ? (
            appState.isAddToCartEnable === null ? (
              <section id="reviewOrderButton">
                {appState.deviceId ? (
                  ""
                ) : appState.cart.length <= 0 ? (
                  ""
                ) : (
                  <div id="process-btn" className=" col-md-12 offset-xl-3 mr-auto col-xl-6">
                    <Button
                      variant="contained"
                      className="col-12  btn-main ProcessBtn text-capitalize"
                      onClick={toCart}
                    >
                      <strong>{t("product.Review-order")}</strong>

                      <span>
                        <span style={{ padding: "0 10px" }}>
                          {t("product.Total")}
                        </span>
                        <b>{appState.total}</b>
                        <span>
                          {" "}
                          &nbsp;
                          {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                        </span>
                      </span>
                    </Button>
                  </div>
                )}
              </section>
            ) : appState.cart.length <= 0 ? (
              ""
            ) : (
              <section id="reviewOrderButton">
                <div id="process-btn" className=" col-md-12 offset-xl-3 mr-auto col-xl-6">
                  <Button
                    variant="contained"
                    className="col-12  btn-main ProcessBtn text-capitalize"
                    onClick={toCart}
                  >
                    <strong>{t("product.Review-order")}</strong>

                    <span>
                      {/* <span style={{ padding: "0 10px" }}>{t("product.Total")}</span> */}
                      <b>{parseFloat(appState.total)}</b>
                      <span>
                        {" "}
                        &nbsp;
                        {get_lang(appState.storeInfo.storeCurrency, appState.langValue)}
                      </span>
                    </span>
                  </Button>
                </div>
              </section>
            )
          ) : (
            <>
              {appState.oldInvoice != null && (
                <section id="reviewOrderButton" className="offset-xl-3 mr-auto col-xl-6">
                  <CheckoutBtn  label={'Pay-the-Bill'}  handleCheckout={()=>history.push(`/checkout${appState.orderQrCode}`)}/>
                </section>
              )}
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default withTranslation()(Products);
