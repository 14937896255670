
export const roundFixed = (amount, level = 0.001, method="average") => {
    if(level == 0){
        return roundFixed(amount);
    }
    switch (method) {
        case "none":
            return roundFixed(amount);
            break;
        case "average":
            return (Math.round(amount * (1 / level))) / (1 / level);
            break;
        case "up":
            return (Math.ceil(amount * (1 / level))) / (1 / level);
            break;
        case "down":
            return (Math.floor(amount * (1 / level))) / (1 / level);
            break;
    }
}

export const parseNumber = (amount, dot = 3) => {
    return parseFloat(amount).toFixed(dot)
}