import React, { useState, useEffect, useContext } from "react";
// global Fuctions
// import { recoveryCode, updatePassword } from "../../../global-functions/authGlobal";

// redux 
import { useSelector, useDispatch } from "react-redux";

// Materila ui
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from "@material-ui/core/TextField";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import Page from "../Components/Page";
// import { useNavigate} from "react-router"
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DispatchContext from "../../../store/DispatchContext";
import axios from "axios"

// Cookies functions
import { getCookie, setCookie, erase } from "../Functions/cookieFunction";

const styles = theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    wrapper: {
        marginTop: "1.5rem",
        position: "relative"
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700]
        }
    },
    fabProgress: {
        color: green[500],
        position: "absolute",
        top: -6,
        left: -6,
        zIndex: 1
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});
const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1)
    }
}));

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ForgetDialog({ handleClose, open }) {
    const classes = useStyles();
    const appDispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [codeStatus, setCodeStatus] = useState(false)
    const [registerdEmail, setRegisterdEmail] = useState("");
    const [newPasswrod, setNewPasswrod] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const [code, setCode] = useState("")
    const [emailId, setEmailId] = useState(0)
    const [alertMessage] = useState({
        open: false,
        message: ""
    });


    // password visibility
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(true);
    };

    const handleMouseDownPassword = () => {
        setShowPassword(false)

    };
    const handleClickShowConfirm = () => {
        setShowConfirm(true);
    };

    const handleMouseDownConfirm = () => {
        setShowConfirm(false)

    };

    // Handle Register Submit
    const handleForget = async e => {
        e.preventDefault();
        setLoading(true);
        // response from recoveryCode component
        var result = await axios.post(`${process.env.REACT_APP_API_URL}/forget/password`,{
            email: registerdEmail
        })
        console.log(result)
                    if (result.data.success == true) {
                        // handleClose();
                        setCookie("user_id",result.data.data.user_id)
                        setCodeStatus(true)
                        appDispatch({
                            type: "SHOW_ALERT",
                            alertType: "success",
                            message:
                               result.data.message
                        });
                        setRegisterdEmail('')
                        setLoading(false);
                    } else {
                        appDispatch({
                            type: "SHOW_ALERT",
                            alertType: "error",
                            message: result.data.message
                        });
                        setLoading(false)
                        return;
                    }
        };


    useEffect(() => {
        setCodeStatus(false)
    }, [open])



    const handleChangePassword = async e => {
        console.log('handlechange')
        e.preventDefault();
        setLoading(true);
// response from updatePAssword component from global
        let result = await axios.post(`${process.env.REACT_APP_API_URL}/update/password`,{
            password: newPasswrod,
            id: getCookie("user_id"),
            code: code
            
            
        })
        console.log(result)``
        if (result.data.success === true) {
                                handleClose();
                                setCodeStatus(false)
                                appDispatch({
                                    type: "SHOW_ALERT",
                                    alertType: "success",
                                    message: result.data.message
                                });
                                setRegisterdEmail('');
                                setCode('');
                                setNewPasswrod('');
                                setConfirmPass('');
                                erase('user_id');
                                setLoading(false);
                            } else {
                                appDispatch({
                                    type: "SHOW_ALERT",
                                    alertType: "error",
                                    message: result.data.message
                                });
                                setLoading(false)
                                return;
                            }
                        }



    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={alertMessage.open}
                className={
                    alertMessage.alertSuccess ? "success-alert" : "danger-alert"
                }
            >
                {alertMessage.alertSuccess ? (
                    <Alert severity="success">{alertMessage.message}</Alert>
                ) : (
                    <Alert severity="error">{alertMessage.message}</Alert>
                )}
            </Snackbar>

            <Dialog
                aria-labelledby="customized-dialog-title"
                open={open}
                id="Login-Modal"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}  >
                    {/* Modal title */}
                </DialogTitle>

                {!codeStatus ? <div>
                    <div className="login-head">
                        <h2>Forget Password</h2>
                    </div>


                    <form action="" onSubmit={handleForget}>
                        <div className="login-fields ">

                            <div className="row mx-3 mb-2">
                                <TextField
                                    value={registerdEmail}
                                    onChange={e =>
                                        setRegisterdEmail(e.target.value)
                                    }
                                    name="email"
                                    type="email"
                                    variant="filled"
                                    id="input-with-icon-textfield"
                                    placeholder="Email Address *"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                position="start"
                                                className="mb-3"
                                            >
                                                <EmailIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                            <div className="row mx-3 regBtn">
                                {/* <div className="col-12 px-4"> */}
                                <Button
                                    variant="contained"
                                    className="btn-main"
                                    disabled={loading}
                                    type="submit"
                                >
                                    {loading ? <React.Fragment> <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    /> Redirecting </React.Fragment> : "Resgister"}
                                </Button>
                                {/* {loading && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )} */}
                                {/* </div> */}
                            </div>


                        </div>
                    </form>
                </div> :

                    <div>
                        <div className="login-head">
                            <h2>Change Password</h2>
                        </div>


                        <form action="" onSubmit={handleChangePassword}>
                            <div className="login-fields ">

                                <React.Fragment>

                                    <div className="row mx-3 mb-2">
                                        <TextField

                                            value={code}
                                            onChange={e =>
                                                setCode(e.target.value)
                                            }
                                            name="code"
                                            type='text'
                                            variant="filled"
                                            
                                            id="input-with-icon-textfield"
                                            placeholder="Recovery Code *"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="mb-3"
                                                    >
                                                        <VpnKeyIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="row mx-3 mb-2">
                                        <TextField

                                            value={newPasswrod}
                                            onChange={e =>
                                                setNewPasswrod(
                                                    e.target.value
                                                )
                                            }
                                            name="New-password"
                                            type={showPassword !== true ? 'password' : 'text'}
                                            variant="filled"
                                            
                                            id="input-with-icon-textfield"
                                            placeholder="New Password *"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="mb-3"
                                                    >
                                                        <LockIcon />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {!showPassword ? <VisibilityOff />
                                                                : <Visibility />
                                                            }
                                                        </IconButton>

                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    {/* <span className="mx-4 text-muted">(Must Include Numbers, Symbols, Uppercase And Lowercase Letters)</span> */}
                                    <div className="row mx-3 mb-2">
                                        <TextField

                                            value={confirmPass}
                                            onChange={e =>
                                                setConfirmPass(
                                                    e.target.value
                                                )
                                            }
                                            name="confirm-password"
                                            type={showConfirm !== true ? 'password' : 'text'}
                                            variant="filled"
                                            
                                            id="input-with-icon-textfield"
                                            placeholder="Confirm Password *"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="mb-3"
                                                    >
                                                        <LockIcon />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowConfirm}
                                                            onMouseDown={handleMouseDownConfirm}
                                                            edge="end"
                                                        >
                                                            {!showConfirm ? <VisibilityOff />
                                                                : <Visibility />
                                                            }
                                                        </IconButton>

                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="row mx-3 mb-2 regBtn">
                                        {/* <div className="col-12 px-4"> */}
                                        <Button
                                            variant="contained"
                                            className="btn-main"
                                            disabled={loading}
                                            type="submit"
                                        >
                                            {loading ? <React.Fragment> <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            /> Redirecting </React.Fragment> : "Resgister"}
                                        </Button>
                                        {/* {loading && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )} */}
                                        {/* </div> */}
                                    </div>
                                </React.Fragment>

                            </div>
                        </form>
                    </div>

                }
            </Dialog>
        </div>
    );
}
