/* eslint-disable  */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import other components
import StateContext from "../../../store/StateContext";
import DispatchContext from "../../../store/DispatchContext";
import StoreOption from "../components/StoreOption";
// import noImg from "../compnent/assets/images/no-image.svg"
import noImg from "../assets/images/no-image.svg";

// redux
import { useSelector, useDispatch } from "react-redux";
import { freezOff, freezOn } from "../../../redux-store/action";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import { get_lang } from "../../../global-functions/apiGlobal";



function StoreBusy() {
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  const [timeStatus, setTimeStatus] = useState(false)
  const [time, setTime] = useState({});
  let history = useHistory();
  const { t } = useTranslation();



  // Set the date we're counting down to

  var currentTime = new Date().getTime();


  const checkingCountDown = () => {
    let branchBusyTill = null;
    var countDownDate = "";
    // new  Date(appState.storeInfo.storeBusyTime).getTime();
    countDownDate = new Date(appState.storeInfo.storeBusyTime).getTime();


    // if(appState.qrBranch != null) {
    //  let branchFound =  appState.storeInfo.branches.find((branch) => branch.id == appState.qrBranch)
    //   if(branchFound != undefined){
    //    countDownDate = new  Date(branchFound.busy_till).getTime();
    //   }else{
    //     countDownDate = new  Date(appState.storeInfo.storeBusyTime).getTime();
    //   }
    // }

    if (appState.storeBusy === true) {
      if (appState.storeInfo.storeBusyTime != null) {

        var x = setInterval(function () {
          // Get today's date and time
          var now = new Date().getTime();
          // Find the distance between now and the count down date
          var distance = countDownDate - now;
          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);
          // Output the result in an element with id="demo"
          // console.log(document.getElementById("demo"))
          // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
          // + minutes + "m " + seconds + "s ";
          document.getElementById("day").innerHTML = days;
          document.getElementById("hours").innerHTML = hours;
          document.getElementById("min").innerHTML = minutes;
          document.getElementById("sec").innerHTML = seconds;

          // If the count down is over, write some text 
          if (distance < 0) {
            clearInterval(x);

            document.getElementById("day").innerHTML = "EXPIRED";
            document.getElementById("hours").innerHTML = "EXPIRED";
            document.getElementById("min").innerHTML = "EXPIRED";
            document.getElementById("sec").innerHTML = "EXPIRED";
            appDispatch({
              type: "SET_StoreBusy",
              storeBusy: false
            })
          }
        }, 1000);
      }

    }

  }
  // Update the count down every 1 second
  useEffect(() => {
    if (appState.storeInfo.storeBusyTime != null) {
      setTimeStatus(true)
      checkingCountDown()
    }
  }, [])


  return (
    <div>
      <div>
        <section id="info" className="bg-transparent">
          <Link className="p-0" to="/contact">
            <div className="row m-0 storeBusyParent justify-content-center align-items-center">
              <div className={` ${appState.langValue === "ar" ? "arrowArabic " : "arrowEng"
                }`} >
                <IconButton
                  onClick={() => history.goBack()}>
                  {appState.langValue === "ar" ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
                  {/* <KeyboardArrowLeftIcon /> */}


                </IconButton>
              </div>
              <div className="logoImg">
                <img
                  src={
                    appState.storeInfo.logo
                      ? `${appState.storeInfo.logo}`
                      : `${noImg}`
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="infoContent px-2">
                <h4 className="m-0">
                  <b>{get_lang(appState.storeInfo.businessName, appState.langValue)}</b>
                </h4>
              </div>

            </div>
          </Link>
        </section>
        {/* StoreOption component */}
        {/* <StoreOption /> */}

        <div id="storeBusy">
          <div className="storeBusy d-flex flex-column align-items-center">
            <img
              src={`/images/storeBusyNew.svg`}
              className="my-3"
              alt=""
            />
            <h2 className="text-center">{t("Service.Restaurant-is-Busy")}</h2>
            <h5 className="text-center mt-5">{t("Service.Oops!-Store-is-busy-right-now")}</h5>
            <p className="text-muted  text-center">
              {t("Service.The-page-you-are-looking-temporarily-unavailable")}
            </p>
            {/* <Link to="/">Back to homepage</Link>x */}
          </div>
          <p className="text-muted text-center mt-3">{t("Service.Store-will-be-open-in")} </p>
        </div>
        {timeStatus && (
          <div className="timer">
            <div className="text-ceter timerContent mb-2" style={{ textAlign: "center" }}>
              <span className="digits">
                <h1 id="day"></h1>

                <span className="text-muted" >{t("Service.Day")}</span>
              </span>

              <span className="digits">
                <h1 id="hours"></h1>

                <span className="text-muted">{t("Service.Hours")}</span>
              </span>

              <span className="digits mili-sec">
                <h1 id="min"></h1>

                <span className="text-muted">{t("Service.Minutes")}</span>
              </span>

              <span className="digits mili-sec">
                <h1 id="sec"></h1>

                <span className="text-muted" >{t("Service.Seconds")}</span>
              </span>

            </div>
          </div>
        )}
        <section id="reviewOrderButton">
          <div id="process-btn" className="row  col-xl-5 col-md-12 m-0">
            <Button
              onClick={() => history.goBack()}
              className="col-12 text-capitalize btn-main align-item-center "

            >
              {t("Service.Back")}
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
}

export default withTranslation()(StoreBusy);
