/* eslint-disable */
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";

// import AppleLogin from 'react-apple-login'
import AppleSignin from "react-apple-signin-auth";
import jwt_decode from "jwt-decode";
import { withTranslation, useTranslation } from "react-i18next";
import apple from "../assets/images/apple.png";
import appleIcon from "../assets/images/appleIcon.png";
import {
  socialLoginApi,
  socialRegisterApi,
} from "../../../global-functions/socialUser";
import GetPhoneNo from "./GetPhoneNo";
// redux 
import { useDispatch } from "react-redux";

function AppleLogin({ loginHandleclose, loading, setLoading }) {
  const { t } = useTranslation();

  const appDispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [phoneNo] = useState();
  const [noStatus, setNoStatus] = useState(false);
  const [dataRes, setDataRes] = useState({
    first_name: "",
    last_name: "",
    email: "",
    id: "",
    phone_no: "",
  });
  const [response, setResponse] = useState('')
  const getPhoneNo = () => {
    setOpen(true);
  };
  const handleClosePhoneNo = () => {
    setOpen(false);
  };
  const responseApple = async (getRes) => {
    await setResponse(getRes)
  };

  const handleChangeUserInfo = (e) => {
    setDataRes({
      ...dataRes, [e.target.name]: e.target.value
    })
  }

  useEffect(async () => {
    if (response != '') {
      console.log(response.authorization, 'responseByAppple')
      setLoading(true);
      let userData = [];
      const token = await response.authorization.id_token;
      var decodedValues = ""
      decodedValues = await jwt_decode(token);
      console.log(decodedValues.email)
      if (decodedValues != "") {
        // var res = Buffer.from(token.id_token, "base64").toString();
        userData.push({
          loginSource: "apple",
          userEmail: decodedValues.email,
          Title: "userID",
          titleValue: response.authorization.code,
        });
        setDataRes(decodedValues);
        let result = await socialLoginApi(
          userData,
          appDispatch,
          loginHandleclose,
          loading,
          setLoading,
          getPhoneNo
        );
      }

      console.log(decodedValues, "gettting");
    }
  }, [response])

  useEffect(async () => {
    if (noStatus) {
      handleClosePhoneNo();

      let userData = [];
      if (dataRes != {}) {
        if (dataRes.email) {
          userData.push({
            loginSource: "apple",
            firstName: dataRes.name.firstName,
            lastName: dataRes.name.lastName,
            userEmail: dataRes.email,
            Title: "userID",
            titleValue: dataRes.authoraizoijiot.code,
            phoneNo: dataRes.phone_no
          });
          let resg = await socialRegisterApi(
            userData,
            appDispatch,
            loginHandleclose,
            loading,
            setLoading,
            phoneNo
          );
          console.log(resg, "successfull");
        } else {
          appDispatch({
            type: "SHOW_ALERT",
            alertType: "error",
            message: "Please try another account!",
          });
          loginHandleclose();
          setLoading(false);
        }
      }
      setNoStatus(false)
    }
  }, [noStatus]);

  const onFailureSuccess = (res) => {
    console.log("onFailureSuccess", res);
  };
  return (
    <>
    <GetPhoneNo
          handleClose={handleClosePhoneNo}
          loginClose={loginHandleclose}
          open={open}
          setNoStatus={setNoStatus}
          handleChangeUserInfo={handleChangeUserInfo}
          purpose="Apple" dataRes={dataRes}
        />
      <div className="px-1 appleLoginOnLargeScreen">
        {/* <AppleLogin clientId="com.react.apple.login" redirectURI="https://redirectUrl.com" /> */}
        

        <AppleSignin
          /** Auth options passed to AppleID.auth.init() */
          authOptions={{
            /** Client ID - eg: 'com.example.com' */
            clientId: "com.tryquickpay.shop",
            scope: "email name",
            redirectURI: "https://shop.tryquickpay.com",
            state: "state",
            nonce: "nonce",
            usePopup: true,
          }}
          /** General props */
          uiType="light"
          /** className */
          className="apple-auth-btn"
          /** Allows to change the button's children, eg: for changing the button text */
          buttonExtraChildren="Signin Apple"
          /** Extra controlling props */
          /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
          onSuccess={responseApple} // default = undefined
          /** Called upon signin error */
          // onClick={componentClicked}
          onFailure={onFailureSuccess}
          // default = undefined
          /** Skips loading the apple script if true */
          // skipScript={false} // default = undefined
          /** Apple image props */
          iconProp={{ style: { marginTop: "10px" } }} // default = undefined
          /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
          render={(renderProps) => (
            <>
              <Button
                className=" appleBtn py-3 familyManrope800"
                variant="contained"
                onClick={renderProps.onClick}
              >
                <span className="px-2">
                  <img src={apple} alt="" />
                </span>
                <span>     {t("Forms.login-with-apple")}</span>
              </Button>


            </>

          )}
        // callback={responseApple}
        />
      </div>
      <div className="appleLoginOnsmallScreen ">
        {/* <AppleLogin clientId="com.react.apple.login" redirectURI="https://redirectUrl.com" /> */}
        

        <AppleSignin
          /** Auth options passed to AppleID.auth.init() */
          authOptions={{
            /** Client ID - eg: 'com.example.com' */
            clientId: "com.tryquickpay.shop",
            scope: "email name",
            redirectURI: "https://shop.tryquickpay.com",
            state: "state",
            nonce: "nonce",
            usePopup: true,
          }}
          /** General props */
          uiType="light"
          /** className */
          className="apple-auth-btn"
          /** Allows to change the button's children, eg: for changing the button text */
          buttonExtraChildren="Signin Apple"
          /** Extra controlling props */
          /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
          onSuccess={responseApple} // default = undefined
          /** Called upon signin error */
          // onClick={componentClicked}
          onFailure={onFailureSuccess}
          // default = undefined
          /** Skips loading the apple script if true */
          // skipScript={false} // default = undefined
          /** Apple image props */
          iconProp={{ style: { marginTop: "10px" } }} // default = undefined
          /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
          render={(renderProps) => (


              <Button
                className="socialButtonAuthModal"
                onClick={renderProps.onClick}

              >
                <img className="img-fluid" src="https://imageio.forbes.com/specials-images/imageserve/5c13d14731358e5b4339c564/0x0.jpg?format=jpg&crop=416,416,x0,y0,safe&fit=crop" alt="" />
              </Button>

          )}
        // callback={responseApple}
        />
      </div>
    </>

  );
}

export default withTranslation()(AppleLogin);
