/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import "./TopUpPopUp.scss";
import Button from "@mui/material/Button";
import { withTranslation, useTranslation } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import Redux
import { useSelector, useDispatch } from "react-redux";
// External Components
import CircularIndeterminate from "../../layout-one/js/components/CircularIndeterminate";
import axios from "axios";
import { getCookie } from "../../layout-one/js/Functions/cookieFunction";
import { get_lang } from "../../global-functions/apiGlobal";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import GoSellElementsTrans from "../../layout-one/js/components/GoSell";
import { handleError } from "../../redux-store/action";
function TopUpPopUp(props) {
  const {  handleClose, topUpOpen } = props;
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  console.log(appState)
  const appDispatch = useDispatch();
  const childRef = useRef();
  const [tapId, setTapId] = useState(undefined);
  const [payWith, setPayWith] = useState("");
  const [paymentValue, setPaymentValue] = useState(1.5);
  const [inpValue, setInpValue] = useState(1.5);
  const [buttonLoading, setButtonLoading] = useState(false);
  useEffect(() => {
    setInpValue(paymentValue);
  }, [paymentValue]);

  const handlePaymentValue = (e) => {
    setPaymentValue(parseFloat(e.target.value));
  };
  const handleInpValue = (e) => {
    isNaN(parseFloat(e.target.value))
      ? setInpValue(0)
      : setInpValue(parseFloat(e.target.value));
  };
  const handlePay = (e) => {
    setPayWith(e.target.value);
    // console.log(e.target.value);
  };

  const topUp = async () => {
    setButtonLoading(true);
    // console.log(tapId,"tapToken")
    const config = {
      headers: { Authorization: `Bearer ${getCookie("tokenAuth")}` },
      ContentType: 'application/json',
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customer/topup`,
        {
          amount: inpValue,
          payment_source: payWith,
          token: tapId ? tapId : null,
        },
        config
      );
      if (response.data.success === true) {
        const win = window.open(`${response.data.data.url}`, "_self");
        win.focus();
      } else {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: `${response.data.message}: ${response.data.data}`,
        });
      }
    } catch (e) {
      if (e.response != undefined) {
        appDispatch(handleError(e));
      } else {
        appDispatch({
          type: "SHOW_ALERT",
          alertType: "error",
          message: "Please check your network.",
        });
      }
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: "Login again.",
      });
      appDispatch({ type: "logout" });
      handleClose();
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonLoading(true);
    if (payWith !== undefined && payWith !== "" && payWith !== null) {
      if (payWith === "knet") {
        topUp();
      } else if (payWith === "card") {
        if (tapId !== undefined && tapId !== "" && tapId !== null) {
          topUp();
        } else {
          childRef.current.submitRequest();
        }
      }
    } else {
      appDispatch({
        type: "SHOW_ALERT",
        alertType: "error",
        message: "Please select any payment method.",
      });
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    if (tapId !== "" && tapId !== undefined) {
      topUp();
    }
    // console.log(tapId);
  }, [tapId]);

  useEffect(() => {
    setButtonLoading(false);
  }, [handleClose]);




  return (
    <>
 <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
            open={topUpOpen}
         / >
          

      <div id="mySidenav" className={` px-3 TopUpPopUpl1   ${appState.langValue === "ar" ? "TopUpPopUpl1Arabic" : " "
        }`}>
        {appState.freezScreen && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
            open={appState.freezScreen}
          >
            <CircularIndeterminate />
          </Backdrop>
        )}
        <a href="javascript:void(0)" classname="closebtn" onclick="closeNav()">&times;</a>


        <div
          className={`${appState.langValue === "ar" ? "directionRtl" : ""}`}
          id="TopUpPopUpMainl1"

        >
          <IconButton className="ml-auto my-3 d-flex" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        
            <div className={`${buttonLoading ? "disableClass" : ""} `}>
              <div className="row mx-2 popUp-content">
                <h3 className={`${appState.langValue === "ar"
                    ? "text-right"
                    : "text-left"
                    }`}>
                  {" "}
                  <strong> {t("checkOut.balance")}</strong>{" "}
                </h3>
                {appState.loggedIn ? (
                  <h4 className={`${appState.langValue === "ar"
                  ? "text-right"
                  : "text-left"
                  }`}>
                    {appState.userInfo
                      ? appState.userInfo.amount
                      : "0"}{" "}
                    {t(`${appState.storeInfo.currencySymbol}`)}
                  </h4>
                ) : (
                  <h4>0{t(`${appState.storeInfo.currencySymbol}`)}</h4>
                )}
                <div className="row m-0 amount-head ">
                  <h5 className={`${appState.langValue === "ar"
                    ? "text-right"
                    : "text-left"
                    }`}> {t("checkOut.amount")}</h5>

                  <div className="popUp-amount p-0">
                    <OutlinedInput
                      id="standard-adornment-weight"
                      type="number"
                      // value={currentAmount}
                      // onChange={(e)=> {
                      //   setCurrentAmount(e.target.value)
                      // }}
                      style={{ textAlign: "center" }}
                      onChange={handleInpValue}
                      value={inpValue}
                      className="border-0 w-100 text-center amount-field hello"
                      endAdornment={
                        <InputAdornment position="end" className="text-muted">
                          {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}
                        </InputAdornment>
                      }
                    />
                  </div>

                  <p className={` py-2 m-0 text-muted ${appState.langValue === "ar"
                    ? "text-right"
                    : "text-left"
                    }`} >
                  {t("checkOut.Or-quickly-select-amount")}
                  </p>
                  <div className="selectPayment">
                    <div
                      id="menuContainer"
                      role="group"
                      className="row m-0 menuContainer btn-group selectPaymentRow"
                      onChange={(e) => handlePaymentValue(e)}
                    >
                      <div className="d-flex">
                        <div className="col-md-3 col-6">
                          <div className="paymentContainer">
                            <input
                              type="radio"
                              value="10"
                              name="selectPayment"
                              id="paymentTwenty"
                            />
                            <label htmlFor="paymentTwenty">
                              <span>
                                <strong>10 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</strong>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className="paymentContainer">
                            <input
                              type="radio"
                              value="20"
                              name="selectPayment"
                              id="paymentThirty"
                            />
                            <label htmlFor="paymentThirty">
                              <span>
                                <strong className="payment">20 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</strong>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className="paymentContainer">
                            <input
                              type="radio"
                              value="30"
                              name="selectPayment"
                              id="paymentFourty"
                            />
                            <label htmlFor="paymentFourty">
                              <span>
                                <strong>30 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</strong>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className="paymentContainer">
                            <input
                              type="radio"
                              value="40"
                              name="selectPayment"
                              id="paymentFifty"
                            />
                            <label htmlFor="paymentFifty">
                              <span>
                                <strong>40 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</strong>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className="paymentContainer">
                            <input
                              type="radio"
                              value="50"
                              name="selectPayment"
                              id="paymentSixty"
                            />
                            <label htmlFor="paymentSixty">
                              <span>
                                <strong>50 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</strong>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className="paymentContainer">
                            <input
                              type="radio"
                              value="60"
                              name="selectPayment"
                              id="paymentSeventy"
                            />
                            <label htmlFor="paymentSeventy">
                              <span>
                                <strong>60 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</strong>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section
                id="paySelection"
                onChange={handlePay}
                className="topUpPayments"
              >
                <div className="mt-3 paymentContainer p-0">
                  <div>
                    <input
                      type="radio"
                      checked={payWith === "knet"}
                      id="payKnet"
                      value="knet"
                      name="payWith"
                      defaultChecked
                    />

                    <label htmlFor="payKnet">
                      <div className="row m-0 align-items-center">
                        <span className="pl-3">
                          <img
                            className="img-pro"
                            width="30"
                            src={`/images/Knet.png`}
                            alt=""
                          />
                        </span>
                        <span className="pl-3">
                          <h4 className="mt-2">Knet</h4>
                          {/* <h6> 15 {get_lang(appState.storeInfo.currencySymbol, appState.langValue)}</h6> */}
                        </span>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="mt-3 mb-5 paymentContainer  p-0">
                  <div>
                    <input
                      type="radio"
                      checked={payWith === "card"}
                      value="card"
                      name="payWith"
                      id="payMaster"
                    />
                    <label htmlFor="payMaster">
                      <div className="row m-0 align-items-center">
                        <span className="pl-3">
                         
                             <img
                              className="img-pro"
                              width="35"
                              src={`/images/credit-card.png`}
                              alt=""
                            />
                        </span>
                        <span className="pl-3">
                          <h4 className="mt-2">Credit Card</h4>
                        </span>
                      </div>
                    </label>
                  </div>
                </div>

                {payWith === "card" ? (
                  <div className="col-12">
                    <GoSellElementsTrans
                      ref={childRef}
                      state={appState}
                        inpValue={inpValue}
                      setTapId={setTapId}
                      payWithValue={payWith}
                      setButtonLoading={setButtonLoading}
                    />
                  </div>
                ) : (
                  ""
                )}
              </section>
              <section id="process-btn" className="row m-0">
                <Button
                  className="col-12  btn-main align-item-center justify-content-center"
                  disabled={buttonLoading}
                  // type="submit"
                  onClick={handleSubmit}
                >
                  {buttonLoading ? "Redirecting" : t("checkOut.Confirm-and-pay")}
                </Button>
              </section>
            </div>
        </div>

      </div>
    </>


  );
}



export default withTranslation()(TopUpPopUp);