import React, { useState, useEffect } from "react";
import "./FeaturedProduct2.scss";
import { useSelector, useDispatch } from "react-redux";
// for Localization
import { withTranslation, useTranslation } from "react-i18next";
// owl carasoul work start
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// owl carasoul work end
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Chip from "@mui/material/Chip";
import { AppBar, Button } from "@material-ui/core";
// axios for api
import axios from "axios";
// material ui
import Backdrop from "@mui/material/Backdrop";
import { Skeleton } from "@material-ui/lab";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import noImg from "../../../assets/images/no-image.svg";
import Tabs from "@mui/material/Tabs";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tab from "@mui/material/Tab";
import AddIcon from "@mui/icons-material/Add";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { useFeaturedProductAddToCart } from "../../../hooks/useFeaturedProductAddToCart";
import {
  freezOff,
  freezOn,
  handleError,
  handleFeaturedProduct,
} from "../../../../../redux-store/action";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Badge } from "@material-ui/core";
import { get_lang } from "../../../../../global-functions/apiGlobal";
import { IconButton } from "@mui/material";
import AskDailog from "./AskDailog";
import { handleOrdersPayload } from "../../../../../global-functions/addToCartGlobal";
import { handleStoreDiscount } from "../../../Pages/Checkout";
import { v4 as uuidv4 } from "uuid";

const FeaturedProduct2 = (props) => {
  const { t } = useTranslation();
  let prodTotalQty = 0;
  const {
    featuredProduct,
    product,
    placedBy,
    type,
    addOpenBill
  } = props;
  const appState = useSelector((state) => state);
  const appDispatch = useDispatch();
  let history = useHistory();
  const [quantityValue, setQuantityValue] = useState(1);
  const [checkCart, setCheckCart] = useState(false);
  const [addons, setAddons] = useState([]);
  const [addOnsTotal, setAddOnsTotal] = useState(0);
  const [addonLimit, setAddonLimit] = useState([]);
  const [errorStatus, setErrorStatus] = useState(false);
  const [newOrder, setNewOrder] = useState([])
  const { handleAddToCart } = useFeaturedProductAddToCart(
    featuredProduct,
    product,
    featuredProduct.quantity,
    setErrorStatus,
    placedBy
  );
  const [stock, setStock] = useState(null);

  ///// handleFunctions:
  const getFeaturedProduct = (id) => {
    appDispatch(freezOn());
    const res = appDispatch(handleFeaturedProduct(id));
    appDispatch(freezOff());
  };

  ///// useffects


  useEffect(() => {
    if (
      appState.featuredProducts != null &&
      appState.featuredProducts.length > 0
    ) {
      appDispatch({
        type: "SET_FEATURED_PRODUCTS",
        featuredProductPopupMode: true,
      });
   
    }
  }, [appState.featuredProducts]);

  const handleClick = (id) => {
    let cart_id = uuidv4();

    var currentCart = [{
      placed_by: 2,
      id: cart_id,
      product_id: featuredProduct.id,
      product_type: featuredProduct.type,
      category_id: featuredProduct.category.id,
      quantity: quantityValue,
      price: featuredProduct.price - featuredProduct.discount,
      discount: featuredProduct.discount,
      price_after_discount: featuredProduct.price - featuredProduct.discount,
      image: featuredProduct.image != null ? featuredProduct.image : noImg,
      meta: cart_id,
      placed_by:  2,
      addons:[],
      product: {
        name: featuredProduct.name,
        image: featuredProduct.image != null ? featuredProduct.image : noImg,
      },
    }];
   
    appDispatch({
      type: "setConfirmPopup",
      confirmPopup: true,
      confirmOrder: currentCart
    });
  };


  return (
    <>
      {featuredProduct != null && (
        <div className=" d-flex " id="ProductsFeatured2">
          <div className="d-flex align-items-center">
            <img
              src={
                featuredProduct?.image
                  ? `${featuredProduct?.image}`
                  : `${noImg}`
              }
              alt=""
              className="orderImage"
            />
            <div className="d-flex flex-column">
              <h4
                className={`  text-capitalized catName familySora400 mt-2 px-2 ${
                  appState.langValue === "ar" ? "text-right" : "text-left"
                } `}
              >
                {`${
                  featuredProduct?.name != null
                    ? get_lang(featuredProduct?.name, appState.langValue)
                    : "Not Available"
                }`}
              </h4>{" "}
              <h6
                className={` textOver text-capitalized priceAmoutn familySora400 mt-1 px-2  ${
                  appState.langValue === "ar" ? "text-right" : "text-left"
                } `}
              >
                {" "}
                {(
                  Number(featuredProduct.price) -
                  Number(featuredProduct.discount)
                ).toFixed(3)}{" "}
{get_lang(appState.storeInfo.storeCurrency, appState.langValue)}              </h6>
            </div>
          </div>

          <div className="">
            {" "}
            <IconButton onClick={()=>handleClick(featuredProduct.id)}>
              <AddIcon className=" " />
            </IconButton>{" "}
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(FeaturedProduct2);
