/* eslint-disable */
import React, { useEffect, useState, useReducer ,useCallback} from "react";
import "./applePayButton.scss";
import { useHistory } from "react-router-dom";

// material ui
import Button from '@mui/material/Button';

// localizations
import { withTranslation, useTranslation, Trans } from "react-i18next";
// redux
import { useSelector, useDispatch } from 'react-redux';

import appleIcon from "../../assets/images/applebtn.svg"
import { ApplePayButton } from "react-apple-pay-button";




function ApplePayBtn({handlCheckoutWithApplePay}) {
  const history = useHistory();
  const appDispatch = useDispatch();
  const { t } = useTranslation();
  const appState = useSelector((state) => state);
  const onRequestApplePay = useCallback(() => /* TODO */ []);







  return (
    <>
      <ApplePayButton id="applePayButtonl1"
      //  className="py-2"
        onClick={handlCheckoutWithApplePay} >
      <span className="px-2">{t("checkOut.pay-with-apple")}</span> 
    </ApplePayButton>

    </>


  );
}



export default withTranslation()(ApplePayBtn);