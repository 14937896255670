/* eslint-disable no-useless-escape */
/* eslint-disable  */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// React Components
/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";

// Materila ui
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TextField from "@material-ui/core/TextField";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
// import Page from "../Components/Page";
// import { useNavigate} from "react-router"
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DispatchContext from "../../../store/DispatchContext";
import axios from "axios";

// Cookies functions
import { getCookie, setCookie, erase } from "../Functions/cookieFunction";
// React Redux
import { useSelector, useDispatch } from "react-redux";
const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    marginTop: "1.5rem",
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function GetPhoneNo({
  handleClose,
  open,
  setNoStatus,
  handleChangeUserInfo,
  purpose,
  dataRes
}) {
  const classes = useStyles();
  const appDispatch = useDispatch();

  // Handle  Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setNoStatus(true)
    handleClose()
  };

  
  return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        id="Login-Modal"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {/* Modal title */}
        </DialogTitle>

        <div className="login-head">
          <h2>User Info</h2>
        </div>
        {purpose = "Facebook" ? 
        
        <form action="" onSubmit={handleSubmit}>
          <div className="login-fields ">
            <React.Fragment>
              <div className="row mx-3 mb-2">
                <TextField
                  inputProps={{ maxLength: 8, inputMode: "numeric"}}                 
                  type="number"
                  required
                  className="w-100 "
                  onChange={handleChangeUserInfo}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 8);
                  }}
                  name = "phone_no"
                  value={dataRes ? dataRes.phone_no :""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="">
                        <div className="d-flex mt-2">
                          {/* <EmailIcon className={`${classes.greyColor}`} /> */}
                          <img
                            src="/images/kuiwatFlag.png"
                            alt="kuwaitFlag"
                            height="25px"
                          />

                          <p className="ml-1  pt-1">+965</p>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="row mx-3 mb-2">
                <TextField
                  type="text"
                  required
                  className="w-100 "
                  onChange={handleChangeUserInfo}
                  name = "first_name"
                  value={dataRes ? dataRes.first_name :""}
                  label="First Name"
                />
              </div>
              <div className="row mx-3 mb-2">
                <TextField
                  type="text"
                  required
                  className="w-100 "
                  onChange={handleChangeUserInfo}
                  name = "last_name"
                  value={dataRes ? dataRes.last_name :""}
                  label="Last Name"
                />
              </div>
              <div className="row mx-3 mb-2">
                <TextField
                  type="email"
                  required
                  className="w-100 "
                  onChange={handleChangeUserInfo}
                  name = "email"
                  value={dataRes ? dataRes.email :""}
                  label="Email"
                />
              </div>
              
              <div className="row mx-3 mb-2 regBtn">
                <Button
                  variant="contained"
                  className="btn-main"
                  type="submit"
                >
                  {"Done"}
                </Button>
              </div>
            </React.Fragment>
          </div>
        </form>
        : <form action="" onSubmit={handleSubmit}>
          <div className="login-fields ">
            <React.Fragment>
              <div className="row mx-3 mb-2">
                <TextField
                  inputProps={{ maxLength: 8, inputMode: "numeric"}}                 
                  type="number"
                  required
                  className="w-100 "
                  onChange={handleChangeUserInfo}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 8);
                  }}
                  name = "phone_no"
                  value={dataRes ? dataRes.phone_no :""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="">
                        <div className="d-flex mt-2">
                          {/* <EmailIcon className={`${classes.greyColor}`} /> */}
                          <img
                            src="/images/kuiwatFlag.png"
                            alt="kuwaitFlag"
                            height="25px"
                          />

                          <p className="ml-1  pt-1">+965</p>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="row mx-3 mb-2 regBtn">
                <Button
                  variant="contained"
                  className="btn-main"
                  type="submit"
                >
                  {"Done"}
                </Button>
              </div>
            </React.Fragment>
          </div>
        </form>}
        
      </Dialog>
)
}
