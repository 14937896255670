import axios from "axios"
// Work for product Category with api products_categories/categories-poducts/
export const categoryProduct = async (id) => {
    try {
        const response = await axios.get(

            `api.enabill.dev/products_categories/categories-poducts/${id}`,{
                headers: {
                  ContentType: 'application/json',
                }
              }
        );
        return {
            category: response.data.category,
            products: response.data.category.products
        }
    } catch (err) {
        console.log(err)
    }
}

export const get_lang = (value, langValue) => { 
    var lang = langValue;
    var result = value.en;
    switch (lang) {
        case "en":
            result = value.en;
            break;
        case "ar":
            // result = value.en;
            result = value.ar ? value.ar : value.en;
            break;
        default:
            result = value.en;
            break;
    }

    return result;
}

    export const get_lang_status = (value, langValue) => { 
        var lang = langValue;
        var result = value.en;
        switch (lang) {
            case "en":
                result = value.en ? true : false;
                break;
            case "ar":
                // result = value.en;
                result = value.ar ? true : false;
                break;
            default:
                result = true;
                break;
        }
    
        return result;
    } 


export const get_lang_search = (value, langValue) => {
    var lang = langValue;
    var result = value.en;

    switch (lang) {
        case "en":
            result = value.en;
            break;
        case "ar":
            result = value.en;
            // result = value.ar ? value.ar : value.en;
            break;
        default:
            result = value.en;
            break;
    }

    return result;
}
// work for show product with api showViaSlug
export const showViaSlug = async (slug) => {
    try {
        const response = await axios.get(
            `api.enabill.dev/products/showViaSlug/${slug}`,{
                headers: {
                  ContentType: 'application/json',
                }
              }
        );
        return {
            product: response.data.data,
            allAddons: response.data.data.addons
        }
    } catch (error) {
        console.log(error);
    }
}

// work for store information and visible fields
export const storeInfoRes = async (sessionID) => {
    try {
        const stResponse = await axios.get(

            `api.enabill.dev/stores/store/information/${sessionID}`,{
                headers: {
                  ContentType: 'application/json',
                }
              }
        );
        // console.log("store Information ", stResponse)
        return stResponse.data.data
    } catch (e) {
        console.log(e);
    }

}

// work for visible fields
export const visibleFieldsRes = async (sessionID) => {
    try {
        const visibleFields = await axios.get(

            `api.enabill.dev/store_visible_fields/show/${sessionID}}`,{
                headers: {
                  ContentType: 'application/json',
                }
              }
        );
        return visibleFields.data.data

    } catch (e) {
        console.log(e)
    }

}

// work for  delivery mode
export const serviceModeRes = async (sessionID) => {
    try {
        const serviceModeResponse = await axios.get(

            `api.enabill.dev/store_delivery_modes/show/${sessionID}`,{
                headers: {
                  ContentType: 'application/json',
                }
              }
        );
        return serviceModeResponse.data.data;
    }
    catch (e) {
        console.log(e)
    }
}

// work for  areaResponse
export const areaRes = async (sessionID) => {
    try {
        const areaResponse = await axios.get(

            `api.enabill.dev/store_delivery_charges/get-RestaurentAreas/${sessionID}`,{
                headers: {
                  ContentType: 'application/json',
                }
              }
        );
        return areaResponse.data.data
    }
    catch (e) {
        console.log(e)
    }
}

// work for user-det
export const userDetRes = async (token) => {
    try {
        const response = await axios.get(
            `api.enabill.dev/user-det`, {
            headers: {
                Authorization: `Bearer ${token}`,
                ContentType: 'application/json',
            }
        }
        );
        return response.data.data

    } catch (e) {
        console.log(e);
    }
}

// work for user Information
export const userInfoRes = async (userId) => {
    try {
        const response = await axios.get(
            `api.enabill.dev/users/shows/${userId}`,{
                headers: {
                  ContentType: 'application/json',
                }
              }

        );
        return response.data.data;

    } catch (e) {
        console.log("error geting id");

    }
}

// work for store social link
export const socialLinkRes = async (sessionID) => {
    try {
        const response = await axios.get(

            `api.enabill.dev/store_social_links/show/${sessionID}`,{
                headers: {
                  ContentType: 'application/json',
                }
              }

        );
        console.log(response, "Social Link")
        return response.data.data;

    } catch (e) {
        console.log(e, "error geting Social Link");

    }
}
